import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, NavLink, Row, Button } from "react-bootstrap";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import Logo from "../../assets/img/eutogen_logo.svg";

import desk_logo from "../../assets/img/eutogen_logo.svg";
import { HassleFree } from "./HassleFree/HassleFree";
import { BrandSlider } from "./BrandSlider/BrandSlider";
import { CarEngine } from "./CarEngine/CarEngine";
import { GirlInCar } from "./GirlInCar/GirlInCar";
import { PeriodicCar } from "./PeriodicCar/PeriodicCar";
import { LandingFaq } from "./LandingFaq/LandingFaq";
import { Header } from "../../components/common/header";
import { HeroBg } from "./HeroBg/HeroBg";
import { HowItWorks } from "./HowItWorks/HowItWorks";
import { WorkshopOne } from "./WorkshopOne/WorkshopOne";
import { WorkshopTwo } from "./WorkshopTwo/WorkshopTwo";
import Footer from "../../components/common/footer";
import { useNavigate } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import UserIcon from "./../../assets/img/autogen-icon.png";
import Logo2 from "./../../assets/img/eautogen_white.svg";
import Working from "./../../assets/img/workinghour.png";
import Location from "./../../assets/img/location.png";
import Email from "./../../assets/img/email.png";
import Phone from "./../../assets/img/phoneicon.png";
import Facebook from "./../../assets/img/face.png";
import Utube from "./../../assets/img/utube.png";
import { RouteContext } from "../../Context/RouteContext";
import { TabContext } from "../../Context/TabContext";
import { useContext } from "react";
export const LandingPageBm = () => {
  const { brandslider } = useContext(RouteContext);
  const { workshopone } = useContext(RouteContext);
  const { workshoptwo } = useContext(RouteContext);
  const { hasslefree } = useContext(RouteContext);
  const { carengine } = useContext(RouteContext);
  const { periodiccar } = useContext(RouteContext);
  const { girlincar } = useContext(RouteContext);
  const { faq } = useContext(RouteContext);
  const { howitworks } = useContext(RouteContext);
  const { scrollToSection } = useContext(RouteContext);
  const { footer } = useContext(RouteContext);
  const {language} = useContext(TabContext);
  const {setlanguage} = useContext(TabContext);
  // const howitworks = useRef(null);
  // const workshopone = useRef(null);
  // const workshoptwo = useRef(null);
  // const faq = useRef(null);
  // const brandslider = useRef(null);
  // const carengine = useRef(null);
  // const girlincar = useRef(null);
  // const periodiccar = useRef(null);
  // const hasslefree = useRef(null);

  function handleBm()
{
  localStorage.setItem("language","true");
 window.location.href="/bm";
 
}
function handleEn()
{
  localStorage.setItem("language","false");
  window.location.href="/";
}

  useEffect(() => {
    var ref = localStorage.getItem("route");
    if (ref === "faq") {
      scrollToSection(faq);
    } else if (ref === "workshopone") {
      scrollToSection(workshopone);
    } else if (ref === "workshoptwo") {
      scrollToSection(workshoptwo);
    } else if (ref === "brandslider") {
      scrollToSection(brandslider);
    } else if (ref === "carengine") {
      scrollToSection(carengine);
    } else if (ref === "girlincar") {
      scrollToSection(girlincar);
    } else if (ref === "periodiccar") {
      scrollToSection(periodiccar);
    } else if (ref === "hasslefree") {
      scrollToSection(hasslefree);
    } else if (ref === "howitworks") {
      scrollToSection(howitworks);
    } else if (ref === "footer") {
      scrollToSection(footer);
    }

    localStorage.removeItem("route");
  }, []);

  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: 'en,es,ms',
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <>
      <div>
        <ToastContainer />

        <ChakraProvider>
          <header>
            <Navbar expand="lg">
              <Container fluid style={{ maxWidth: "1600px", margin: "auto" }}>
                <Navbar.Brand href="/">
                  <Image
                    src={Logo}
                 className="logo_image"
                    width="300px" height="300px"
                  />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="navbar_section" style={{width:"600px",position:"relative",left:"50px"}}>
                  <Nav.Link
                      href="/aboutus"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop:"3px"
                      }}
                      // onClick={() => scrollToSection(howitworks)}
                    >
                   ABOUT US

                    </Nav.Link>
                    <Nav.Link>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="Primary"
                          id="dropdown-basic"
                          style={{
                            fontSize: "15px",

                            fontWeight: "bold",
                            padding: "0px",
                          }}
                        >
                          AUTOGEN PLUS
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(brandslider)}
                          >
                           KELAYAKAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(carengine)}
                          >
                            PERLINDUNGAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(girlincar)}
                          >
                           NILAI PEMBAIKAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(hasslefree)}
                          >
                           PERMOHONAN MEMFAILKAN KEROSAKAN
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(periodiccar)}
                          >
                            PROGRAM KEPERLUAN PENYELENGGARAAN
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>

                    <Nav.Link
                      href="#link"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop:"3px"
                      }}
                      onClick={() => scrollToSection(howitworks)}
                    >
                     BAGAIMANA IA BERFUNGSI

                    </Nav.Link>
                    <Nav.Link
                      href="#link"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop:"3px"

                      }}
                      onClick={() => scrollToSection(faq)}
                    >
                      SOALAN-SOALAN LAZIM 
                    </Nav.Link>
                    <Nav.Link href="#link">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="Primary"
                          id="dropdown-basic"
                          style={{
                            fontSize: "15px",

                            fontWeight: "bold",
                            padding: "0px",
                          }}
                        >
                         LOKASI
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(workshopone)}
                          >
                          RAKAN KONGSI SAH UNTUK PENGAKTIFAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-1"
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(workshoptwo)}
                          >
                          BENGKEL PANEL UNTUK KERJA <br/>  PENYELENGGARAAN DAN PEMBAIKAN KERETA
                            <br />
                            WORK
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
               
  <div  className="usericonbm">
             <Menu>
                
                {localStorage.getItem("token") !== null ? (
                      <MenuButton>
                        {" "}
                        <i
                          style={{ width: "40px" }}
                          className="fa-regular fa-circle-user fa-lg"

                        ></i>
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Logout</span>
                      </MenuButton>
                    ) : (<MenuButton>
                      {" "}
                      <i
                        style={{ width: "40px" }}
                        className="fa-regular fa-circle-user fa-lg"

                      ></i>
                      <span style={{ fontSize: "18px", fontWeight: "bold" }}>Login</span>

                    </MenuButton>)}


                  {localStorage.getItem("token") !== null ? (
                    <MenuList>
                      <MenuItem>
                        <Row>
                          <Col md={5}>
                            <Image src={UserIcon} />
                          </Col>
                          <Col md={7}>
                          {
                          localStorage.getItem("dealer_name") !== "null" ?
                          <p
                            style={{
                              fontSize: "10px",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            {localStorage.getItem("dealer_name")}
                          </p>
                          :null
                        }
                          
                            {/* <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Your Plan
                            </p> */}
                            {/* <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Expiry Date:26 May
                            </p> */}
                            <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Active
                            </p>
                          </Col>
                        </Row>
                      </MenuItem>
                      {
                      localStorage.getItem("token") ?
                      <MenuItem onClick={() => navigate("/home")}>
                     Dashboard
                    </MenuItem>
                    : null
                    }
                      <MenuItem onClick={() => navigate("/userprofile")}>
                        Edit Profile
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/privacy-policy")}>
                        Privacy Policy
                      </MenuItem>
                      <MenuItem>
                        <Button
                          variant="primary"
                          style={{
                            width: "100%",
                            padding: "2px",
                            border: "2px solid #22BBB9",
                          }}
                          onClick={() => logout()}
                        >
                          Logout
                        </Button>
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList>
                      <MenuItem className="justify-content-center">
                        <Image src={UserIcon} />
                      </MenuItem>

                      <MenuItem>
                        <Button
                          variant="primary"
                          style={{
                            width: "100%",
                            padding: "2px",
                            border: "2px solid #22BBB9",
                          }}
                          onClick={() => navigate("/login")}
                        >
                          Login
                        </Button>
                      </MenuItem>
                    </MenuList>
                  )}
                </Menu>
                </div>
                {/* <div id="google_translate_element"></div> */}
                <div className="enbm">

<Link style={{fontSize:"18px",margin:"5px"}} onClick={handleEn} to=""> EN</Link>

<div style={{height:"30px",width:"3px",background:"black",margin:"5px"}} className = "vertical"></div>
<Link style={{fontSize:"18px",margin:"5px"}} onClick={handleBm} to=""> BM</Link>

</div>       
              </Container>
            </Navbar>
          </header>
        </ChakraProvider>
        <HeroBg />
        <div ref={howitworks}>
          <HowItWorks />
        </div>
        <div ref={brandslider}>
          <BrandSlider />
        </div>
        <div ref={carengine}>
          <CarEngine />
        </div>
        <div ref={girlincar}>
          <GirlInCar />
        </div>
        <div ref={hasslefree}>
          <HassleFree />
        </div>
        <div ref={periodiccar}>
          <PeriodicCar />
        </div>

        <div ref={workshopone}>
          <WorkshopOne />
        </div>
        <div ref={faq}>
          <LandingFaq />
        </div>
        <div ref={workshoptwo}>
          <WorkshopTwo />
        </div>

        <footer className="py-5" ref={footer}>
          <Container>
            <Row>
              <Col className="pb-5">
                <Image
                  src={Logo2}
                  onClick={() => (window.location.href = "/")}
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <strong className="pb-2 d-flex mobile_center">
                  EAUTOGEN CAPITAL SDN BHD (1402277-K){" "}
                </strong>

                <div style={{ paddingBottom: "20px" }}>
                  <Row>
                    <Col md={2}>
                      <Image src={Working} width="30px" height="30px" />
                    </Col>
                    <Col md={10}>
                      <p className="mobile_center" style={{ opacity: "60%" }}>
                      Isnin hingga Jumaat, tidak termasuk cuti am
                        <br />
                        9:00 pagi hingga 6:00 petang
                      </p>
                    </Col>
                  </Row>
                </div>

                <div style={{ paddingBottom: "20px" }}>
                  <Row>
                    <Col md={2}>
                      <Image src={Location} width="20px" height="20px" />
                    </Col>
                    <Col md={10}>
                      <p className="mobile_center" style={{ opacity: "60%" }}>
                        D-26-3 Menara Suezcap 1, KL Gateway, <br />
                        2, Jalan Kerinchi, 59200 Kuala Lumpur.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <Row>
                    <Col md={2}>
                      <Image src={Email} width="20px" height="20px" />
                    </Col>
                    <Col md={10} className="m-auto">
                      <p className="mobile_center" style={{ opacity: "60%" }}>
                        cs@eautogen.my
                      </p>
                    </Col>
                  </Row>
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <Row>
                    <Col md={2}>
                      <Image src={Phone} width="20px" height="20px" />
                    </Col>
                    <Col md={10}>
                      <p className="mobile_center" style={{ opacity: "60%" }}>
                        +603-7931 3663
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <ul>
                  <li>
                    <strong
                      className="pb-2  d-flex mobile_center"
                      style={{ fontSize: "18px", marginLeft: "15px" }}
                    >
                      AUTOGEN PLUS
                    </strong>
                  </li>
                  <li>
              <NavLink onClick={()=>window.location.href="/aboutus"}  className="mobile_center">
              Tentang Kami
              </NavLink>
            </li>
                 
                  <li>
                    <NavLink

                      onClick={() => scrollToSection(brandslider)}
                      className="mobile_center"
                    >
                      Tentang  AutoGen Plus
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => scrollToSection(howitworks)}
                      className="mobile_center"
                    >
                     BAGAIMANA IA BERFUNGsI
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => scrollToSection(faq)}
                      className="mobile_center"
                    >
                     Soalan-Soalan Lazim
                    </NavLink>
                  </li>
                </ul>
              </Col>
              <Col md={3}>
                <ul>
                  <li>
                    <strong
                      className="pb-2 d-flex mobile_center"
                      style={{ fontSize: "18px", marginLeft: "15px" }}
                    >
                      Locate Us
                    </strong>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => scrollToSection(workshopone)}
                      className="mobile_center"
                    >
                     Rakan Kongsi Sah Untuk Pengaktifan Program
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={() => scrollToSection(workshoptwo)}
                      className="mobile_center"
                    >
                     Bengkel Panel untuk Kerja Penyelenggaraan dan Pembaikan Kereta
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={() => scrollToSection(periodiccar)}
                      className="mobile_center"
                    ></NavLink>
                  </li>
                </ul>
              </Col>
              <Col md={2}>
                <ul>
                  <li>
                    {" "}
                    <strong
                      className="pb-2 d-flex mobile_center"
                      style={{ fontSize: "18px", marginLeft: "15px" }}
                    >
                      Quick Links
                    </strong>
                  </li>
                  <li>
                    <NavLink href="/termconditions" className="mobile_center">
                    Terma dan Syarat
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="/refund-policy" className="mobile_center">
                    Polisi Bayaran Balik{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="/privacy-policy" className="mobile_center">
                      {" "}Dasar Privasi
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="text-center d-flex justify-content-center">
              <Col
                md={2}
                className="m-0 p-0 text-center d-flex justify-content-center"
              >
                <p style={{ fontWeight: "bold" }}>Ikut Kami Di: </p>
                <Image
                  className="m-1 p-0  "
                  src={Facebook}
                  width="20px"
                  height="20px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://www.facebook.com/eAutoGen/")
                  }
                />
              </Col>
              {/* <Col md={1} className="m-0 p-0">
       
        </Col>
        <Col md={1} className="m-0 p-0">
       
        </Col>
      */}
            </Row>
          </Container>
          <div className="footer-bottom">
            <p style={{ fontSize: "12px" }}>
            HAK CIPTA © 2022 EAUTOGEN CAPITAL SDN BHD. SEMUA HAK CIPTA TERPELIHARA.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};
