import { createContext, useState, useRef } from "react";
export const RouteContext = createContext();
export const RouteContextProvider = ({ children }) => {
  const howitworks = useRef(null);
  const workshopone = useRef(null);
  const workshoptwo = useRef(null);
  const faq = useRef(null);
  const brandslider = useRef(null);
  const carengine = useRef(null);
  const girlincar = useRef(null);
  const periodiccar = useRef(null);
  const hasslefree = useRef(null);
  const footer=useRef(null);
  const header=useRef(null);
  const scrollToSection = (ref) =>
  
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });

    function handleRoute(ref) {
      localStorage.setItem("language","false");
      localStorage.setItem("route", ref);
       window.location.href="/";
    
    }


    
    

  return (
    <RouteContext.Provider
      value={{
        howitworks,
        workshopone,
        workshoptwo,
        faq,
        brandslider,
        carengine,
        girlincar,
        periodiccar,
        hasslefree, 
        footer,
        scrollToSection,
        handleRoute
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};
