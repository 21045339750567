import React from 'react'
import './AboutSection3.css'
import { Container, Row, Col } from 'react-bootstrap'

export const AboutSection3 = () => {
  return (
    <div>
         <section>
                <Container>
                    <Row className=' mt-5 pb-5'>
                        <Col md={4} >
                            <h2 className='section_title'><span>Third <br /> technology <br /> partner</span> of<br /> FMCCAM.</h2>
                        </Col>
                        <Col md={8} className='section_prg '>
                            <Row>
                                <p>The Federation of Motor & Credit Companies Associations of Malaysia (FMCCAM) -- the voice of Malaysia's used car dealers since 1979 -- is an influential and visionary association that led to the advancement of the Malaysia automotive industry to what it is today.   </p>
                            </Row>
                            <Row className='mt-3'>
                                <p>By working closely with FMCCAM, we aim to unlock new opportunities for used car dealers by boosting confidence in the used car market to bridge the gap of trust between sellers and buyers in a currently under regulated market. eAutoGen's extended warranty programs to create value-added car buy experience in many ways. For starters, via our preliminary car inspection prior to program activation, both used car dealers and used car buyers can reduce the risk associated to trading used cars and buying used cars with pre-existing issues i.e. stolen car, car beyond economy repair, etc.</p>
                            </Row>
                            
                        </Col>
                    </Row>
                </Container>
            </section>
    </div>
  )
}
