import React, { useEffect } from "react";
import Claims from "../components/common/claim";
import Footer from "../components/common/footer";
import HeaderInnner from "../components/common/headerInner";
import HowItWork from "../components/common/howItWork";
import WhatIncluded from "../components/common/included";
import QuotationPartBm from "../components/quotation/quotationpartbm";
import QuotationPart from "../components/quotation/quotationpart";
import { useContext } from "react";
import { TabContext } from "../Context/TabContext";
function Quotation() {
  const { language } = useContext(TabContext);
  const { setlanguage } = useContext(TabContext);

  // useEffect(() => {
  //   if (!localStorage.getItem("userId")) {
  //     window.location.href = "/login";
  //   }
  // }, []);
  return (
    <>
      {localStorage.getItem("language") === "true" ? (
        <QuotationPartBm />
      ) : (
        <QuotationPart />
      )}

      <Footer />
    </>
  );
}

export default Quotation;
