import React from "react";
import { Header } from "../components/common/header";
import { Container, Row, Col } from "react-bootstrap";
import "./refundpolicy.css";
import Footer from "../components/common/footer";
export const Refundpolicy = () => {
  return (
    <div>
      {
        localStorage.getItem("language") === "false" ?
          <div style={{ maxWidth: "1600px", margin: "auto" }}>
            <Header />
            <h1 className="text-center term_head pb-2 mb-3">REFUND POLICY</h1>
            <Container className="refundpolicy"  >

              <ol type="1">
                <h2><li>GENERAL</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol className="">
                    <li>
                      eAutoGen Capital Sdn. Bhd (Company No. 202101001979
                      (1402277-K)) (hereinafter “eAutoGen”, “we”, “us”, or “our”) is the
                      owner of the website known as www.eAutoGen.my (“Site”). Any
                      application for refund of the AutoGen PLUS (as defined
                      hereinafter) purchased by the Customer (as defined hereinafter)
                      shall subject to these terms and conditions (“Refund Policy”) and
                      such other procedures as may be determined by eAutoGen from time
                      to time, in its sole and absolute discretion.
                    </li>
                    <li>
                      The Customer hereby agrees to be bound by this Refund
                      Policy, Terms and Conditions, Privacy Policy and any other
                      policies and terms and conditions as may be posted on eAutoGen’s
                      Site from time to time (“Other Terms”).
                    </li>
                  </ol>
                </div>
                <h2><li>DEFINITIONS</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li>Unless otherwise defined or the context otherwise requires, any capitalised words in this Refund Policy shall have the same meaning as found in the Terms and Conditions. This Refund Policy shall form an integral part of the Terms and Conditions.</li>
                    <li>The following terms shall have the following respective meanings:
                      <ol type="a">
                        <li>“Affiliate” means in relation to a person or entity (“Person”), any person or entity which is directly or indirectly controlled by the Person, which controls the Person or which is controlled by a person or entity that controls the Person and shall include another member of the group of which such Person is a member, and the term "Affiliates" shall be construed accordingly;</li>
                        <li> “Customer”, “you”, “your” or “yours” means the person purchasing the AutoGen PLUS from us and it shall be construed accordingly;</li>
                        <li>“AutoGen PLUS” means the extended warranty programme provided by eAutoGen; and</li>
                        <li> “Vehicle” means the private use motorcar and it shall be construed accordingly.</li>
                      </ol>
                    </li>
                  </ol>
                </div>
                <h2><li> ELIGIBILITY AND REQUIREMENTS</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li>For the Vehicle to be eligible for AutoGen PLUS, the Customer shall ensure that the Vehicle meet all the following requirements prior to purchase: -
                      <ol type="a">
                        <li>  the Vehicle must not be older than 10 years, calculated from the date of registration;</li>
                        <li>has a mileage (actual and based on reading) of no more than 200,000 kilometers; and</li>
                        <li>the engine and transmission of the Vehicle are in a good and workable condition.(collectively, the “Eligibility Criteria”)</li>
                      </ol>
                    </li>
                    <li>eAutoGen will issue an order code (“Order Code”) to the Customer upon your successful purchase of AutoGen PLUS. The Customer shall thereafter make an appointment with any one of eAutoGen’s designated car inspection centres ("eAutoGen Authorised Partner”) to conduct a diagnosis test for the Vehicle (“Inspection”) within ninety (90) days from the date of receipt of the Order Code (“Inspection Period”). For the avoidance of doubt, if the Customer fails, refuses and/or neglects to undergo the Inspection within the Inspection Period, the AutoGen PLUS shall automatically be void and terminated without refund and liability to eAutoGen.  </li>
                    <li>  Inspection date and appointment shall be liaised between the Customer and eAutoGen Authorised Partner. eAutoGen Authorised Partner will prepare all inspection reports for the Vehicle (“Report”). eAutoGen shall not be held liable and responsible for any representations made by eAutoGen Authorised Partner in relation to the Vehicle. The finding of the Report issued by eAutoGen Authorised Partner shall be final and binding.</li>
                    <li> While eAutoGen will have the right to evaluate the Eligibility Criteria based on the Report, it shall remain the sole responsibility and liability of the Customer to ensure that the Vehicle meets the Eligibility Criteria.</li>
                    <li>In the event where the Vehicle does not meet the Eligibility Criteria, eAutoGen reserves its right to cancel and/or terminate the Customer’s purchase of AutoGen PLUS. eAutoGen will refund the purchase price of the AutoGen PLUS, less any costs and expenses incurred by eAutoGen, including but not limited to administrative cost, handling fee, Inspection fee and such other charges as may be imposed by any third party in respect of the AutoGen Plus.</li>
                  </ol>
                </div>
                <h2><li>REFUND AND CANCELLATION</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li> The Customer is responsible for ensuring that the Vehicle meets the Eligibility Criteria and such other terms as set out in this Refund Policy and the Other Terms before proceeding to make an order to purchase the AutoGen PLUS (“Order”). eAutoGen reserves the right to accept or reject the Order in whole or in part. Once an Order Code has been issued and sent by eAutoGen to the Customer, the Customer shall not be permitted to cancel the Order unless you receive written communication from eAutoGen permitting the cancellation of the Order. eAutoGen reserves the right to cancel the Order for any reason whatsoever, with or without providing a reason.</li>
                    <li>Each Customer’s application for refund of AutoGen PLUS will be assessed based on this Refund Policy or a case-by-case basis, at eAutoGen’s sole discretion. eAutoGen’s decision shall be final and the Customer agrees to be bound by the same.</li>
                    <li>Notwithstanding the provisions herein, if AutoGen PLUS was purchased by the Customer through a third party online platform (“Third Party Platform”), all refund and cancellation related matters shall be governed by the relevant refund policy of the Third Party Platform. We do not control the Third Party Platform and assume no responsibility for their content or policies and/or statements. You agree to waive any claim against eAutoGen with respect to the Third Party Platform.</li>
                    <li> The Customer hereby acknowledges and agrees that any monies to be refunded pursuant to a successful application shall be subject to a deduction of administrative charges as may be imposed by eAutoGen, from time to time, in its sole and absolute discretion or such other charges as may be imposed by any third party.</li>
                    <li> Refunds will be processed within fifteen (15) business days and it will be credited back to your bank account. </li>
                    <li>Notwithstanding any other provision of this Refund Policy and Other Terms, eAutoGen reserve the right to terminate the contract should it be discovered that: -
                      <ol type="a">
                        <li> the Report shows that the Vehicle does not meet the Eligibility Criteria;</li>
                        <li>failure of the Customer to undertake the Inspection within the Inspection Period;</li>
                        <li> the mileage reading of the Vehicle has been tampered, altered and/or modified;</li>
                        <li> breach of warranties and covenant stipulated under Clause 5.1 below; and/or</li>
                        <il>such other reasons that eAutoGen believes may affect the in-scope coverage of AutoGen PLUS.</il>
                      </ol>
                    </li>
                    <li>Once eAutoGen has received the full and cleared fund of the purchase price of AutoGen PLUS, the Report and Vehicle are acceptable by eAutoGen, then AutoGen PLUS will be activated. eAutoGen will send an email to the Customer upon such activation and will be accompanied by a product summary of AutoGen PLUS with respect to your Vehicle. AutoGen PLUS shall be subjected to a condition where it will not be cancellable nor refundable once it is activated.</li>
                    <li>For refund and cancellation matters, all such application or queries shall be addressed to eAutoGen’s Customer Service at (a) our Helpdesk: 03-7931 3663; or (b) our email: cs@eautogen.my</li>

                  </ol>
                </div>
                <h2><li>WARRANTIES AND COVENANTS</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li><b> The Customer hereby warrants and undertakes to eAutoGen that: -</b>
                      <ol>
                        <li> The Customer is the registered owner of the Vehicle and has good and marketable title to the Vehicle;</li>
                        <li>The Customer is able to provide evidence of a service history for the Vehicle upon request by eAutoGen;</li>
                        <li> The Vehicle is not an insurance total loss and has not sustained major accident damage, fire damage or flood damage;</li>
                        <li>The Vehicle is not a cut-and-join car; and/or</li>
                        <li>The Vehicle has no missing parts, mechanical and/or electrical defects, including exterior and/or interior.</li>
                      </ol>
                    </li>
                    <p>The foregoing warranties are in addition to any other warranty, expressed or implied, furnished by the Customer to eAutoGen, or implied by law. All warranties shall insure to the benefit of eAutoGen and its Affiliates and their respective officers, directors, employees, agents, contractors, successors and assigns. Any attempt by the Customer to limit, disclaim or restrict the foregoing warranties or any remedies of eAutoGen in the Customer’s acknowledgement, confirmation, invoice or other documentation is hereby objected to and shall be null and void. All warranties shall survive payment to and acceptance by eAutoGen.</p>
                  </ol>
                </div>
                <h2><li>GOVERNING LAW AND DISPUTE RESOLUTION</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>This Refund Policy shall be governed by and construed in accordance by the laws in force in Malaysia. Unless and otherwise required by applicable laws, any dispute, controversy, claim or difference of any kind whatsoever shall arising out of or relating to these terms against or relating to eAutoGen under these terms shall be resolved by arbitration in accordance with the rules of Asian International Arbitration Centre in Kuala Lumpur (AIAC). The number of arbitrators shall be one. The seat of arbitration shall be Kuala Lumpur and the arbitration shall apply substantive law of Malaysia. The arbitral proceedings shall be conducted in English language. You hereby give up the right to litigate (or participate in as a party or class member) all disputes against eAutoGen in court and/or tribunal before a judge or jury.</p>
                </div>
                <h2><li> LIABILITY AND INDEMNITY</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>In no event shall eAutoGen be responsible for any loss, damage, or injury (including damage to property) arising out of or related to this Refund Policy and Other Terms or caused or contributed to in any way by the use and operation of the AutoGen PLUS. Notwithstanding any other provision of this Refund Policy and Other Terms, in the event where eAutoGen is responsible for the loss or damage, the total liability of eAutoGen to the Customer or to any other party shall be only limited to the purchase price of such AutoGen PLUS.</p>
                </div>
                <h2><li>ASSIGNMENT</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>The AutoGen PLUS cannot be transferred, assigned and/or novated by the Customer to any subsequent owner of the Vehicle without prior written consent of eAutoGen.</p>
                </div>
                <h2><li>WAIVER</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>
                    No conduct by eAutoGen with respect to any default of the Customer and the performance of any obligation or condition of this Refund Policy shall be deemed to be a waiver of that or any other obligations or condition.
                  </p>
                </div>
                <h2><li>VARIATION</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>eAutoGen reserves the rights at any time, at its sole discretion, to change, amend or otherwise modify any part of this Refund Policy by posting the relevant amended and restated this Refund Policy on the Site. Such amendments are effective as of the date of posting, and the continued usage of eAutoGen’s Site signifies the acceptance of the updated or modified this Refund Policy.</p>
                </div>
              </ol>


            </Container>
            <div style={{ marginTop: "50px" }}>
              <Footer />
            </div>
          </div>
          :
          <div style={{ maxWidth: "1600px", margin: "auto" }}>
            <Header />
            <h1 className="text-center term_head pb-2 mb-3">POLISI BAYARAN BALIK
            </h1>
            <Container className="refundpolicy"  >

              <ol type="1">
                <h2><li>MAKLUMAT AM
                </li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol className="">
                    <li>
                      eAutoGen Capital Sdn. Bhd (No. Syarikat 202101001979 (1402277-K)) (kemudian daripada ini disebut sebagai “eAutoGen”, “kita” atau “kami”) merupakan pemilik dan penggendali laman web yang dikenali sebagai www.eAutoGen.my (“Laman Web”). Sebarang permohonan untuk bayaran balik AutoGen PLUS (sebagaimana yang ditakrifkan selepas ini) yang dibeli oleh Pelanggan (sebagaimana yang ditakrifkan selepas ini) adalah tertakluk kepada terma dan syarat ini (“Polisi Bayaran Balik”) dan prosedur lain yang ditentukan oleh eAutoGen dari semasa ke semasa, atas budi bicara mutlaknya.

                    </li>
                    <li>
                      Pelanggan dengan ini bersetuju untuk terikat dengan Polisi Bayaran Balik, Terma dan Syarat, Dasar Privasi and mana-mana dasar serta terma dan syarat lain yang mungkin disiarkan di Laman Web eAutoGen dari semasa ke semasa. (“Terma-terma Lain”).
                    </li>
                    <li>Polisi Bayaran Balik ini dikeluarkan dalam Bahasa Inggeris dan Bahasa Melayu. Sekiranya terdapat sebarang ketidakselarasan antara dua versi ini, versi Bahasa Inggeris akan diutamakan. </li>
                  </ol>
                </div>
                <h2><li>DEFINISI</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li>Melainkan yang ditetapkan atau konteksnya menghendaki sebaliknya, sebarang perkataan berhuruf besar dalam Polisi Bayaran Balik ini akan mempunyai makna seperti yang terdapat dalam Terma dan Syarat. Polisi Bayaran Balik ini akan membentuk sebahagian yang tidak terpisahkan daripada Terma dan Syarat.
                    </li>
                    <li>Istilah-istilah dibawah ini akan mempunyai maksud yang berikut:

                      <ol type="a">
                        <li>“Syarikat bersekutu” bermaksud berhubungan dengan seseorang atau entiti (“Orang”), mana-mana orang atau entiti yang dikawal secara langsung atau tidak langsung oleh Orang tersebut, yang mengawal Orang tersebut atau yang dikawal oleh seseorang atau entiti yang mengawal Orang tersebut, dan hendaklah meliputi ahli lain dalam kumpulan yang mana Orang tersebut adalah ahli;
                        </li>
                        <li> “Pelanggan” atau “anda” bermaksud orang yang membeli AutoGen PLUS daripada kami, dan ia akan ditafsirkan dengan sewajarnya; </li>
                        <li>“AutoGen PLUS” bermaksud program jaminan lanjutan (extended warranty programme) yang disediakan oleh eAutoGen; dan
                        </li>
                        <li> “Kenderaan” bermaksud kenderaan kereta kegunaan persendirian dan ia akan ditafsirkan dengan sewajarnya.   </li>
                      </ol>
                    </li>
                  </ol>
                </div>
                <h2><li> KELAYAKAN DAN PERSYARATAN
                </li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li>Untuk Kenderaan layak mendapat AutoGen PLUS, Pelanggan hendaklah memastikan Kenderaan tersebut memenuhi semua syarat-syarat berikut sebelum membeli AutoGen PLUS: -

                      <ol type="a">
                        <li> Kenderaan mestilah tidak melebihi 10 tahun, dikira dari tarikh pendaftaran;
                        </li>
                        <li>Mempunyai mileage (sebenar dan berdasarkaan bacaan) yang tidak melebihi 200,000 kilometers; and</li>
                        <li>Enjin dan transmisi Kenderaan berada dalam keadaan yang baik dan boleh berfungsi.(secara kolektif, “Kriteria Kelayakan”)</li>
                      </ol>
                    </li>
                    <li>eAutoGen akan mengeluarkan kod pesanan (“Kod Pesanan”) kepada Pelanggan apabila anda berjaya membeli AutoGen PLUS. Selapas menerima Kod Pesanan, Pelanggan hendaklah membuat temu janji dengan mana-mana pusat pemeriksaan kereta yang ditetapkan oleh eAutoGen ("Rakan Kongsi Sah eAutoGen”) untuk menjalankan ujian diagnosis bagi Kenderaan anda (“Pemeriksaan”) dalam tempoh sembilan puluh (90) hari dari tarikh penerimaan Kod Pesanan (“Tempoh Pemeriksaan”). Untuk mengelakkan keraguan, jika Pelanggan gagal, enggan, dan/atau megabaikan untuk melaksanakan Pemeriksaan dalam Tempoh Pemeriksaan, AutoGen PLUS akan dibatalkan secara automatic dan ditamatkan tanpa bayaran balik dan liabiliti kepada eAutoGen.
                    </li>
                    <li> Tarikh pemeriksaan dan temujanji hendaklah dihubungkan antara Pelanggan dan Rakan Kongsi Sah e-AutoGen. Rakan Kongsi Sah eAutoGen akan menyediakan semua laporan pemeriksaan untuk Kenderaan tersebut (“Laporan”). eAutoGen tidak akan bertanggungjawab atas sebarang representasi yang dibuat oleh Rakan Kongsi Sah eAutoGen berkaitan dengan Kenderaan tersebut. Keputusan Laporan yang dikeluarkan oleh Rakan Kongsi Sah eAutoGen adalah muktamad dan mengikatkan.
                    </li>
                    <li>Walaupun eAutoGen mempunyai hak untuk menilai Kriteria Kelayakan berdasarkan Laporan, ia tetap merupakan tanggungjawab dan liabiliti Pelanggan sepenuhnya untuk memastikan Kenderaan memenuhi Kriteria Kelayakan.
                    </li>
                    <li>Sekiranya Kenderaan anda tidak memenuhi Kriteria Kelayakan, eAutoGen berhak untuk membatalkan dan/atau menamatkan pembelian AutoGen Plus oleh Pelanggan. eAutoGen akan membayar balik harga pembelian AutoGen PLUS, selepas menolak sebarang kos dan perbelanjaan yang ditanggung oleh eAutoGen, termasuk tetapi tidak terhad kepada kos pentadbiran, yuran pengedalian, yuran Pemeriksaan dan sebarang caj lain yang mungkin dikenakan oleh mana-mana pihak ketiga berkenaan dengan AutoGen Plus.
                    </li>
                  </ol>
                </div>
                <h2><li>PEMBAYARAN BALIK DAN PEMBATALAN
                </li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li>Pelanggan bertanggungjawab untuk memastikan Kenderaan memenuhi Kriteria Kelayakan dan terma lain seperti yang dinyatakan dalam Polisi Bayaran Balik ini dan Terma-terma Lain sebelum meneruskan untuk membuat pesanan untuk membeli AutoGen PLUS (“Pesanan”). eAutoGen berhak untuk menerima atau menolak Pesanan secara keseluruhan atau sebahagiannya. Sebaik sahaja Kod Pesanan telah dikeluarkan dan dihantar oleh eAutoGen kepada Pelanggan, Pelanggan tidak akan dibenarkan untuk membatalkan Pesanan melainkan anda menerima komunikasi bertulis daripada  eAutoGen untuk membenarkan pembatalan Pesanan tersebut. eAutoGen berhak untuk membatalkan Pesanan atas apa jua sebab sekalipun, dengan atau tanpa memberikan sebab.
                    </li>
                    <li>Setiap permohonan Pelanggan untuk bayaran balik AutoGen PLUS akan dinilai berdasarkan Polisi Bayaran Balik ini atau berdasarkan kes demi kes, mengikut budi bicara mutlak eAutoGen. Keputusan eAutoGen’s adalah muktamad dan Pelanggan bersetuju untuk terikat dengan keputusan tersebut.
                    </li>
                    <li>Walaupun terdapat peruntukan dibawah ini, jika AutoGen PLUS dibeli oleh Pelanggan melalui platform dalam talian pihak ketiga (“Platform Pihak Ketiga”), semua perkara yang berkaitan dengan bayaran balik dan pembatalan akan dikawal oleh polisi bayaran balik bagi Platform Pihak Ketiga tersebut. Kami tidak mengawal Platform Pihak Ketiga dan tidak bertanggungjawab atas kandungan atau dasar dan/atau kenyataan mereka. Anda bersetuju untuk mengecualikan sebarang tuntutan terhadap eAutoGen yang berkenaan dengan Platform Pihak Ketiga.
                    </li>
                    <li>Pelanggan dengan ini mengakui dan bersetuju bahawa sebarang wang yang akan dibayar balik menurut permohonan yang berjaya akan tertakluk kepada potongan caj pentadbiran yang mungkin dikenakan oleh eAutoGen dari semasa ke semasa, mengikut budi bicara mutlaknya atau sebarang caj lain yang mungkin dikenakan oleh mana-mana pihak ketiga.
                    </li>
                    <li> Bayaran balik akan diproseskan dalam tempoh lima belas (15) hari bekerja dan ia akan dikreditkan semula ke akaun bank anda.
                    </li>
                    <li>Walaupun terdapat peruntukan lain dibawah Polisi Bayaran Balik ini dan Terma-terma Lain, eAutoGen berhad untuk menamatkan kontrak sekiranya terdapati bahawa: -

                      <ol type="a">
                        <li> Laporan menunjukkan bahawa Kenderaan tidak memenuhi Kriteria Kelayakan;</li>
                        <li>Kegagalan Pelanggan untuk menjalankan Pemeriksaan dalam Tempoh Pemeriksaan;
                        </li>
                        <li>Bacaan mileage Kenderaan telah diganggu dan/atau diubah suai;
                        </li>
                        <li> Pelanggaran waranti dan perjanjian yang ditetapkan dibawah Klausa 5.1; dan/atau</li>
                        <il>Sebab-sebab lain yang eAutoGen beranggap boleh menjejaskan liputan dalam skop AutoGen PLUS.
                        </il>
                      </ol>
                    </li>
                    <li>Sebaik sahaja eAutoGen menerima dana yang penuh dan dijelaskan bagi harga pembelian AutoGen PLUS, Laporan dan Kenderaan boleh diterimakan oleh eAutoGen, AutoGen PLUS akan diaktifkan. eAutoGen akan menghantar e-mel kepada Pelanggan selepas pengaktifan tersebut dan akan disertakan dengan ringkasan produk AutoGen PLUS yang berkenaan dengan Kenderaan anda. AutoGen PLUS akan tertakluk kepada syarat bahawa ia tidak boleh dibatalkan atau dikembalikan selepas ia telah diaktifkan.  </li>
                    <li>Untuk urusan bayaran balik dan pembatalan, semua permohonan atau pertanyaan sedemikian perlu dihantarkan kepada Perkhidmatan Pelanggan eAutoGen di (a) Meja Bantuan kami: 03-7931 3663; atau (b) e-mel kami: cs@eautogen.my</li>

                  </ol>
                </div>
                <h2><li>WARANTI DAN PERJANJIAN
                </li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <ol>
                    <li><b> Pelanggan dengan ini menjamin dan berjanji kepada eAutoGen bahawa: -
                    </b>
                      <ol>
                        <li> Pelanggan adalah pemilik berdaftar Kenderaan dan mempunyai hakmilik yang baik dan boleh dipasarkan kepada Kenderaan tersebut;
                        </li>
                        <li>Pelanggan boleh memberikan bukti sejarah servis bagi Kenderaan atas permintaan eAutoGen;
                        </li>
                        <li>Kenderaan mempunyai bacaan mileage yang tepat dan tidak tertakluk kepada pengubahsuaian dan/atau gangguan dalam apa jua cara;
                        </li>
                        <li>Kenderaan itu tidak merupakan kerugian keseluruhan insurans dan tidak mengalami kerosakan besar diakibatkan oleh kemalangan, kebakaran atau banjir;
                        </li>
                        <li>Kenderaan itu tidak merupakan kereta potong-dan-sambung; dan/atau</li>
                        <li>Kenderaan tidak mempunyai bahagian yang kehilangan, kecacatan mekanikal dan/atau elektrikal, termasuk bahagian luaran dan/atau dalaman.
                        </li>
                      </ol>
                    </li>
                    <p>Waranti diatas ini adalah tambahan kepada sebarang waranti lain, tersurat atau tersirat, yang diberikan oleh Pelanggan kepada eAutoGen, atau tersirat oleh undang-undang. Semua waranti perlu menginsuranskan untuk manfaat eAutoGen dan Syarikat bersekutunya serta pegawai, pengarah, pekerja, ejen, kontraktor, pengganti dan penerima tugasan masing-masing. Sebarang percubaan Pelanggan untuk mengehadkan, menafikan atau menyekat waranti diatas ini atau sebarang remedi eAutoGen dalam pengakuan, pengesahan, invois atau dokumentasi dengan ini dibantahkan, dibatalkan dan adalah tidak sah. Semua waranti akan kekal selepas pembayaran kepada dan penerimaan oleh eAutoGen.
                    </p>
                  </ol>
                </div>
                <h2><li>UNDANG PENTADBIRAN DAN PENYELESAIAN PERTIKAIAN
                </li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>Polisi Bayaran Balik ini akan ditadbir dan ditafsirkan mengikuti undang-undang yang berkuat kuasa di Malaysia. Melainkan dan sebaliknya dikehendaki oleh undang-undang yang terpakai, sebarang pertikaian, kontroversi, tuntutan atau perbezaan dalam apa-apa bentuk yang menimbul daripada atau berkaitan dengan terma ini terhadap atau berkaitan dengan eAutoGen dibawah terma ini perlu diselesaikan melalui timbang tara berdasarkan peraturan Pusat Timbang Tara Antarabangsa Asia (Asian International Arbitration Centre) di Kuala Lumpur. Bilangan penimbang tara adalah seorang. Tempat timbang tara hendaklah di Kuala Lumpur dan timbang tara perlu menggunakan undang-undang substantif Malaysia. Prosiding timbang tara perlu dilaksanakan dalam bahasa Inggeris. Anda dengan ini melepaskan hak untuk membicara (atau mengambil bahagian sebagai parti atau ahli kelas dalam) semua pertikaian terhadap eAutoGen di mahkamah dan/atau tribunal di hadapan hakim atau juri.</p>
                </div>
                <h2><li> LIABILITI DAN INDEMNITI
                </li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>eAutoGen tidak akan dalam apa jua keadaan, bertanggungjawab atas sebarang kerugian, kerosakan atau kecederaan (termasuk kerosakan harta benda) yang menimbul daripada atau berkaitan dengan Polisi Bayaran Balik ini dan Terma-terma Lain atau disebabkan atau disumbangkan dalam apa jua cara melalui penggunaan dan pengendalian AutoGen PLUS. Walaupun terdapat peruntukan lain dibawah Polisi Bayaran Balik ini dan Terma-terma Lain, sekiranya eAutoGen dikehendaki bertanggungjawab ke atas kerugian atau kerosakan tersebut, jumlah liabiliti eAutoGen kepada Pelanggan atau mana-mana pihak lain hanya terhad kepada harga pembelian AutoGen PLUS tersebut.
                  </p>
                </div>
                <h2><li>PINDAHAN
                </li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>AutoGen PLUS tidak boleh dipindahkan, diserahkan dan/atau diubahkan oleh Pelanggan kepada mana-mana pemilik Kenderaan yang berikutnya tanpa kebenaran bertulis daripada eAutoGen terlebih dahulu.</p>
                </div>
                <h2><li>PENGECUALIAN</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>
                    Tindakan eAutoGen berkenaan dengan apa-apa keingkaran Pelanggan dan pelaksanaan kewajipan atau syarat dibawah Polisi Bayaran Balik ini tidak boleh dianggap sebagai pengecualian kewajipan atau syarat itu atau yang selainnya.
                  </p>
                </div>
                <h2><li>VARIASI</li></h2>
                <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                  <p>eAutoGen berhak untuk pada bila-bila masa, mengikut budi bicara mutlaknya, menukar, meminda atau mengubah suai mana-mana bahagian Polisi Bayaran Balik ini dengan menyiarkan pindaan Polisi Bayaran Balik yang berkaitan dan menyatakan semula Polisi Bayaran Balik di Laman Web. Pindaan tersebut akan berkuat kuasa pada tarikh siaran, dan penggunaan berterusan Laman Web eAutoGen menandakan penerimaan Polisi Bayaran Balik yang telah dikemas kini atau diubah suai</p>
                </div>
              </ol>


            </Container>
            <div style={{ marginTop: "50px" }}>
              <Footer />
            </div>
          </div>
      }
    </div>
  );
};
