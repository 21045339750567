import React, { useEffect } from "react";
import { Carousel, Col, Container, Form, Image, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import values_that_lead_us from "./../../../assets/img/values_that_lead_us.png";
import { ORDERSBYDEALER, USERBOOKINGONDASHBOARD, GETBOOKINGRECEIPTONSUCCESS } from "../../../queries/userQueries";
import { useQuery } from "@apollo/react-hooks";
var FileSaver = require('file-saver');

function Dashboard() {

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (data) {
  //       setValue(data);
  //     }
  //   } , 1000);

  // });



  const userId = localStorage.getItem("user_id");
  // const createdByUserId = "62fcefbfe3fc5f8eb9009b9d";
  var { data } = useQuery(USERBOOKINGONDASHBOARD, {
    variables: { userId },
  });
  const allBookingRes = data?.getAllBookingForUserDashboard.allBookingRes;
  const draft = data?.getAllBookingForUserDashboard.draft;
  const paid = data?.getAllBookingForUserDashboard.paid;
  const unpaid = data?.getAllBookingForUserDashboard.unpaid;
  const expired = data?.getAllBookingForUserDashboard.expired;

  let booking_res = localStorage.getItem("booking_res")

  var { data, loading, refetch } = useQuery(GETBOOKINGRECEIPTONSUCCESS, {
    variables: { bookingId: booking_res },
  });

  const receiptUrl = data?.getBookingReceiptOnSuccess?.receiptUrl

  function handleReceiptDownload(id) {
    localStorage.setItem("booking_res", id)

    refetch()
    if (receiptUrl) {
      // window.location.href = receiptUrl
      // history.push(to);
      FileSaver.saveAs(receiptUrl, "receipt.pdf");
      window.open(receiptUrl, "_blank")
    }
  }


  return (
    <Container>
      <Row>
        <Col md={4}>
          <div className="overview-box">
            <h5><b>Overview</b></h5>
            <Row>
              <Col md={6}>
                <div className="overview-item">
                  <div className="expired">
                    <h1>{expired}</h1>
                    <span>Expired</span>
                    <p>
                      {/* •7 Updates <br />
                      •3 Remarks */}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="overview-item">
                  <div className="unpaid">
                    <h1>{unpaid}</h1>
                    <span>Unpaid</span>
                    {/* <p>
                      •7 Updates <br />
                      •3 Remarks
                    </p> */}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="overview-item">
                  <div className="paid">
                    <h1>{paid}</h1>
                    <span>Paid</span>
                    {/* <p>
                      •7 Updates <br />
                      •3 Remarks
                    </p> */}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="overview-item">
                  <div className="draft">
                    <h1>{draft}</h1>
                    <span>Draft</span>
                    {/* <p>
                      •7 Updates <br />
                      •3 Remarks
                    </p> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {(() => {
          if (allBookingRes?.length === 0) {
            return (
              <Col md={8}><div className="payment-box dashbord-box" style={{ zIndex: "1000" }}>
                <Row className="align-items-center">
                  <Col md={8}>
                    <h1>
                      eAutoGen was started to pick up where your auto warranty
                      expires.{" "}
                    </h1>
                    <br />
                    <p>
                      *Fill up the form with your car’s details to get a quote for
                      your AutoGen+ plan.
                    </p>
                    <br />
                    <Link to="/buyplan" className="btn btn-secondary rounded">
                      {" "}
                      buy plan
                    </Link>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Image src={values_that_lead_us} fluid />
                  </Col>
                </Row>
              </div></Col>
            )
          } else {
            return (
              <Col md={8}>
                <div className="payment-box dashbord-box">
                  <h1>AutoGen Plus Extended Warranty Program</h1>
                  <Carousel>
                    {allBookingRes?.map((allBookingResItem) => (<Carousel.Item>
                      <Row className="align-items-end">
                        <Col md={6}>
                          <div className="authentication-inputs">
                            {/* <Form noValidate validated={validated} onSubmit={onSubmit}> */}
                            <div className="quot pb-0">
                              <p>
                                <strong>Car Details</strong>
                              </p>

                              <Row>
                                <Col md={4}>
                                  <p>Brand :</p>
                                </Col>
                                <Col md={8}>
                                  <p>{allBookingResItem.brandName}</p>
                                </Col>
                                <Col md={4}>
                                  <p>Model :</p>
                                </Col>
                                <Col md={8}>
                                  <p>{allBookingResItem.modelName}</p>
                                </Col>
                                <Col md={4}>
                                  <p>Year :</p>
                                </Col>
                                <Col md={8}>
                                  <p>{allBookingResItem.year}</p>
                                </Col>
                              </Row>
                            </div>
                            &nbsp;
                            <div className="quot pt-0">
                              <p>
                                <strong>Insurance Duration</strong>
                              </p>

                              <Row>
                                <Col md={4}>
                                  <p>Start Date :</p>
                                </Col>
                                <Col md={8}>
                                  <p>{allBookingResItem.startDate}</p>
                                </Col>
                                <Col md={4}>
                                  <p>End Date :</p>
                                </Col>
                                <Col md={8}>
                                  <p>{allBookingResItem.endDate}</p>
                                </Col>
                                <Col md={4}>
                                  <p>Status :</p>
                                </Col>

                                <Col md={8}>
                                  <p
                                    // variant={}
                                    style={allBookingResItem?.status == "confirm" ? { background: "#17bebb", width: "70px", borderRadius: "5px", textAlign: "center", color: "white" } : { background: "#DF5C5C", width: "70px", borderRadius: "5px", textAlign: "center", color: "white" }}

                                  >
                                    {(function () {
                                      if (allBookingResItem?.status == "confirm") {
                                        return "Paid"
                                      } else if (allBookingResItem?.status == "failure") {
                                        return "Unpaid"
                                      } else {
                                        return "Draft"
                                      }
                                    })()}
                                  </p>
                                </Col>
                                &nbsp;

                              {
                                allBookingResItem?.status == "confirm" && (
                                <div className=" pb-0 pl-2">
                                  <p>
                                    <strong>Payment Details</strong>
                                  </p>
                                  <Row >
                                    <Col md={4}>
                                      <p>Amount :</p>
                                    </Col>
                                    <Col md={8}>
                                      <p>RM {" "}{allBookingResItem.planPrice}</p>
                                    </Col>
                                    <Col md={4}>
                                      <p>Promo Code :</p>
                                    </Col>
                                    <Col md={8}>
                                      <p>{allBookingResItem.promocodeApplied}</p>
                                    </Col>
                                    <Col md={4}>
                                      <p>Discount :</p>
                                    </Col>
                                    <Col md={8}>
                                      <p>RM {" "}{allBookingResItem.discount}</p>
                                    </Col>
                                    <Col md={4}>
                                      <p>Grand Total :</p>
                                    </Col>
                                    <Col md={8}>
                                      <p>RM {" "}{allBookingResItem.price}</p>
                                    </Col>
                                  </Row>
                                </div>   
                              )}
                                
                                &nbsp;
                                <Col md={12}>
                                  <strong>Get covered by AUTOGEN+ for only</strong>
                                  <div className="section-title d-flex align-items-end mb-3">
                                    <h2 className="mb-0" style={allBookingResItem?.status == "confirm" ? { color: "rgb(23, 190, 187)" } : { color: "#DF5C5C" }}>RM  {allBookingResItem.price}<span style={{ fontSize: "16px" }}>/YEAR</span></h2>

                                  </div>
                                  <a href={allBookingResItem?.status == "confirm" ? "#" : "/buyplan"}
                                    onClick={allBookingResItem?.status == "confirm" ? handleReceiptDownload(allBookingResItem.id) : undefined}

                                  // className="btn btn-secondary rounded"
                                  >
                                    <p style={{ background: "#17bebb", width: "60%", textAlign: "center", color: "white", zIndex: "1000" }}>

                                      {(function () {
                                        if (allBookingResItem?.status == "confirm") {
                                          return "DOWNLOAD RECIEPT"
                                        } else if (allBookingResItem?.status == "failure") {
                                          return "RENEW NOW"
                                        } else if (allBookingResItem?.status == "init") {
                                          return ""
                                        } else {
                                          return ""
                                        }
                                      })()}
                                    </p>
                                  </a>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <Image src={values_that_lead_us} fluid />
                        </Col>
                      </Row>
                    </Carousel.Item>))}


                    {/* <Carousel.Item>
      <Row className="align-items-end">
              <Col md={6}>
                <div className="authentication-inputs">
                
                  <div className="quot pb-0">
                    <p>
                      <strong>Car Details</strong>
                    </p>

                    <Row>
                      <Col md={4}>
                        <p>Brand :</p>
                      </Col>
                      <Col md={8}>
                        <p>Name</p>
                      </Col>
                      <Col md={4}>
                        <p>Model :</p>
                      </Col>
                      <Col md={8}>
                        <p>Name</p>
                      </Col>
                      <Col md={4}>
                        <p>Year :</p>
                      </Col>
                      <Col md={8}>
                        <p>Name</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="quot pt-0">
                    <p>
                      <strong>Insurance Duration</strong>
                    </p>

                    <Row>
                      <Col md={4}>
                        <p>Start Date :</p>
                      </Col>
                      <Col md={8}>
                        <p>20 Jul 2022</p>
                      </Col>
                      <Col md={4}>
                        <p>End Date :</p>
                      </Col>
                      <Col md={8}>
                        <p>20 Jul 2022</p>
                      </Col>
                      <Col md={4}>
                        <p>Status :</p>
                      </Col>
                      <Col md={8}>
                        <p>
                          {" "}
                          <Link
                            to="/"
                            className="btn btn-danger rounded"
                          >
                            {" "}
                            Paid
                          </Link>
                        </p>
                      </Col>
                      <Col md={12}>
                        <strong>Get covered by AUTOGEN+ for only</strong>
                        <div className="section-title d-flex align-items-end mb-3">
                          <h2 className="mb-0 text-danger">RM 298.00</h2>
                          <h5>/YEAR</h5>
                        </div>
                        <Link
                          to="/"
                          className="btn btn-secondary rounded"
                        >
                          {" "}
                          Renew now
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <Image src={values_that_lead_us} fluid />
              </Col>
            </Row>
      </Carousel.Item> */}
                  </Carousel>

                </div>
              </Col>)
          }
        })()}

      </Row>
    </Container >
  );
}

export default Dashboard;
