import React, { useState } from "react";
import {
    Button,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Pagination,
    Row,
    Table,
    Image,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from "@chakra-ui/react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { ORDERSBYDEALER, GETBOOKINGRECEIPTONSUCCESS, GETCREDITTRANSACTIONFORDEALER } from "../../queries/userQueries";
import { useQuery } from "@apollo/react-hooks";
import { ChakraProvider } from "@chakra-ui/react";
import values_that_lead_us from "../../assets/img/values_that_lead_us.png";
import { UserTransactionEditForm } from "../pages/UserTransactionEditForm/UserTransactionEditForm";
var FileSaver = require('file-saver');

function CreditTransection() {
    const [basicModal, setBasicModal] = useState(false);
    const [curr, setCurr] = useState(null);
    const [curr1, setCurr1] = useState(null);
    const [modal, setModal] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    let active = 2;
    let items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>
        );
    }

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: "selection",
        },
    ]);



    function handleReceipt() {
        setModal(!modal);
        setBasicModal(!basicModal);
    }



    const createdByUserId = localStorage.getItem("user_id");
    // const createdByUserId = "62fcefbfe3fc5f8eb9009b9d";
    // var { data, loading } = useQuery(ORDERSBYDEALER, {
    //     variables: { createdByUserId },
    // });
    // const orders = data?.getAllBookingCreatedByAdmin?.allBookingRes;



    var { data, loading } = useQuery(GETCREDITTRANSACTIONFORDEALER, {
        variables: { dealerId: createdByUserId },
    });
    const orders = data?.getCreditTransactionForDealer?.creditTransactionResults

    function showDetails(id) {
        let temp = orders?.filter((item) => {
            if (item.id === id) {
                return item;
            }
        });
        setCurr(temp);

        localStorage.setItem("dealer_amount", temp[0].amount);
        localStorage.setItem("dealer_createdBy", temp[0].createdBy);
        localStorage.setItem("dealer_createdDate", temp[0].date);
        localStorage.setItem("dealer_ownerName", temp[0].ownerName);
        localStorage.setItem("dealer_user_transactionId", temp[0].transactionId);
        localStorage.setItem("dealer_type", temp[0].type);
        // localStorage.setItem("dealer_endDate", temp[0].endDate);
        // localStorage.setItem("dealer_price", temp[0].price);
        // localStorage.setItem("dealer_voucherCode", temp[0].voucherCode);
        // localStorage.setItem("dealer_owner_brandName", temp[0].brandName);
        // localStorage.setItem("dealer_owner_modelName", temp[0].modelName);
        // localStorage.setItem("dealer_owner_year", temp[0].year);

        setModal(!modal);
    }

    function handleReceiptDetails(id) {
        let temp1 = orders?.filter((item) => {
            if (item.id === id) {
                return item;
            }
        });
        setCurr1(temp1);
        localStorage.setItem("receipt_owner_brandName", temp1[0].brandName);
        localStorage.setItem("receipt_owner_modelName", temp1[0].modelName);
        localStorage.setItem("receipt_owner_year", temp1[0].year);
        localStorage.setItem("receipt_price", temp1[0].price);
        localStorage.setItem("receipt_startDate", temp1[0].startDate);
        localStorage.setItem("receipt_endDate", temp1[0].endDate);
        localStorage.setItem("receipt_status", temp1[0].status);
        setModal(!modal);
        setBasicModal(!basicModal);
    }


    let bookingId = localStorage.getItem("booking_id")

    var { data, loading, refetch } = useQuery(GETBOOKINGRECEIPTONSUCCESS, {
        variables: { bookingId },
    });
    const receiptUrl = data?.getBookingReceiptOnSuccess?.receiptUrl

    function handleReceiptDownload(id) {
        localStorage.setItem("booking_id", id);
        refetch()

        if (receiptUrl) {
            // window.location.href = receiptUrl
            // history.push(to);
            FileSaver.saveAs(receiptUrl, "receipt.pdf");
            window.open(receiptUrl, "_blank")
        }
    }

    // let bookingId = localStorage.getItem("booking_id")

    return (
        <>
            <ChakraProvider>
                <Row className="mt-5 justify-content-between">
                    <Col md={3}>
                        <InputGroup>
                            <Form.Control
                                aria-label="Search…"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <InputGroup.Text>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Modal isOpen={modal} className="modal-dialog modal-lg">
                    <ModalBody>
                        <div>
                            <Row>
                                <Col md={11}>
                                    <h1 style={{ fontSize: "22px", fontWeight: "bold", marginBottom: "10px" }}>Order Details</h1>
                                </Col>
                                <Col md={1} className="justify-content-end">
                                    <i
                                        onClick={() => setModal(!modal)}
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-remove"
                                    ></i>
                                </Col>
                            </Row>
                            <div  >
                                <div >
                                    <Form>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Label>
                                                Owner Name <span className="text-danger">*</span>{" "}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                required="required"
                                                readOnly
                                                value={ localStorage.getItem("dealer_ownerName") != "null" ? localStorage.getItem("dealer_ownerName") : " - "}
                                                placeholder="e.g. 0123456789"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>
                                                    Transaction ID <span className="text-danger">*</span>{" "}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    required="required"
                                                    readOnly
                                                    value={localStorage.getItem("dealer_user_transactionId")  != "null" ? localStorage.getItem("dealer_user_transactionId") : " - " }
                                                    placeholder="e.g. 0123456789"
                                                />
                                            </Form.Group>
                                            {
                                                localStorage.getItem("role") === "dealer" ?
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="exampleForm.ControlTextarea1"
                                                    >
                                                        <Form.Label>
                                                            Created By<span className="text-danger">*</span>{" "}
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="e.g. 0123456789"
                                                            name="mobile"
                                                            required="required"
                                                            readOnly
                                                            value={localStorage.getItem("dealer_createdBy")   != "null" ? localStorage.getItem("dealer_createdBy") : " - " }
                                                        />
                                                    </Form.Group>
                                                    :
                                                    null
                                            }

                                            <Form.Label>
                                                Inspection Code <span className="text-danger">*</span>{" "}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                required="required"
                                                readOnly
                                                value={localStorage.getItem("dealer_ownerName") != "null" ? localStorage.getItem("dealer_ownerName") : " - " }
                                                placeholder="e.g. 0123456789"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Label>
                                                Date <span className="text-danger">*</span>{" "}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                required="required"
                                                readOnly

                                                value={localStorage.getItem("dealer_createdDate")  != "null" ? localStorage.getItem("dealer_createdDate") : " - " }
                                                placeholder="e.g. 0123456789"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Label>
                                                Type <span className="text-danger">*</span>{" "}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                required="required"
                                                readOnly
                                                value={localStorage.getItem("dealer_type")  != "null" ? localStorage.getItem("dealer_type") : " - " }
                                                placeholder="e.g. 0123456789"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Label>
                                                Amount <span className="text-danger">*</span>{" "}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                required="required"
                                                readOnly
                                                value={localStorage.getItem("dealer_amount")  != "null" ? localStorage.getItem("dealer_amount") : " - " }
                                                placeholder="e.g. 0123456789"
                                            />
                                        </Form.Group>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Row className="mt-3">
                    <Col md={12}>
                        <Table striped bordered hover className="history" responsive>
                            <thead>
                                <tr>
                                    <th>Owner name</th>
                                    <th> Date & Time</th>
                                    <th>Transaction Id</th>
                                    {
                                        localStorage.getItem("role") === "dealer" ?
                                            <th>Created By</th> : null
                                    }
                                    <th>Inspection Code</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders
                                    ?.filter((val) => {
                                        if (searchTerm === "") {
                                            return val;
                                        } else if (
                                            val.status
                                                ?.toLowerCase()
                                                .includes(searchTerm?.toLowerCase()) ||
                                            val.orderId?.includes(searchTerm) ||
                                            val.transactionId?.includes(searchTerm) ||
                                            val.createdByName?.includes(searchTerm) ||
                                            val.ownerName
                                                ?.toLowerCase()
                                                .includes(searchTerm?.toLowerCase()) ||
                                            val.voucherCode?.includes(searchTerm) ||
                                            val.startDate
                                                ?.toLowerCase()
                                                .includes(searchTerm?.toLowerCase()) ||
                                            val.endDate
                                                ?.toLowerCase()
                                                .includes(searchTerm?.toLowerCase()) ||
                                            val.price?.includes(searchTerm)
                                        ) {
                                            return val;
                                        }
                                    })

                                    .map((p, ind) => (
                                        <tr key={ind}>
                                            <td>{p.ownerName}</td>
                                            <td>{p.date}</td>
                                            <td>{p.transactionId}</td>
                                            {
                                                localStorage.getItem("role") === "dealer" ?
                                                    <td>{p.createdBy}</td> : null
                                            }

                                            <td>-</td>
                                            <td>{p.type}</td>
                                            <td style={ p.type === "credit" ? { color:"green" , fontWeight: "600" } : { color:"red" , fontWeight: "600" } }>RM{" "}{p.type === "credit" ? '+' : '-'}{" "}{p.amount}{ p.amount % 1 === 0 ? ".00" : ""}</td>

                                            <td>
                                                <Menu>
                                                    <MenuButton>
                                                        {" "}
                                                        <i className="fa-solid fa-ellipsis"></i>
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem onClick={() => showDetails(p.id)}>
                                                            View Details
                                                        </MenuItem>
                                                        {
                                                            p.status == "confirm" ?
                                                                <MenuItem
                                                                    onClick={() => handleReceiptDetails(p.id)}
                                                                >
                                                                    View Receipt
                                                                </MenuItem> :
                                                                ""
                                                        }
                                                        {
                                                            p.status == "confirm" ?

                                                                <MenuItem

                                                                    target="_blank"
                                                                    onClick={() => handleReceiptDownload(p.id)}

                                                                >
                                                                    Download
                                                                </MenuItem> :
                                                                ""
                                                        }

                                                        {/* <MenuItem>Download</MenuItem> */}
                                                    </MenuList>
                                                </Menu>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        {/* <Pagination size="sm" className="justify-content-end">
                  {items}
                </Pagination> */}
                    </Col>
                </Row>
            </ChakraProvider>
        </>
    );
}

export default CreditTransection