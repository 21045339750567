import React from 'react'
import './AboutSection4.css'
import { Container, Row, Col, Image } from 'react-bootstrap'
import stakeholders from '../../../assets/img/stakeholders.png'


export const AboutSection4 = () => {
    return (
        <div>
            <section>
                <Container>
                    <Row className=' mt-5 mb-5'>
                        <Col md={4} >
                            <h2 className='section_title'>For the <br /> betterment  <br /> of <span> used and <br /> aged car <br /> market</span>.</h2>
                        </Col>
                        <Col md={8} className='section_prg'>
                            <Row>
                                <Image src={stakeholders} />
                            </Row>
                            <Row>
                                <p>Aspiring to change for the betterment of the used and aged car market, eAutoGen connects the  relevant stakeholders in the market to build a better eco-system in Malaysia.</p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            
        </div>
    )
}
