import React from 'react'
import autogenbanner from '../../../assets/img/autogen plus banner.png'
import { Container, Row, Col, Image ,Button} from 'react-bootstrap'
import zIndex from '@material-ui/core/styles/zIndex'
import { Link } from 'react-router-dom'

export const AboutBanner = () => {
    return (
        <div style={{position:"relative"}}>
            <Image src={autogenbanner} width="100%" />
            <a href="https://eautogen.my/"><Button variant="secondary" className="banner-btn" style={{position:"absolute", zIndex:"9999",top:"75%",left:"11%",transform:"translate(-50%, -50%)"}}>
                GET QUOTE
            </Button></a>
        </div>
    )
}
