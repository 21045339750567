import React ,{useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom';
export const Ipay = () => {
  const navigate = useNavigate();
const api="https://www.eautogen.my/payment/response"
const [data, setData] = useState([]);
  // useEffect(() => {
  //   loadusers();
  //   },[])
    
    // const loadusers = async () => {
    //   const response = await fetch(
    //     api,
    //     {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       }
    //     }
    //   ).then(response => {
    //     if(response.Status === 1){
    //       navigate("/checkoutpage")
    //     }
    //     else if(response.Status === 0){
    //       navigate("/checkoutunsuccesspage")
    //     }


    //   }).catch(err => {
    //     console.log(err);
        
        
    //   });
    // }
   
    
  return (
    <div>ferfrvefvefv</div>
  )
}
