import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import stakeholders from '../../../assets/img/stakeholders.png'

export const AboutSectionBM4 = () => {
    return (
        <div>
            <section>
                <Container>
                    <Row className=' mt-5 mb-5'>
                        <Col md={4} >
                            <h2 className='section_title'>Untuk penambahbaikan <br /> pasaran kereta  <br />  <span> terpakai  dan  <br /> kereta  lama</span>.</h2>
                        </Col>
                        <Col md={8} className='section_prg'>
                            <Row>
                                <Image src={stakeholders} />
                            </Row>
                            <Row>
                                <p>eAutogen bercita-cita untuk mengubah untuk penambahbaikan pasaran kereta terpakai dan kereta lama, eAutogen menghubungkan pihak berkepentingan yang relevan di pasaran untuk membina sebuah eko-sistem yang lebih baik di Malaysia bagi penjual, pembeli, pembiaya, syarikat insurans, bengkel, pemeriksaan, produk dan servis penyelenggaraan kereta.</p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

        </div>
    )
}
