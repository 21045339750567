import React from 'react'
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import car_wireframe from "../../assets/img/Payment Unsuccessful.png";
function CheckoutboxUnsuccessful() {
    return (  
      <div>
        {
          localStorage.getItem('language') === 'true' ? 
          <section className="py-5 mt-5">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title mb-5">
                    <span>OH TIDAK, TERDAPAT KESILAPAN!</span>
                    <span>GET COVERD BY AUTOGEN PLUS</span>
                    <h2>
                      <span style={{color:"#EF487D"}}>BAYARAN TIDAK BERJAYA</span>
                    </h2>
                    <p>Kami mengalami ralat semasa transaksi anda.<br/> Sila cuba sekali lagi dengan kaedah pembayaran yang lain.</p>

                  </div>
                </Col>
              </Row>
              <Row className='justify-content-center'>
                <Col md={5} className="text-center" >
                  <Image src={car_wireframe} fluid width="500px" height="500px" />
                </Col>
                <Col md={8} className="d-flex justify-content-center m-auto">
                <Button variant="primary" className=" text-center m-auto mt-4" href={
                  localStorage.getItem('token')  ? '/buyplan' : '/quotation'
                }>BALIK KE BAYARAN</Button>
                </Col>
              </Row>
            </Container>
          </section>
          :
          <section className="py-5 mt-5">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title mb-5">
                    <span>OH NO, SOMETHING WENT WRONG</span>
                    <h2>
                      <span style={{color:"#EF487D"}}>PAYMENT UNSUCCESSFUL</span>
                    </h2>
                    <p>We encountered on error during your transaction.<br/> Please try again with different payment method.</p>

                  </div>
                </Col>
              </Row>
              <Row className='justify-content-center'>
                <Col md={5} className="text-center" >
                  <Image src={car_wireframe} fluid width="500px" height="500px" />
                </Col>
                <Col md={8} className="d-flex justify-content-center m-auto">
                <Button variant="primary" className=" text-center m-auto mt-4" href={
                  localStorage.getItem('token')  ? '/buyplan' : '/quotation'
                }
                >RETURN TO PAYMENT</Button>
                </Col>
              </Row>
            </Container>
          </section>
        }
      </div>
   
   );
}

export default CheckoutboxUnsuccessful;