import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AboutImg1 from '../../../assets/img/underwritten by berjaya sompo.png'
import AboutImg2 from '../../../assets/img/multicar.png'
import AboutImg3 from '../../../assets/img/workshops panel.png'
import AboutImg4 from '../../../assets/img/authorised eautogen.png'
import AboutImg5 from '../../../assets/img/lightweight.png'
import AboutImg6 from '../../../assets/img/limit expenses.png'
import AboutImg7 from '../../../assets/img/car ownership transfer.png'
import AboutImg8 from '../../../assets/img/car dealers.png'
import AboutImg9 from '../../../assets/img/peace of mind.png'

export const AboutSectionBM2 = () => {
    return (
        <div>
            <section>
                <Container>
                    <Row className=' mt-5 pb-5'>
                        <Col md={4}>
                            <h2 className='section_title'><span>Pembekal</span> <br />program <br /> waranti <br />lanjutan kereta<br /> <span>yang dipercayai
                            </span>.</h2>
                        </Col>
                        <Col md={8} className='section2_prg '>
                            <Row className="text-center">
                                <Col md={4} >
                                    <Image src={AboutImg1} width="60%" className="mx-auto" />
                                    <p>Ditugaskan oleh Berjaya Sompo Insurance </p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg2} width="60%" className="mx-auto" />
                                    <p>Pemeriksaan kereta pelbagai</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg3} width="60%" className="mx-auto" />
                                    <p>200+ bengkel panel di seluruh negara</p>
                                </Col>
                            </Row>

                            <Row className="text-center">
                                <Col md={4} >
                                    <Image src={AboutImg4} width="60%" className="mx-auto" />
                                    <p>Penggantian dan bengkel panel eAutoGen yang sah</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg5} width="60%" className="mx-auto" />
                                    <p>Kemampuan yang ringgan santun</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg6} width="60%" className="mx-auto" />
                                    <p>Mengehadkan perbelanjan keluar  </p>
                                </Col>
                            </Row>

                            <Row className="text-center">
                                <Col md={4} >
                                    <Image src={AboutImg7} width="60%" className="mx-auto" />
                                    <p>Boleh dipindah milik dengan pertukaran pemilikan kereta </p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg8} width="60%" className="mx-auto" />
                                    <p>Sokongan peniaga kereta terpakai di seluruh negara </p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg9} width="60%" className="mx-auto" />
                                    <p>Ketenangan fikiran dalam perjalanan pemilikan kereta</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
