import React from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
export const UserTransactionEditForm = () => {
    function onChange()
    {
       
    }
  return (
    <div>
       <div  >
                <div >

                 <ToastContainer />
                  <Form>
                  <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                     Status <span className="text-danger">*</span>{" "}
                      </Form.Label>
                     
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        readOnly
                       value={localStorage.getItem("dealer_user_status")  != "null" ? localStorage.getItem("dealer_user_status") : " - "}
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                  <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                      Order ID <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_order_Id")  != "null" ? localStorage.getItem("dealer_order_Id") : " - "}
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                     <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                       Transaction ID <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_user_transactionId") != "null" ? localStorage.getItem("dealer_user_transactionId") : " - "}
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                    {
                      localStorage.getItem("role") === "dealer" ?
                      <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        Created By<span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="e.g. 0123456789"
                        name="mobile"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_createdByName") != "null" ? localStorage.getItem("dealer_createdByName") : " - "}
                      />
                    </Form.Group>
                    :
                    null
                    }
                 
                      <Form.Label>
                    Owner Name <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_ownerName") != "null" ? localStorage.getItem("dealer_ownerName") : " - "}
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                    
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                       Voucher Code <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_voucherCode") != "null" ? localStorage.getItem("dealer_voucherCode") : " - "}
                        placeholder="e.g. abc@text.com"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                      Brand Name <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_owner_brandName") != "null" ? localStorage.getItem("dealer_owner_brandName") : " - "}
                        placeholder="e.g. abc@text.com"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                       Model Name <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_owner_modelName") != "null" ? localStorage.getItem("dealer_owner_modelName") : " - "}
                        placeholder="e.g. abc@text.com"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                     Year <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_owner_year") != "null" ? localStorage.getItem("dealer_owner_year") : " - "}
                        placeholder="e.g. abc@text.com"
                      />
                    </Form.Group>
                  
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                       Start Date <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_startDate") != "null" ? localStorage.getItem("dealer_startDate") : " - "}
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                       End date <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_endDate") != "null" ? localStorage.getItem("dealer_endDate") : " - " }
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                      Amount <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        readOnly
                        value={localStorage.getItem("dealer_price") != "null" ? localStorage.getItem("dealer_price") : " - "}
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                    {/* <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Role Types</Form.Label>

                <Form.Select
                  onChange={onChange}
                  name="role"
                  id="fruit-select"
                >
                  <option >Choose the Role</option>
                  <option value="staff">Staff</option>
                  <option value="admin">Admin</option>
                 
                </Form.Select>
                <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Status</Form.Label>

                <Form.Select
                  onChange={onChange}
                  name="status"
                  id="fruit-select"
                >
                  <option >Choose the Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                 
                </Form.Select>
              </Form.Group>
              </Form.Group>
                   */}
                    {/* <div className="text-right gap-2 mb-3 float-end">
                      <Button
                      
                        variant="secondary"
                        size="lg"
                       
                       
                      >
                        SAVE CHANGES
                      </Button>
                     
                    </div> */}
                  </Form>
                </div>
              </div>
    </div>
  )
}
