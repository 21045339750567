
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/home";
import Quotation from "./pages/Quotation";
import PayPage from "./pages/pay";
import { Privacypolicy } from "./pages/privacypolicy";
import Checkoutpage from "./pages/checkoutpage";
import CheckoutUnsuccessPage from "./pages/checkoutunsuccesspage";
import Login from "./pages/login";
import OTP from "./pages/otp";
import Signup from "./pages/signup";
import BuyPlanCar from "./admin/pages/buyplandetails";
import { UserProfileUpdate } from "./pages/UserProfileUpdate";
import HomePageAdmin from "./admin/pages/home";
import PagenotFound from "./pages/pagenotfound";
import HomePageDealer from "./dealer/pages/home";
import { Dealeradmin } from "./dealer/pages/dealeradmin";
import { CreateUserForm } from "./dealer/pages/CreateUserForm/CreateUserForm";
import { ChakraProvider } from '@chakra-ui/react'
import { LandingPage } from "./pages/LandingPage/LandingPage";
import { LandingPage1 } from "./pages/LandingPage1/LandingPage1";
import { LandingPageBm } from "./pages/LandingPageBm/LandingPageBm";
// import { Payment } from "./pages/Payment/Payment";
import Faq from "./components/home/faq";
import { Refundpolicy } from "./pages/refundpolicy";
import { TermConditions } from "./pages/term&conditions";
import { Reactslider } from "./components/home/reactslider";
import { RouteContextProvider } from './Context/RouteContext';
import CustomerDetails from "./admin/components/Buyplan/CustomerDetails";
import { TabContextProvider } from "./Context/TabContext";
import { Ipay } from "./pages/Ipay";
import Dashboard from "./admin/components/Homepage/dashboard";
import DashboardDealer from "./dealer/Homepage/dashboard";
import { TabContext } from "./Context/TabContext"
import { useContext } from 'react';
import { AboutUs } from "./pages/AboutUs/AboutUs";
import { AboutUsBM } from "./pages/AboutUsBM/AboutUsBM";
import { BarChart } from "./revenueGraph/BarChart"
import { TermConditionBM } from "./pages/term&conditionBM";
import CreditTransection from "./dealer/Homepage/CreditTransection";
import ShowCredit from "./dealer/pages/ShowCredit";

function App() {
  const language = useContext(TabContext);
  return (
    <div className="App">
      <BrowserRouter>
        <RouteContextProvider>
          <TabContextProvider>
            <Routes>
              {/* <Route path="/" element={<Homepage />} /> */}
              <Route path="/quotation" element={<Quotation />} />
              <Route path="/pay" element={<PayPage />} />
              <Route path="/checkout" element={<Checkoutpage />} />
              <Route path="/checkoutfail" element={<CheckoutUnsuccessPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/otp" element={<OTP />} />
              {/* <Route path="/signup" element={<Signup />} /> */}
              <Route path="/privacy-policy" element={<Privacypolicy />} />
              <Route path="/dealer-admin" element={<Dealeradmin />} />
              <Route path="/createuser" element={<CreateUserForm />} />
              <Route path="/credit-transection" element={<CreditTransection />} />
              <Route path="/credit" element={<ShowCredit />} />
              <Route path="/" element={
                <Login />
              } />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard-dealer" element={<DashboardDealer />} />
              <Route path="/payment/response" element={<Ipay />} />
              {/* Admin */}

              {/* <Route path="/payment" element={<Payment />} /> */}
              <Route path="/reactslider" element={<Reactslider />} />
              <Route path="/userprofile" element={<UserProfileUpdate />} />
              <Route path="/landingpage" element={<LandingPage />} />
              <Route path="/buyplan" element={<BuyPlanCar />} />
              <Route path="/home" element={<HomePageAdmin />} />
              <Route path="/buyplan/customer-details" element={<CustomerDetails />} />
              <Route path="/dealer" element={<HomePageDealer />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/refund-policy" element={<Refundpolicy />} />
              <Route path="/termconditions" element={<TermConditions />} />
              <Route path="/termconditionsBM" element={< TermConditionBM />} />
              <Route path="/bm" element={<LandingPageBm />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="aboutusbm" element={<AboutUsBM />} />

              <Route path="*" element={<PagenotFound />} />

              <Route path="/chart" element={<BarChart />} />
              {/* <Route path="expenses" element={<Expenses />} />
      <Route path="invoices" element={<Invoices />} /> */}
            </Routes>
          </TabContextProvider>
        </RouteContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
