import { gql } from "@apollo/client";

const GETAllBRANDS = gql`
  query {
    getAllBrands {
      allVehicleRes {
        id
        brand
      }
    }
  }
`;

const GETAllMODELS = gql`
  query getBrandById($brandId: ID) {
    getBrandById(brandId: $brandId) {
      allVehicleModelRes {
        id
        modelName
        price
      }
    }
  }
`;

const GETCREDITTRANSACTIONFORDEALER = gql`
  query getCreditTransactionForDealer($dealerId: ID) {
    getCreditTransactionForDealer(dealerId: $dealerId) {
      creditTransactionResults{
        id
        amount
        type
        date
        transactionId
        ownerName
        createdBy
      }
    }
  }
`;

const ORDERSBYDEALER = gql`
  query getAllBookingCreatedByAdmin($createdByUserId: ID) {
    getAllBookingCreatedByAdmin(createdByUserId: $createdByUserId) {
        allBookingRes {
        id
        userName
        price
        status
        orderBy
        orderId
        startDate
        endDate
        ownerName
        transactionId
        createdByName
        brandName
        modelName
        year
        email
        provider
        voucherCode
        promocodeApplied
        planPrice
        discount
      }
      totalRevenue
    }
  }
`;

const GETVOUCHERCODE = gql`
  query getBookingOnSuccess($bookingId: ID) {
    getBookingOnSuccess(bookingId: $bookingId) {
      voucherCode
      message
    }
  }
`;
const GETALLDEALERUSERS = gql`
  query getAllUserCreatedByAdmin($createdByuserId: ID) {
    getAllUserCreatedByAdmin(createdByuserId: $createdByuserId) {
      allUserRes {
        id
        name
        email
        mobile
        role
        status
      }
    }
  }
`;
const GETALLSTATES = gql`
  query {
    getAllState {
      allStateRes {
        id
        name
      }
    }
  }
`;
const GETAllWORKSHOPS = gql`
  query getWorkshopById($stateId: ID) {
    getWorkshopById(stateId: $stateId) {
      allWorkshopRes {
        area
        address
        telephone
        name
        email
        fax
        id
      }
    }
  }
`;

const GETAllPARTNERS = gql`
  query getInspectionById($stateId: ID) {
    getInspectionById(stateId: $stateId) {
      allInspectionRes {
        area
        address
        telephone
        name
        email
        fax
        id
      }
    }
  }
`;


const USERBOOKINGONDASHBOARD = gql`
  query getAllBookingForUserDashboard($userId: ID) {
    getAllBookingForUserDashboard(userId: $userId) {
      allBookingRes {
      id
      price
      status 
      startDate
      endDate        
      brandName
      modelName
      year
      promocodeApplied
      planPrice
      discount
      }
      paid
      unpaid
      draft
      expired
    }
  }
`;

const DEALERBOOKINGONDASHBOARD = gql`
  query getAllBookingForDealerDashboard($userId: ID) {
    getAllBookingForDealerDashboard(userId: $userId) {
      draft
      totalRevenue
      totalOrders
      totalCustomers
      paid
      unpaid
      expired
      totalCars
    }
  }
`;

const GETBOOKINGRECEIPTONSUCCESS = gql`
  query getBookingReceiptOnSuccess($bookingId: ID) {
    getBookingReceiptOnSuccess(bookingId: $bookingId) {
      receiptUrl
    }
  }
`;
const GETPLANSBYCRITERIA = gql`
  query getQuotePlanByCriteria($brandId: ID, $odometer: String, $year: String) {
    getQuotePlanByCriteria(brandId: $brandId, odometer: $odometer, year: $year){
      allQuotePlanRes{
        id
        EWPCategory
        CoveredComponents
        CoveredComponentsDetails{
          name
          components{
            type
          }
        }
        NoOfCoveredComponents
        CarType
        CarAge
        OdometerReading
        CarBrandByCountry
        MaxPerVisit
        MaxPerYear
        FinancialInstituitionsRate
        DealersRate
      }
      brands{
        brand
        category
      }
    }
  }
`;

export {
  GETAllBRANDS,
  GETAllMODELS,
  GETALLSTATES,
  GETAllWORKSHOPS,
  GETALLDEALERUSERS,
  GETAllPARTNERS,
  GETVOUCHERCODE,
  ORDERSBYDEALER,
  USERBOOKINGONDASHBOARD,
  DEALERBOOKINGONDASHBOARD,
  GETBOOKINGRECEIPTONSUCCESS,
  GETCREDITTRANSACTIONFORDEALER,
  GETPLANSBYCRITERIA,
};
