import React from 'react'
import './AboutSection2.css'
import { Container, Row, Col, Image } from 'react-bootstrap'
import AboutImg1 from '../../../assets/img/underwritten by berjaya sompo.png'
import AboutImg2 from '../../../assets/img/multicar.png'
import AboutImg3 from '../../../assets/img/workshops panel.png'
import AboutImg4 from '../../../assets/img/authorised eautogen.png'
import AboutImg5 from '../../../assets/img/lightweight.png'
import AboutImg6 from '../../../assets/img/limit expenses.png'
import AboutImg7 from '../../../assets/img/car ownership transfer.png'
import AboutImg8 from '../../../assets/img/car dealers.png'
import AboutImg9 from '../../../assets/img/peace of mind.png'


export const AboutSection2 = () => {
    return (
        <div>
            <section>
                <Container>
                    <Row className=' mt-5 pb-5'>
                        <Col md={4}>
                            <h2 className='section_title'>Trusted<br />provider of <br /> <span> car extended <br />warranty<br />programs.</span></h2>
                        </Col>
                        <Col md={8} className='section2_prg '>
                            <Row className="text-center">
                                <Col md={4} >
                                    <Image src={AboutImg1} width="60%"  className="mx-auto"/>
                                    <p>Underwritten by Berjaya Sompo Insurance</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg2} width="60%"  className="mx-auto"/>
                                    <p>Multi-inspection points</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg3} width="60%"  className="mx-auto"/>
                                    <p>200+ panel workshop nationwide</p>
                                </Col>
                            </Row>

                            <Row className="text-center">
                                <Col md={4} >
                                    <Image src={AboutImg4} width="60%"  className="mx-auto"/>
                                    <p>eAutoGen authorized parts and panel workshops</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg5} width="60%"  className="mx-auto"/>
                                    <p>Lightweight affordability </p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg6} width="60%"  className="mx-auto"/>
                                    <p>Limits your out-of-pocket expenses </p>
                                </Col>
                            </Row>

                            <Row className="text-center">
                                <Col md={4} >
                                    <Image src={AboutImg7} width="60%"  className="mx-auto"/>
                                    <p>Transferable by car ownership change</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg8} width="60%"  className="mx-auto"/>
                                    <p>Used car dealer support nationwide</p>
                                </Col>
                                <Col md={4}>
                                    <Image src={AboutImg9} width="60%"  className="mx-auto"/>
                                    <p>Peace of Mind Car Ownership Journey</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
