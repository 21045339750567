import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import car_wireframe from "../../assets/img/car_wireframe.png";
import eautogen_product from "../../assets/img/header_bg.png";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_BOOKING } from "../../mutations/userMutations";
import { SIGNUP } from "../../mutations/userMutations";
import { CREATE_QUOTATION } from "../../mutations/userMutations";


import { CREATE_PAYMENT, APPLYPROMOCODEWITHOUTLOGIN } from "../../mutations/userMutations";
import { useForm } from "../../utils/hooks";
import { GETAllBRANDS } from "../../queries/userQueries";
import { GETAllMODELS, GETALLSTATES } from "../../queries/userQueries";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ipay from "../../assets/img/ipay88.png";
import credit from "../../assets/img/credit logo crop.jpg";
import reyPay from "../../assets/img/revPay.png";
import visa from "../../assets/img/visa.png";
import fpx from "../../assets/img/fpxlogo.png";
import touchngo from "../../assets/img/touchlogo.png";
import boost from "../../assets/img/boostlogo.png";
import maybank from "../../assets/img/Maybank.png";


import WhatIncluded from "../common/included";
import { useQuery } from "@apollo/react-hooks";
import { hashValueRev } from './hashFunc/HashFunc';
const publicIp = require("react-public-ip");


function QuotationPart() {
  const [checked, setChecked] = React.useState(false);
  const [showbtn, setShowbtn] = React.useState(false);
  const [curr, setCurr] = useState(null);
  const [currBrand, setCurrBrand] = useState(null);
  const [currModel, setCurrModel] = useState(null);
  const [promoData, setPromoData] = useState([]);
  const [promo, setPromo] = useState("");
  const [payGatewayForm, setPayGatewayForm] = useState(null);
  const [payMethod, setPayMethod] = useState(null);

  var { data } = useQuery(GETAllBRANDS);
  const brand = data?.getAllBrands?.allVehicleRes;

  var { data: stateData } = useQuery(GETALLSTATES);
  const stateInfo = stateData?.getAllState?.allStateRes;

  const handleChecked = () => {
    setChecked(!checked);
  };
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const quoteId = localStorage.getItem("quote_id");

  // const { onSubmit, onChange, values } = useForm(handleSubmit, {
  //   quotationId: `${quoteId}`,
  //   nric: "",
  //   state: "",
  // });


  const { onChange, onSubmit, values } = useForm(handleSubmit, {
    email: `${localStorage.getItem("owner_email")}`,
    year: `${localStorage.getItem("owner_year")}`,
    userName: `${localStorage.getItem("owner_name")}`,
    mobile: `${localStorage.getItem("owner_mobile")}`,
    modelId: `${localStorage.getItem("modelId")}`,
    brandId: `${localStorage.getItem("brandId")}`,
    userId: `${localStorage.getItem("user_id")}`,
    nric: `${localStorage.getItem("nric_num")}`,
    mileage: `${localStorage.getItem("mileage_num")}`,
    stateId: "",
    discount: promoData?.deductedAmount,
    price: promoData?.discountAmount
  });



  localStorage.setItem("state", values.stateId);

  const [addUser, { loading }] = useMutation(SIGNUP, {
    update(_, { data: { registerUser: userData } }) {

      localStorage.setItem("user_id", userData.userId);
      const userId = localStorage.getItem("user_id");
      const dealerId = localStorage.getItem("dealer_id");
      const Role = localStorage.getItem("role");

      let userbookingValue;
      if (Role === "dealer") {
        userbookingValue = dealerId;
      } else {
        userbookingValue = userId;
      }

      addQuotation({
        variables: {
          email: values.email,
          year: values.year,
          userName: values.userName,
          mobile: values.mobile,
          modelId: values.modelId,
          brandId: values.brandId,
          nric: values.nric,
          userId: userbookingValue,
          mileage: values.mileage,
          state: values.state,
          stateId: values.stateId,
          isPromocodeApplied: (promoData?.promocodeError == false) ? true : false,
          discount: promoData?.deductedAmount,
          price: promoData?.discountAmount,
          promocodeApplied: promo
        },
      });
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
    variables: values,
  });

  const [addQuotation] = useMutation(CREATE_QUOTATION, {
    update(_, { data: { createQuotation: userData } }) {
      // localStorage.setItem("owner_name", userData.userName);
      // localStorage.setItem("owner_email", userData.email);
      // localStorage.setItem("quote_id", userData.id);
      // localStorage.setItem("owner_mobile", userData.mobile);
      // localStorage.setItem("owner_brand", userData.brandName);
      // localStorage.setItem("owner_model", userData.modelName);
      // localStorage.setItem("owner_year", userData.year);
      // localStorage.setItem("owner_price", userData.price);
      // localStorage.setItem("token", userData.token);

      localStorage.setItem("quote_id", userData.id);
      const quoteId = localStorage.getItem("quote_id");

      addBooking({
        variables: {
          quotationId: quoteId,
        },
      });
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
    variables: values,
  });

  const [addBooking] = useMutation(CREATE_BOOKING, {
    update(_, { data: { bookingInit: userData } }) {
      localStorage.setItem("booking_id", userData.id);
      const bookingId = localStorage.getItem("booking_id");
      addPayment({
        variables: {
          bookingId: bookingId,
        },
      });
      setShowbtn(!showbtn);
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
    variables: values,
  });

  const hashValue = function (s) {
    var chrsz = 8;
    var hexcase = 0;

    function safe_add(x, y) {
      var lsw = (x & 0xffff) + (y & 0xffff);
      var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
      return (msw << 16) | (lsw & 0xffff);
    }

    function S(X, n) {
      return (X >>> n) | (X << (32 - n));
    }
    function R(X, n) {
      return X >>> n;
    }
    function Ch(x, y, z) {
      return (x & y) ^ (~x & z);
    }
    function Maj(x, y, z) {
      return (x & y) ^ (x & z) ^ (y & z);
    }
    function Sigma0256(x) {
      return S(x, 2) ^ S(x, 13) ^ S(x, 22);
    }
    function Sigma1256(x) {
      return S(x, 6) ^ S(x, 11) ^ S(x, 25);
    }
    function Gamma0256(x) {
      return S(x, 7) ^ S(x, 18) ^ R(x, 3);
    }
    function Gamma1256(x) {
      return S(x, 17) ^ S(x, 19) ^ R(x, 10);
    }

    function core_sha256(m, l) {
      var K = new Array(
        0x428a2f98,
        0x71374491,
        0xb5c0fbcf,
        0xe9b5dba5,
        0x3956c25b,
        0x59f111f1,
        0x923f82a4,
        0xab1c5ed5,
        0xd807aa98,
        0x12835b01,
        0x243185be,
        0x550c7dc3,
        0x72be5d74,
        0x80deb1fe,
        0x9bdc06a7,
        0xc19bf174,
        0xe49b69c1,
        0xefbe4786,
        0xfc19dc6,
        0x240ca1cc,
        0x2de92c6f,
        0x4a7484aa,
        0x5cb0a9dc,
        0x76f988da,
        0x983e5152,
        0xa831c66d,
        0xb00327c8,
        0xbf597fc7,
        0xc6e00bf3,
        0xd5a79147,
        0x6ca6351,
        0x14292967,
        0x27b70a85,
        0x2e1b2138,
        0x4d2c6dfc,
        0x53380d13,
        0x650a7354,
        0x766a0abb,
        0x81c2c92e,
        0x92722c85,
        0xa2bfe8a1,
        0xa81a664b,
        0xc24b8b70,
        0xc76c51a3,
        0xd192e819,
        0xd6990624,
        0xf40e3585,
        0x106aa070,
        0x19a4c116,
        0x1e376c08,
        0x2748774c,
        0x34b0bcb5,
        0x391c0cb3,
        0x4ed8aa4a,
        0x5b9cca4f,
        0x682e6ff3,
        0x748f82ee,
        0x78a5636f,
        0x84c87814,
        0x8cc70208,
        0x90befffa,
        0xa4506ceb,
        0xbef9a3f7,
        0xc67178f2
      );
      var HASH = new Array(
        0x6a09e667,
        0xbb67ae85,
        0x3c6ef372,
        0xa54ff53a,
        0x510e527f,
        0x9b05688c,
        0x1f83d9ab,
        0x5be0cd19
      );
      var W = new Array(64);
      var a, b, c, d, e, f, g, h, i, j;
      var T1, T2;

      m[l >> 5] |= 0x80 << (24 - (l % 32));
      m[(((l + 64) >> 9) << 4) + 15] = l;

      for (var i = 0; i < m.length; i += 16) {
        a = HASH[0];
        b = HASH[1];
        c = HASH[2];
        d = HASH[3];
        e = HASH[4];
        f = HASH[5];
        g = HASH[6];
        h = HASH[7];

        for (var j = 0; j < 64; j++) {
          if (j < 16) W[j] = m[j + i];
          else
            W[j] = safe_add(
              safe_add(
                safe_add(Gamma1256(W[j - 2]), W[j - 7]),
                Gamma0256(W[j - 15])
              ),
              W[j - 16]
            );

          T1 = safe_add(
            safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]),
            W[j]
          );
          T2 = safe_add(Sigma0256(a), Maj(a, b, c));

          h = g;
          g = f;
          f = e;
          e = safe_add(d, T1);
          d = c;
          c = b;
          b = a;
          a = safe_add(T1, T2);
        }

        HASH[0] = safe_add(a, HASH[0]);
        HASH[1] = safe_add(b, HASH[1]);
        HASH[2] = safe_add(c, HASH[2]);
        HASH[3] = safe_add(d, HASH[3]);
        HASH[4] = safe_add(e, HASH[4]);
        HASH[5] = safe_add(f, HASH[5]);
        HASH[6] = safe_add(g, HASH[6]);
        HASH[7] = safe_add(h, HASH[7]);
      }
      return HASH;
    }

    function str2binb(str) {
      var bin = Array();
      var mask = (1 << chrsz) - 1;
      for (var i = 0; i < str.length * chrsz; i += chrsz) {
        bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
      }
      return bin;
    }

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    }

    function binb2hex(binarray) {
      var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
      var str = "";
      for (var i = 0; i < binarray.length * 4; i++) {
        str +=
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
      }
      return str;
    }

    s = Utf8Encode(s);
    return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
  };

  let iPayMerchantKey = process.env.REACT_APP_IPAY_MERCHANT_KEY;
  let iPayMerchantCode = process.env.REACT_APP_IPAY_MERCHANT_CODE;

  function handleSign() {
    const price = +localStorage.getItem("owner_price");
    localStorage.setItem("calculated_price", String(price));
    const price_str = localStorage.getItem("calculated_price");
    const paymentId = localStorage.getItem("payment_id");
    const result = iPayMerchantKey + iPayMerchantCode + `${paymentId}` + `${price_str}` + "MYR";
    // const result = "D9ZO8VzbaR" + "M39459" + `${paymentId}` + "100" + "MYR";
    const signature_ipay = hashValue(result);
    localStorage.setItem("signature_ipay", signature_ipay);
  }

  const custIPAdd = async () => {
    const ipv4 = await publicIp.v4() || "";
    localStorage.setItem("customer_IP", ipv4);
  }

  let revpayMerchantId = process.env.REACT_APP_REVPAY_MERCHANT_ID;
  let revpayMerchantKey = process.env.REACT_APP_REVPAY_MERCHANT_KEY;


  function handleRevPaySign() {
    custIPAdd();
    const price = +localStorage.getItem("owner_price");
    localStorage.setItem("calculated_price", String(price));
    const price_str = localStorage.getItem("calculated_price");
    const referenceNumber = localStorage.getItem("payment_id");
    const result = revpayMerchantKey + revpayMerchantId + `${referenceNumber}` + `${price_str}` + "MYR";
    const signature_rev = hashValueRev(result);
    localStorage.setItem("signature_rev", signature_rev);
  }

  const [addPayment] = useMutation(CREATE_PAYMENT, {
    update(_, { data: { createPayment: userData } }) {
      localStorage.setItem("payment_id", userData.id);
      handleSign();
      handleRevPaySign();
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
  });

  function handleSubmit() {
    localStorage.setItem("owner_name", values.userName);
    localStorage.setItem("nric_num", values.nric);
    localStorage.setItem("mileage_num", values.mileage);

    if (
      !values.userName ||
      !values.email ||
      !values.mobile ||
      !values.year ||
      !values.modelId ||
      !values.brandId ||
      !values.nric ||
      !values.mileage
    ) {
      toast("Please fill all the fields");
      return;
    } else if (checked === false) {
      toast("Please accept the terms and conditions");
      return;
    }
    if (parseInt(values.mileage) >= 200000) {
      toast("Mileage should not exceed 200,000 limit");
      return;
    }
    addUser({
      variables: {
        name: values.userName || "",
        email: values.email || "",
        mobile: values.mobile || "",
      },
    });
  }

  function handleHome() {
    localStorage.setItem("language", "false");
    navigate("/");
  }

  localStorage.setItem("brandId", values.brandId);
  const brandId = localStorage.getItem("brandId");

  var { data } = useQuery(GETAllMODELS, { variables: { brandId } });
  const model = data?.getBrandById?.allVehicleModelRes;
  let priceValue = model?.filter((item2) => {
    if (item2.id === values.brandId) {
      return item2.price;
    }

    localStorage.setItem("owner_price", item2.price);
  });


  const [addPromotionCode] = useMutation(APPLYPROMOCODEWITHOUTLOGIN, {
    update(_, { data: { applyPromocodeWithoutLogin: promotionData } }) {
      if (promotionData.promocodeError == false) {
        setPromoData(promotionData);
        setPromo(promo);
        toast(`${promotionData?.promocodeStatus}`);
      } else {
        toast(`${promotionData?.promocodeStatus}`);
      }
    },
    
  });

  if(promoData.promocodeError == false){
    localStorage.setItem("owner_price",promoData.discountAmount)
  }

  const codeSubmit = () => {
    addPromotionCode({
      variables: {
        modelId: `${localStorage.getItem("modelId")}`,
        promoCode: promo.trim()
      },
    });
  }



  return (
    <section>
      <header
        className="py-3 justify-content-center"
        style={{ display: "flex" }}
      >
        <Image src={eautogen_product} fluid width="400px" height="400px" />
      </header>
      <ToastContainer />
      <Container>
        <Row>
          <Col md={12}>
            <div className="section-title mb-5">
              <span>AN AFFORDABLE WAY TO LIMIT YOUR CAR REPAIR EXPENSES</span>
              <h2>
                <span style={{ color: "#225D8E" }}>YOUR</span> AUTOGEN PLUS LEAD{" "}
                <span style={{ color: "#225D8E" }}>QUOTATION</span>{" "}
              </h2>
              <p className="mt-5">
                This is a computer generated quotation, no signature is
                required.{" "}
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="car_image">
            <Image src={car_wireframe} fluid />
          </Col>
          <Col md={6}>
            <div className="customer_details">
              <Row style={{ border: "1px solid #D3D3D3" }} className="mt-5 p-3">
                <Row>
                  <Col md={10}>
                    <table style={{ width: "100%" }}>
                      <h1
                        style={{
                          marginTop: "15px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          fontSize: "24px",
                        }}
                      >
                        Car Details
                      </h1>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>Owner Name:</th>
                        <td>
                          <Form.Group
                            style={{ marginTop: "15px" }}
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              name="userName"
                              required="required"
                              value={values.userName}
                              // onChange={onChange}
                              placeholder="e.g. Tan Eng"
                            />
                          </Form.Group>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>Car Brand:</th>
                        <td>
                          <Form.Select
                            onChange={onChange}
                            value={values.brandId}
                            name="brandId"
                            id="fruit-select"
                          >
                            <option>Select</option>
                            {brand?.map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.brand}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>Car Model: </th>
                        <td>
                          {" "}
                          <Form.Select
                            aria-label="Default select example"
                            onChange={onChange}
                            value={values.modelId}
                            name="modelId"
                          >
                            <option>Select</option>
                            {model?.map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.modelName}
                              </option>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>Car Year Make :</th>
                        <td>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={onChange}
                            value={values.year}
                            name="year"
                          >
                            <option>Select </option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                          </Form.Select>
                        </td>
                      </tr>
                    </table>
                  </Col>
                </Row>

                <h2
                  className="mt-3"
                  style={{ fontSize: "24px", fontWeight: "bold" }}
                >
                  Customer Details
                </h2>

                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="userName"
                      required="required"
                      value={values.userName}
                      onChange={onChange}
                      placeholder="e.g. Tan Eng"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      required="required"
                      value={values.email}
                      onChange={onChange}
                      placeholder="e.g. abg@gmail.com"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Phone Number </Form.Label>
                    <Form.Control
                      type="number"
                      name="mobile"
                      required="required"
                      value={values.mobile}
                      onChange={onChange}
                      placeholder="e.g. 0123456789"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ border: "1px solid #D3D3D3" }} className="mt-5 p-3">
                <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
                  Additional Details Required <span>*</span>{" "}
                </h2>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>NRIC Number<span className="text-danger">*</span>{" "}</Form.Label>
                    <Form.Control
                      type="number"
                      name="nric"
                      required="required"
                      value={values.nric}
                      onChange={onChange}
                      placeholder="e.g. 810202101234"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>
                      Mileage<span className="text-danger">*</span>{" "}
                    </Form.Label>

                    <Form.Control
                      type="number"
                      name="mileage"
                      required="required"
                      placeholder="0 To 200,000 kilometers."
                      onChange={onChange}
                      value={values.mileage}
                    />

                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={onChange}
                      name="stateId"
                      value={values.stateId}
                    >
                      <option value="" selected="selected">Select a state ... </option>
                      {stateInfo?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ border: "1px solid #D3D3D3" }} className="mt-5 p-3">
                <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
                  Promo Code <span>*</span>{" "}
                </h2>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    style={{ position: "relative" }}
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Add Promo Code<span className="text-danger">*</span>{" "}</Form.Label>
                    <Form.Control
                      type="text"
                      value={promo}
                      onChange={(e) => setPromo(e.currentTarget.value)}
                      class="form-control" id="inputPassword2"
                      placeholder="Apply Promotion Code"
                      disabled={promoData.promocodeError == false ? true : false}
                    />
                    {promoData.promocodeError == false ? "" : (
                      <button type="" className="btn p-0 position-absolute"
                        style={{ color: "#32bec0", fontWeight: "600", top: "50%", right: "15px" }}
                        onClick={codeSubmit}
                      >
                        Apply
                      </button>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {
                promoData.promocodeError === false ? (
                <>
                  <Row style={{ border: "1px solid #D3D3D3" }} className="mt-5 p-3">
                    <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
                      Payment Details 
                    </h2>
                    <Row>
                      <Col md={4}>
                        <p><b>Amount</b> :</p>
                      </Col>
                      <Col md={8}>
                        <p>RM <span>{promoData.quotationPrice}</span>.00</p>
                      </Col>

                      <Col md={4}>
                        <p><b>Deduction</b> :</p>
                      </Col>
                      <Col md={8}>
                        <p>RM <span>-{" "}{promoData.deductedAmount}</span></p>
                      </Col>

                      <Col md={4}>
                        <p><b>Grand Total</b> :</p>
                      </Col>
                      <Col md={8}>
                        <p>RM <span>{promoData.discountAmount}</span></p>
                      </Col>
                    </Row>
                  </Row>
                  </>
                ) : ( " " )
              }
                


            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row
          className="justify-content-center"
          style={{
            background: "#22BBB9",
            paddingTop: "35px",
            paddingBottom: "35px",
          }}
        >
          <Col md={4} className="justify-content-center text-center">
            <strong>Get AutoGen PLUS for only</strong>
            <div className="section-title-annun align-items-end">
              <h2 className="mb-0" style={{ color: "#ffffff" }}>
                RM{" "}
                <span style={{ color: "#ffffff" }}>
                  {promoData.promocodeError == false ? (promoData.discountAmount) : localStorage.getItem("owner_price")}
                </span>
              </h2>
              <h5>/ANNUM</h5>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="mt-5">
        <WhatIncluded />
      </div>

      <Container fluid>
        <Row
          className="justify-content-center"
          style={{
            background: "#22BBB9",
            paddingTop: "35px",
            paddingBottom: "35px",
          }}
        >
          <Col md={4} className="justify-content-center text-center">
            <strong>Get AutoGen PLUS for only</strong>
            <div className="section-title-annun align-items-end text-center">
              <h2 className="mb-0" style={{ color: "#ffffff" }}>
                RM{" "}
                <span style={{ color: "#ffffff" }}>
                  {promoData.promocodeError == false ? (promoData.discountAmount) : localStorage.getItem("owner_price")}
                </span>
              </h2>
              <h5>/ANNUM</h5>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col md={8} className="d-flex m-auto mt-5">
            <Form.Check className="mb-3">
              {" "}
              <Form.Check.Input
                type="checkbox"
                onChange={handleChecked}
                required="required"
              />
              <Form.Check.Label>
                I have read and agreed to the Terms and Conditions stipulated
                and will abide by it. eAutoGen reserves the right of final
                decision in the case of disputes and may at any appropriate time
                and at its sole discretion update the Terms and Conditions of
                use.
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        
        <div className="justify-content-center text-center mt-5">
          <Button variant="secondary" className="gethassle" onClick={onSubmit}>
            BUY NOW
          </Button>
        </div>

        {showbtn === true && (
          <>
            {
              localStorage.getItem("owner_price") != '0.00' ? <div>
                <Row
                  className="justify-content-between align-item-center mt-5"
                  style={{
                    display: "flex",
                    width: "60%",
                    margin: "auto",
                    boxShadow: "0px 2px 8px 7px #eaebed",
                    padding: "15px",
                  }}
                >
                  <Col
                    md={3}
                    className="align-item-center"
                    style={{ position: "relative", top: "9px" }}
                  >
                    <Form.Check className="mb-3">
                      {" "}
                      <Form.Check.Input
                        type="radio"
                        required="required"
                        name="payOption"
                        value="iPay88"
                        checked={payGatewayForm === 'iPay88'}
                        onChange={(e) => setPayGatewayForm(e.currentTarget.value)}
                      />
                      <Form.Check.Label>iPay88</Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col
                    md={3}
                    style={{ position: "relative", top: "2px", left: "32px" }}
                  >
                    <Image src={ipay} />
                  </Col>
                </Row>

                {/* revPay pay method  */}
                <Row
                  className="justify-content-between align-item-center mt-4"
                  style={{
                    display: "flex",
                    width: "60%",
                    margin: "auto",
                    // background: "#F5F6F8",
                    boxShadow: "0px 2px 8px 7px #eaebed",
                    padding: "15px",
                  }}
                >
                  <Col
                    md={3}
                    className="align-item-center"
                    style={{ position: "relative", top: "9px" }}
                  >
                    <Form.Check className="mb-3">
                      {" "}
                      <Form.Check.Input
                        type="radio"
                        required="required"
                        name="payOption"
                        value="revPay"
                        checked={payGatewayForm === 'revPay'}
                        onChange={(e) => setPayGatewayForm(e.currentTarget.value)}
                      />
                      <Form.Check.Label>revPAY</Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col
                    md={3}
                    style={{ position: "relative", top: "2px", left: "32px" }}
                  >
                    <Image src={reyPay} width="103px" height="36px" />
                  </Col>
                </Row>
              </div> : <div>
                          <Row>
                            <Col className="text-center mt-5">
                              <form
                                method="post"
                                name="ePayment"
                                action="https://uat.eautogen.my/payment/promocode"
                              >
                                <input
                                  type="hidden"
                                  name="booking_id"
                                  value={localStorage.getItem("booking_id")}
                                />
                                <input
                                  type="hidden"
                                  name="ResponseURL"
                                  value="https://uat.eautogen.my/payment/response"
                                  // value="https://www.eautogen.my/payment/response"
                                />
                                <input
                                  type="hidden"
                                  name="BackendURL"
                                  value="https://uat.eautogen.my/payment/backend"
                                  // value="https://www.eautogen.my/payment/backend"
                                />
                                <input
                                  type="submit"
                                  value="PROCEED"
                                  name="Submit"
                                  className="submit-btn text-center"
                                  style={{
                                    backgroundColor: "#17BEBB",
                                    border: "none",
                                    color: "white",
                                    padding: "10px 20px",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    borderRadius: "5px",
                                  }}
                                />
                              </form>
                            </Col>
                          </Row>
                        </div>
            }
          </>
        )}
      </Container>

      <Container>
        <Row className="justify-content-center text-center mt-5 ">
          {payGatewayForm === "iPay88" && (
            <Col md={3}>
              <form
                method="post"
                name="ePayment"
                action="https://payment.ipay88.com.my/ePayment/entry.asp"
              >
                <input type="hidden" name="MerchantCode" value="M39459" />
                <input type="hidden" name="PaymentId" value="" />
                <input
                  type="hidden"
                  name="RefNo"
                  value={localStorage.getItem("payment_id")}
                />
                <input type="hidden" name="Amount" value={localStorage.getItem("calculated_price")} />
                {/* <input type="hidden" name="Amount" value="1.00" /> */}
                <input type="hidden" name="Currency" value="MYR" />
                <input type="hidden" name="ProdDesc" value="Payment" />

                <input
                  type="hidden"
                  name="UserName"
                  value={localStorage.getItem("owner_name")}
                />
                <input
                  type="hidden"
                  name="UserEmail"
                  value={localStorage.getItem("owner_email")}
                />
                <input
                  type="hidden"
                  name="UserContact"
                  value={localStorage.getItem("owner_mobile")}
                />
                <input type="hidden" name="Remark" value="" />
                <input type="hidden" name="Lang" value="UTF-8" />
                <input type="hidden" name="SignatureType" value="SHA256" />
                <input
                  type="hidden"
                  name="Signature"
                  value={localStorage.getItem("signature_ipay")}
                />
                <input
                  type="hidden"
                  name="ResponseURL"
                  value="https://uat.eautogen.my/payment/response"
                  // value="https://www.eautogen.my/payment/response"
                />
                <input
                  type="hidden"
                  name="BackendURL"
                  value="https://uat.eautogen.my/payment/backend"
                  // value="https://www.eautogen.my/payment/backend"
                />

                <input type="hidden" name="Xfield1" value="" />
                <input
                  type={showbtn === true ? "submit" : "hidden"}
                  value="PROCEED TO PAYMENT"
                  name="Submit"
                  style={{
                    backgroundColor: "#17BEBB",
                    border: "none",
                    color: "white",
                    padding: "10px 20px",
                    fontSize: "20px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                />
              </form>
            </Col>
          )}


          {payGatewayForm === "revPay" && (
            <>
              <Col md={10}>
                <div className="payment_box payment_method_revpay">
                  <p>Pay with revPAY </p>
                  <div className="form-row form-row-wide">
                    <label>Select yout payment method<span className="required">*</span></label><br />
                    <div className="pay-method form-check">

                      <div className="method-wrap">
                        <Form.Check.Input
                          type="radio"
                          required="required"
                          name="payMethod"
                          value="2"
                          checked={payMethod === '2'}
                          onChange={(e) => setPayMethod(e.currentTarget.value)}
                        /> Visa / Mastercard <div className="pay-img"><img src={visa} width="140px" /></div>
                      </div>

                      <div className="method-wrap">
                        <Form.Check.Input
                          type="radio"
                          required="required"
                          name="payMethod"
                          value="3"
                          checked={payMethod === '3'}
                          onChange={(e) => setPayMethod(e.currentTarget.value)}
                        /> FPX <div className="pay-img"><img src={fpx} width="140px" /></div>
                      </div>

                      <div className="method-wrap">
                        <Form.Check.Input
                          type="radio"
                          required="required"
                          name="payMethod"
                          value="28"
                          checked={payMethod === '28'}
                          onChange={(e) => setPayMethod(e.currentTarget.value)}
                        /> Touch n Go <div className="pay-img"><img src={touchngo} width="140px" /></div>
                      </div>

                      <div className="method-wrap">
                        <Form.Check.Input
                          type="radio"
                          required="required"
                          name="payMethod"
                          value="11"
                          checked={payMethod === '11'}
                          onChange={(e) => setPayMethod(e.currentTarget.value)}
                        /> Boost <div className="pay-img"><img src={boost} width="140px" /></div>
                      </div>

                      <div className="method-wrap">
                        <Form.Check.Input
                          type="radio"
                          required="required"
                          name="payMethod"
                          value="12"
                          checked={payMethod === '12'}
                          onChange={(e) => setPayMethod(e.currentTarget.value)}
                        /> Maybank QR <div className="pay-img"><img src={maybank} width="120px" height="18px" /></div>
                      </div>

                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <form
                  method="post"
                  name="ePayment"
                  action="https://gateway.revpay.com.my/payment"
                >

                  <input type="hidden" name="Revpay_Merchant_ID" value="MER00000128183" />
                  <input type="hidden" name="Payment_ID" value={payMethod} />
                  <input type="hidden" name="Bank_Code" value="" />
                  <input type="hidden" name="Reference_Number" value={localStorage.getItem("payment_id")} />
                  <input type="hidden" name="Amount" value={localStorage.getItem("calculated_price")} />
                  <input type="hidden" name="Currency" value="MYR" />

                  <input type="hidden"
                    name="Transaction_Description"
                    value="OeAutoGen Quatation"
                  />

                  <input type="hidden" name="Billing_Address" value="" />
                  <input type="hidden" name="Shipping_Address" value="" />
                  <input type="hidden" name="Device_ID" value="" />
                  <input type="hidden" name="Ecomm_Marketplace" value="" />
                  <input type="hidden" name="Promo_Code" value="" />
                  <input type="hidden" name="Transaction_Type" value="" />
                  <input type="hidden" name="Customer_ID" value="" />
                  <input type="hidden" name="Merchant_key" value="wF7jkXHBAR" />
                  <input
                    type="hidden"
                    name="UserName"
                    value={localStorage.getItem("owner_name")}
                  />
                  <input
                    type="hidden"
                    name="UserEmail"
                    value={localStorage.getItem("owner_email")}
                  />
                  <input
                    type="hidden"
                    name="UserContact"
                    value={localStorage.getItem("owner_mobile")}  //customer contact number
                  />
                  <input type="hidden" name="customer_IP" value={localStorage.getItem("customer_IP")} />
                  <input type="hidden" name="Card_Type" value="" />
                  <input type="hidden" name="Card_Holder_Name" value="" />
                  <input type="hidden" name="Funding_Pan" value="" />
                  <input type="hidden" name="Funding_Exp_Date" value="" />
                  <input type="hidden" name="Funding_CVV" value="" />
                  <input type="hidden" name="Card_Issuer_Bank_Country_Code" value="" />
                  <input type="hidden" name="Instalment_Plan" value="" />
                  <input type="hidden" name="Instalment_Term" value="" />
                  <input type="hidden" name="Token_Pan" value="" />
                  <input type="hidden" name="Token_Exp_Date" value="" />
                  <input type="hidden" name="Key_Index" value="1" />
                  <input type="hidden" name="SignatureType" value="SHA512" />
                  <input
                    type="hidden"
                    name="Signature"
                    value={localStorage.getItem("signature_rev")}
                  />
                  <input
                    type="hidden"
                    name="Return_URL"
                    value="https://uat.eautogen.my/payment/response"
                    // value="https://www.eautogen.my/payment/response"
                  />

                  <input
                    type="hidden"
                    name="Backend_URL"
                    // value="https://www.eautogen.my/payment/backend"
                  value="https://uat.eautogen.my/payment/backend"
                  />
                  <input
                    type={showbtn === true ? "submit" : "hidden"}
                    value="PROCEED TO PAYMENT"
                    name="Submit"
                    style={{
                      backgroundColor: "#17BEBB",
                      border: "none",
                      color: "white",
                      padding: "10px 20px",
                      fontSize: "20px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      margin: "10px 0 20px",
                    }}
                  />
                </form>
              </Col>
            </>
          )}

        </Row>
      </Container>
    </section>
  );
}

export default QuotationPart;
