import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { GETAllBRANDS } from "../../../queries/userQueries";
import { GETAllMODELS } from "../../../queries/userQueries";
import { GETQUOTATION } from "../../../mutations/userMutations";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/client";
import { useForm } from "../../../utils/hooks";
import { toast, ToastContainer } from "react-toastify";
import { TabContext } from "../../../Context/TabContext";
import { DatePicker } from "rsuite";
function Buyplanwizard() {
  const activeTab = useContext(TabContext);
  const { handleTab } = useContext(TabContext);
  const [errors, setErrors] = useState({});

  const { onChange, onChangeMulti, onSubmit, values } = useForm(handleSubmit, {
    email: "",
    year: "",
    modelId: "",
    modelName: "",
    userName: "",
    brandId: "",
    mobile: "",
    userId: `${localStorage.getItem("dealer_id")}`,
    mileage: ""
  });
  
  const navigate = useNavigate();
  localStorage.setItem("brandId", values.brandId);
  localStorage.setItem("modelId", values.modelId);
  localStorage.setItem("owner_year", values.year);
  localStorage.setItem("mileage", values.mileage);
  const [addUser] = useMutation(GETQUOTATION, {
    update(_, { data: { createQuotation: userData } }) {
      handleTab("CustomerDetails");
    },
    variables: values,
  });

  var { data } = useQuery(GETAllBRANDS);
  const brand = data?.getAllBrands?.allVehicleRes;

  const brandId = localStorage.getItem("brandId");

  var { data } = useQuery(GETAllMODELS, { variables: { brandId } });
  const model = data?.getBrandById?.allVehicleModelRes;

  function handleSubmit() {
    console.log("values", values);
    if (!values.year || !values.modelId || !values.brandId || !values.mileage) {
      toast("Please Fill All The Fields");
      return;
    }
    if (parseInt(values.mileage) >= 200000) {
      toast("Mileage should not exceed 200,000 limit");
      return;
    }
    addUser();
  }

  function test(e) {
    onChangeMulti("year",new Date(e));
  }


  return (
    <>
      <ToastContainer />
      <div className="payment-box">
        <div className="authentication-inputs">
          <h1>Car Details</h1>
          <p className="mb-3">Input car model, brand & year</p>
          {/* <Form noValidate validated={validated} onSubmit={onSubmit}> */}
          <Form>
            <Form.Group
              className="mb-3 "
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Brand</Form.Label>

              <Form.Select
                onChange={onChange}
                name="brandId"
                id="fruit-select"
              >
                <option>Select Car Brand</option>
                {brand?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.brand}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Car Model<span className="text-danger">*</span>{" "}
              </Form.Label>
              {/* <Form.Select aria-label="Default select example">
                      <option>Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select> */}

              <Form.Select
                aria-label="Default select example"
                onChange={onChange}
                name="modelId"
              >
                <option>Select Car Model</option>
                {model?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.modelName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {/* <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Car Year<span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={onChange}
                name="year"
              >
                <option>Select</option>
                {[...Array(16).keys()].map(foo => new Date().getFullYear() - foo).map((yr)=>(
                  <option value={yr} key={yr}>{yr}</option>
                ))}
              </Form.Select>
            </Form.Group> */}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Car Year<span className="text-danger">*</span>{" "}
              </Form.Label>
              <DatePicker name="year" onChange={test} className="w-100"/>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Mileage<span className="text-danger">*</span>{" "}
              </Form.Label>

              <Form.Control
                type="number"
                name="mileage"
                required="required"
                placeholder="0 To 200,000 kilometers."
                onChange={onChange}
              />

            </Form.Group>

            <div className="d-flex justify-content-between  mt-5">
              <Link to="/" className="btn btn-outline-secondary btn-md">
                {" "}
                Back
              </Link>
              <button onClick={onSubmit} className="btn btn-secondary btn-md">
                {" "}
                SAVE & CONTINUE
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Buyplanwizard;
