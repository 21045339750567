import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
// import Carousel from "react-bootstrap/Carousel";
import Carousel from "react-grid-carousel";
import Brand1 from "../../../assets/img/1bm.png";
import Brand2 from "../../../assets/img/2bm.png";
import Brand3 from "../../../assets/img/3bm.png";
import Brand4 from "../../../assets/img/4bm.png";
import Brand5 from "../../../assets/img/5bm.png";
import "./BrandSlider.css";
export const BrandSlider = () => {
  return (
    <div className="py-5 ">
      <Container className="brandslider_div">
        <Row className="justify-content-center ">
          <Col lg={6} className="section-title text-lg-start">
            <p>KELAYAKAN PROGRAM AUTOGEN PLUS</p>
            <Col md={10}>
              <h2>
                <span>Program Waranti Lanjutan AutoGen PLUS</span>
              </h2>
            </Col>
          </Col>
          <Col lg={6} className="brandslider_right">
            <p>
              Dapatkan ketenangan fikiran dengan AutoGen PLUS yang akan
              mengurangkan beban anda daripada kos pembaikan enjin dan kerosakan
              transmisi yang mahal. <br />
              <span className="program_eligibilty">Kelayakan Program:</span>
            </p>

            <ul>
              <li>
                <p>
                  <strong>• Kereta kegunaan persendirian </strong>(tidak
                  termasuk e-hailing), dan
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  <strong>• Usia kereta: sehingga 10 tahun</strong> (dari tarikh
                  pendaftaran), dan
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    • Bacaan odometer: kurang daripada 200,000 kilometer.
                  </strong>
                </p>
              </li>
            </ul>
            <p>
              Pelbagai jenama dan modal kereta yang boleh diterima; sila pilih
              daripada 4 kategori utama:{" "}
            </p>
          </Col>
        </Row>
        <Container>
          <Row width="100%" className="brandcarausal">
            <Carousel cols={1} rows={1} showDots={true} loop>
              <Carousel.Item className="justify-content-center">
                <Col md={12} className="justify-content-center">
                  <Image src={Brand5} fluid width="100%" />
                </Col>
              </Carousel.Item>
              <Carousel.Item className="justify-content-center">
                <Col md={12} className="justify-content-center">
                  <Image src={Brand1} fluid width="100%" />
                </Col>
              </Carousel.Item>
              <Carousel.Item>
                <Image src={Brand2} fluid width="100%" />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={Brand3} fluid width="100%" />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={Brand4} fluid width="100%" />
              </Carousel.Item>
            </Carousel>

            {/* <Carousel>
            <Carousel.Item>
              <Image src={Brand1} fluid />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={Brand2} fluid />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={Brand3} fluid />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={Brand4} fluid />
            </Carousel.Item>
          </Carousel> */}

            <p>
              PENAFIAN AFILIASI: Logo yang digunakan adalah untuk tujuan
              ilustrasi sahaja. Kami tidak mempunyai pertalian, asosiasi,
              diizinkan, disokong oleh, atau dalam apa-apa bentuk berhubung
              secara rasmi dengan atau mana-mana anak syarikatnya atau
              afiliasinya.
            </p>

            <Col md={8} className="d-flex m-auto">
              <a
                className=" text-center m-auto"
                style={{ textDecoration: "none" }}
                onClick={() => window.scrollTo(0, window.innerHeight)}
              >
                <Button className="getstarted" variant="secondary">
                DAPATKAN SEBUT HARGA
                </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
