import React from 'react'
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useLocation } from 'react-router-dom';
import  Header  from '../../admin/components/common/header'

function ShowCredit() {
    const location = useLocation();
    const creditAmt = location.state.creditAmt ;

    return (
        <>
            <Header />

            <Container className='mt-5'>
                <Row>
                    <Col md={6} className="m-auto ">
                        <section className="justify-content-center" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                            <div className="pt-5" style={{fontSize:"20px",fontWeight:"700",marginLeft:"40px"}}>My Credit</div>
                            <Card className="justify-content-center" style={{ backgroundColor: "#fff", height: "200px" }}>
                                <CardBody>
                                    <div className=''>
                                        <form>
                                            <div className="form-group">
                                                <label style={{fontSize:"16px",fontWeight:"700"}} >Current Credit Amount</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={creditAmt} disabled readonly />
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>
                        </section>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default ShowCredit
