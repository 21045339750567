import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Periodic from "../../../assets/img/periodic-calender.png";
import "./PeriodicCar.css";
import Table from "react-bootstrap/Table";

export const PeriodicCar = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <Container style={{ width: "80%" }}>
        <Row>
          <Col
            md={6}
            style={{
              disply: "flex",
              textAlign: "right",
            }}
          >
            <Image src={Periodic} fluid />
          </Col>
          <Col md={6} className="periodic-left">
            <h1>
              AUTOGEN PLUS PROGRAM <br /> MAINTENANCE REQUIREMENT
            </h1>
            
              <h2>Periodic Car Maintenance  Requirement</h2>
              
          
            <p>
              <br />
              Cultivate good car owner behavior by keeping your program
              activated. You are required to perform your periodic car
              maintenance at your own expense at any eAutoGen's panel workshops.
              <br />
              <br />
              AutoGen PLUS periodic car maintenance schedule (as per recommended
              by manufacturers) as below:
            </p>
          </Col>
        </Row>
        <Row className=" justify-content-center ">
          <Table style={{ width: "85%", marginTop: "30px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    color: "white",
                    margin: "10px",
                    padding: "20px",
                    alignItems: "center",
                    width: "50%",
                    fontSize: "17px",
                    border: "5px solid white",
                    background: "#0C4C81",
                  }}
                >
                  <p className="lubricant">Car Lubricant Type</p>{" "}
                </th>
                <th
                  md={5}
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    color: "white",
                    margin: "10px",
                    border: "5px solid white",
                    width: "50%",
                    fontSize: "17px",
                    padding: "20px",
                    background: "#0C4C81",
                  }}
                >
                  {" "}
                  AutoGen PLUS Periodic Car Maintenance Schedule
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    fontSize: "16px",
                    background: "#F4F6F6",
                  }}
                >
                  Semi-synthetic
                </td>
                <td
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    fontSize: "16px",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    background: "#F4F6F6",
                  }}
                >
                  Every 3 months (or 5,000 kilometers, whichever earlier)
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    fontSize: "16px",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    background: "#F4F6F6",
                  }}
                >
                  Fully-synthetic
                </td>
                <td
                  style={{
                    disply: "flex",
                    fontSize: "16px",
                    textAlign: "center",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    background: "#F4F6F6",
                  }}
                >
                  Every 6 months (or 10,000 kilometers, whichever earlier)
                </td>
              </tr>
            </tbody>
          </Table>
         
        </Row>
        <Row className="justify-content-center">
        <Col md={10}>
        <p>
          Please retain copies of all maintenance receipts during the term of
          the AutoGen PLUS and submission of these maintenance receipts may be
          requested during repair request.{" "}
        </p>
        </Col>
        </Row>
        <Row>
          <Col className="mt-4 text-center">
            <Button
              className="getstarted text-center"
              variant="secondary"
              onClick={() =>
                (window.location.href = "https://pgmall.my/s/IMA2/3033")
              }
            >
            Buy Now Exclusively On PGMall
            </Button>
          </Col>
        </Row>
       
      </Container>
    </div>
  );
};
