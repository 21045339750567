import React from 'react'
import { Header } from '../../components/common/header'
import { AboutBanner } from './AboutBanner/AboutBanner'
import { AboutHeader } from './AboutHeader/AboutHeader'
import { AboutSection1 } from './AboutSection1/AboutSection1'
import { AboutSection2 } from './AboutSection2/AboutSection2'
import { AboutSection3 } from './AboutSection3/AboutSection3'
import { AboutSection4 } from './AboutSection4/AboutSection4'
import Footer from '../../components/common/footer'
import { AboutSectionBM1 } from '../AboutUsBM/AboutSectionBM1/AboutSectionBM1'
import { AboutSectionBM2 } from '../AboutUsBM/AboutSectionBM2/AboutSectionBM2'
import { AboutSectionBM3 } from '../AboutUsBM/AboutSectionBM3/AboutSectionBM3'
import { AboutSectionBM4 } from '../AboutUsBM/AboutSectionBM4/AboutSectionBM4'

export const AboutUs = () => {
  return (
    <div>
        <Header/>
        <AboutHeader/>
        {
           localStorage.getItem("language") === "true" ?
           <>
           <AboutSectionBM1/>
           <AboutSectionBM2/>
           <AboutSectionBM3/>
           <AboutSectionBM4/>
           </>
           :
           <>
           <AboutSection1/>
           <AboutSection2/>
           <AboutSection3/>
           <AboutSection4/>
           </>
        }
       
        <AboutBanner/>
        <Footer/>
    </div>
  )
}
