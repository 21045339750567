import React from "react";
import Checkoutbox from "../components/Checkout/checkout";
import { Checkoutworkshop } from "../components/Checkout/checkoutworkshop";
// import CheckoutboxUnsuccessful from "../components/Checkout/checkoutUnsuccessful";
import Footer from "../components/common/footer";
import HeaderInnner from "../components/common/headerInner";
import HowItWork from "../components/common/howItWork";
import AuthorizedWorkshops from "../components/home/authorizedWorkshops";
import { GETVOUCHERCODE } from "../queries/userQueries";
import { useQuery } from "@apollo/react-hooks";
function Checkoutpage() {
      
  const bookingId = localStorage.getItem("booking_id");
  // const bookingId="62f39c24eacaccf8aa332d8a"
  var { data ,loading } = useQuery(GETVOUCHERCODE, { variables: { bookingId } });
 

  localStorage.setItem("voucher_code", data?.getBookingOnSuccess?.voucherCode);
  localStorage.setItem("voucher_message", data?.getBookingOnSuccess?.message);

  




  return (
    <>
     {
      loading ?   <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div> :
            <div>
            <Checkoutbox />
       <Checkoutworkshop/>
       <div style={{marginTop:"100px"}}>
<Footer />
     </div>
            </div>
     }
     
     
     
    
    </>
  );
}

export default Checkoutpage;
