import React, { useEffect, useState } from "react";
import Header from "../../admin/components/common/header";
import debounce from "lodash.debounce";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Pagination,
  Row,
  Table,
  Nav,
  Tab 
} from "react-bootstrap";
import { CreateUserForm } from "./CreateUserForm/CreateUserForm";
import "bootstrap/dist/css/bootstrap.min.css";
import { GETALLDEALERUSERS } from "../../queries/userQueries";
import { useQuery } from "@apollo/react-hooks";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import { ChakraProvider } from "@chakra-ui/react";
import Badge from 'react-bootstrap/Badge';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { DELETE_USER } from "../../mutations/userMutations";
import { DEALER_UPDATE_USER } from "../../mutations/userMutations";
import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { TabContext } from "../../Context/TabContext";
export const Dealeradmin = () => {
  const { userUpdate,setuserUpdate} = useContext(TabContext);
  const [errors, setErrors] = useState({});
  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);
  const [value, setValue] = React.useState("");
  
  const [modal, setModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [show, setShow] = useState(false);
  const [userDelete, setuserDelete] = useState(false);

  function toggle()
  {
    setuserUpdate(!userUpdate);
    setModal(!modal);
  }
  function toggle1()
  {
    localStorage.setItem("update_id", "");
    localStorage.setItem("update_status", "");
    localStorage.setItem("update_name", "");
    localStorage.setItem("update_email","");
    localStorage.setItem("update_mobile", "");
    localStorage.setItem("update_role","");
    setModal(!modal);
  }
 
  const createdByuserId = localStorage.getItem("user_id");
  var { data ,loading} = useQuery(GETALLDEALERUSERS, {
    variables: { createdByuserId },
  });
  const dealer_user_data = data?.getAllUserCreatedByAdmin?.allUserRes;

  const [curr, setCurr] = useState(null);
  const [curr1, setCurr1] = useState(null);

  function handleDetails(id) {
    let temp = dealer_user_data?.filter((item) => {
      if (item.id === id) {
        return item;
      }
    });
    setCurr(temp);
    // localStorage.setItem("details", temp);
    
  }
  const [deleteUser] = useMutation(DELETE_USER, {
    update(_, { data: { deleteUser: userData } }) {
    
      toast(userData.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
  });
  function handleDelete(id) {
    localStorage.setItem("delete_id", id);
    setBasicModal(!basicModal)
    
    
  }
  function finalDelete() {
    const id = localStorage.getItem("delete_id");
    deleteUser({
      variables: { 
        userId: id,
       },
    });
    setBasicModal(!basicModal)
  }
  const [updateUser] = useMutation(DEALER_UPDATE_USER);

  function handleUpdate(id) {
    let temp = dealer_user_data?.filter((item) => {
      if (item.id === id) {
        return item;
      }
    });
    setCurr1(temp);
    setuserUpdate(!userUpdate);
    setModal(!modal)
    localStorage.setItem("update_id", id);
    localStorage.setItem("update_status", temp[0].status);
    localStorage.setItem("update_name", temp[0].name);
    localStorage.setItem("update_email", temp[0].email);
    localStorage.setItem("update_mobile", temp[0].mobile);
    localStorage.setItem("update_role", temp[0].role);

   
  }


 

  

  return (
    <div>
      <ToastContainer />
      <ChakraProvider>
        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Delete User</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                Are you sure? You can't undo this action afterwards.
              </MDBModalBody>

              <MDBModalFooter>
                <Button color="secondary" style={{padding:"10px"}} onClick={toggleShow}>
                  Cancel
                </Button>
                <Button color="secondary" style={{padding:"10px"}} onClick={finalDelete}>
                  Delete
                </Button>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        <Header />
        <Tab.Container id="left-tabs-example" defaultActiveKey="Dashboard">
        <header className="home-nav">
    <Container >
      <Row>
        <Col sm={12}>
          <Nav>
            <Nav.Item>
              {/* <Nav.Link eventKey="Dashboard">
              Dashboard
              </Nav.Link> */}
            </Nav.Item>
            <Nav.Item>
              {/* <Nav.Link  >
              User Account
              </Nav.Link> */}
              <h1 style={{fontSize:"22px",color:"white",fontWeight:"bold"}}>User Account</h1>
            </Nav.Item>
          </Nav>
        </Col>
        </Row>
        </Container>
        </header>
     </Tab.Container>
        <Modal isOpen={modal}  className="modal-dialog modal-lg">
          <ModalBody>
            <Row>
              <Col md={11}>
              {
                userUpdate===true?
                <h1>Update user</h1>:  <h1>Create New User</h1>
              }
              
                <p>You're about to change user status.</p>
              </Col>
              <Col md={1} className="justify-content-end">
              {
                userUpdate===true?
                <i onClick={toggle} className="fa fa-remove" style={{cursor:"pointer"}}></i>:  <i onClick={toggle1} style={{cursor:"pointer"}} className="fa fa-remove"></i>
              }
               
              </Col>
            </Row>

            <CreateUserForm />
          </ModalBody>
        </Modal>
        <Container>
          <Row>
            <Col md={6}>
              {curr === null ? null : (
                <table style={{ width: "100%" }}>
                  <h1 style={{marginTop:"15px",fontWeight:"bold",marginBottom:"10px",fontSize:"24px"}}>User Details</h1>
                  <tr>
                    <th style={{fontWeight:"normal"}}>ID Number</th>
                    <td style={{fontWeight:"bold"}}>: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].id}</td>
                  </tr>
                  <tr>
                    <th style={{fontWeight:"normal"}}>User Name</th>
                    <td style={{fontWeight:"bold"}}>: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].name}</td>
                  </tr>
                  <tr>
                    <th style={{fontWeight:"normal"}}>Email</th>
                    <td style={{fontWeight:"bold"}}>: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].email}</td>
                  </tr>
                  <tr>
                    <th style={{fontWeight:"normal"}}>Role Types</th>
                    <td style={{fontWeight:"bold"}}>: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{curr[0].role}</td>
                  </tr>
                  <tr>
                    <th style={{fontWeight:"normal"}}>Status</th>
                    <td style={{fontWeight:"bold"}}>: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge style={{ padding: '0.6rem' }} bg={
                      curr[0].status === "active" ? "success" : "danger"
                    }>{curr[0].status}</Badge>
                      
                
                    
                   </td>
                  </tr>
                </table>
              )}
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="mt-5 justify-content-between">
            <Col md={3}>
              <InputGroup>
                <Form.Control aria-label="Search…" 
                onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputGroup.Text>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col>
              <Button
                className=" gap-2 mb-3 float-end"
                variant="secondary"
                onClick={toggle1}
              >
                Create User
              </Button>
            </Col>
          </Row>
          {
            loading===true?
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>:
            <Row className="mt-3">
            <Col md={12}>
              <Table striped bordered hover className="history" responsive>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th> Id Number</th>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Contact No</th>
                    <th>Role Types</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dealer_user_data?.filter((val)=>{
                    if(searchTerm===''){
                      return val
                    }
                    else if(
                      val.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                      val.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                      val.mobile?.includes(searchTerm) ||
                      val.id?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                      val.role?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                      val.status?.toLowerCase().includes(searchTerm?.toLowerCase())
                    ){
                      return val;
                    }
                    }
                    )
                  .map((p, ind) => (
                    <tr key={ind}>
                      <td>
                      <Button variant={
                        p.status === "active" ? "success" : "danger"
                      } style={{color:"white"}} size="sm">
                      {p.status}
                  </Button>
                      
                      </td>
                      <td>{p.id}</td>
                      <td>{p.name}</td>
                      <td>{p.email}</td>
                      <td>{p.mobile}</td>
                      <td>{p.role}</td>
                      <td>
                        <Menu>
                          <MenuButton>
                            {" "}
                            <i className="fa-solid fa-ellipsis"></i>
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => handleDetails(p.id)}>
                              View User Details
                            </MenuItem>
                            <MenuItem onClick={() => handleUpdate(p.id)}>
                              Edit User
                            </MenuItem>
                            <MenuItem onClick={() => handleDelete(p.id)}>
                              Delete
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <Pagination
                size="sm"
                className="justify-content-end"
              ></Pagination> */}
            </Col>
          </Row>
          }
        
        </Container>
      </ChakraProvider>
    </div>
  );
};
