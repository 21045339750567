
import { gql } from "@apollo/client";

const SIGNUP = gql`
  mutation registerUser($name: String, $email: String, $mobile: String) {
    registerUser(name: $name, email: $email, mobile: $mobile) {
      userId
      
    }
  }
`;

const DEALER_USER = gql`
  mutation userCreateByDealer(
    $name: String
    $email: String
    $mobile: String
    $role: String
    $status: String
    $createdBy: ID
  ) {
    userCreateByDealer(
      name: $name
      email: $email
      mobile: $mobile
      role: $role
      status: $status
      createdBy: $createdBy
    ) {
      userId
      message
      token
      error
      name
      email
      mobile
      role
    }
  }
`;

const USER_PROFILE_UPDATE = gql`
  mutation updateUserProfile(
    $userId: String
    $name: String
    $email: String
    $mobile: String
  ) {
    updateUserProfile(
      userId: $userId
      name: $name
      email: $email
      mobile: $mobile
    ) {
      message
      error
      name
      email
      mobile
    }
  }
`;
const CUSTO_DETAILS = gql`
  mutation customerDetails(
    $name: String
    $email: String
    $mobile: String
    $nric: String
    $state: String
  ) {
    customerDetails(
      name: $name
      email: $email
      mobile: $mobile
      nric: $nric
      state: $state
    ) {
      id
      name
      email
      mobile
      nric
      state
    }
  }
`;
const CREATE_BOOKING = gql`
  mutation bookingInit($quotationId: ID, $nric: String, $state: String, $planId: String, $planName: String) {
    bookingInit(quotationId: $quotationId, nric: $nric, state: $state, planId: $planId, planName: $planName) {
      id
    }
  }
`;

const CREATE_PAYMENT = gql`
  mutation createPayment($bookingId: ID) {
    createPayment(bookingId: $bookingId) {
      id
    }
  }
`;
const CREATE_QUOTATION = gql`
  mutation createQuotation(
    $brandId: ID
    $modelId: ID
    $userId: ID
    $email: String
    $mobile: String
    $nric: String
    $state: String
    $mileage: String
    $year: String
    $stateId: ID
    $userName: String
    $isPromocodeApplied: Boolean
    $discount: String
    $price: String
    $promocodeApplied: String
    
  ) {
    createQuotation(
      inputQuote: {
        brandId: $brandId
        modelId: $modelId
        userId: $userId
        email: $email
        mobile: $mobile
        nric: $nric
        state: $state
        mileage: $mileage
        year: $year
        stateId: $stateId
        userName: $userName
        isPromocodeApplied: $isPromocodeApplied
        discount: $discount
        price: $price
        promocodeApplied: $promocodeApplied
      }
    ) {
      id
      brandName
      modelName
      year
      userName
      email
      mobile
      price
      brandId
      userId
      nric
      state
      mileage
    }
  }
`;

const DELETE_USER = gql`
mutation deleteUser(
  $userId: ID
)
{
  deleteUser
  (userId: $userId)
   {
    message
    
    error
   
  }
}
`
const GETADMINREVENUEBYDATES = gql`
mutation getAdminRevenueByDates(
    $userId: ID
    $fromDate: Date
    $toDate: Date
  ){
    getAdminRevenueByDates(
      userId: $userId
      fromDate: $fromDate
      toDate: $toDate
    ){
      adminRevenueByDates {
        date
        revenue
      }
    }
  }
`;

const GETADMINREVENUEBYDATESREFETCH = gql`
mutation getAdminRevenueByDatesRefetch(
    $userId: ID
    $fromDate: Date
    $toDate: Date
  ){
    getAdminRevenueByDatesRefetch(
      userId: $userId
      fromDate: $fromDate
      toDate: $toDate
    ){
      adminRevenueByDates {
        date
        revenue
      }
    }
  }
`;

const DEALER_UPDATE_USER = gql`
mutation updateUser(
  $userId: ID
  $name: String
  $email: String
  $mobile: String
  $role: String
  $status: String
  $updatedByuserId: ID
)
{
  updateUser(
    userId: $userId
    name: $name
    email: $email
    mobile: $mobile
    role:$role
    status: $status
    updatedByuserId: $updatedByuserId
  ) {
    
    message
    error
  }
}

`;
const GETQUOTATION = gql`
  mutation createQuotation(
    $email: String
    $year: String
    $userName: String
    $mobile: String
    $modelId: ID
    $brandId: ID
  ) {
    createQuotation(
      inputQuote: {
        email: $email
        year: $year
        userName: $userName
        mobile: $mobile
        modelId: $modelId
        brandId: $brandId
      }
    ) {
      id
      brandName
      modelName
      year
      userName
      email
      mobile
      price
      token
      brandId
    }
  }
`;

const LOGIN = gql`
  mutation validateUser($email: String) {
    validateUser(email: $email) {
      token
    }
  }
`;

const UPDATEBOOKINGPRICEBYUSINGCREDITPOINT = gql`
  mutation updateBookingPriceByUsingCreditPoint($bookingId: ID) {
    updateBookingPriceByUsingCreditPoint(bookingId: $bookingId) {
      price
    }
  }
`;


const HANDLEBOOKINGSUCCESSAFTERCREDIT = gql`
  mutation handleBookingSuccessAfterCredit($bookingId: ID) {
    handleBookingSuccessAfterCredit(bookingId: $bookingId) {
      error
    }
  }
`;

const GETDEALERCURRENTCREDIT = gql`
  mutation getDealerCurrentCredit($dealerId: ID) {
    getDealerCurrentCredit(dealerId: $dealerId) {
      amount
    }
  }
`;

const APPLYPROMOCODE = gql`
  mutation applyPromocode($quotationId: ID, $promoCode: String) {
    applyPromocode(quotationId: $quotationId, promoCode: $promoCode) {
      discountAmount
      promocodeStatus
      promocodeError
      deductedAmount
      quotationPrice
    }
  }
`;

const APPLYPROMOCODEWITHOUTLOGIN = gql`
  mutation applyPromocodeWithoutLogin($modelId: ID, $promoCode: String) {
    applyPromocodeWithoutLogin(modelId: $modelId, promoCode: $promoCode) {
      discountAmount
      promocodeStatus
      promocodeError
      deductedAmount
      quotationPrice
    }
  }
`;


const USER_OTP= gql`
mutation
  verifyOtp($email: String, $otp: String) 
{
  verifyOtp(email:$email,otp:$otp){
     message
     error
     token
			role
      userId
      name
      email
      mobile
  }
 }
`;

const UPDATE_QOUTATION = gql`
mutation updateQuotation(
  $id: String,
  $planId: String,
  $planName: String
)
{
  updateQuotation(
    id: $id,
    planId: $planId,
    planName: $planName
  ) {
    id
  }
}

`;

export {
  LOGIN,
  SIGNUP,
  USER_OTP,
  GETQUOTATION,
  CUSTO_DETAILS,
  DEALER_USER,
  USER_PROFILE_UPDATE,
  CREATE_QUOTATION,
  CREATE_BOOKING,
  CREATE_PAYMENT,
  DELETE_USER,
  DEALER_UPDATE_USER,
  GETADMINREVENUEBYDATES,
  GETADMINREVENUEBYDATESREFETCH,
  GETDEALERCURRENTCREDIT,
  UPDATEBOOKINGPRICEBYUSINGCREDITPOINT,
  HANDLEBOOKINGSUCCESSAFTERCREDIT,
  APPLYPROMOCODE,
  APPLYPROMOCODEWITHOUTLOGIN,
  UPDATE_QOUTATION
};