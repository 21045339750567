import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import Banner_img from "../../assets/img/woman_with_bill.png";
import { GETAllBRANDS } from "../../queries/userQueries";
import { GETAllMODELS } from "../../queries/userQueries";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/client";
import { CREATE_QUOTATION } from "../../mutations/userMutations";
import { useForm } from "../../utils/hooks";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/style.css";
function BannerBm() {
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = React.useState(false);
  const handleChecked = () => {
    setChecked(!checked);
  };
 
  const [brandvalue, setBrandvalue] = useState("");
  const [modelvalue, setModelvalue] = useState("");
  const [yearvalue, setYearvalue] = useState("");
  

  localStorage.setItem("brandId", brandvalue);
  localStorage.setItem("modelId", modelvalue);
  localStorage.setItem("owner_year", yearvalue);
  const { onChange, onSubmit, values } = useForm(handleSubmit, {
    email: "",
    mobile: "", 
     name: "",
  });
  const navigate = useNavigate();

  
  

  function handleSubmit() {
   

    
    if (!values.email || !values.name || !values.mobile || !brandvalue || !modelvalue || !yearvalue) {
      toast("Please Fill All The Fields");

      return;
    } else if (values.email.includes("@") === false) {
      toast("Please Enter Valid Email");
      return;
    } else if (values.mobile.length > 15) {
      toast("Please Enter Valid Mobile Number");
      return;
    } else if (values.mobile.length < 8) {
      toast("Please Enter Valid Mobile Number");
      return;
    }
    else if(checked===false)
    {
      toast("Please Accept Terms And Conditions")
      return;
    }
    localStorage.setItem("owner_name", values.name);
    localStorage.setItem("owner_email", values.email);
    localStorage.setItem("owner_mobile", values.mobile);
   navigate("/quotation");
  }
  var { data } = useQuery(GETAllBRANDS);
  const brand = data?.getAllBrands?.allVehicleRes;

  const brandId = localStorage.getItem("brandId");
  var { data } = useQuery(GETAllMODELS, { variables: { brandId } });
  const model = data?.getBrandById?.allVehicleModelRes;
  
  let priceValue = model?.filter((item2) => {

    if (item2.id === modelvalue ){
      return item2.price;

    }
 localStorage.setItem("owner_price", item2.price);
  });

  return (
    <>
      <section>
        <Container>
          <Row className="align-items-center">
            {/* <Col md={6}>
              <div className="banner-content">
                <h1>Daunted by expensive car repairs ?</h1>
                <p>
                  eAutoGen picks up where your auto warranty expires. Protect
                  your car's engine and transmission with our vehicle protection
                  plan that shields you from major car repair costs.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <Image src={Banner_img} fluid />
            </Col> */}
            <div className="banner-quote">
              <Form className="banner_form">
                <Row>
                  <Col md={4}>
                    {" "}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label  style={{ fontWeight: "bold" }}>Jenama Kereta</Form.Label>

                      <Form.Select
                       onChange={(e) => {
                          setBrandvalue(e.target.value);
                        }}
                        name="brandId"
                        id="fruit-select"
                      >
                        <option>Sila Pilih</option>
                        {brand?.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.brand}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label  style={{ fontWeight: "bold" }}>Modal Kereta</Form.Label>

                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => {
                          setModelvalue(e.target.value);
                        }}
                        name="modelId"
                      >
                        <option>Sila Pilih </option>
                        {model?.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.modelName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label  style={{ fontWeight: "bold" }}>Tahun Buatan Kereta</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                      
                        onChange={(e) => {
                          setYearvalue(e.target.value);
                        }}
                        name="year"
                      >
                        <option>Sila Pilih </option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label  style={{ fontWeight: "bold" }}>Nama</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        onChange={onChange}
                        placeholder="e.g. Tan Eng"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                        E-mel{" "}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        required="required"
                        value={values.email}
                        onChange={onChange}
                        placeholder="e.g. abg@gmail.com"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label style={{ fontWeight: "bold" }}>
                      Nombor Telefon{" "}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="mobile"
                        required="required"
                        value={values.mobile}
                        onChange={onChange}
                        placeholder="e.g. 0123456789"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={8} className="d-flex m-auto justify-content-center">
                    <Form.Check className="mb-3">
                      {" "}
                      <Form.Check.Input
                        type="checkbox"
                        onChange={handleChecked}
                      />
                      <Form.Check.Label>
                      Saya memahami dan bersetuju dengan{" "}
                        <a
                          href="/privacy-policy"
                          style={{
                            textDecoration: "underline",
                            color: "#777777",
                          }}
                        >
                          Polisi Privasi 
                        </a>{" "}
                        dan{" "}
                        <a
                        href="/termconditions"
                          style={{
                            textDecoration: "underline",
                            color: "#777777",
                          }}
                        >
                        Terma dan Syarat
                        </a>
                        .
                        eAutoGen
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col md={8} className="d-flex m-auto">
                    {/* <Button variant="primary" className=" text-center m-auto">GET QUOTE</Button> */}
                    <a
                      className=" text-center m-auto"
                      style={{ textDecoration: "none" }}
                    >
                      <Button variant="secondary" onClick={onSubmit}>
                      DAPATKAN SEBUT HARGA
                      </Button>
                    </a>
                  </Col>
                </Row>
              </Form>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default BannerBm;
