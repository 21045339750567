import React from 'react'
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import "./LandingFaq.css";
import { useNavigate } from "react-router-dom";

export const LandingFaqSectionBm = () => {
    return (
        <div className="py-5">
            <Container style={{ width: "80%" }}>
                <Row className="justify-content-center">
                    <Col
                        md={9}
                        style={{ marginBottom: "28px" }}
                        className="section-title"
                    >
                        <h2>
                            <span>Soalan-Soalan Lazim</span>{" "}
                        </h2>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Bagaimanakah saya tahu sama ada kereta saya layak untuk AutoGen PLUS?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Kereta anda mestilah memenuhi kriteria-kriteria yang berikut:
                                    <br />
                                    Jenis Kereta: Kereta Kegunaan Persendirian (tidak termasuk Kereta Kegunaan Komersial dan e-Hailing), termasuklah Kereta Recon/Kereta Terpakai/Sedia Ada di jalan raya
                                    <br />
                                    Usia Kereta: Sehingga 10 tahun (dari tarikh pendaftaran), dan
                                    <br />
                                    Bacaan Odometer: kurang daripada 200,000 kilometer
                                    <span style={{ color: "" }}>kilometer</span> mileage
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Adakah Pemeriksaan Kereta diwajibkan sebelum pengaktifan pelan?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Ya, Kereta anda dikehendaki untuk menjalankan pemeriksaan kereta di mana-mana rakan kongsi sah kami sebelum pengaktifan pelan. Sila kunjungi lokasi yang berhampiran dengan anda untuk mendapatkan keputusan singkat ujian diagnosis dalam tempoh 90 hari selepas Kod Pesanan dihantarkan kepada anda selepas pembelian.

                                    <a style={{ textDecoration: "underline" }}>
                                        Sila rujuk semua rakan kongsi sah eAutoGen di sini.
                                    </a>{" "}

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Bagaimanakah jika saya tidak boleh menghantar kereta saya untuk diperiksa di mana-mana rakan kongsi sah anda yang disenaraikan?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Sila hubungi Talian Bantuan kami di 03-7931 3663 pada waktu pejabat atau WhatsApp nombor tersebut untuk mengaturkan pemeriksaan secara mudah alih (kawasan terhad).
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Apakah komponen-komponen waranti bagi AutoGen PLUS?
                                </Accordion.Header>
                                <Accordion.Body>
                                    AutoGen PLUS merangkumi 2 komponen-komponen utama kereta iaitu enjin dan transmisi.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Apakah tempoh perlindungan program AutoGen PLUS?
                                </Accordion.Header>
                                <Accordion.Body>
                                    1 tahun kalendar atau 30,000 kilometer perbatuan, yang mana terlebih dahulu.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </Col>
                    <Col md={12}>
                        <Accordion className="accordance">
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    Apakah had pembaikan AutoGen PLUS?
                                </Accordion.Header>
                                <Accordion.Body>
                                    AutoGen PLUS memberikan had pembaikan sehingga RM20,000 setahun dan RM10,000 setiap pembaikan bergantung kepada pelan program anda.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Apakah bilangan maksimum pembaikan setahun?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Tiada had bagi bilangan pembaikan asalkan ia berada dalam nilai pembaikan kereta setahun.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Apakah tempoh bertenang bagi program?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Tempoh bertenang program adalah 7 hari pertama atau 300 kilometer pertama dari tarikh
                                    pengaktifan program sebelum kerja pembaikan kereta boleh dimulakan.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Adakah saya perlu menjalankan penyelenggaraan program seperti yang diperlukan?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Untuk memastikan AutoGen PLUS anda aktif, anda mesti menjalankan penyelenggaraan berkala mengikut Jadual Penyelenggaraan Perkhidmatan Selang Waktu mengikut cadangan pengeluar di mana-mana bengkel panel eAutoGen, dengan perbelanjaan anda sendiri seperti yang berikut:
                                    <br />- Untuk kereta yang menggunakan pelincir separa sintetik: 3 bulan (atau 5,000 kilometer, yang mana lebih awal)<br />
                                    - Untuk kereta yang menggunakan pelincir sintetik sepenuhnya: 6 bulan (atau 10,000 kilometer, yang mana lebih awal)<br /> <a style={{ textDecoration: "underline" }}>
                                        Sila rujuk semua rakan kongsi sah eAutoGen di sini.
                                    </a>{" "}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Bolehkah saya pergi ke bengkel bukan panel untuk penyelenggaraan program?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Anda dikehendaki untuk menghantar kereta anda ke bengkel-bengkel panel eAutoGen sahaja untuk penyelenggaraan berkala. Sila simpan semua salinan-salinan resit penyelenggaraan (dokumen janaan komputer dengan setem bengkel) sepanjang tempoh AutoGen PLUS dan penyerahan resit penyelenggaraan tersebut boleh diminta semasa tuntutan pembaikan.
                                    <br /> <a style={{ textDecoration: "underline" }}>
                                        Sila rujuk semua rakan kongsi sah eAutoGen di sini.
                                    </a>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Bagaimanakah saya tahu sama ada masalah kereta saya adalah dalam skop AutoGen PLUS?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Anda boleh menghantarkan kereta anda ke mana-mana bengkel panel eAutoGen untuk penilaian.
                                    <br /> <a style={{ textDecoration: "underline" }}>
                                        Sila rujuk semua rakan kongsi sah eAutoGen di sini.
                                    </a>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Apakah jenis bahagian yang boleh digantikan dalam keadaan pengantian enjin dan transmisi?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Komponen-komponen yang rosak dibawah AutoGen PLUS boleh digantikan dengan bahagian-bahagian barang terpakai, dibaik pulih, atau OEM di bawah budi bicara eAutoGen.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Adakah saya perlu membayar pendahuluan untuk pembaikan?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Tiada bayaran pendahuluan diperlukan. Sila pastikan anda hanya pergi ke bengkel-bengkel panel eAutoGen untuk pembaikan.
                                    <br /> <a style={{ textDecoration: "underline" }}>
                                        Sila rujuk semua rakan kongsi sah eAutoGen di sini.
                                    </a>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Apakah yang akan berlaku jikalau kerja-kerja pembaikan melibatkan jaminan AutoGen PLUS di luar komponen skop?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Sekiranya terdapat sebarang pembaikan yang melebihi had perlindungan dan pembaikan AutoGen PLUS, anda dikehendaki untuk membayar terus kepada bengkel.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Sekiranya berlaku kemalangan kereta, adakah saya berhak untuk membaiki bahagian yang rosak (enjin kereta dan transmisi) di bawah AutoGen PLUS?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Dalam keadaan sedemikian, anda harus menghubungi syarikat insurans kereta anda. Program AutoGen PLUS mengurangkan beban anda daripada kos pembaikan yang timbul akibat kerosakan mekanikal komponen jaminan yang tidak dijangka.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Adakah terdapat perkhidmatan menunda?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Perkhidmatan menunda tidak termasuk dalam AutoGen PLUS. Anda dinasihati untuk menggunakan perkhidmatan menunda insurans kereta anda ke mana-mana bengkel panel kami. Sila rujuk semua rakan kongsi sah eAutoGen di sini.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Bolehkah AutoGen Plus dibatalkan atau dibayar balik? </Accordion.Header>
                                <Accordion.Body>
                                    AutoGen PLUS tidak boleh dibatalkan dan tidak boleh dibayar balik. Dengan pembelian AutoGen PLUS, ia adalah dianggap bahawa anda telah membaca dan bersetuju dengan Terma dan Syarat yang ditetapkan dan akan mematuhinya.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Bolehkah AutoGen PLUS diperbaharui?
                                </Accordion.Header>
                                <Accordion.Body>
                                    AutoGen PLUS boleh diperbaharui pada setiap 1 tempoh kalendar atau 30,000 kilometer dengan pemeriksaan awal kereta dan tertakluk kepada tiada tuntutan dilaporkan pada tahun sebelumnya.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Bolehkah AutoGen PLUS dipindah milik ?
                                </Accordion.Header>
                                <Accordion.Body>
                                    AutoGen PLUS tidak boleh dipindah milik oleh pemilik kereta. Walau bagaimanapun, sekiranya terdapatnya penukaran pemilikan semasa tempoh program, sila hubungi kami untuk mengemaskinikan pemilikan kereta untuk kesinambungan program.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion className="accordance">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Siapakah yang perlu saya hubungi untuk pertanyaan mengenai AutoGen PLUS ?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Untuk sebarang pertanyaan, Talian Bantuan kami boleh didapati di +603-7931 3663 setiap hari Isnin hingga hari Jumaat dari jam 9:00 pagi hingga jam 6:00 petang. Sebagai alternatifnya, anda boleh menghantar e-mel kepada kami di cs@eAutoGen.my
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>



                    </Col>
                </Row>
            </Container>
        </div>
    )
}
