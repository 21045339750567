import React from 'react'
import {Image} from 'react-bootstrap'
import eautogenoffice from '../../../assets/img/eautogen office.png'

export const AboutHeader = () => {
  return (
    <div>
        <Image src={eautogenoffice}  height="200px" fluid/>
    </div>
  )
}
