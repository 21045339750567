import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_QUOTATION } from "../../../mutations/userMutations";
import { useForm } from "../../../utils/hooks";
import { ToastContainer, toast } from "react-toastify";
import { TabContext } from "../../../Context/TabContext";
import {useContext} from 'react';
function CustomerDetails() {
  const { handleTab } = useContext(TabContext);
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const handleChecked = () => {
    setChecked(!checked);
  };
  const [errors, setErrors] = useState({});
    const first=localStorage.getItem("modelId");
    const second=localStorage.getItem("brandId");
    const third=localStorage.getItem("owner_year");
    const fourth=localStorage.getItem("mileage");

  const { onChange, onSubmit, values } = useForm(handleSubmit, {
    email: "",
    year:  `${third}`,
    userName: "",
    mobile: "",
    modelId: `${first}`,
    brandId: `${second}`,
    nric: "",
    userId: `${localStorage.getItem("user_id")}`,
    mileage: `${fourth}`

  });

  const [addUser, { loading }] = useMutation(CREATE_QUOTATION, {
    update(_, { data: { createQuotation: userData } }) {
      localStorage.setItem("quote_id", userData.id);
      localStorage.setItem("owner_name", userData.userName);
      localStorage.setItem("owner_email", userData.email);
      localStorage.setItem("owner_mobile", userData.mobile);
      localStorage.setItem("owner_nric", userData.nric);
      localStorage.setItem("owner_model", userData.modelName);
      localStorage.setItem("owner_brand", userData.brandName);
      localStorage.setItem("owner_year", userData.year);
      localStorage.setItem("owner_price", userData.price);
      localStorage.setItem("owner_mileage", userData.mileage);
      // practice
      handleTab("PlanQuotation");
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
    variables: values,
  });

  function handleSubmit() {
    localStorage.getItem("quote_id");
    localStorage.getItem("owner_name");
    localStorage.getItem("owner_email");
    localStorage.getItem("owner_mobile");
    localStorage.getItem("owner_nric");
    localStorage.getItem("owner_model");
    localStorage.getItem("owner_brand");
    localStorage.getItem("owner_year");
    localStorage.getItem("owner_price");

    if (!values.email || !values.userName || !values.mobile) {
      toast("Please Fill All The Fields");

      return;
    } else if (values.email.includes("@") === false) {
      toast("Please Enter Valid Email");
      return;
    } else if (values.mobile.length > 15) {
      toast("Please Enter Valid Mobile Number");
      return;
    } else if (values.mobile.length < 8) {
      toast("Please Enter Valid Mobile Number");
      return;
    } 
    else if (values.nric.length !== 12) {
      toast("Please Enter Valid Nric Number");
      return;
    } 
    
    addUser();
  
  }
  return (
    <>
      <ToastContainer />
      <div className="payment-box">
        <div className="authentication-inputs">
          <h1>Customer Details</h1>
          <p className="mb-3">Input customer information</p>
          {/* <Form noValidate validated={validated} onSubmit={onSubmit}> */}
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Name <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                name="userName"
                required="required"
                value={values.userName}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Email <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                required="required"
                value={values.email}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Phone Number  <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="number"
                name="mobile"
                required="required"
                value={values.mobile}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                NRIC Number  <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="number"
                name="nric"
                required="required"
                value={values.nric}
                onChange={onChange}
                placeholder="e.g. 810202101234"
              />
            </Form.Group>
             <div className="d-flex justify-content-between  mt-5">
              <Link
                to="/buyplan"
                onClick={()=>handleTab("CarDetails")}
                className="btn btn-outline-secondary btn-md"
              >
                {" "}
                Back
              </Link>
              <Link
               to="/buyplan"
                className="btn btn-secondary btn-md"
                onClick={onSubmit}
              >
                {" "}
                SAVE & CONTINUE
              </Link>
            </div> 
          </Form>
        
        </div>
      </div>
    </>
  );
}

export default CustomerDetails;
