import React from 'react'
import './AboutSection1.css'
import { Container, Row, Col } from 'react-bootstrap'

export const AboutSection1 = () => {
    return (
        <div>
            <section>
                <Container>
                    <Row className=' mt-5 pb-5'>
                        <Col md={4} >
                            <h2 className='section_title'>With us,<br /> every idea <br /> starts with a <br /> <span>what-if.</span></h2>
                        </Col>
                        <Col md={8} className='section_prg'>
                            <Row>
                                <p>"What-if owning a car is affordable and sustainable ?"</p>
                            </Row>
                            <Row>
                                <p>"What-if car repair didn't leave our wallets bare?"</p>
                            </Row>
                            <Row>
                                <p >"What-if we can create a value-added car buying experience?"</p>
                            </Row>
                            <Row className='mt-3'>
                                <p >While car is one of the largest purchase in one's lifetime, the traditional automotive industry places high value only during the early stages of a car life cycle despite the long loan repayment and lifespan of car. New cars typically come with a limited warranty that guarantees the ability, performance and quality of the product. Beyond this, used car buyers and aged car owners are increasingly exposed to high out-of-pocket repair expenses as their car ages. </p>
                            </Row>
                            <Row className='mt-3'>
                                <p>Founded in the year of 2022, eAutoGen offers car extended warranty programs to aged and used cars owners with features designed to benefit them via better products, accessibility and higher affordability. </p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
