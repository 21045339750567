import React, { useEffect } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Header from '../components/common/header';
import Dashboard from '../components/Homepage/dashboard';
import DashboardDealer from '../../dealer/Homepage/dashboard';
import DealerHistory from '../../dealer/Homepage/dealerhistory';
import { useNavigate } from 'react-router-dom';
import CreditTransection from '../../dealer/Homepage/CreditTransection';
function HomePageAdmin() {
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token") === "" || localStorage.getItem("token") === null) {
      navigate("/login");
    }
    else {
      navigate("/home")
    }

  }, []);

  return (<React.Fragment>

    <Header />
    <Tab.Container id="left-tabs-example" defaultActiveKey="Dashboard">
      <header className="home-nav">
        <Container >
          <Row>
            <Col sm={12}>
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey="Dashboard">
                    Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Historypage" >
                    History
                  </Nav.Link>
                </Nav.Item>

                {
                  localStorage.getItem("role") === "dealer" ? <Nav.Item>
                    <Nav.Link eventKey="credit-transection" >
                      Credit Transactions
                    </Nav.Link>
                  </Nav.Item> : ""
                }


              </Nav>
            </Col>
          </Row>
        </Container>
      </header>
      <Container>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="Dashboard">
                {
                  localStorage.getItem("role") === "dealer" ?
                    <DashboardDealer />
                    :
                    <Dashboard />

                }

              </Tab.Pane>
              <Tab.Pane eventKey="Historypage">
                <DealerHistory />
              </Tab.Pane>
              <Tab.Pane eventKey="credit-transection">
                <CreditTransection />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Container>
    </Tab.Container>
  </React.Fragment>);
}

export default HomePageAdmin;