import React from 'react'
import { Header } from "../components/common/header";
import { Container, Row, Col } from "react-bootstrap";
import "./term&conditions.css";
import { Button, Dropdown, Form, InputGroup, Pagination, Table } from "react-bootstrap";
import Footer from "../components/common/footer";

export const TermConditionBM = () => {
  return (
    <>
      <div>
        {
          localStorage.getItem("language") === "true" ?

            <div style={{ maxWidth: "1600px", margin: "auto" }}>
              <Header />
              <h1 className="text-center term_head"> TERMS AND CONDITIONS</h1>
              <Container className="terms mt-4" >

                <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                  <p>
                    eAutoGen Capital Sdn. Bhd (Company No. 202101001979 (1402277-K)) (hereinafter “<b>eAutoGen</b>”, “<b>we</b>”,
                    “<b>us</b>”, or “<b>our</b>”) is the owner of the website known as <a href="https://eautogen.my/">www.eAutoGen.my</a> (“<b>Site</b>”) which offers car
                    extended warranty package to the customer (hereinafter “<b>Customer</b>”, “<b>you</b>” or “<b>yours</b>”) in accordance
                    with these terms and conditions (“<b>Terms</b>”) and such other procedures as may be determined by
                    eAutoGen from time to time, in its sole and absolute discretion.
                  </p>
                  &nbsp;
                  <p>
                    You hereby agree to be bound by these Terms, Refund Policy, Privacy Policy and any other policies and
                    terms and conditions as may be posted on eAutoGen’s Site from time to time (“<b>Other Terms</b>”).
                  </p>
                  &nbsp;
                  <p>
                    These Terms govern your access and use of the Site and the use and purchase of any products, services,
                    information and functions made available by us at the Site (“<b>Services</b>”). Before using this Site or the
                    Services, you must read carefully and accept these Terms and you must consent to the processing of
                    your personal data as described in the Privacy Policy posted on the Site.
                  </p>
                  &nbsp;
                  <p>
                    By accessing the Site and/or using the Services, you agree to be bound by these Terms and any revisions
                    to the foregoing issued by eAutoGen from time to time. If you do not agree to these Terms, please do
                    not access and/or use the Site and/or the Services.
                  </p>
                </div>
                <p className="mt-2 mx-2">The AutoGen PLUS comprises of: </p>
                <ul className="ul_wrapp">

                  &nbsp;
                  <li>Section 1: Eligibility and Car Inspection </li>
                  <li>Section 2: Extended Warranty Program</li>
                  <li>Section 3: General Terms and Conditions</li>
                </ul>

                <h2 style={{ fontSize: "22px" }} className="mt-5">Definitions</h2>
                <p>The following terms shall have the following respective meanings:</p>

                <ol>
                  <div
                    style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}
                  >
                    <ol type="1">

                      <li>
                        “Applicable Laws“ means with respect to any person, any and all applicable constitutions, treaties,
                        statutes, laws, by-laws, regulations, ordinances, codes, rules, rulings, judgments, rules of common
                        law, orders, decrees, awards, injunctions or any form of decisions, determinations or requirements
                        of or made or issued by, governmental, statutory, regulatory, administrative, supervisory or judicial
                        authorities or bodies (including without limitation, any relevant stock exchange or securities council)
                        or any court, arbitrator or tribunal with competent jurisdiction and to which such person is subject.
                      </li>
                      <li>
                        “AutoGen PLUS” means the extended warranty programme provided by eAutoGen.
                      </li>
                      <li>
                        “Breakdown” means the sudden and unforeseen failure of a In Scope component subject to the
                        terms and conditions of these Terms.
                      </li>
                      <li>
                        “Car” means the private use motorcar and it shall be construed accordingly.
                      </li>
                      <li>
                        “Commercial Use Car” means cars used under the license of commercial cars including but not
                        limited to farming, ranching, route work, job-site activities, service or repair work and delivery of
                        goods.
                      </li>
                      <li>
                        “Claim” means a request for repair made by the Customer under the AutoGen PLUS.
                      </li>
                      <li>
                        “eAutoGen Panel Workshop” refers to authorized workshops to perform car repair works and/or
                        maintenance during the Activation Date.
                      </li>
                      <li>
                        . “pre-existing” means any defects or damage in existence prior to the Activation Date.
                      </li>
                      <li>
                        “Activation Date” refers to commencement date of AutoGen PLUS.
                      </li>
                      <li>
                        “Summary” means the document to be provided by eAutoGen to the Customer upon activation of
                        the AutoGen PLUS which gives information about the Car, AutoGen PLUS and In-Scope Components
                        for the Car.
                      </li>
                      <li>
                        “wear and tear” means the gradual deterioration associated with the normal use, age and mileage
                        of the Car and its components.
                      </li>
                    </ol>
                  </div>

                  <h1 className="mx-0 my-5">Section 1: Eligibility and Car Inspection</h1>
                  <p>For the Car to be eligible for AutoGen PLUS, the Customer shall ensure that the Car meet all the
                    following requirements prior to purchase of the AutoGen PLUS: -</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>

                    <ol type="a">
                      <li>the Car must not be older than 10 years, calculated from the date of registration;</li>
                      <li> has a mileage (actual and based on reading) of no more than 200,000 kilometers; and</li>
                      <li>the engine and transmission of the Car are in a good and workable condition,</li>
                    </ol>
                  </div>

                  <p className="my-2">(collectively, the “<b>Eligibility Criteria</b>”)</p>

                  <p>eAutoGen will issue an order code (“<b>Order Code</b>”) upon your successful purchase of AutoGen PLUS. You
                    shall thereafter make an appointment with any one of eAutoGen’s designated car inspection centres
                    ("<b>eAutoGen Authorised Partner</b>”) to conduct a diagnosis test for the Car (“<b>Inspection</b>”) within 90 days
                    from the date of receipt of the Order Code (“<b>Inspection Period</b>”). For the avoidance of doubt, if the
                    Customer fails, refuses and/or neglects to undergo the Inspection within the Inspection Period, the
                    AutoGen PLUS shall automatically be void and terminated without refund and liability to eAutoGen. </p>


                  <h1 className="mx-0 my-3">1.1. Purpose and Scope of the Car Inspection.</h1>
                  <p>The Inspection is intended to assist eAutoGen to evaluate the condition of the Car and to ensure that
                    the Car meets the Eligibility Criteria. eAutoGen Authorised Partner will conduct an impartial, noninvasive evaluation of the readily accessible, permanently installed systems and components of the Car,
                    for AutoGen PLUS activation. eAutoGen Authorised Partner will prepare all inspection reports including
                    car inspection summary for the Car (“<b>Report</b>”). eAutoGen shall not be held liable and responsible for any
                    representations made by eAutoGen Authorised Partner in relation to the Car and the contents of the
                    Report. </p>
                  <p>eAutoGen Authorised Partners are generalists and may possess training / certifications/ permissions
                    necessary to conduct the Inspection however, they do not act as experts in any craft, discipline or trade,
                    or as licensed engineers. You hereby acknowledge and agree that in no event shall eAutoGen be liable
                    for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever,
                    whether in an action of contract, negligence or other tort, arising out of or in connection with the
                    Inspection conducted by eAutoGen Authorised Partner.</p>

                  <h1 className="mx-0 my-3">1.2. In-Scope</h1>
                  <p>The following is the scope of Inspection to be carried out by eAutoGen Authorised Partner (" <b>Inspection Scope</b>"): -</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="i">
                      <li>Engine: Starting, idling, noise, acceleration, scan tool / faulty code
                      </li>
                      <li>Transmission: Clutch Operation, Drive Axle / transfer Case Noise, Shift Interlock Operation, Scan
                        tool, faulty code</li>
                    </ol>
                  </div>
                  <p>Items not stipulated above are deemed to be out of scope and not part of the Inspection Scope.</p>

                  <h1 className="mx-0 my-3">1.3. Limitations and Out-of-Scope. </h1>
                  <p>The Inspection is physically limited to areas which are readily accessible, available for inspection, and are
                    not safety risks. eAutoGen Authorised Partners have absolute discretion regarding whether or not to:</p>


                  <h1 className="mx-0 my-3">1.4. Physical Limitations. </h1>
                  <p>The following is the scope of Inspection to be carried out by eAutoGen Authorised Partner (" Inspection
                    Scope"): -</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="i">
                      <li>enter any area or perform any procedure which is, in the opinion of the eAutoGen
                        Authorised Partner, unsafe and likely to be dangerous to the eAutoGen Authorised Partner
                        or other persons, or
                      </li>
                      <li>enter any area or perform any procedure which will, in the opinion of the eAutoGen
                        Authorised Partner, likely damage the Car or its systems or components. </li>
                    </ol>
                  </div>


                  <h1 className="mx-0 my-3">1.5.Repairs or Further Evaluation.</h1>
                  <p>The Authorised Partners may recommend the need or possible need to repair, replace or monitor a
                    system or component or to obtain examination and analysis of a system or component by a tradesman
                    or service technician in a designated field of expertise. You must engage and instruct any professional,
                    tradesman or service technician who evaluates a system or component for the purpose of preparing a
                    repair/replacement proposal to address the problem, concern and/or issue identified by the Inspector,
                    and any adjacent, collateral or contiguous, intersecting and/or hidden/obstructed systems or
                    components that may require repair, replacement or upgrading. Repairs, replacement, upgrading or
                    maintenance of problems, concerns and/or issues identified by the Authorised Partner may be more
                    difficult to correct and/or be more costly than anticipated. </p>


                  <h1 className="mx-0 my-3">1.6. Status and Usefulness. </h1>
                  <p>The Report may contain estimates of status and usefulness of systems and components with respect to
                    the Car. Annotations of systems and components are based on physical observation and available data;
                    actual conditions may change the status and usefulness of any system or component. eAutoGen
                    Authorised Partners cannot make a determination regarding unfavorable conditions such as prior
                    misuse, overuse, service maintenance intervals, poor product quality, or faulty manufacture. It is
                    impossible for eAutoGen Authorised Partners to report precisely as to when a system or component will
                    require repair or replacement. </p>
                  <p>The Inspection does not constitute and/or tantamount to: </p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="i">
                      <li>an appraisal or opinion of the value of the Car;
                      </li>
                      <li>an opinion as to the advisability of a purchase;</li>
                      <li>a rating of the Car with respect to desirability of purchase; and</li>
                      <li>an opinion as to the suitability of the Car or the Car for any particular or specialized use.</li>
                    </ol>
                  </div>

                  <h1 className="mx-0 my-3">1.7.Representative Sampling.</h1>
                  <p>There are many items to be checked during the Inspection; numerous and repetitive items are randomly
                    selected and a representative number are checked for functionality. The Report do not imply that every
                    system or component is inspected or every possible defect is identified. </p>

                  <h1 className="mx-0 my-3">1.8. Failure to Meet the Eligibility Criteria</h1>
                  <p>In the event where the Car does not meet the Eligibility Criteria, eAutoGen reserves its right to cancel
                    and/or terminate the Customer’s purchase of AutoGen PLUS. eAutoGen will refund the purchase price
                    of the AutoGen PLUS, less any costs and expenses incurred by eAutoGen, including but not limited to
                    administrative cost, handling fee, Inspection fee and such other charges as may be imposed by any third
                    party in respect of the AutoGen Plus. </p>
                  &nbsp;
                  <p>The cancellation and/or refund of the AutoGen PLUS shall subject to a separate refund policy and such
                    other procedures as may be determined by eAutoGen from time to time, in its sole and absolute
                    discretion (“<b>Respective Refund Policy</b>”). </p>
                  &nbsp;
                  <p>Each application for cancellation and/or refund of the AutoGen PLUS will be assessed based on the
                    Respective Refund Policy or a case-by-case basis, at eAutoGen's sole discretion and eAutoGen's decision
                    shall be final and you hereby agree to be bound by the same.
                  </p>

                  <h1 className="mx-0 my-3" style={{ fontSize: "20px" }}>1.8.1. Time Limitation.</h1>
                  <p>Any dispute arising from the Report must be made to eAutoGen within 14 days from the date the
                    Inspection. eAutoGen has the sole discretion in examining and determining the Report on a case-tocase basis. The finding and determination of eAutoGen shall be final and binding. </p>


                  <h1 className="mx-0 my-3">Section 2: Extended Warranty Program</h1>

                  <h1 className="mx-0 my-3">2.1. Nature of Extended Warranty Program </h1>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <p>2.1.1. You agree and understand that the AutoGen PLUS is not an insurance policy.</p>
                    <p>2.1.2. To be eligible for the AutoGen PLUS, you must ensure that your Car meet all the Eligibility
                      Criteria as set out in Section 1 above.
                    </p>
                  </div>

                  <h1 className="mx-0 my-3" >2.2. Entire Extended Warranty Program</h1>
                  <p>This Section, including these Terms, Other Terms, the Report and the Summary together with any
                    endorsements, if any, constitutes the entire agreement between eAutoGen and the Customer
                    ("<b>Agreement</b>”). eAutoGen is entitled to change, revise, alter and modify the terms of the Agreement
                    from time to time. </p>
                  &nbsp;
                  <p>This Section includes mechanical Breakdown applies only with respect to the Car described on the
                    Summary. This Section shall be invalidated if there has been an inaccuracy, tampering or alteration to
                    the odometer mileage of the Car so that the Car’s true and actual mileage is not shown on the odometeror cannot be determined. If the odometer becomes inoperable during the term of the Agreement, youmust immediately notify eAutoGen and within fifteen (15) days of the odometer becoming inoperable provide eAutoGen documentation proving that the odometer has been repaired, failure to do so, eAuoGen is entitled to reject and/or cancel the Claim requested by the Customer. All costs and expenses for repairing the odometer shall be solely borne by the Customer. </p>
                  &nbsp;
                  <p>This Section provides request to repair for Breakdown of In-Scope Component(s) installed by the original manufacturer of the Car, as those terms are defined above. </p>

                  <h1 className="mx-0 my-3" >2.3. Extended Warranty Period</h1>
                  <p>12 months or 30,000 km from Activation Date or repair limit as stipulated in the Summary is fully
                    exhausted, whichever comes first ("<b>Warranty Period</b>”). </p>
                  &nbsp;
                  <p>Notwithstanding anything to the contrary in these Terms, if a Breakdown occurs to the Car, we will only accept Claim after 7 days from the Activation Date and the Car has been driven at least 500 kilometers from the Activation Date based on the mileage of the Car recorded at the Report. </p>

                  <h1 className="mx-0 my-3" >2.4.Breakdown</h1>
                  <p>In the event of a Breakdown of any In Scope Component(s) as listed in Clause 2.8 below, Customer is conly entitled to make a Claim from eAutoGen for repair and/or replacement services subject to the repair limit as stated in the Summary. Replacement of In Scope Component(s) that have experienced a  Breakdown may be made with original equipment manufacturer parts / non-original equipment manufacturer parts /re-manufactured parts / used parts / reconditioned parts at eAutoGen’s sole and absolute discretion. </p>

                  <h1 className="mx-0 my-3" >2.5.Repair Limit:</h1>
                  <p>The aggregate repair limit is subject to the limit as stated in the Summary. eAutoGen’s liability for incidental and consequential damages including, but not limited to, personal injury, physical damage, property damage, loss of use of your Car, loss of time, loss of wages, inconvenience, and commercial loss resulting from and/or arising out of the AutoGen PLUS and all subject matter with regard to the AutoGen PLUS including the operation, maintenance, or use of your Car is hereby expressly excluded. </p>

                  <h1 className="mx-0 my-3" >2.6. Servicing and Maintenance Requirements: </h1>
                  <p>Customer shall ensure that the Car to be serviced and maintained by any one of the eAutoGen’s designated panel workshops (“<b></b>Authorised Panel Workshop”) every 3 months or 5,000 km, whichever
                    comes first (for semi-synthetic oil), OR 6 months or 10,000 km, whichever comes first (for fully synthetic oil) during and throughout the Warranty Period ("<b>Servicing Requirements</b>”). You are required to retain all receipts and invoices for the purpose of our inspection in the event of a Claim.</p>
                  &nbsp;
                  <p>In addition to Servicing Requirements, you must also reasonably service and maintain the Car as
                    recommended by eAutoGen and/or Authorised Panel Workshop.</p>
                  <p>You shall promptly furnish eAutoGen the proof of servicing / service history for the Car upon request by eAutoGen. The only acceptable proof of servicing if eAutoGen requires this will be the fully detailed service invoices indicating servicing dates and mileages. </p>
                  &nbsp;
                  <p>Failure to comply with the above Servicing Requirements will result in rejection of any Claim, without liability to eAutoGen. </p>

                  <h1 className="mx-0 my-3">2.7. Filing a Breakdown Request to Repair: </h1>
                  <p>If a Breakdown occurs to the Car during the Warranty Period, you must take the following steps to file a request to repair: </p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>

                    <p className="my-2">2.7.1. Take Your Car to the Authorised Panel Workshop(s).</p>
                    <p className="my-2"> 2.7.2. Provide the Authorised Panel Workshop(s) with a copy of Your AutoGen PLUS Summary.</p>
                    <p className="my-2"> 2.7.3. Obtain authorization from eAutoGen before commencing any repairs – Prior to any request to  repair being made, you shall instruct the Authorised Panel Workshop(s) to contact eAutoGen to obtain an authorization for the repair at +603-7931 3663 or cs@eAutoGen.my. Any request to repair without prior authorization will be denied. </p>
                    <p className="my-2">2.7.4. Authorize dismantle and/or Inspection – In some cases, if necessary, you are responsible for authorising the Authorised Panel Workshop(s) to inspect and/or dismantle your Car in order to
                      determine the cause and cost of the repair. We will only pay for reasonable
                      diagnostic costs that are associated with a valid Claim for a faulty part under this Section. You shall be  responsible for these charges if the faulty part is not covered under the AutoGen PLUS. eAutoGen reserve the right to require an inspection of your Car to be carried out by an expert to decide whether your Claim is covered and repair quantum prior to any repair being made. If you or anyone acting on your behalf prevents us and/or our authorized person from being able to find out what has caused the failure by inspecting the Car or faulty parts, eAutoGen is entitled to reject such Claim on its absolute and sole discretion.
                    </p>
                    <p className="my-2">2.7.5. Out-of Scope cost to be borne by the Customer.</p>
                    <p className="my-2">2.7.6. Prevent Further Damage – You shall take immediate action to prevent further damage to your Car. This Section will not cover the damage caused by continued operation or by not securing a timely repair of the failed component. You are responsible for observing any warning lights and gauges of the Car, and taking appropriate action immediately. Failure to do so may result in the denial of the Claim. </p>
                  </div>

                  <h1 className="mx-0 my-3">2.8. In-Scope Components</h1>
                  <p>The following are the components and parts that will be covered under the AutoGen PLUS (collectively “In-Scope <b>Components</b>”, each a “In-Scope <b>Component”</b>):</p>
                  &nbsp;
                  <p><b>Engine</b>: Engine Block, Cylinder Head, Crankshaft, Eccentric Shaft, Connecting Rod, Piston, Piston Ring, Variable Valve Timing, Camshaft Adjuster Solenoid, Balancer Shaft, Intake Valve, Exhaust Valve, Intake Camshaft, Exhaust Camshaft, Valvetronic Motor, Oil Pump, Emission Control Air Pump, Air Mass Sensor  (Air Flow Sensor), Engine Vacuum Pump, Crankshaft & Position Sensor</p>
                  &nbsp;
                  <p><b>Transmission:</b> (Automatic or Manual) Clutch Temperature Sensor, Transmission Control Module TCM, Transmission Oil Pump, All Type of Solenoid Valve, Torque Converter, Transmission Speed Sensor, Gear  Shaft, Gear Shifting / Selector Module, Valve Body</p>

                  <p>Subject to the provisions of this Terms and the Summary, AutoGen PLUS covers the In-Scope
                    Components of your Car.</p>


                  <h1 className="mx-0 my-3">2.9. Out-Of-Scope – What Is Not Covered by AutoGen PLUS </h1>
                  <p>AutoGen PLUS shall not extend and shall not cover the following: </p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <p className="my-1">2.9.1. For any part not specifically listed in Clause 2.8 above, or for any of the following parts:
                      thermostat housing, shock absorbers, carburetor, battery and battery cable/harness, distributor cap and
                      rotor, safety restraint systems (including air bags), glass, lenses, sealed beams, light bulbs, LED lighting,
                      cellular phones, personal computers, and pre-heated car systems, game centers, speakers. Radio,
                      compact disc player, and cassette player, electronic transmitting/receiving devices, voice recognition
                      systems, remote control consoles, radar detection devices, brake rotors and drums, all exhaust
                      components, and the following emission components: EGR purge valve/, vacuum canister, vapor return
                      canister, vapor return lines/valves, air pump (except engine air pimp) /lines/valves, catalytic
                      converter/filtering, gas cap/filler neck, weather strips, trim, moldings, bright metal chrome, upholstery
                      and carpet, paint, outside ornamentation, bumpers, body sheet metal and panels, frame and structural
                      body parts, vinyl and convertible tops, any convertible top assemblies, door handles, lift gate handles,
                      tailgate handles, door bushings/bearings, hardware or linkages, tires, tire pressure sensors, wheel/rims,
                      programming, reprogramming, or updating a component that has not mechanically failed. </p>
                    <p className="my-1">2.9.2. Cars used for racing, competitions, rallies, motor sporting events or of a purpose for which it is not
                      licensed, whether for 1 or more occurrences.</p>
                    <p className="my-1">2.9.3. Slight irregularities not recognized as affected quality or function of the Car or parts such as slight
                      noise or vibration and defects appearing only under particular or irregular operations.</p>
                    <p className="my-1">2.9.4. For any claim caused by or arising from (i) a lack of servicing to the Car in accordance with the
                      manufacturer’s recommendations or otherwise (as applicable) in accordance with the Servicing
                      Requirements or failure by you to remedy a known problem before your Car is driven.</p>
                    <p className="my-1">2.9.5. Repair where the fault causing the mechanical breakdown was evident prior to the expiry of the
                      original manufacturer’s warranty.</p>
                    <p className="my-1">2.9.6. In any way connected with the use or operation of any computer, computer virus, computer
                      system, computer software, programming, realignment, upgrade of software, programme or process of
                      any electronic system as a consequence of (a) data change to the year 2000 or any other date change
                      and/or (b) any change or modification of or to such computer, computer system, computer software,
                      programme or process or any electronic system in relation to any date change.</p>
                    <p className="my-1">2.9.7. Any equipment not installed by the original manufacturer of the Car and/or it is not the In-Scope
                      Components, such as external nuts, bolts and fasteners.</p>
                    <p className="my-1">2.9.8. Engine block and cylinder heads are not in-scope if damaged by overheating, freezing or warping. </p>
                    <p className="my-1">2.9.9. For maintenance services and parts described in your Car owner’s manual as supplied by the  manufacturer and other normal maintenance services and parts which include, but are not limited to: alignments, adjustments, wheel balancing, tune-ups, spark plugs, spark plug wires, glow plugs, hoses  (unless listed as specific In-scope Parts), drive belts, brake pads, brake linings/shoes, and wiper blades.  Filters, lubricants, coolants, fluids and refrigerants will be in-scope only if replacement is required in connection with an in-scope Breakdown. </p>
                    <p className="my-1">2.9.10. For any damage and/or Breakdown resulting from collision, road hazard, fire, theft, vandalism, riot, explosion, lightning, earthquake, freezing, rust or corrosion, windstorm, air leaks, hail, water or flood, acts of God, salt, environmental damage, chemicals, contamination of fluids, fuels, coolants or lubricants, burglary, accident, aircraft and/or vehicle impact, battery leakage, water leaking, power outages or surges, inadequate and / or improper voltage, animal and / or insect infestations. </p>
                    <p className="my-1">2.9.11. For any part of the Car that the Authorised Panel Workshop recommends or requires that it be replaced or repaired, updated, and is not an in-scope mechanical Breakdown. In such circumstances, all  cost and expense shall be fully borne by you.</p>
                    <p className="my-1">2.9.12. For any Breakdown caused by misuse, abuse, negligence, lack of normal maintenance required  by the manufacturer’s maintenance schedule or our Authorised Panel Workshop for your Car or  improper or lack of servicing or repairs subsequent to purchase. For any Breakdown caused by sludge  build-up resulting from your failure to perform recommended maintenance services, or failure to  maintain proper levels of lubricants and/or coolants, or failure to protect your Car from further damage  when a Breakdown has occurred or failure to have your Car towed to the service facility when continued  operation may result in further damage. Continued operation includes your failure to observe warning  lights, gauges, or any other signs of overheating or component failure, such as fluid leakage, slipping,  knocking, or smoking, and not protecting your Car by continuing to drive creating damage beyond the  initial failure. </p>
                    <p className="my-1">2.9.13. For any safety related maintenance events required by the authority, the Authorised Panel  Workshop or the manufacturer of your Car or a Breakdown caused by continued operation of the Car in  an overheated condition irrespective of thermostat failure or the lack of proper and necessary amounts of coolants or lubricants. </p>
                    <p className="my-1">2.9.14. For any repair or replacement of any In-Scope Components if a Breakdown has not occurred.</p>
                    <p className="my-1">2.9.15. claims for mechanical or electrical failure of In-Scope Components due to wear and tear. </p>
                    <p className="my-1">2.9.16. If any alterations that have changed the design or performance of the In-Scope Components.</p>
                    <p className="my-1">2.9.17. If your odometer has ceased to operate and odometer repairs have not been made immediately, or the odometer has been altered in any way subsequent to purchase, mileages tuning, where the odometer has been altered or misrepresented your Car’s actual mileage.</p>
                    <p className="my-1">2.9.18. For any liability for property damage, or for injury to or death of any person arising out of the  operation, maintenance or use of your Car described in these Terms, whether or not related to the parts in-scope. For loss of use, time, profit, inconvenience, or any other consequential loss, including any consequential damage to a non-in-scope part that results from a Breakdown. </p>
                    <p className="my-1">2.9.19. When the responsibility for the repair is in-scope by an insurance policy, manufacturer and/or dealer customer assistance program, or any warranty from the manufacturer, or a repairer’s guarantee/ warranty (regardless of the manufacturer’s or repairer’s ability to pay for such repairs). Further, In-scope  under this T&C is similarly limited in the event of a Breakdown if the manufacturer has announced its responsibility through any means, including public recalls and factory service bulletins, or news alerts. </p>
                    <p className="my-1">2.9.20. For any pre-existing condition, for any Breakdown or condition existing prior to the Activation Date.</p>
                    <p className="my-1">2.9.21. Reimbursement for any repair or replacement made without prior authorization from eAutoGen.</p>
                    <p className="my-1">2.9.22. Valve grinding, , burnt valves, stuck valves and/or broken of timing belt & chainand are not inscope. </p>
                    <p className="my-1">2.9.23. Any mechanical Breakdown caused by failure to maintain proper levels of lubrication, lubricant blockage, coolant blockage lack of lubrication or carbon buildup in cylinders.</p>
                    <p className="my-1">2.9.24. Breakdown caused by or due to the failure of nuts, bolts or fasteners (internal and/or external). Notwithstanding the foregoing, if the nuts, bolts or fasteners are in-scope in conjunction with an InScope Component, repair for such Breakdown will be deemed as an in-scope part. </p>
                    <p className="my-1">2.9.25. Engine Chip Tuning.
                    </p>
                    <p className="my-1">2.9.26. Arose from an intentional act of the owner of the Car.</p>
                    <p className="my-1">2.9.27. Any repair work resulted directly or indirectly from any dishonest, fraudulent, criminal, or illegal act committed by the owner of the Car.</p>
                    <p className="my-1">2.9.28. Airborne industrial pollutants including acid rain, industrial fallout, salt, sand, stones, tree sap, bird and animal droppings, windstorm, hail, flood, lightning or other acts of nature.</p>
                    <p className="my-1">2.9.29. Bodily injury or property damage liability, medical payments, physical damage, personal injury protection, or losses other than those stated in these Terms.</p>
                    <p className="my-1">2.9.30. Breakdown caused by any foreign object getting into or onto a part.</p>
                    <p className="my-1">2.9.31. Correct oil / fluid levels not maintained.</p>
                    <p className="my-1">2.9.32. Any incidental and consequential losses such as telephone calls, towing, car rental charges, hotel charges, loss of time, commercial loss.</p>
                    <p className="my-1">2.9.33. Attributable to impact or road traffic accident.</p>
                    <p className="my-1">2.9.34. Refilling or topping up with incorrect specification fuel or alternative fuel.</p>
                    <p className="my-1">2.9.35. Consequential damage to a component not covered as a result of the failure of a In-Scope
                      Component, and/or damage to an In-Scope Component caused by the failure of an excluded component
                      or event.</p>
                    <p className="my-1">2.9.36. The cost and expense to test or replace parts as required by government authority.</p>
                    <p className="my-1">2.9.37. Normal routine service, maintenance, cleaning, lubrication, adjustments or alignment.</p>
                    <p className="my-1">2.9.38. Damage arising from transportation of the Car. </p>
                    <p className="my-1">2.9.39. Damage caused by war, sonic boom, or nuclear radiation.</p>
                    <p className="my-1">2.9.39. Damage caused by war, sonic boom, or nuclear radiation.</p>
                    <p className="my-1">2.9.41. Glass of the power window</p>
                    <p className="my-1">2.9.42. Any parts or components for the purpose of raising the engine’s compression when a breakdown has not occurred</p>
                    <p className="my-1">2.9.43. Road assistance is excluded</p>
                    <p className="my-1">2.9.44. Breakdown arising directly or indirectly from infectious and/or communicable disease is excluded</p>
                    <p className="my-1">2.9.45. Any cyber loss is excluded</p>
                    <p className="my-1">2.9.46. Any sanction, prohibition or restriction under United Nations resolutions or the trade or  economic sanctions, laws or regulations of Malaysia, the European Union, United Kingdom or United  States of America, unless such trade or economic sanctions, laws or regulations of the United Kingdom or United States of America would contravene the laws or regulations of the European Union or Germany is excluded.</p>
                  </div>

                  <h1 className="mx-0 my-3">2.10. Ineligible for AutoGen PLUS</h1>
                  <p>The following Cars are ineligible under this Section 2:</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <p className="my-1">2.10.1. Any Car where original manufacturer warranty still active or valid or in force.</p>
                    <p className="my-1">2.10.2. Commercial Use Cars. </p>
                    <p className="my-1">2.10.3. Any Car used for towing, or used as a commercial unit, or used for rental, taxi, limousine or shuttle, towing/wrecker service, dumping (dump beds), cherry pickers, lifting or hoisting, police  or emergency service, principally off-road use, prearranged or organized racing or competitive driving.</p>
                    <p className="my-1">2.10.4. Any Car that total loss, beyond economical repair, salvage/refundable, salvage theft, assembled, dismantled, scrap, fire, flood, physical damage, saltwater, frame change, motor change, body  exchange, junk or parts only. </p>
                    <p className="my-1">2.10.5. Any Car that has sustained major accident damage, fire damage or floor damage. </p>
                    <p className="my-1">2.10.6. the Car is a cut-and-join car. </p>
                    <p className="my-1">2.10.7. the Car has missing parts, mechanical and/or electrical defects, including exterior and/or interior. </p>
                  </div>

                  <h1 className="mx-0 my-3">2.11. Security </h1>
                  <p>Our obligations and the performance to You under this Section is insured by a policy issued by Berjaya  Sompo Insurance Berhad. </p>

                  <h1 className="mx-0 my-3">2.12. Transferability</h1>
                  <p>AutoGen PLUS may be transferred by the original Customer to the subsequent owner of the Car with
                    prior written approval of eAutoGen. For any transfer of ownership, please contact +603-7931 3663 or
                    cs@eAutoGen.my. eAutoGen reserves its rights to accept or reject such application for transfer of
                    ownership of AutoGen PLUS at its sole discretion.</p>


                  <h1 className="mx-0 my-3">Section 3: General Terms and Conditions </h1>

                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="1">
                      <li>eAutoGen reserves the right at any time, at its sole and absolute discretion, to change, amend or otherwise modify these Terms without prior notice by posting the relevant amended Terms on the  Site. Such amendments are effective as of the date of posting, and the continued usage of
                        eAutoGen’s Site signifies the acceptance of the updated or modified these Terms.</li>
                      <li>AutoGen PLUS is limited and only valid in Malaysia.</li>
                      <li>Any and all disputes, controversies and conflicts (“<b>Disputes</b>”) arising out of the AutoGen PLUS and all subject matter with regard to the AutoGen PLUS shall be governed by the laws of Malaysia. Unless and otherwise required by the Applicable Laws, any dispute, controversy, claim or difference of any kind whatsoever shall arising out of or relating to these Terms against or relating to eAutoGen  under these Terms shall be resolved by arbitration in accordance with the rules of Asian International Arbitration Centre in Kuala Lumpur (AIAC). The number of arbitrators shall be one. The  seat of arbitration shall be Kuala Lumpur and the arbitration shall apply substantive law of Malaysia. The arbitral proceedings shall be conducted in English language. You hereby give up the right to litigate (or participate in as a party or class member) all disputes against eAutoGen in court before a judge or jury.</li>
                      <li>eAutoGen will not, under any circumstances or by whatsoever reason, be liable for any loss or injury to a person or loss or damage to property or any incidental, contingent, special or any direct or  indirect loss and consequential damages including but not limiting to losses incurred due to any delay in performing the obligations under these Terms and loss of use during the period that your Car is at the Authorised Panel Workshop(s) or eAutoGen Authorised Partner, and/or while awaiting  repair.</li>
                      <li>The Customer hereby agree that eAutoGen may collect, store, process, disclose, access, review and/or use personal data (including sensitive personal data) about the Customer, whether obtained from the Customer themselves or from other sources in accordance with our privacy policy (“<b>Privacy Policy</b>”). Subject to our Privacy Policy, if you send any communications or materials to us by electronic mail or otherwise, including any data, questions, comments, suggestions, or the like, all such communications are, and will be treated as, non-confidential and non-proprietary. Anything you transmit or post may be used by eAutoGen or its affiliates for any purpose, including, but not  limited to, reproduction, disclosure, transmission, publication, broadcast, and posting. Furthermore,  eAutoGen is free to use, without any compensation to you, any ideas, concepts, know-how, or techniques contained in any communication you send to the Site for any purpose whatsoever, including, but not limited to, developing, manufacturing, and marketing products using such information.</li>
                      <li>eAutoGen shall assume no responsibility and shall not be liable for any unauthorized or unlawful interception or modification of any communication between you and eAutoGen (whether by electronic mail or otherwise), or for any loss or damage arising thereby (including but not limited to any loss or damage arising out of the unauthorized or fraudulent use of any information contained in or made accessible via such communication).</li>
                      <li>eAutoGen may without prior notice to the Customer, immediately terminate, suspend or revoke any all of the Customer rights granted under these Terms. Upon any termination of these Terms, the Customer shall immediately cease all access to and use of this Site and/or Services in whole or in part. Any termination shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. eAutoGen shall not be liable to the Customer or to any other person as a result of any such suspension, revocation or termination. </li>
                      <li>The Customer agrees to defend, indemnify (and keep indemnified) and hold eAutoGen and its
                        affiliates, and their agents, directors, officers, employees, successors and/or assignees, harmless from and against any claims, liabilities, damages, costs, judgments, losses or expenses (including reasonable legal fees on a full indemnity basis), arising out of or in connection with the Customer’s violation or breach of any terms, conditions, representations and warranties of these Terms, including warranty with respect to the AutoGen PLUS or any Applicable Laws, including any local laws or ordinances, whether or not referenced herein. </li>
                      <li>The Parties hereby acknowledge that such other policies and terms that may be prescribed and/or published by eAutoGen from time to time shall constitute an integral part of these Terms. </li>
                      <li>eAutoGen places great value on its Customers’ satisfaction and to take complaints seriously.
                        eAutoGen aims to respond to the Customer at the soonest possible time. Should you have any
                        inquiries or complaints relating to these Terms, the use of this Site, Services and/or AutoGen PLUS, please contact our customer service at +603-7931 3663 or cs@eAutoGen.my. </li>
                    </ol>
                  </div>
                </ol>

              </Container>
              <div style={{ marginTop: "50px" }}>
                <Footer />
              </div>

            </div>
            :
            <div style={{ maxWidth: "1600px", margin: "auto" }}>
              <Header />
              <h1 className="text-center term_head">Terma dan syarat</h1>
              <Container className="terms mt-4" >

                <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                  <p>
                    eAutoGen Capital Sdn. Bhd (No. Syarikat 202101001979 (1402277-K)) (selepas ini dirujuk sebagai
                    “<b>eAutoGen</b>” atau “<b>kami</b>”) ialah pemilik laman web yang dikenali sebagai <a href='https://eautogen.my/'>www.eAutoGen.my</a> (“<b>Laman Web</b>”) yang menawarkan pakej jaminan lanjutan kereta kepada pelanggan (selepas ini dirujuk sebagai
                    "<b>Pelanggan</b>" atau "<b>anda</b>") berdasarkan terma-terma dan syarat-syarat ini ("<b>Terma</b>") serta prosedur lain
                    yang akan ditentukan oleh eAutoGen dari semasa ke semasa, dalam budi bicara mutlaknya.
                  </p>
                  &nbsp;
                  <p>
                    Anda dengan ini bersetuju untuk terikat dengan Terma ini, Polisi Bayaran Balik, Dasar Privasi dan
                    sebarang dasar serta terma dan syarat lain seperti yang mungkin disiarkan di Laman Web eAutoGen dari
                    semasa ke semasa (“<b>Terma Lain</b>”).

                  </p>
                  &nbsp;
                  <p>
                    Terma ini mengawal akses dan penggunaan Laman Web dan penggunaan serta pembelian sebarang
                    produk, perkhidmatan, maklumat dan fungsi yang disedia oleh kami di Laman Web ini (“
                    Perkhidmatan”). Sebelum menggunakan Laman Web ini atau Perkhidmatan kami, anda mesti membaca
                    dengan teliti dan menerima Terma ini dan anda mesti bersetuju dengan pemprosesan data peribadi
                    anda seperti yang diterangkan dalam Dasar Privasi yang disiarkan di Laman Web ini.
                  </p>
                  &nbsp;
                  <p>
                    Dengan mengakses Laman Web dan/atau menggunakan Perkhidmatan kami, anda bersetuju untuk
                    terikat dengan Terma ini dan sebarang pindaan terhadap perkara di atas yang dikeluarkan oleh
                    eAutoGen dari semasa ke semasa. Jika anda tidak bersetuju dengan Terma ini, sila tamatkan akses anda
                    dan/atau penggunaan Laman Web dan/atau Perkhidmatan kami.

                  </p>
                </div>
                <p className="mt-2 mx-2">AutoGen PLUS terdiri daripada:</p>
                <ul className="ul_wrapp">

                  &nbsp;
                  <li>Bahagian 1: Kelayakan dan Pemeriksaan Kereta</li>
                  <li>Bahagian 2: Program Waranti Lanjutan</li>
                  <li>Bahagian 3: Terma dan Syarat Am</li>
                </ul>

                <h2 style={{ fontSize: "22px" }} className="mt-5">Definisi</h2>
                <p>Istilah-istilah berikut mempunyai maksud berikut:</p>

                <ol>
                  <div
                    style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}
                  >
                    <ol type="1">

                      <li>
                        “Undang-undang Terpakai” bermaksud berkenaan dengan mana-mana orang, mana-mana dan
                        semua perlembagaan, perjanjian, statut, undang-undang, undang-undang kecil, peraturan, ordinan,
                        kod, peraturan, ketetapan, penghakiman, peraturan undang-undang lazim, perintah, dekri,
                        anugerah, injunksi atau sebarang bentuk keputusan, penentuan atau keperluan atau dibuat atau
                        dikeluarkan oleh pihak berkuasa atau kerajaan, berkanun, pengawalseliaan, pentadbiran, penyeliaan
                        atau kehakiman (termasuk tanpa had, mana-mana bursa saham atau suruhanjaya sekuriti yang
                        berkaitan) atau mana-mana mahkamah, penimbang tara atau tribunal yang mempunyai bidang
                        kuasa kompeten dan yang mana orang itu tertakluk.
                      </li>
                      <li>
                        “AutoGen PLUS” bermaksud program jaminan lanjutan yang disediakan oleh eAutoGen.
                      </li>
                      <li>
                        “Kerosakan” bermaksud kegagalan secara tiba-tiba dan tidak dijangka bagi komponen Skop Dalam
                        tertakluk kepada Terma ini.
                      </li>
                      <li>
                        “Kereta” bermaksud kereta kegunaan persendirian dan ia hendaklah ditafsirkan sewajarnya.

                      </li>
                      <li>
                        “Kereta Penggunaan Komersial” bermaksud kereta yang digunakan di bawah lesen kereta komersial
                        termasuk tetapi tidak terhad kepada pertanian, penternakan, kerja laluan, aktiviti tapak kerja, kerja
                        servis atau pembaikan dan penghantaran barang.

                      </li>
                      <li>
                        “Tuntutan” bermaksud permintaan pembaikan yang dibuat oleh Pelanggan di bawah AutoGen PLUS.
                      </li>
                      <li>
                        “Bengkel Panel eAutoGen” merujuk kepada bengkel yang dibenarkan untuk melaksanakan kerjakerja pembaikan dan/atau penyelenggaraan kereta sewaktu Tarikh Pengaktifan.

                      </li>
                      <li>
                        “sedia ada” bermaksud sebarang kecacatan atau kerosakan yang wujud sebelum Tarikh Pengaktifan.
                      </li>
                      <li>
                        “Tarikh Pengaktifan” merujuk kepada tarikh permulaan AutoGen PLUS.
                      </li>
                      <li>
                        “Ringkasan” bermaksud dokumen yang akan diberikan oleh eAutoGen kepada Pelanggan semasa
                        pengaktifan AutoGen PLUS yang memberikan maklumat tentang Kereta, AutoGen PLUS dan
                        Komponen Skop Dalam bagi Kereta.

                      </li>
                      <li>
                        “pakai dan lusuh” bermaksud kemerosotan beransur-ansur yang berkaitan dengan penggunaan
                        biasa, umur dan perbatuan Kereta dan komponennya.
                      </li>
                    </ol>
                  </div>

                  <h1 className="mx-0 my-5">Bahagian 1: Kelayakan dan Pemeriksaan Kereta</h1>
                  <p>Pelanggan hendaklah memastikan Kereta anda memenuhi semua keperluan berikut sebelum membeli
                    AutoGen PLUS untuk layak mendapatkan AutoGen PLUS: -</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>

                    <ol type="a">
                      <li>Kereta mestilah tidak melebihi 10 tahun, dikira bermula dari tarikh pendaftaran;</li>
                      <li>mempunyai perbatuan (sebenar dan berdasarkan bacaan) tidak lebih daripada 200,000 kilometer;
                        dan</li>
                      <li>enjin dan transmisi Kereta berada dalam keadaan baik dan boleh digunakan,</li>
                    </ol>
                  </div>

                  <p className="my-2">(secara kolektif, " <b>Kriteria Kelayakan</b> ")</p>

                  <p>eAutoGen akan mengeluarkan kod pesanan (“<b>Kod Pesanan</b>”) apabila anda berjaya membeli AutoGen
                    PLUS. Selepas itu, anda hendaklah membuat temu janji dengan mana-mana pusat pemeriksaan kereta
                    yang ditetapkan oleh eAutoGen ("<b>Rakan Kongsi Sah eAutoGen</b>") untuk menjalankan ujian diagnosis bagi
                    Kereta (“Pemeriksaan”) dalam tempoh 90 hari dari tarikh penerimaan Kod Pesanan (“<b>Tempoh
                      Pemeriksaan</b>”). Bagi mengelakkan keraguan, jika Pelanggan gagal, enggan dan/atau mengabaikan untuk
                    menjalani Pemeriksaan dalam Tempoh Pemeriksaan, AutoGen PLUS secara automatik akan dibatalkan
                    dan ditamatkan tanpa bayaran balik dan liabiliti kepada eAutoGen</p>


                  <h1 className="mx-0 my-3">1.1. Tujuan dan Skop Pemeriksaan Kereta.</h1>
                  <p>Pemeriksaan ini bertujuan untuk membantu eAutoGen menilai keadaan Kereta dan memastikan Kereta
                    tresebut memenuhi Kriteria Kelayakan. Rakan Kongsi Sah eAutoGen akan menjalankan penilaian yang
                    saksama, tidak invasif terhadap sistem dan komponen Kereta yang dipasang secara kekal dan mudah
                    diakses, untuk pengaktifan AutoGen PLUS. Rakan Kongsi Sah eAutoGen akan menyediakan semua
                    laporan pemeriksaan termasuk ringkasan pemeriksaan untuk Kereta (“<b>Laporan</b>”). eAutoGen tidak akan
                    bertanggungjawab bagi sebarang representasi yang dibuat oleh Rakan Kongsi Sah eAutoGen berhubung
                    dengan Kereta dan kandungan Laporan </p>
                  <p>Rakan Kongsi Sah eAutoGen adalah generalis dan mungkin memiliki latihan / pensijilan/ kebenaran yang
                    memerlukan untuk menjalankan Pemeriksaan namun, mereka tidak bertindak sebagai pakar dalam
                    mana-mana kraf, disiplin atau perdagangan, atau sebagai jurutera berlesen. Anda dengan ini mengakui
                    dan bersetuju bahawa eAutoGen tidak akan bertanggungjawab terhadap sebarang kerosakan khas,
                    langsung, tidak langsung, berbangkit atau sampingan atau apa-apa kerosakan sekalipun, sama ada
                    dalam tindakan kontrak, kecuaian atau tort lain, yang timbul daripada atau berkaitan dengan
                    Pemeriksaan yang dijalankan oleh Rakan Kongsi Sah eAutoGen</p>

                  <h1 className="mx-0 my-3">1.2. Skop Pemeriksaan</h1>
                  <p>Berikut ialah skop Pemeriksaan yang akan dijalankan oleh Rakan Kongsi Sah eAutoGen ("<b>SkopPemeriksaan</b>"): -</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="i">
                      <li>Enjin: Permulaan, melahu, bunyi bising, pecutan, alat imbasan / kod rosak
                      </li>
                      <li>Transmisi kereta: Operasi Klac, Gandar Pemacu / Bunyi Sarung Pemindahan, Operasi Interlock
                        Shift, Alat imbasan, kod rosak
                      </li>
                    </ol>
                  </div>
                  <p>Item yang tidak disenaraikan di atas dianggap sebagai di luar skop dan bukan sebahagian daripada Skop
                    Pemeriksaan.</p>

                  <h1 className="mx-0 my-3">1.3. Had dan Di Luar Skop.</h1>
                  <p>Laporan yang disediakan oleh Rakan Kongsi Sah eAutoGen adalah terhad kepada Skop Pemeriksaan dan
                    berdasarkan keadaan sistem dan komponen Kereta yang boleh dilihat dan jelas seperti yang wujud pada
                    masa Pemeriksaan. Keadaan Kereta mungkin berubah dari masa Pemeriksaan dijalankan.
                  </p>


                  <h1 className="mx-0 my-3">1.4. Had Fizikal </h1>
                  <p>Pemeriksaan secara fizikal terhad kepada kawasan yang mudah diakses, tersedia untuk pemeriksaan,
                    dan bukan risiko keselamatan. Rakan Kongsi Sah eAutoGen mempunyai budi bicara mutlak sama ada
                    untuk:</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="i">
                      <li>memasuki mana-mana kawasan atau melakukan sebarang prosedur yang, pada pendapat
                        Rakan Kongsi Sah eAutoGen, tidak selamat dan berkemungkinan berbahaya kepada Rakan
                        Kongsi Sah eAutoGen atau orang lain, atau
                      </li>
                      <li>memasuki mana-mana kawasan atau melakukan sebarang prosedur yang, pada pendapat
                        Rakan Kongsi Sah eAutoGen, berkemungkinan merosakkan Kereta atau sistem atau
                        komponennya. </li>
                    </ol>
                  </div>


                  <h1 className="mx-0 my-3">1.5. Pembaikan atau Penilaian Lanjut. </h1>
                  <p>Rakan Kongsi Sah boleh mengesyorkan keperluan atau kemungkinan keperluan untuk membaiki,
                    menggantikan atau memantau sistem atau komponen atau untuk mendapatkan pemeriksaan dan
                    analisis sistem atau komponen oleh peniaga atau juruteknik perkhidmatan dalam bidang kepakaran
                    yang ditetapkan. Anda mesti melibatkan dan mengarahkan mana-mana profesional, peniaga atau
                    juruteknik perkhidmatan yang menilai sistem atau komponen untuk menyediakan cadangan pembaikan/
                    penggantian untuk menangani masalah, kebimbangan dan/atau isu yang dikenal pasti oleh Inspektor,
                    dan mana-mana yang bersebelahan, cagaran atau bersebelahan, sistem atau komponen yang bersilang
                    dan/atau tersembunyi/terhalang yang mungkin memerlukan pembaikan, penggantian atau peningkatan.
                    Pembaikan, penggantian, peningkatan atau penyelenggaraan masalah, kebimbangan dan/atau isu yang
                    dikenal pasti oleh Rakan Kongsi Sah mungkin lebih sukar untuk diperbetulkan dan/atau lebih mahal
                    daripada yang dijangkakan.</p>


                  <h1 className="mx-0 my-3">1.6. Status dan Kegunaan.</h1>
                  <p>Laporan mungkin mengandungi anggaran status dan kegunaan sistem dan komponen berkenaan dengan
                    Kereta. Anotasi sistem dan komponen adalah berdasarkan pemerhatian fizikal dan data yang tersedia;
                    keadaan sebenar boleh mengubah status dan kegunaan mana-mana sistem atau komponen. Rakan
                    Kongsi Sah eAutoGen tidak boleh membuat penentuan mengenai keadaan yang tidak menguntungkan
                    seperti penyalahgunaan sebelumnya, penggunaan berlebihan, selang penyelenggaraan perkhidmatan,
                    kualiti produk yang tidak baik atau pembuatan yang rosak. Ia adalah mustahil untuk Rakan Kongsi Sah
                    eAutoGen melaporkan dengan tepat bila sistem atau komponen memerlukan pembaikan atau
                    penggantian.
                  </p>
                  <p>Pemeriksaan itu tidak membentuk dan/atau sama dengan:</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="i">
                      <li>penilaian atau pendapat tentang nilai Kereta;
                      </li>
                      <li>pendapat tentang kesesuaian pembelian;
                      </li>
                      <li>penarafan Kereta berkenaan dengan keinginan pembelian; dan</li>
                      <li>pendapat tentang kesesuaian Kereta atau Kereta untuk sebarang kegunaan tertentu atau
                        khusus..</li>
                    </ol>
                  </div>

                  <h1 className="mx-0 my-3">1.7. Persampelan Perwakilan.</h1>
                  <p>Terdapat banyak perkara yang perlu disemak semasa Pemeriksaan; item yang banyak dan berulang
                    dipilih secara rawak dan nombor wakil disemak untuk kefungsian. Laporan itu tidak membayangkan
                    bahawa setiap sistem atau komponen diperiksa atau setiap kemungkinan kecacatan dikenal pasti.
                  </p>

                  <h1 className="mx-0 my-3">1.8. Kegagalan Memenuhi Kriteria Kelayakan</h1>
                  <p>Sekiranya Kereta tidak memenuhi Kriteria Kelayakan, eAutoGen berhak untuk membatalkan dan/atau
                    menamatkan pembelian AutoGen PLUS oleh Pelanggan. eAutoGen akan membayar balik harga
                    pembelian AutoGen PLUS, ditolak sebarang kos dan perbelanjaan yang ditanggung oleh eAutoGen,
                    termasuk tetapi tidak terhad kepada kos pentadbiran, yuran pengendalian, yuran Pemeriksaan dan caj
                    lain yang mungkin dikenakan oleh mana-mana pihak ketiga berkenaan dengan AutoGen Tambahan pula.</p>
                  &nbsp;
                  <p>Pembatalan dan/atau bayaran balik AutoGen PLUS hendaklah tertakluk kepada polisi bayaran balik yang
                    berasingan dan prosedur lain seperti yang mungkin ditentukan oleh eAutoGen dari semasa ke semasa,
                    mengikut budi bicara mutlaknya (“<b>Polisi Bayaran Balik</b>”). </p>
                  &nbsp;
                  <p>Setiap permohonan untuk pembatalan dan/atau bayaran balik AutoGen PLUS akan dinilai berdasarkan
                    Polisi Bayaran Balik atau berdasarkan kes demi kes, mengikut budi bicara mutlak eAutoGen. Keputusan
                    eAutoGen adalah muktamad dan anda dengan ini bersetuju untuk terikat dengan perkara yang sama.

                  </p>

                  <h1 className="mx-0 my-3" style={{ fontSize: "20px" }}>1.8.1.Had Masa.</h1>
                  <p>Sebarang pertikaian yang timbul daripada Laporan mesti dibuat kepada eAutoGen dalam tempoh 14 hari
                    dari tarikh Pemeriksaan. eAutoGen mempunyai budi bicara mutlak dalam memeriksa dan menentukan
                    Laporan berdasarkan kes demi kes. Penemuan dan penentuan eAutoGen adalah muktamad dan
                    mengikat. </p>


                  <h1 className="mx-0 my-3">Bahagian 2: Program Waranti Lanjutan</h1>

                  <h1 className="mx-0 my-3">2.1. Sifat Program Waranti Lanjutan   </h1>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <p>2.1.1. Anda bersetuju dan memahami bahawa AutoGen PLUS bukanlah polisi insurans.</p>
                    <p>2.1.2. Untuk layak mendapat AutoGen PLUS, anda mesti memastikan Kereta anda memenuhi semua
                      Kriteria Kelayakan seperti yang dinyatakan dalam Bahagian 1 di atas.
                    </p>
                  </div>

                  <h1 className="mx-0 my-3" >2.2. Keseluruhan Program Waranti Lanjutan</h1>
                  <p>Bahagian ini, termasuk Terma ini, Terma Lain, Laporan dan Ringkasan bersama-sama dengan sebarang
                    pengesahan, jika ada, membentuk keseluruhan perjanjian antara eAutoGen dan Pelanggan
                    ("<b>Perjanjian</b>”). eAutoGen berhak untuk menukar, menyemak, mengubah dan mengubah suai termaterma Perjanjian dari semasa ke semasa.</p>
                  &nbsp;
                  <p>Bahagian ini termasuk Kerosakan mekanikal terpakai hanya berkenaan dengan Kereta yang diterangkan
                    pada Ringkasan. Bahagian ini akan menjadi tidak sah jika terdapat ketidaktepatan, gangguan atau
                    pengubahan pada jarak tempuh odometer Kereta sehingga jarak tempuh kereta yang sebenar tidak
                    ditunjukkan pada odometer atau tidak dapat ditentukan. Jika odometer menjadi tidak boleh beroperasi
                    dalam tempoh Perjanjian, anda mesti memberitahu eAutoGen degan segera dan menyediakan
                    dokumentasi eAutoGen yang membuktikan bahawa odometer telah dibaiki selepas odometer menjadi
                    tidak boleh beroperasi dalam tempoh lima belas (15) hari. eAuoGen berhak untuk menolak dan/atau
                    membatalkan Tuntutan yang diminta oleh Pelanggan jika gagal berbuat demikian. Semua kos dan
                    perbelanjaan untuk membaiki odometer hendaklah ditanggung sepenuhnya oleh Pelanggan. </p>
                  &nbsp;
                  <p>Bahagian ini menyediakan permintaan untuk membaiki Kerosakan Komponen Dalam Skop yang
                    dipasang oleh pengeluar asal Kereta, seperti yang ditakrifkan di atas</p>

                  <h1 className="mx-0 my-3" >2.3. Tempoh Waranti Lanjutan</h1>
                  <p>12 bulan atau 30,000 km dari Tarikh Pengaktifan atau had pembaikan seperti yang ditetapkan dalam
                    Ringkasan telah habis sepenuhnya, yang mana dahulu (" <b>Tempoh Waranti </b>”).
                  </p>
                  &nbsp;
                  <p>Walau apa pun yang menyatakan sebaliknya di dalam Terma ini, jika Kerosakan berlaku pada
                    Kereta, kami hanya akan menerima Tuntutan selepas 7 hari dari Tarikh Pengaktifan atau Kereta
                    telah dipandu sekurang-kurangnya 300 kilometer dari Tarikh Pengaktifan berdasarkan perbatuan
                    Kereta direkodkan di Laporan. </p>

                  <h1 className="mx-0 my-3" >2.4. Kerosakan</h1>
                  <p>Sekiranya berlaku kerosakan mana-mana Komponen Dalam Skop seperti yang disenaraikan dalam
                    Klausa 2.8 di bawah, Pelanggan hanya berhak membuat Tuntutan kepada eAutoGen untuk
                    perkhidmatan pembaikan dan/atau penggantian tertakluk kepada had pembaikan seperti yang
                    dinyatakan dalam Ringkasan. Penggantian Komponen Dalam Skop yang telah mengalami Kerosakan
                    boleh dibuat dengan bahagian pengeluar peralatan asal / bahagian pengeluar peralatan bukan asal /
                    bahagian yang dikilang semula / bahagian terpakai / bahagian yang dibaik pulih mengikut budi bicara
                    mutlak eAutoGen.</p>

                  <h1 className="mx-0 my-3" >2.5. Had Pembaikan:</h1>
                  <p>Had pembaikan agregat adalah tertakluk kepada had seperti yang dinyatakan dalam Ringkasan. Liabiliti
                    eAutoGen untuk kerosakan sampingan dan berbangkit termasuk, tetapi tidak terhad kepada,
                    kecederaan peribadi, kerosakan fizikal, kerosakan harta benda, kehilangan penggunaan Kereta anda,
                    kehilangan masa, kehilangan gaji, kesulitan dan kerugian komersial akibat dan/atau timbul AutoGen
                    PLUS dan semua perkara berkaitan AutoGen PLUS termasuk pengendalian, penyelenggaraan atau
                    penggunaan Kereta anda dengan ini dikecualikan dengan nyata.
                  </p>

                  <h1 className="mx-0 my-3" >2.6. Keperluan Servis dan Penyelenggaraan :</h1>
                  <p>Pelanggan hendaklah memastikan bahawa Kereta diservis dan diselenggara oleh mana-mana bengkel
                    panel yang ditetapkan oleh eAutoGen (“<b>Bengkel Panel Dibenarkan</b>”) setiap 3 bulan atau 5,000 km, yang
                    mana dahulu (untuk minyak separa sintetik), ATAU 6 bulan atau 10,000 km, yang mana dahulu (untuk
                    minyak sintetik sepenuhnya) semasa dan sepanjang Tempoh Waranti (“Keperluan Servis”). Anda
                    dikehendaki menyimpan semua resit dan invois untuk tujuan pemeriksaan kami sekiranya berlaku
                    Tuntutan.</p>
                  &nbsp;
                  <p>Sebagai tambahan kepada Keperluan Servis, anda juga mesti menservis dan menyelenggara Kereta
                    dengan sewajarnya seperti yang disyorkan oleh eAutoGen dan/atau Bengkel Panel Dibenarkan.</p>
                  <p>Anda hendaklah memberikan eAutoGen dengan segera bukti servis / sejarah servis untuk Kereta atas
                    permintaan oleh eAutoGen. Satu-satunya bukti servis yang boleh diterima jika eAutoGen memerlukan
                    ini adalah invois perkhidmatan terperinci sepenuhnya yang menunjukkan tarikh dan jarak tempuh
                    servis.
                  </p>
                  &nbsp;
                  <p>Kegagalan untuk mematuhi Keperluan Servis di atas akan mengakibatkan penolakan sebarang Tuntutan
                    tanpa liabiliti kepada eAutoGen. </p>

                  <h1 className="mx-0 my-3">2.7. Memfailkan Permintaan Kerosakan untuk Pembaikan: </h1>
                  <p>Jika Kerosakan berlaku pada Kereta semasa Tempoh Waranti, anda mesti mengambil langkah berikut
                    untuk memfailkan permintaan pembaikan:
                  </p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>

                    <p className="my-2">2.7.1. Bawa Kereta Anda ke Bengkel Panel Dibenarkan.</p>
                    <p className="my-2"> 2.7.2. Sediakan Bengkel Panel Dibenarkan dengan salinan Ringkasan AutoGen PLUS Anda.</p>
                    <p className="my-2"> 2.7.3. Dapatkan kebenaran daripada eAutoGen sebelum memulakan sebarang pembaikan – Sebelum
                      sebarang permintaan untuk pembaikan dibuat, anda hendaklah mengarahkan Bengkel Panel yang
                      Dibenarkan untuk menghubungi eAutoGen bagi mendapatkan kebenaran pembaikan di talian +603-
                      7931 3663 atau cs@eAutoGen.my. Sebarang permintaan untuk membaiki tanpa kebenaran terlebih
                      dahulu akan ditolak. </p>
                    <p className="my-2">2.7.4. Membenarkan pembongkaran dan/atau Pemeriksaan – Dalam sesetengah kes, jika perlu, anda
                      bertanggungjawab untuk membenarkan Bengkel Panel Dibenarkan untuk memeriksa dan/atau
                      membuka Kereta anda untuk menentukan punca dan kos pembaikan. Kami hanya akan membayar yang
                      berpatutan
                      kos diagnostik yang dikaitkan dengan Tuntutan yang sah untuk bahagian yang rosak di bawah Seksyen
                      ini. Anda akan menanggung caj ini jika bahagian yang rosak tidak dilindungi di bawah AutoGen PLUS.
                      eAutoGen berhak untuk menghendaki pemeriksaan Kereta anda dijalankan oleh pakar untuk
                      memutuskan sama ada Tuntutan anda dilindungi dan kuantum pembaikan sebelum sebarang
                      pembaikan dibuat. Jika anda atau sesiapa yang bertindak bagi pihak anda menghalang kami dan/atau
                      orang yang diberi kuasa kami daripada dapat mengetahui apa yang menyebabkan kegagalan dengan
                      memeriksa Kereta atau bahagian yang rosak, eAutoGen berhak untuk menolak Tuntutan tersebut atas
                      budi bicara mutlaknya.
                    </p>
                    <p className="my-2">2.7.5. Kos di luar Skop akan ditanggung oleh Pelanggan.</p>
                    <p className="my-2">2.7.6. Mencegah Kerosakan selanjutnya – Anda hendaklah mengambil tindakan segera untuk
                      mengelakkan kerosakan selanjutnya pada Kereta anda. Bahagian ini tidak akan melindungi Kerosakan
                      yang disebabkan oleh operasi berterusan atau dengan tidak mendapatkan pembaikan tepat pada
                      masanya bagi komponen yang gagal. Anda bertanggungjawab untuk memerhati sebarang lampu amaran
                      dan tolok Kereta, dan mengambil tindakan yang sewajarnya dengan segera. Kegagalan berbuat demikian
                      boleh mengakibatkan penolakan Tuntutan.</p>
                  </div>

                  <h1 className="mx-0 my-3">2.8. Komponen Dalam Skop</h1>
                  <p>Berikut ialah komponen dan bahagian yang akan dilindungi di bawah AutoGen PLUS ("<b>Komponen Dalam
                    Skop"</b>):</p>
                  &nbsp;
                  <p><b>Enjin</b> : Blok Enjin, Kepala Silinder, Aci Engkol, Aci Sipi, Rod Penyambung, Omboh, Gelang Omboh,
                    Pemasa Injap Boleh Ubah, Solenoid Pelaras Aci Sesondol, Aci Pengimbang, Injap Masuk, Injap Ekzos, Aci
                    Sesukan, Aci Sesondol Ekzos, Motor Injap, Pam Minyak, Pam Udara Kawalan Pelepasan, Penderia Jisim
                    Udara (Penderia Aliran Udara), Pam Vakum Enjin & Penderia Kedudukan</p>
                  &nbsp;
                  <p><b>Penghantaran</b> : (Automatik atau Manual) Penderia Suhu Klac, Modul Kawalan Penghantaran (TCM), Pam
                    Minyak Penghantaran, Semua Jenis Injap Solenoid, Penukar Tork, Penderia Kelajuan Transmisi, Aci Gear,
                    Modul Peralihan / Pemilih Gear, Badan Injap</p>

                  <p>takluk kepada peruntukan Terma ini dan Ringkasan, AutoGen PLUS merangkumi Komponen Dalam Skop Kereta anda.</p>


                  <h1 className="mx-0 my-3">2.9. Luar Skop – Perkara yang Tidak Dilindungi oleh AutoGen PLUS</h1>
                  <p>AutoGen PLUS tidak terpakai dan tidak meliputi perkara berikut: </p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <p className="my-1">2.9.1. Untuk mana-mana bahagian yang tidak disenaraikan secara khusus dalam Klausa 2.8 di atas, atau
                      untuk mana-mana bahagian berikut: perumah termostat, penyerap hentak, karburetor, kabel/abahabah bateri dan bateri, penutup pengedar dan rotor, sistem sekatan keselamatan (termasuk beg udara),
                      kaca, kanta, rasuk tertutup, mentol lampu, lampu LED, telefon selular, komputer peribadi, dan sistem
                      kereta pra-panas, pusat permainan, pembesar suara. Radio, pemain cakera padat dan pemain kaset,
                      peranti pemancar/penerima elektronik, sistem pengecaman suara, konsol kawalan jauh, peranti
                      pengesanan radar, pemutar brek dan dram, semua komponen ekzos, dan komponen pelepasan berikut:
                      Injap pembersihan EGR/, tong vakum , kanister pemulangan wap, garis/injap pemulangan wap, pam
                      udara (kecuali pimp udara enjin) / garis /injap, penukar/penapisan pemangkin, penutup gas/leher
                      pengisi, jalur cuaca, trim, acuan, krom logam terang, upholsteri dan permaidani, cat, hiasan luar,
                      bampar, kepingan logam badan dan panel, rangka dan bahagian badan struktur, vinil dan bahagian atas
                      boleh tukar, sebarang pemasangan atas boleh tukar, pemegang pintu, pemegang pintu lif, pemegang
                      pintu belakang, sesendal/bearing pintu, perkakasan atau penyambung, tayar, penderia tekanan tayar,
                      roda/rim, pengaturcaraan, pengaturcaraan semula atau mengemas kini komponen yang tidak gagal
                      secara mekanikal.</p>
                    <p className="my-1">2.9.2. Kereta yang digunakan untuk perlumbaan, pertandingan, rali, acara sukan bermotor atau untuk tujuan yang tidak dilesenkan, sama ada untuk 1 atau lebih kejadian.</p>
                    <p className="my-1">2.9.3. Penyimpangan kecil tidak diiktiraf sebagai kualiti atau fungsi Kereta yang terjejas atau bahagianbahagian seperti bunyi bising atau getaran dan kecacatan yang muncul hanya di bawah operasi tertentu
                      atau tidak teratur.</p>
                    <p className="my-1">2.9.4. Untuk sebarang tuntutan yang disebabkan oleh atau timbul daripada (i) kekurangan servis kepada
                      Kereta menurut cadangan pengilang atau sebaliknya (mengikut kesesuaian) menurut Keperluan Servis
                      atau kegagalan anda untuk membetulkan masalah yang diketahui sebelum Kereta anda dipandu. </p>
                    <p className="my-1">2.9.5. Pembaikan di mana kerosakan yang menyebabkan kerosakan mekanikal terbukti sebelum tamat
                      tempoh waranti pengeluar asal.</p>
                    <p className="my-1">2.9.6. Dalam apa-apa yang berkaitan dengan penggunaan atau pengendalian mana-mana komputer,
                      virus komputer, sistem komputer, perisian komputer, pengaturcaraan, penjajaran semula, peningkatan
                      perisian, program atau proses mana-mana sistem elektronik akibat daripada (a) perubahan data kepada
                      tahun 2000 atau apa-apa perubahan tarikh lain dan/atau (b) apa-apa perubahan atau pengubahsuaian
                      atau kepada komputer, sistem komputer, perisian komputer, program atau proses atau mana-mana
                      sistem elektronik tersebut berhubung dengan sebarang perubahan tarikh.</p>
                    <p className="my-1">2.9.7. Sebarang peralatan yang tidak dipasang oleh pengilang asal Kereta dan/atau ia bukan Komponen Dalam Skop, seperti nat luar, bolt dan pengikat.  </p>
                    <p className="my-1">2.9.8. Blok enjin dan kepala silinder tidak berada dalam skop jika rosak akibat terlalu panas, beku atau meleding.</p>
                    <p className="my-1">2.9.9. Untuk perkhidmatan penyelenggaraan dan bahagian yang diterangkan dalam manual pemilik
                      Kereta anda seperti yang dibekalkan oleh pengilang dan perkhidmatan dan bahagian penyelenggaraan
                      biasa lain yang termasuk, tetapi tidak terhad kepada: penjajaran, pelarasan, pengimbangan roda,
                      penalaan, palam pencucuh, wayar palam pencucuh, palam cahaya, hos (melainkan disenaraikan sebagai
                      Bahagian Dalam skop tertentu), tali pinggang pemacu, pad brek, pelapik/kasut brek dan bilah pengelap.
                      Penapis, pelincir, penyejuk, cecair dan penyejuk akan berada dalam skop hanya jika penggantian
                      diperlukan berkaitan dengan Kerosakan dalam skop.
                    </p>
                    <p className="my-1">2.9.10. Untuk sebarang kerosakan dan/atau Kerosakan akibat perlanggaran, bahaya jalan raya,
                      kebakaran, kecurian, vandalisme, rusuhan, letupan, kilat, gempa bumi, pembekuan, karat atau kakisan,
                      ribut angin, kebocoran udara, hujan batu, air atau banjir, perbuatan Tuhan, garam, kerosakan alam
                      sekitar, bahan kimia, pencemaran cecair, bahan api, penyejuk atau pelincir, pecah rumah, kemalangan,
                      kapal terbang dan/atau kesan kenderaan, kebocoran bateri, kebocoran air, gangguan kuasa atau
                      lonjakan, voltan tidak mencukupi dan/atau tidak betul, haiwan dan/atau serangan serangga.</p>
                    <p className="my-1"> 2.9.11. Untuk mana-mana bahagian Kereta yang disyorkan atau memerlukan Bengkel Panel Dibenarkan
                      supaya ia diganti atau dibaiki, dikemas kini dan bukan Kerosakan mekanikal dalam skop. Dalam keadaan
                      sedemikian, semua kos dan perbelanjaan akan anda tanggungkan dengan sepenuhnya.</p>
                    <p className="my-1">2.9.12. Untuk sebarang Kerosakan yang disebabkan oleh penyalahgunaan, penyalahgunaan, kecuaian,
                      kekurangan penyelenggaraan biasa yang diperlukan oleh jadual penyelenggaraan pengilang atau
                      Bengkel Panel yang Dibenarkan untuk Kereta anda atau tidak wajar atau kekurangan servis atau
                      pembaikan selepas pembelian. Untuk sebarang Kerosakan yang disebabkan oleh pengumpulan enap
                      cemar akibat kegagalan anda melaksanakan perkhidmatan penyelenggaraan yang disyorkan, atau
                      kegagalan untuk mengekalkan tahap pelincir dan/atau penyejuk yang betul, atau kegagalan untuk
                      melindungi Kereta anda daripada kerosakan selanjutnya apabila Kerosakan telah berlaku atau kegagalan
                      untuk mempunyai Kereta anda ditarik ke kemudahan servis apabila operasi berterusan boleh
                      mengakibatkan kerosakan selanjutnya. Operasi berterusan termasuk kegagalan anda untuk
                      memerhatikan lampu amaran, tolok atau apa-apa tanda panas terlampau atau kegagalan komponen
                      lain, seperti kebocoran bendalir, tergelincir, mengetuk atau merokok, dan tidak melindungi Kereta anda
                      dengan terus memandu menyebabkan kerosakan melebihi kegagalan awal. </p>
                    <p className="my-1">2.9.13. Untuk sebarang acara penyelenggaraan berkaitan keselamatan yang diperlukan oleh pihak
                      berkuasa, Bengkel Panel yang Dibenarkan atau pengilang Kereta anda atau Kerosakan yang disebabkan
                      oleh operasi berterusan Kereta dalam keadaan terlalu panas tanpa mengira kegagalan termostat atau
                      kekurangan jumlah penyejuk atau pelincir yang betul dan diperlukan.
                    </p>
                    <p className="my-1">2.9.14. Untuk sebarang pembaikan atau penggantian mana-mana Komponen Dalam Skop jika Kerosakan
                      tidak berlaku.
                    </p>
                    <p className="my-1">2.9.15. Tuntutan untuk kegagalan mekanikal atau elektrik Komponen Dalam Skop disebabkan pakai dan lusuh. </p>
                    <p className="my-1">2.9.16. Jika sebarang perubahan yang telah mengubah reka bentuk atau prestasi Komponen Dalam
                      Skop.</p>
                    <p className="my-1">2.9.17. Jika odometer anda telah berhenti beroperasi dan pembaikan odometer tidak dibuat sertamerta, atau odometer telah diubah dalam apa jua cara selepas pembelian, penalaan perbatuan, di mana
                      odometer telah diubah atau salah nyatakan perbatuan sebenar Kereta anda.
                    </p>
                    <p className="my-1">2.9.18. Untuk sebarang liabiliti untuk kerosakan harta benda, atau untuk kecederaan atau kematian
                      mana-mana orang yang timbul daripada operasi, penyelenggaraan atau penggunaan Kereta anda yang
                      diterangkan dalam Terma ini, sama ada berkaitan atau tidak dengan bahagian dalam skop. Untuk
                      kehilangan penggunaan, masa, keuntungan, kesulitan, atau apa-apa kerugian berbangkit yang lain,
                      termasuk sebarang kerosakan berbangkit pada bahagian bukan dalam skop yang disebabkan oleh
                      Kerosakan.
                    </p>
                    <p className="my-1">2.9.19. Apabila tanggungjawab untuk pembaikan adalah dalam skop oleh polisi insurans, program
                      bantuan pelanggan pengilang dan/atau peniaga, atau sebarang waranti daripada pengilang, atau
                      jaminan/waranti pembaikan (tanpa mengira keupayaan pengilang atau pembaikan untuk membayar
                      pembaikan tersebut ). Selanjutnya, Dalam skop di bawah T&S ini juga terhad sekiranya berlaku
                      Kerosakan jika pengilang telah mengumumkan tanggungjawabnya melalui sebarang cara, termasuk
                      panggilan balik awam dan buletin perkhidmatan kilang, atau makluman berita.
                    </p>
                    <p className="my-1">2.9.20. Untuk sebarang keadaan sedia ada, untuk sebarang Kerosakan atau keadaan sedia ada sebelum
                      Tarikh Pengaktifan.</p>
                    <p className="my-1">2.9.21. Pembayaran balik untuk sebarang pembaikan atau penggantian yang dibuat tanpa kebenaran
                      terlebih dahulu daripada eAutoGen.</p>
                    <p className="my-1">2.9.22. Pengisaran injap, injap terbakar, injap tersangkut dan/atau tali pinggang masa & rantai patah dan tidak dalam skop.</p>
                    <p className="my-1">2.9.23. Sebarang Kerosakan mekanikal yang disebabkan oleh kegagalan mengekalkan tahap pelinciran
                      yang betul, pelincir tersumbat, penyumbatan penyejuk kekurangan pelinciran atau pembentukan
                      karbon dalam silinder. </p>
                    <p className="my-1">2.9.24. Kerosakan disebabkan oleh kegagalan nat, bolt atau pengikat (dalaman dan/atau luaran). Walau
                      apa pun yang dinyatakan di atas, jika nat, bolt atau pengikat berada dalam skop bersama dengan
                      Komponen Dalam Skop, pembaikan untuk Kerosakan tersebut akan dianggap sebagai bahagian dalam
                      skop.</p>
                    <p className="my-1">2.9.25. Penalaan Cip Enjin.</p>
                    <p className="my-1">2.9.26. Timbul dari perbuatan yang disengajakan oleh pemilik Kereta tersebut.</p>
                    <p className="my-1">2.9.27. Sebarang kerja pembaikan yang terhasil secara langsung atau tidak langsung daripada sebarang tindakan tidak jujur, penipuan, jenayah atau menyalahi undang-undang yang dilakukan oleh pemilik
                      Kereta.</p>
                    <p className="my-1">2.9.28. Bahan pencemar industri bawaan udara termasuk hujan asid, kejatuhan industri, garam, pasir, batu, getah pokok, najis burung dan haiwan, ribut angin, hujan batu, banjir, kilat atau perbuatan alam
                      lain.</p>
                    <p className="my-1">2.9.29. Kecederaan badan atau liabiliti kerosakan harta benda, bayaran perubatan, kerosakan fizikal, perlindungan kecederaan diri, atau kerugian selain daripada yang dinyatakan dalam Terma ini.</p>
                    <p className="my-1">2.9.30. Kerosakan yang disebabkan oleh sebarang objek asing masuk ke dalam atau ke bahagian.</p>
                    <p className="my-1">2.9.31. Paras minyak / cecair yang betul tidak dikekalkan.</p>
                    <p className="my-1">2.9.32. Sebarang kerugian sampingan dan berbangkit seperti panggilan telefon, tunda, caj sewa kereta,  caj hotel, kehilangan masa, kerugian komersial.</p>
                    <p className="my-1">2.9.33. Disebabkan impak atau kemalangan jalan raya.</p>
                    <p className="my-1">2.9.34. Mengisi semula atau menambah dengan bahan api spesifikasi yang salah atau bahan api
                      alternatif.</p>
                    <p className="my-1">2.9.35. Kerosakan berbangkit pada komponen yang tidak dilindungi akibat daripada kegagalan
                      Komponen Dalam Skop, dan/atau kerosakan pada Komponen Dalam Skop disebabkan oleh kegagalan
                      komponen atau peristiwa yang dikecualikan.</p>
                    <p className="my-1">2.9.36. Kos dan perbelanjaan untuk menguji atau menggantikan bahagian seperti yang dikehendaki oleh pihak berkuasa kerajaan.</p>
                    <p className="my-1">2.9.37. Servis rutin biasa, penyelenggaraan, pembersihan, pelinciran, pelarasan atau penjajaran.</p>
                    <p className="my-1">2.9.38. Kerosakan yang timbul daripada pengangkutan Kereta.</p>
                    <p className="my-1">2.9.39. Kerosakan yang disebabkan oleh peperangan, ledakan sonik atau sinaran nuklear.</p>
                    <p className="my-1">2.9.40. Sebarang Kerosakan berlaku di luar Malaysia</p>
                    <p className="my-1">2.9.41. Kaca power window</p>
                    <p className="my-1">2.9.42. Mana-mana bahagian atau komponen untuk tujuan menaikkan mampatan enjin apabila
                      kerosakan tidak berlaku</p>
                    <p className="my-1">2.9.43. Bantuan jalan raya dikecualikan</p>
                    <p className="my-1">2.9.44. Kerosakan yang timbul secara langsung atau tidak langsung daripada penyakit berjangkit
                      dan/atau berjangkit dikecualikan
                    </p>
                    <p className="my-1">2.9.45. Sebarang kehilangan siber dikecualikan</p>
                    <p className="my-1">2.9.46. Sebarang sekatan, larangan atau sekatan di bawah resolusi Pertubuhan Bangsa-Bangsa Bersatu
                      atau sekatan perdagangan atau ekonomi, undang-undang atau peraturan Malaysia, European Union,
                      United Kingdom dan/atau Amerika Syarikat, melainkan sekatan perdagangan atau ekonomi, undangundang atau peraturan United Kingdom atau Amerika Syarikat yang akan melanggar undang-undang
                      atau peraturan European Union atau Germany akan dikecualikan.</p>
                  </div>

                  <h1 className="mx-0 my-3">2.10. Tidak layak untuk AutoGen PLUS</h1>
                  <p>Kereta-kereta berikut tidak layak di bawah Seksyen 2 ini:</p>
                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <p className="my-1">2.10.1. Mana-mana Kereta di mana waranti pengeluar asal masih aktif atau sah atau berkuat kuasa.</p>
                    <p className="my-1">2.10.2. Kereta-kereta Kegunaan Komersil.</p>
                    <p className="my-1">2.10.3. Mana-mana Kereta yang digunakan untuk menunda, atau digunakan sebagai unit komersial,
                      atau digunakan untuk penyewaan, teksi, limosin atau pengangkutan ulang-alik, perkhidmatan
                      menunda/pemusnah, lambakan (katil pembuangan), pemetik ceri, mengangkat, polis atau
                      perkhidmatan kecemasan, terutamanya di luar-penggunaan jalan raya, perlumbaan yang telah
                      diatur atau teratur atau pemanduan kompetitif.</p>
                    <p className="my-1">2.10.4. Mana-mana Kereta yang kehilangan total, di luar pembaikan ekonomi, salvaj/boleh dibayar
                      balik, kecurian salvaj, dipasang, dibongkar, sekerap, kebakaran, banjir, kerosakan fizikal, air
                      masin, penukaran rangka, penukaran motor, pertukaran badan, sampah atau bahagian sahaja.</p>
                    <p className="my-1">2.10.5. Mana-mana Kereta yang mengalami kerosakan akibat kemalangan besar, kerosakan kebakaran
                      atau kerosakan lantai. </p>
                    <p className="my-1">2.10.6. Kereta itu ialah kereta potong (cut-and-join).</p>
                    <p className="my-1">2.10.7. Kereta mempunyai bahagian yang hilang, kecacatan mekanikal dan/atau elektrik, termasuk
                      bahagian luar dan/atau dalaman.</p>
                  </div>

                  <h1 className="mx-0 my-3">2.11. Keselamatan</h1>
                  <p>Kewajipan kami dan prestasi kepada anda di bawah Bahagian ini diinsuranskan oleh polisi yang
                    dikeluarkan oleh Berjaya Sompo Insurance Berhad.
                  </p>

                  <h1 className="mx-0 my-3">2.12. Kebolehpindahan</h1>
                  <p>AutoGen PLUS boleh dipindahkan oleh Pelanggan asal kepada pemilik Kereta berikutnya dengan
                    kelulusan bertulis terlebih dahulu daripada eAutoGen. Untuk sebarang pemindahan hak milik, sila
                    hubungi talian kami +603-7931 3663 atau cs@eAutoGen.my. eAutoGen berhak untuk menerima atau
                    menolak permohonan tersebut untuk pemindahan pemilikan AutoGen PLUS mengikut budi bicara
                    mutlaknya.</p>


                  <h1 className="mx-0 my-3">Bahagian 3: Terma dan Syarat Am</h1>

                  <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                    <ol type="1">
                      <li>eAutoGen berhak pada bila-bila masa, mengikut budi bicara mutlaknya, untuk menukar, meminda
                        atau sebaliknya mengubah suai Terma ini tanpa notis terlebih dahulu dengan menyiarkan Terma
                        yang dipinda yang berkaitan di Laman Web. Pindaan tersebut berkuat kuasa pada tarikh siaran, dan
                        penggunaan berterusan Laman Web eAutoGen menandakan penerimaan Terma ini yang dikemas
                        kini atau diubah suai.</li>
                      <li>AutoGen PLUS adalah terhad dan hanya sah di Malaysia.</li>
                      <li>Sebarang dan semua pertikaian, kontroversi dan konflik (“<b>Pertikaian</b>”) yang timbul daripada
                        AutoGen PLUS dan semua perkara berkaitan AutoGen PLUS akan dikawal oleh undang-undang
                        Malaysia. Melainkan dan sebaliknya dikehendaki oleh Undang-undang Terpakai, sebarang
                        pertikaian, kontroversi, tuntutan atau perbezaan dalam apa-apa bentuk sekalipun akan timbul
                        daripada atau berkaitan dengan Terma ini terhadap atau berkaitan dengan eAutoGen di bawah
                        Terma ini akan diselesaikan melalui timbang tara mengikut peraturan Pusat Timbang Tara Asia
                        Antarabangsa di Kuala Lumpur (AIAC) dan ditimbang oleh seorang sahaja. Tempat timbang tara
                        dijalankan di Kuala Lumpur dan timbang tara hendaklah menggunakan undang-undang substantif
                        Malaysia. Prosiding timbang tara hendaklah dijalankan dalam bahasa Inggeris. Anda dengan ini
                        melepaskan hak untuk membicarakan (atau mengambil bahagian sebagai parti atau ahli kelas)
                        semua pertikaian terhadap eAutoGen di mahkamah di hadapan hakim atau juri.</li>
                      <li>eAutoGen tidak akan, dalam apa-apa keadaan atau apa-apa sebab, bertanggungjawab terhadap apaapa kerugian atau kecederaan kepada seseorang atau kerugian atau kerosakan harta benda atau
                        sebarang kerugian sampingan, luar jangka, khas atau apa-apa kerugian langsung atau tidak langsung
                        dan ganti rugi berbangkit termasuk tetapi tidak terhad kepada kerugian ditanggung disebabkan oleh
                        sebarang kelewatan dalam melaksanakan kewajipan di bawah Terma ini dan kehilangan penggunaan
                        sepanjang tempoh Kereta anda berada di Bengkel Panel Dibenarkan atau Rakan Kongsi Sah
                        eAutoGen, dan/atau sementara menunggu pembaikan.</li>
                      <li>Pelanggan dengan ini bersetuju bahawa eAutoGen boleh mengumpul, menyimpan, memproses,
                        mendedahkan, mengakses, menyemak dan/atau menggunakan data peribadi (termasuk data
                        peribadi sensitif) tentang Pelanggan, sama ada diperoleh daripada Pelanggan sendiri atau daripada
                        sumber lain mengikut dasar privasi kami (“<b>Dasar Privasi</b>”). Tertakluk kepada Dasar Privasi kami, jika
                        anda menghantar sebarang komunikasi atau bahan kepada kami melalui mel elektronik atau
                        sebaliknya, termasuk sebarang data, soalan, komen, cadangan, atau seumpamanya, semua
                        komunikasi tersebut adalah, dan akan dianggap sebagai, tidak sulit dan bukan milik. Apa-apa sahaja
                        yang anda hantar atau siarkan boleh digunakan oleh eAutoGen atau ahli gabungannya untuk
                        sebarang tujuan, termasuk, tetapi tidak terhad kepada, pengeluaran semula, pendedahan,
                        penghantaran, penerbitan, penyiaran dan penyiaran. Tambahan pula, eAutoGen boleh
                        menggunakan, tanpa sebarang pampasan kepada anda, apa-apa idea, konsep, pengetahuan, atau
                        teknik yang terkandung dalam sebarang komunikasi yang anda hantar ke Laman Web untuk
                        sebarang tujuan sekalipun, termasuk, tetapi tidak terhad kepada, pembangunan, pembuatan, dan
                        memasarkan produk menggunakan maklumat tersebut.</li>
                      <li>eAutoGen tidak akan bertanggungjawab ke atas sebarang pemintasan atau pengubahsuaian yang
                        tidak dibenarkan atau menyalahi undang-undang mana-mana komunikasi antara anda dan
                        eAutoGen (sama ada melalui mel elektronik atau sebaliknya), atau untuk sebarang kehilangan atau
                        kerosakan yang timbul daripadanya (termasuk tetapi tidak terhad kepada sebarang kerugian atau
                        kerosakan yang timbul daripada penggunaan tanpa kebenaran atau penipuan mana-mana maklumat
                        yang terkandung dalam atau boleh diakses melalui komunikasi tersebut).</li>
                      <li>eAutoGen boleh tanpa notis terlebih dahulu kepada Pelanggan, menamatkan, menggantung atau
                        membatalkan semua hak Pelanggan yang diberikan di bawah Terma ini dengan serta-merta. Selepas
                        sebarang penamatan Terma ini, Pelanggan hendaklah dengan serta-merta menghentikan semua
                        akses kepada dan penggunaan Laman Web dan/atau Perkhidmatan ini secara keseluruhan atau
                        sebahagian. Sebarang penamatan tidak akan menjejaskan hak dan kewajipan masing-masing
                        (termasuk tanpa had, kewajipan pembayaran) pihak yang timbul sebelum tarikh penamatan.
                        eAutoGen tidak akan bertanggungjawab kepada Pelanggan atau kepada mana-mana orang lain
                        akibat daripada sebarang penggantungan, pembatalan atau penamatan sedemikian.</li>
                      <li> Pelanggan bersetuju untuk mempertahankan, menanggung rugi (dan terus menanggung rugi) dan
                        memegang eAutoGen dan sekutunya, dan ejen, pengarah, pegawai, pekerja, pengganti dan/atau
                        penerima serah hak mereka, tidak berbahaya daripada dan terhadap sebarang tuntutan, liabiliti,
                        ganti rugi, kos, penghakiman, kerugian atau perbelanjaan (termasuk yuran guaman yang munasabah
                        dengan dasar indemniti penuh), yang timbul daripada atau berkaitan dengan pelanggaran Pelanggan
                        atau pelanggaran terhadap mana-mana terma, syarat, representasi dan waranti yang dinyatakan
                        dalam Terma ini, termasuk waranti berkenaan dengan AutoGen PLUS atau mana-mana Undangundang yang Terpakai, termasuk mana-mana undang-undang atau ordinan tempatan, sama ada
                        dirujuk di sini atau tidak.</li>
                      <li>Pihak-pihak dengan ini mengakui bahawa dasar dan terma lain yang akan ditetapkan dan/atau
                        diterbitkan oleh eAutoGen dari semasa ke semasa akan menjadi sebahagian daripada Terma ini.</li>
                      <li>eAutoGen meletakkan nilai yang tinggi pada kepuasan Pelanggannya dan mengambil perhatian
                        kepada aduan-aduan. eAutoGen bertujuan untuk bertindak balas kepada Pelanggan secepat
                        mungkin. Sekiranya anda mempunyai sebarang pertanyaan atau aduan berkaitan Terma ini,
                        penggunaan Laman Web ini, Perkhidmatan dan/atau AutoGen PLUS, sila hubungi perkhidmatan
                        pelanggan kami di talian + 603-7931 3663 atau cs@eAutoGen.my .</li>
                    </ol>
                  </div>
                </ol>

              </Container>
              <div style={{ marginTop: "50px" }}>
                <Footer />
              </div>

            </div>
        }
      </div>

    </>
  )
}
