import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from "@apollo/client";
import App from "./App";

// let backendAPIUrl =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_BACKEND_PROD
//     : process.env.REACT_APP_BACKEND_DEV;
let backendAPIUrl =  process.env.REACT_APP_BACKEND_DEV;
// let backendAPIUrl = "https://86aa-110-226-61-224.in.ngrok.io/graphql ";
// let backendAPIUrl = "https://294d-122-177-55-193.in.ngrok.io/graphql ";

// const client = new ApolloClient({
//   uri: backendAPIUrl,
//   cache: new InMemoryCache(),
// });

const httpLink = createHttpLink({
  uri: backendAPIUrl,
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('token');
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


export default (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
