import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Pagination,
  Row,
  Table,
  Image,
} from "react-bootstrap";


import { Link, useNavigate } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { ORDERSBYDEALER, GETBOOKINGRECEIPTONSUCCESS } from "../../queries/userQueries";
import { useQuery } from "@apollo/react-hooks";
import { ChakraProvider } from "@chakra-ui/react";
import values_that_lead_us from "../../assets/img/values_that_lead_us.png";
import { UserTransactionEditForm } from "../pages/UserTransactionEditForm/UserTransactionEditForm";
var FileSaver = require('file-saver');



function DealerHistory() {
  const [basicModal, setBasicModal] = useState(false);
  const [curr, setCurr] = useState(null);
  const [curr1, setCurr1] = useState(null);
  const [modal, setModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);



  function handleReceipt() {
    setModal(!modal);
    setBasicModal(!basicModal);
  }



  const createdByUserId = localStorage.getItem("user_id");
  // const createdByUserId = "62fcefbfe3fc5f8eb9009b9d";
  var { data, loading } = useQuery(ORDERSBYDEALER, {
    variables: { createdByUserId },
  });
  const orders = data?.getAllBookingCreatedByAdmin?.allBookingRes;

  function showDetails(id) {
    let temp = orders?.filter((item) => {
      if (item.id === id) {
        return item;
      }
    });
    setCurr(temp);

    localStorage.setItem("dealer_order_Id", temp[0].orderId);
    localStorage.setItem("dealer_user_status", temp[0].status);
    localStorage.setItem("dealer_createdByName", temp[0].createdByName);
    localStorage.setItem("dealer_ownerName", temp[0].ownerName);
    localStorage.setItem("dealer_user_transactionId", temp[0].transactionId);
    localStorage.setItem("dealer_startDate", temp[0].startDate);
    localStorage.setItem("dealer_endDate", temp[0].endDate);
    localStorage.setItem("dealer_price", temp[0].price);
    localStorage.setItem("dealer_voucherCode", temp[0].voucherCode);
    localStorage.setItem("dealer_owner_brandName", temp[0].brandName);
    localStorage.setItem("dealer_owner_modelName", temp[0].modelName);
    localStorage.setItem("dealer_owner_year", temp[0].year);

    setModal(!modal);
  }

  function handleReceiptDetails(id) {
    let temp1 = orders?.filter((item) => {
      if (item.id === id) {
        return item;
      }
    });
    setCurr1(temp1);
    console.warn("temp1",temp1)
    localStorage.setItem("receipt_owner_brandName", temp1[0].brandName);
    localStorage.setItem("receipt_owner_modelName", temp1[0].modelName);
    localStorage.setItem("receipt_owner_year", temp1[0].year);
    localStorage.setItem("receipt_price", temp1[0].price);
    localStorage.setItem("receipt_startDate", temp1[0].startDate);
    localStorage.setItem("receipt_endDate", temp1[0].endDate);
    localStorage.setItem("receipt_status", temp1[0].status); 
    localStorage.setItem("receipt_planPrice", temp1[0].planPrice);
    localStorage.setItem("receipt_promocodeApplied", temp1[0].promocodeApplied);
    localStorage.setItem("receipt_discount", temp1[0].discount);
    setModal(!modal);
    setBasicModal(!basicModal);
  }


  let bookingId = localStorage.getItem("booking_id")

  var { data, loading, refetch } = useQuery(GETBOOKINGRECEIPTONSUCCESS, {
    variables: { bookingId },
  });
  const receiptUrl = data?.getBookingReceiptOnSuccess?.receiptUrl

  function handleReceiptDownload(id) {
    localStorage.setItem("booking_id", id);
    refetch()

    if (receiptUrl) {
      // window.location.href = receiptUrl
      // history.push(to);
      FileSaver.saveAs(receiptUrl, "receipt.pdf");
      window.open(receiptUrl, "_blank")
    }
  }


  
  const [sortedField, setSortedField] = React.useState(null);
  const [sortConfig, setSortConfig] = React.useState(null);

  // let sortedProducts = [orders];
  // if (sortedField !== null) {
  //   sortedProducts.sort((a, b) => {
  //     if (a[sortedField] < b[sortedField]) {
  //       return -1;
  //     } 
  //     if (a[sortedField] > b[sortedField]) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }
  // const { items, requestSort } = useSortableData(products);

  const useSortableData = (orders, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = orders;
      if (sortConfig !== null) {
        sortableItems.slice().sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      
      return sortableItems;

    }, [orders, sortConfig]);
    
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    return { orders: sortedItems, requestSort, sortConfig };
  };

  var { orders: ash, requestSort, sortConfig:confg } = useSortableData(orders);

  const getClassNamesFor = (name) => {
    if (!confg) {
      return;
    }
    return confg.key === name ? confg.direction : undefined;
  };
  

  return (
    <>
      <ChakraProvider>
        <Row className="mt-5 justify-content-between">
          <Col md={3}>
            <InputGroup>
              <Form.Control
                aria-label="Search…"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <InputGroup.Text>
                <i className="fa-solid fa-magnifying-glass"></i>
              </InputGroup.Text>
            </InputGroup>
          </Col>
          {/* <Col md={3}>
            <Dropdown autoClose="outside" className="datepicker">
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-autoclose-outside"
              >
                Last 7 days
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#">
                  <DateRangePicker
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={"Active"}
                    moveRangeOnFirstSelection={"Inactive"}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col> */}
        </Row>
        <Modal isOpen={modal} className="modal-dialog modal-lg">
          <ModalBody>
            {basicModal === true ? (
              <Row className="align-items-end">
                <Row>
                  <Col md={11}>
                    <h1>AutoGen Plus Extended Warranty Program</h1>
                  </Col>
                  <Col md={1} className="justify-content-end">
                    <i
                      onClick={handleReceipt}
                      className="fa fa-remove"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </Col>
                </Row>

                <Col md={6}>
                  <div className="authentication-inputs">
                    {/* <Form noValidate validated={validated} onSubmit={onSubmit}> */}
                    <div className="quot pb-0">
                      <p>
                        <strong>Car Details</strong>
                      </p>

                      <Row>
                        <Col md={3}>
                          <p>Brand </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>{localStorage.getItem("receipt_owner_brandName")}</p>
                        </Col>
                        <Col md={3}>
                          <p>Model </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>{localStorage.getItem("receipt_owner_modelName")}</p>
                        </Col>
                        <Col md={3}>
                          <p>Year </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>{localStorage.getItem("receipt_owner_year")}</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="quot pt-1">
                      <p>
                        <strong>Insurance Duration</strong>
                      </p>

                      <Row>
                        <Col md={3}>
                          <p>Start Date </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>{localStorage.getItem("receipt_startDate")}</p>
                        </Col>
                        <Col md={3}>
                          <p>End Date </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>{localStorage.getItem("receipt_endDate")}</p>
                        </Col>
                        <Col md={3}>
                          <p>Status </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span>
                          <p>
                            {localStorage.getItem("receipt_status")}
                          </p>
                        </Col>
                      </Row>
                    </div>

                    <div className="quot pt-0">
                      <p>
                        <strong>Payment Details</strong>
                      </p>
                      <Row>
                        <Col md={3}>
                          <p>Amount </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>RM{" "}{localStorage.getItem("receipt_planPrice")}</p>
                        </Col>
                        <Col md={3}>
                          <p>PromoCode </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>{localStorage.getItem("receipt_promocodeApplied")}</p>
                        </Col>
                        <Col md={3}>
                          <p>Discount </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>RM{" "}{localStorage.getItem("receipt_discount")}</p>
                        </Col>
                        <Col md={3}>
                          <p>Grand Total </p>
                        </Col>
                        <Col md={9} className="d-flex">
                        <span style={{ marginRight: "35px"}}>:</span><p>RM{" "}{localStorage.getItem("receipt_price")}</p>
                        </Col>
                      </Row>
                    </div>
                    
                    <div className="quot pt-0">
                      <Row>
                        <Col md={12}>
                          <strong>Get covered by AUTOGEN+ for only</strong>
                          <div className="section-title d-flex align-items-end mb-3">
                            <h2 className="mb-0">RM <span>   {localStorage.getItem("receipt_price")}</span></h2>
                            <h5>/YEAR</h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <Image src={values_that_lead_us} fluid />
                </Col>
              </Row>
            ) : (
              <div>
                <Row>
                  <Col md={11}>
                    <h1 style={{ fontSize: "22px", fontWeight: "bold", marginBottom: "10px" }}>Order Details</h1>
                  </Col>
                  <Col md={1} className="justify-content-end">
                    <i
                      onClick={() => setModal(!modal)}
                      style={{ cursor: "pointer" }}
                      className="fa fa-remove"
                    ></i>
                  </Col>
                </Row>
                <UserTransactionEditForm />
              </div>
            )}
          </ModalBody>
        </Modal>
        <Row className="mt-3">
          <Col md={12}>
            <Table striped bordered hover className="history" responsive>
              <thead>
                <tr>
                  <th>Status</th>

                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('orderId')}
                      className={getClassNamesFor('orderId')}
                    >
                      Order Id
                    </button>
                  </th>

                  <th>Transaction Id</th>
                  {
                    localStorage.getItem("role") === "dealer" ?
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('createdByName')}
                        className={getClassNamesFor('createdByName')}
                      >
                        Created By
                      </button>
                    </th> : null
                  }
                  {/* {
                    localStorage.getItem("role") === "dealer" ?
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('orderBy')}
                        className={getClassNamesFor('orderBy')}
                      >
                        Ordered By
                      </button>
                    </th> : null
                  } */}

                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('ownerName')}
                      className={getClassNamesFor('ownerName')}
                    >
                      Owner Name
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('transactionId')}
                      className={getClassNamesFor('transactionId')}
                    >
                      Voucher Code
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('startDate')}
                      className={getClassNamesFor('startDate')}
                    >
                    Start Date
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('endDate')}
                      className={getClassNamesFor('endDate')}
                    >
                    End Date
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('provider')}
                      className={getClassNamesFor('provider')}
                    >
                    Type
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('price')}
                      className={getClassNamesFor('price')}
                    >
                    Amount
                    </button>
                  </th>
                  {/* <th>Start Date</th> */}
                  {/* <th>End Date</th> */}
                  {/* <th>Type</th> */}
                  {/* <th>Amount</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ash
                  ?.filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.status
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                      val.orderId?.includes(searchTerm) ||
                      val.transactionId?.includes(searchTerm) ||
                      val.createdByName?.includes(searchTerm) ||
                      val.ownerName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                      val.voucherCode?.includes(searchTerm) ||
                      val.startDate
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                      val.endDate
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                      val.price?.includes(searchTerm)
                    ) {
                      return val;
                    }
                  })

                  .map((p, ind) => (
                    <tr key={ind}>
                      <td>
                        <Button
                          variant={p?.status === "confirm" ? "success" : "secondary"}
                          style={p?.status === "confirm" ? { color: "white" , backgroundColor : "#1e9a8b",border:'none', textTransform: "capitalize" }  : p?.status === "init" ? {color: "white" , backgroundColor : "grey",border:'none', textTransform: "capitalize" } : {color: "white" , backgroundColor : "#426af7",border:'none', textTransform: "capitalize" } }
                          size="sm"
                        >
                          {/* {p.status} */}
                          {(function () {
                            if (p?.status == "confirm") {
                              return "Paid"
                            } else if (p?.status == "failure") {
                              return "Unpaid"
                            } else {
                              return "Draft"
                            }
                          })()}
                        </Button>
                      </td>
                      <td>{p.orderId}</td>
                      <td>{p.transactionId != null ? (p.transactionId) : '-'}</td>
                      {
                        localStorage.getItem("role") === "dealer" ?
                          <td>{p.createdByName}</td> : null
                      }
                      {/* {
                        localStorage.getItem("role") === "dealer" ?
                        <td>{p.orderBy}</td> : null
                      } */}

                      <td>{p.ownerName}</td>
                      <td>{p.voucherCode != null ? (p.voucherCode) : '-'}</td>

                      <td>{p.startDate}</td>
                      <td>{p.endDate}</td>
                      <td>{ p.provider != null ? (p.provider) : '-'}</td>
                      <td>RM{" "}{p.price}</td>

                      <td>
                        <Menu>
                          <MenuButton>
                            {" "}
                            <i className="fa-solid fa-ellipsis"></i>
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => showDetails(p.id)}>
                              View Details
                            </MenuItem>
                            {
                              p.status == "confirm" ?
                                <MenuItem
                                  onClick={() => handleReceiptDetails(p.id)}
                                >
                                  View Receipt
                                </MenuItem> :
                                ""
                            }
                            {
                              p.status == "confirm" ?

                                <MenuItem

                                  target="_blank"
                                  onClick={() => handleReceiptDownload(p.id)}

                                >
                                  Download
                                </MenuItem> :
                                ""
                            }

                            {/* <MenuItem>Download</MenuItem> */}
                          </MenuList>
                        </Menu>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* <Pagination size="sm" className="justify-content-end">
              {items}
            </Pagination> */}
          </Col>
        </Row>
      </ChakraProvider>
    </>
  );
}

export default DealerHistory;
