import React from "react";
import { Col, Container, Image, NavLink, Row } from "react-bootstrap";
import Logo from "./../../assets/img/eautogen_white.svg";
import Working from "./../../assets/img/workinghour.png";
import Location from "./../../assets/img/location.png";
import Email from "./../../assets/img/email.png";
import Phone from "./../../assets/img/phoneicon.png";
import Facebook from "./../../assets/img/face.png";
import Utube from "./../../assets/img/utube.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { RouteContext } from "./../../Context/RouteContext";
function Footer() {
 
  const {handleRoute} = useContext(RouteContext);
  const navigate = useNavigate();
  return (
    <div>
      {
        localStorage.getItem("language") === "true"? 
        <footer className="py-5">
    <Container>
      <Row>
        <Col className="pb-5">
          <Image src={Logo} onClick={()=>window.location.href="/"} style={{cursor:"pointer"}}/>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <strong className="pb-2 d-flex mobile_center">
            EAUTOGEN CAPITAL SDN BHD (1402277-K){" "}
          </strong>

          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Working} width="30px" height="30px" />
              </Col>
              <Col md={10}>
                <p className="mobile_center" style={{opacity:"60%"}}>
                Isnin hingga Jumaat, tidak termasuk cuti am<br />
                9:00 pagi hingga 6:00 petang
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Location} width="20px" height="20px" />
              </Col>
              <Col md={10}>
                <p className="mobile_center" style={{opacity:"60%"}}>
                  D-26-3 Menara Suezcap 1, KL Gateway, <br />
                  2, Jalan Kerinchi, 59200 Kuala Lumpur.
                </p>
              </Col>
            </Row>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Email} width="20px" height="20px" />
              </Col>
              <Col md={10} className="m-auto" >
                <p className="mobile_center" style={{opacity:"60%"}}>cs@eautogen.my</p>
              </Col>
            </Row>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Phone} width="20px" height="20px" />
              </Col>
              <Col md={10}>
                <p className="mobile_center" style={{opacity:"60%"}}>+603-7931 3663</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={3}>
          <ul>
            <li>
             
                <strong className="pb-2  d-flex mobile_center" style={{fontSize:"18px",marginLeft:"15px"}}>AUTOGEN PLUS</strong>
              
            </li>
            <li>
              <NavLink onClick={()=>window.location.href="/aboutus"}  className="mobile_center">
              Tentang Kami
              </NavLink>
            </li>
           
            <li>
              <NavLink onClick={()=>handleRoute("brandslider")}  className="mobile_center">
              Tentang AutoGen PLUS
              </NavLink>
            </li>
            <li>
              <NavLink onClick={()=>handleRoute("howitworks")} className="mobile_center">
              Bagaimanakah Ia Berfungsi
              </NavLink>
            </li>
            <li>
              <NavLink onClick={()=>handleRoute("workshoptwo")} className="mobile_center">
              Soalan-Soalan Lazim
              </NavLink>
            </li>
          </ul>
        </Col>
        <Col md={3}>
          <ul>
            <li>
              
                <strong className="pb-2 d-flex mobile_center" style={{fontSize:"18px",marginLeft:"15px"}}>Locate Us</strong>
              
            </li>
            <li>
              <NavLink onClick={()=>handleRoute("faq")} className="mobile_center">
              Rakan Kongsi Sah Untuk Pengaktifan Program
              </NavLink>
            </li>
           
            <li>
              <NavLink onClick={()=>handleRoute("footer")} className="mobile_center">
              Bengkel Panel untuk Kerja Penyelenggaraan dan Pembaikan Kereta
              </NavLink>
            </li>
            <li>
              <NavLink  className="mobile_center">
                
              </NavLink>
            </li>
           
          </ul>
        </Col>
        <Col md={2}>
          <ul>
            <li>
             
                {" "}
                <strong className="pb-2 d-flex mobile_center" style={{fontSize:"18px",marginLeft:"15px"}}>Quick Links</strong>
            
            </li>
            <li>
              <NavLink href="/termconditions" className="mobile_center">Terma dan Syarat</NavLink>
            </li>
            <li>
              <NavLink href="/refund-policy" className="mobile_center">Polisi Bayaran Balik</NavLink>
            </li>
            <li>
              <NavLink href="/privacy-policy" className="mobile_center" >Dasar Privasi </NavLink>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row className="text-center d-flex justify-content-center">
        <Col
          md={2}
          className="m-0 p-0 text-center d-flex justify-content-center"
        >
          <p style={{ fontWeight: "bold" }}>Ikut Kami Di:  :</p>
          <Image
            className="m-1 p-0  "
            src={Facebook}
            width="20px"
            height="20px"
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://www.facebook.com/eAutoGen/")}
          />
         
        </Col>
        {/* <Col md={1} className="m-0 p-0">
   
    </Col>
    <Col md={1} className="m-0 p-0">
   
    </Col>
  */}
      </Row>
    </Container>
    <div className="footer-bottom">
      <p style={{fontSize:"12px"}}>HAK CIPTA © 2022 EAUTOGEN CAPITAL SDN BHD. SEMUA HAK CIPTA TERPELIHARA.</p>
    </div>
  </footer>
  :
  <footer className="py-5">
    <Container>
      <Row>
        <Col className="pb-5">
          <Image src={Logo} onClick={()=>window.location.href="/"} style={{cursor:"pointer"}}/>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <strong className="pb-2 d-flex mobile_center">
            EAUTOGEN CAPITAL SDN BHD (1402277-K){" "}
          </strong>

          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Working} width="30px" height="30px" />
              </Col>
              <Col md={10}>
                <p className="mobile_center" style={{opacity:"60%"}}>
                  Monday to Friday, excluding PH<br />
                  9:00 am to 6:00 pm
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Location} width="20px" height="20px" />
              </Col>
              <Col md={10}>
                <p className="mobile_center" style={{opacity:"60%"}}>
                  D-26-3 Menara Suezcap 1, KL Gateway, <br />
                  2, Jalan Kerinchi, 59200 Kuala Lumpur.
                </p>
              </Col>
            </Row>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Email} width="20px" height="20px" />
              </Col>
              <Col md={10} className="m-auto" >
                <p className="mobile_center" style={{opacity:"60%"}}>cs@eautogen.my</p>
              </Col>
            </Row>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={2}>
                <Image src={Phone} width="20px" height="20px" />
              </Col>
              <Col md={10}>
                <p className="mobile_center" style={{opacity:"60%"}}>+603-7931 3663</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={3}>
          <ul>
            <li>
             
                <strong className="pb-2  d-flex mobile_center" style={{fontSize:"18px",marginLeft:"15px"}}>AUTOGEN PLUS</strong>
              
            </li>
             
            <li>
              <NavLink onClick={()=>window.location.href="/aboutus"}  className="mobile_center">
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink onClick={()=>handleRoute("brandslider")}  className="mobile_center">
                About AutoGen Plus
              </NavLink>
            </li>
            <li>
              <NavLink onClick={()=>handleRoute("howitworks")} className="mobile_center">
                How It Works
              </NavLink>
            </li>
            <li>
              <NavLink onClick={()=>handleRoute("workshoptwo")} className="mobile_center">
                Frequently Asked Questions
              </NavLink>
            </li>
          </ul>
        </Col>
        <Col md={3}>
          <ul>
            <li>
              
                <strong className="pb-2 d-flex mobile_center" style={{fontSize:"18px",marginLeft:"15px"}}>Locate Us</strong>
              
            </li>
            <li>
              <NavLink onClick={()=>handleRoute("faq")} className="mobile_center">
                Authorised Partners For Program Activation
              </NavLink>
            </li>
           
            <li>
              <NavLink onClick={()=>handleRoute("footer")} className="mobile_center">
                Panel Workshop for Car Maintenance and Repair Work
              </NavLink>
            </li>
            <li>
              <NavLink  className="mobile_center">
                
              </NavLink>
            </li>
           
          </ul>
        </Col>
        <Col md={2}>
          <ul>
            <li>
             
                {" "}
                <strong className="pb-2 d-flex mobile_center" style={{fontSize:"18px",marginLeft:"15px"}}>Quick Links</strong>
            
            </li>
            <li>
              <NavLink href="/termconditions" className="mobile_center">Terms & Conditions</NavLink>
            </li>
            <li>
              <NavLink href="/refund-policy" className="mobile_center">Refund Policy </NavLink>
            </li>
            <li>
              <NavLink href="/privacy-policy" className="mobile_center" >Privacy Policy </NavLink>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row className="text-center d-flex justify-content-center">
        <Col
          md={2}
          className="m-0 p-0 text-center d-flex justify-content-center"
        >
          <p style={{ fontWeight: "bold" }}>Find Us On :</p>
          <Image
            className="m-1 p-0  "
            src={Facebook}
            width="20px"
            height="20px"
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://www.facebook.com/eAutoGen/")}
          />
         
        </Col>
        {/* <Col md={1} className="m-0 p-0">
   
    </Col>
    <Col md={1} className="m-0 p-0">
   
    </Col>
  */}
      </Row>
    </Container>
    <div className="footer-bottom">
      <p style={{fontSize:"12px"}}>COPYRIGHT © 2022 EAUTOGEN CAPITAL SDN BHD. ALL RIGHTS RESERVED.</p>
    </div>
  </footer>

      }
    </div>
  
  );
}

export default Footer;
