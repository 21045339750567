import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Engine from "../../../assets/img/enginee.png";
import Dropdown from "react-bootstrap/Dropdown";
import "./CarEngine.css";
export const CarEngine = () => {
  return (
    <div className="py-5">
      <Container style={{ width: "80%" }}>
        <Row className="justify-content-center ">
          <Col md={6} className="section-title text-lg-start">
            <p>PERLINDUNGAN PROGRAM AUTOGEN PLUS</p>

            <h2>
              <span>Komponen-Komponen Dalam Skop Program</span>
            </h2>
            <Col md={10} style={{ fontSize: "16px" }}>
            AutoGen PLUS merangkumi dua komponen utama kereta - Enjin dan
              Transmisi (juga dikenali sebagai Kotak Gear).
              <br />
              Dengan harga yang berpatutan serendah RM298/setahun. Perbelanjaan
              di luar poket yang meliputi sebanyak 30x bahagian bagi pembaikan
              dan kerosakan enjin serta sistem transmisi anda dapat dikurangkan.
              <br />
              Cara berpatutan untuk mengurangkan perbelanjaan di luar poket yang
              meliputi sebanyak 30x bahagian
            </Col>
            <Row className="enginedrop">
              <Col md={12}>
                <h3>Enjin Kereta</h3>
                <h5>Program ini meliputi 21x bahagian komponen enjin kereta</h5>
                <Dropdown style={{ marginTop: "10px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Program ini meliputi 21x bahagian komponen enjin kereta
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                    Blok Enjin{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                    Kepala Silinder
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Crankshaft</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Eccentric Shaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Connecting Rod
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Piston</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Piston Ring</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Variable Valve Timing
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Camshaft Adjuster Solenoid
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Balancer Shaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Intake Valve
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Exhaust Valve
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Intake Camshaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Exhaust Camshaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Valvetronic Motor
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Oil Pump</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Emission Control Air Pump
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Air Mass Sensor (Air Flow Sensor) &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp;
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Engine Vacuum Pump
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Crankshaft</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Position Sensor
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={12}>
                <h3>Transmisi Kereta</h3>
                <h5>
                  Program ini meliputi 9x bahagian komponen transmisi kereta
                  termasuklah batang gear rosak yang biasa dilaporkan.{" "}
                </h5>
                <Dropdown style={{ marginTop: "10px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Program ini meliputi 9x Bahagian Transmisi Kereta
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                    (Automatik atau Manual) Penderia Suhu Klac &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Modul Kawalan Penghantaran TCM
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Pam Minyak Penghantaran
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Semua Jenis Injap Solenoid
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Penukar Tork
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Penderia Kelajuan Penghantaran
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Aci Gear</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Peralihan Gear/ Modul Pemilih
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Badan Injap</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Image src={Engine} fluid />
          </Col>
        </Row>

        <Row>
          <Col className="mt-4 text-center">
            <Button
              className="getstarted text-center"
              variant="secondary"
              onClick={() => window.scrollTo(0, window.innerHeight)}
            >
            DAPATKAN SEBUT HARGA
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
