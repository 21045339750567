import React, { useState, useRef } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import autogenplus_logo from "../../assets/img/autogenplus_logo.png";
import { useQuery } from "@apollo/react-hooks";
import Slider from 'react-slick'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import "./react.css"; 
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { GETALLSTATES } from "../../queries/userQueries";
import { GETAllWORKSHOPS } from "../../queries/userQueries";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Container,
  Chip,
} from "@material-ui/core/";
import Carousel from "react-material-ui-carousel";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { ContactMail } from "@material-ui/icons";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

export function Reactslider() {
  const [stateValue, setStateValue] = useState("62d134e6f13bf1bf557aa7d8");
  const [sliderRef, setSliderRef] = useState(null)

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  function onChange(e) {
    setStateValue(e.target.value);
  }
  localStorage.setItem("stateId", stateValue);
  var { data } = useQuery(GETALLSTATES);
  const stateArray = data?.getAllState?.allStateRes;

  const newStateArray = stateArray?.filter((object) => {
    return (
      object.id !== "62d134e6f13bf1bf557aa7d8" &&
      object.id !== " 62d13579f13bf1bf557aa7f7" &&
      object.id !== "62d1355cf13bf1bf557aa7ed" &&
      object.id !== "62d13556f13bf1bf557aa7ea" &&
      object.id !== "62d1351ef13bf1bf557aa7dc" 
    );
  });

  const stateId = localStorage.getItem("stateId");
  var { data } = useQuery(GETAllWORKSHOPS, { variables: { stateId } });
  const workshops = data?.getWorkshopById?.allWorkshopRes;
  
  
  
  return (
    <>
      <section className="workshops">
        <Container className="p-0 m-0">
          <Row>
            <Col md={4}>
              <Form.Group
                className="mb-5 mt-5"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={onChange}
                >
                  <option value={"62d134e6f13bf1bf557aa7d8"}>
                    Kuala Lumpur
                  </option>
                  {newStateArray?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
         
           <Row className="justify-content-center">
                   <Col md={12}>
                   <div className='content'>
      <div className='controls'>
        <button onClick={sliderRef?.slickPrev}>
          <FaChevronLeft />
        </button>
        <button onClick={sliderRef?.slickNext}>
          <FaChevronRight />
        </button>
      </div>
      <div style={{width:"90%",margin:"auto"}}>
      <Slider ref={setSliderRef} {...sliderSettings}>
        {workshops?.map((card, index) => (
          <div key={index} className='card'  style={{border:"5px solid red"}}>
        
            <div className='text-info'>
              <div >
              <Image src={autogenplus_logo} fluid />
                <p style={{color:"#000000",fontWeight:"bold"}}>{card.name}</p>
                <p style={{color:"#000000"}}>{card.address}</p>
                <p style={{color:"#000000"}}>{card.telephone}</p>
         
              </div>
             
              <ul>
             
              </ul>
            </div>
           
          </div>
        ))}
      </Slider>
      </div>
    </div>
                   </Col>
           </Row>
         
        </Container>
      </section>
    </>
  );
}
const hotelCards = [
    {
      imageSrc:
        'https://images.unsplash.com/photo-1559508551-44bff1de756b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=387&q=80',
      title: 'Studio Room',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 50/Day',
      features: ['Free Wifi', 'Free breakfast'],
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1616940844649-535215ae4eb1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
      title: 'Deluxe Room',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 80/Day',
      features: ['Free Wifi', 'Free breakfast'],
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1599619351208-3e6c839d6828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80',
      title: 'King Deluxe Room',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 150/Day',
      features: ['Free Wifi', 'Free breakfast', 'Discounted Meals'],
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1461092746677-7b4afb1178f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
      title: 'Royal Suite',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 299/Day',
      features: [
        'Free Wifi',
        'Free breakfast',
        'Discounted Meals',
        "MacBook for work use (hotel's property)",
      ],
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1461092746677-7b4afb1178f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
      title: 'Royal Suite',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 299/Day',
      features: [
        'Free Wifi',
        'Free breakfast',
        'Discounted Meals',
        "MacBook for work use (hotel's property)",
      ],
    },
  ]
