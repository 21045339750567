import { createContext, useState,  } from "react";
export const TabContext = createContext();
export const TabContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("CarDetails");

    // UserUpdate by the dealer
    const [userUpdate,setuserUpdate]=useState(false);
    // Language Context 

    const [language,setlanguage]=useState(false);

 const handleTab = (e) => {
    setActiveTab(e);
     
    }
    

  return (
    <TabContext.Provider
      value={{
        activeTab,
        handleTab,
        userUpdate,
        setuserUpdate,
        language,
        setlanguage
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
