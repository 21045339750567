import React, { useState } from "react";
import { Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import values_that_lead_us from "./../../assets/img/values_that_lead_us.png";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { DEALERBOOKINGONDASHBOARD } from "../../queries/userQueries";
import { useQuery } from "@apollo/react-hooks";
import { BarChart } from "../../revenueGraph/BarChart"

function DashboardDealer() {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const userId = localStorage.getItem("user_id");
  // const createdByUserId = "62fcefbfe3fc5f8eb9009b9d";
  var { data } = useQuery(DEALERBOOKINGONDASHBOARD, {
    variables: { userId },
  });

  const draft = data?.getAllBookingForDealerDashboard.draft;
  const paid = data?.getAllBookingForDealerDashboard.paid;
  const unpaid = data?.getAllBookingForDealerDashboard.unpaid;
  const expired = data?.getAllBookingForDealerDashboard.expired;
  const totalCars = data?.getAllBookingForDealerDashboard.totalCars;
  const totalRevenue = data?.getAllBookingForDealerDashboard.totalRevenue;
  const totalOrders = data?.getAllBookingForDealerDashboard.totalOrders;
  const totalCustomers = data?.getAllBookingForDealerDashboard.totalCustomers;


  return (
    <Container>
      <Row className="mt-3">
        <Col md={3}>
          <div className="dealer-overview">
            <span>Total Revenue</span>
            <h1>RM {totalRevenue}</h1>
          </div>
        </Col>
        <Col md={3}>
          <div className="dealer-overview">
            <span>Total Customer</span>
            <h1>{totalCustomers}</h1>
          </div>
        </Col>
        <Col md={3}>
          <div className="dealer-overview">
            <span>Total Orders</span>
            <h1>{totalOrders}</h1>
          </div>
        </Col>
        <Col md={3}>
          <div className="dealer-overview">
            <span>Total Cars</span>
            <h1>{totalCars}</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="overview-box">
            <h5>Customer Overview</h5>
            <Row>
              <Col md={6}>
                <div className="overview-item">
                  <div className="expired">
                    <h1>{expired}</h1>
                    <span>Expired</span>
                    {/* <p>
                      •7 Updates <br />
                      •3 Remarks
                    </p> */}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="overview-item">
                  <div className="unpaid">
                    <h1>{unpaid}</h1>
                    <span>Unpaid</span>
                    {/* <p>
                      •7 Updates <br />
                      •3 Remarks
                    </p> */}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="overview-item">
                  <div className="paid">
                    <h1>{paid}</h1>
                    <span>Paid</span>
                    {/* <p>
                      •7 Updates <br />
                      •3 Remarks
                    </p> */}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="overview-item">
                  <div className="draft">
                    <h1>{draft}</h1>
                    <span>Draft</span>
                    {/* <p>
                      •7 Updates <br />
                      •3 Remarks
                    </p> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* <Row className="mt-3">
            <Col md={12}>
              <div className="dealer-overview">
                <span>Your Availeble Credit</span>
                <h1>RM {totalRevenue}</h1>
              </div>
            </Col>
          </Row> */}
        </Col>

        <Col md={8}>
          <BarChart />
          <div className="payment-box dashbord-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h1>
                  eAutoGen was started to pick up where your auto warranty
                  expires.{" "}
                </h1>
                <br />
                <p>
                  *Fill up the form with your car’s details to get a quote for
                  your AutoGen+ plan.
                </p>
                <br />
                <Link to="/buyplan" className="btn btn-secondary rounded">
                  {" "}
                  buy plan
                </Link>
              </Col>
              <Col md={4}>
                {" "}
                <Image src={values_that_lead_us} fluid />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardDealer;
