import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AuthorizedWorkshops from "../../../components/home/authorizedWorkshops";
import "./WorkshopTwo.css";
export const WorkshopTwo = () => {
  return (
    <div style={{ background: "#F4F6F6", marginTop: "50px" }} className="py-5">
      <Container style={{ width: "80%" }}>
        <Row className="workshopone">
          <Col md={6} className="section-title text-lg-start">
            <span>BENGKEL-BENGKEL PANEL EAUTOGEN</span>
            <h2>
              <span>Akses Kepada 200+ Bengkel Panel Di Seluruh Negara</span>
            </h2>
          </Col>
          <Col md={6}>
            <p>
              Dengan lebih daripada 200 bengkel panel di seluruh negara, ia
              adalah menyenangkan dan mudah diakses bagi tujuan penyelenggaraan
              dan pembaikan kereta anda secara berkala
            </p>
          </Col>
          <AuthorizedWorkshops />
        </Row>
      </Container>
    </div>
  );
};
