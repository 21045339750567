import React from "react";
import { Header } from "../components/common/header";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/common/footer";
import "./privacypolicy.css";
export const Privacypolicy = () => {
  return (
    <div>
    {
      localStorage.getItem("language") === "true" ? 
      <div style={{ maxWidth: "1600px", margin: "auto" }}>
            <Header />
            <Container  >
                <div className="privacy-policy py-4">
                    <h2 className="text-center privacy_head pb-2"><span>DASAR PRIVASI
                    </span></h2>
                    <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                        <p>
                            Dasar Privasi ini (“Dasar Privasi”) adalah disediakan dan dikeluarkan oleh eAutoGen Capital Sdn Bhd (No. Syarikat 202101001979 (1402277-K)) (seterusnya dirujuk sebagai “eAutoGen”, “kami” atau “kita”). Kami komited untuk melindungi semua privasi dan keselamatan data peribadi yang diberi kepada kami dan diproses oleh kami, menurut Akta Perlindungan Data Peribadi 2010 (seperti yang dipinda dari semasa ke semasa, kemudian daripada ini disebut sebagai “PDPA”). Bagi tujuan Dasar Privasi ini, kecuali jika konteksnya menghendaki sebaliknya, istilah "data peribadi" dan "pemprosesan" akan mempunyai makna seperti yang dinyatakan dalam PDPA. Dasar Privasi ini terpakai kepada pelanggan, ejen, vendor, pembekal, rakan kongsi, kontraktor, subkontraktor dan penyedia perkhidmatan kami (selepas ini dirujuk sebagai "Pengguna" atau "anda").
                        </p>
                        <br />
                        <p>
                            Dasar Privasi ini menerangkan kegiatan pengumpulan, penyimpanan, penyebaran dan pemprosesan kami berkenaan dengan data peribadi individu melalui penggunaan atau akses anda ke laman web kami di www.eautogen.my ("Laman Web") serta semua kandungan, produk, perkhidmatan dan fungsi yang disediakan di atau melalui Laman Web ini. Apabila anda berkongsi data peribadi anda dengan kami atau apabila kami mengumpul data peribadi anda, kami akan menggunakannya selaras dengan Dasar Privasi ini. Sila baca Dasar Privasi ini dengan teliti untuk memahami cara kami mengguna dan memproses data peribadi yang kami telah mengumpul atau mungkin mengumpul daripada anda. Jika anda mempunyai sebarang pertanyaan dan/atau kebimbangan mengenai data peribadi anda, sila hubungi kami melalui butiran hubungan yang dinyatakan di bawah.
                        </p>
                        <br />
                        <p>
                            Dengan menggunakan Laman Web kami, memberi data peribadi anda, memohon, mendaftar dan/atau melanggan mana-mana produk dan/atau perkhidmatan kami, anda adalah dianggap telah membaca dan menyetujui untuk terikat dengan Dasar Privasi ini dan pengumpulan, penggunaan, pengaksesan, pemindahan, penyimpanan dan pemprosesan data peribadi anda yang dijelaskan dalam Dasar Privasi ini seperti yang mungkin kami akan ubahsuai atau kemas kini dari semasa ke semasa. Apa-apa Dasar Privasi yang telah disemak akan terus digunakan kepada semua data peribadi yang telah dikumpulkan sebelum ini serta maklumat yang telah disimpan atau diproses secara berterusan oleh kami. Oleh itu, anda adalah digalakkan untuk menyemak Platform kami secara berkala untuk sebarang kemas kini atau perubahan terhadap Dasar Privasi ini.
                        </p>
                    </div>

                    {/* list style    */}


                    <ol type="1">
                        <h2><li> Pengumpulan dan Penggunaan Data Peribadi</li></h2>
                        <div
                            style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}
                        >
                            <p>
                                Sebarang data peribadi yang diberikan oleh Pengguna hendaklah dilindungi menurut PDPA dan langkah keselamatan kami dilaksanakan untuk memastikan keselamatan data peribadi anda.

                            </p>
                            <br />
                            <p>
                                Kami berhasrat untuk menganggap dan memandang semua data peribadi anda yang telah didedahkan dan/atau diberikan kepada kami dengan serius. Kami berusaha untuk mengekalkan semua data peribadi yang diberikan kepada kami dengan integriti tertinggi.

                            </p>
                            <br />
                            <p>
                                Semasa urusan anda dengan Laman Web kami sebagai Pengguna kami yang dihargai, kami akan meminta anda memberikan data dan maklumat peribadi anda untuk membolehkan kami memahami dengan lebih baik, memenuhi dan menentukur keputusan, butiran dan/atau maklumat yang paling sesuai untuk anda dan/atau untuk menyampaikan pemberitahuan, notis, perkhidmatan dan/atau produk yang diperlukan. Data Peribadi anda membolehkan kami mencapai tujuan di sini berkaitan dengan Laman Web kami.

                            </p>
                        </div>
                        <h2>1A.  Cookies dan fail log
                        </h2>
                        <div
                            style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}
                        >
                            <p>
                                Kami membenarkan penggunaan terhad pelbagai teknologi untuk mengumpul dan menyimpan maklumat apabila Pengguna mengunjungi Laman Web kami. Ini termasuk menggunakan cookies (iaitu rentetan data unik yang disimpan oleh laman web pada komputer pengguna dan yang disediakan oleh penyemak imbas pengguna ke laman web setiap kali pengguna kembali) atau teknologi serupa untuk mengenal pasti penyemak imbas atau peranti pengguna. Ini membantu kami mengoptimumkan pengalaman pengguna kami dan terus menambah baik serta menyesuaikan produk dan perkhidmatan kami untuk pengguna.

                            </p>
                            <br />
                            <p>
                                Pengguna boleh menetapkan penyemak imbas mereka untuk menyekat semua cookies atau untuk menunjukkan apabila cookies sedang ditetapkan. Walau bagaimanapun, Laman Web kami mungkin tidak berfungsi dengan baik jika cookies anda dilumpuhkan. Kami juga mengumpul alamat Protokol Internet (IP) semua pelawat ke Laman Web kami, jenis penyemak imbas dan tarikh/masa, yang membantu usaha sokongan kami, diagnosis isu dan pertanyaan yang dimulakan pengguna, dan audit untuk tujuan keselamatan. Seperti kebanyakan pengendali laman web, kami juga menggunakan syarikat bebas untuk mengukur dan menganalisis penggunaan internet di seluruh Laman Web. Data agregat, bukan peribadi ini dikumpul oleh syarikat bebas sedemikian yang diberikan kepada kami untuk membantu dalam menganalisis penggunaan Laman Web.

                            </p>
                        </div>

                        <h2><li>Jenis Data Peribadi
                        </li></h2>
                        <ol >
                            <li type="none">
                                <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>Data peribadi tersebut akan tertakluk kepada perlindungan data, privasi dan undang-undang lain yang serupa. Data peribadi tersebut bermaksud sebarang maklumat atau cebisan maklumat yang boleh mengenal pasti anda sama ada secara langsung atau tidak langsung dan/atau maklumat yang menjadikan anda boleh dikenal pasti termasuk sebarang data peribadi yang sensitif dan luahan pendapat tentarang anda. Data peribadi sensitif merujuk kepada sebarang data peribadi berkaitan dengan kesihatan fizikal atau mental anda, pandangan politik anda, agama anda atau kepercayaan yang serupa, pelanggaran atau pertuduhan pelanggaran oleh anda atau data peribadi lain yang akan ditentukan oleh undang-undang dari semasa ke semasa. Data peribadi yang akan dikumpul oleh kami termasuk tetapi tidak terhad kepada maklumat mengenai nama, umur, nombor kad pengenalan, nombor pasport, alamat, jantina, tarikh lahir, status perkahwinan, pekerjaan, maklumat hubungan, alamat e-mel, bangsa, etnik. asal, dan kewarganegaraan, kata laluan yang anda berikan semasa anda membuat akaun di Laman Web, data kenderaan dan maklumat yang anda masukkan untuk mengesahkan kenderaan untuk pembelian insurans, pengebilan dan/atau maklumat kewangan dan maklumat tentang interaksi anda di Laman Web termasuk, penglibatan anda dengan kami untuk sebarang produk dan/atau perkhidmatan.
                                </div></li>
                        </ol>

                        <h2><li>Kesan daripada kegagalan untuk membekalkan Data Peribadi
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                            <p>
                                Kegagalan untuk membekalkan data peribadi anda akan menyebabkan kami tidak dapat:-

                            </p>
                            <ol type="a">
                                <li>
                                    memberi anda notis, perkhidmatan dan/atau program yang diminta oleh anda;

                                </li>
                                <li>
                                    memaklumkan anda tentang program, tawaran, promosi dan/atau perkhidmatan terkini kami yang ditawarkan oleh kami;

                                </li>
                                <li>
                                    mengesyorkan produk dan/atau perkhidmatan yang tersedia paling sesuai untuk anda.
                                </li>
                            </ol>
                        </div>


                        <h2><li>Tujuan Pengumpulan Data Peribadi
                        </li></h2>
                        <div
                            style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}
                        >
                            <p>
                                Data peribadi anda adalah dikumpul, digunakan dan/atau sebaliknya diproses oleh kami untuk, antara lain, tujuan berikut:

                            </p>
                            <ol type="a">
                                <li>
                                    untuk mengenal pasti dan menentukan identiti dan latar belakang anda;

                                </li>
                                <li>
                                    untuk menilai kelayakan dan kesesuaian anda untuk produk dan/atau perkhidmatan dan proses permohonan kami yang dikemukakan oleh anda;

                                </li>
                                <li>
                                    untuk memudahkan penciptaan dan keselamatan pembukaan akaun anda di Laman Web kami;

                                </li>
                                <li>
                                    untuk menghantar maklumat, notis, perkhidmatan, produk, keputusan dan/atau surat berita kepada anda;

                                </li>
                                <li> untuk mentadbir dan mengurus perkhidmatan kami;
                                </li>
                                <li>untuk mengekalkan dan menambah baik pengalaman Pengguna;
                                </li>
                                <li>untuk menyelenggara dan mengemas kini penyimpanan rekod dalaman;
                                </li>
                                <li>
                                    untuk semua tujuan yang berkaitan dengan atau berkenaan dengan penglibatan produk dan/atau perkhidmatan kami;

                                </li>
                                <li>
                                    untuk menawarkan, mendapatkan, menyediakan atau memudahkan penyelesaian insurans atau pembiayaan untuk anda;

                                </li>
                                <li>
                                    untuk membolehkan rakan kongsi kami mengurus dan memudahkan penyelesaian insurans;

                                </li>
                                <li>
                                    untuk operasi dan tujuan pentadbiran harian kami termasuk pengurusan akaun, pengebilan dan pengumpulan, audit, pelaporan dan penyiasatan;

                                </li>
                                <li>
                                    sekiranya terdapat sebarang keingkaran atau kegagalan untuk mengekalkan kewajipan anda, kami mungkin memaklumkan mana-mana agensi pelaporan kredit yang berkaitan atau agensi rujukan kredit untuk merekodkan maklumat tersebut terhadap profil kredit anda;

                                </li>
                                <li>
                                    untuk mengendalikan, menyiasat, memproses dan menyelesaikan sebarang isu perkhidmatan, aduan, komunikasi atau pertanyaan lain yang mungkin anda serahkan kepada kami;

                                </li>
                                <li>
                                    untuk mengesahkan ketepatan maklumat atau data yang diberi oleh anda dengan pihak ketiga;

                                </li>
                                <li>
                                    untuk memudahkan perniagaan kami, pemantauan kewangan dan risiko serta membuat keputusan;

                                </li>
                                <li>
                                    untuk tujuan lain yang mungkin diarahkan atau dipersetujui oleh anda;

                                </li>
                                <li>
                                    untuk mematuhi sebarang keperluan undang-undang atau kawal selia dan membuat pendedahan di bawah keperluan mana-mana undang-undang, peraturan, terma dan syarat, arahan, perintah mahkamah, undang-undang kecil, garis panduan, pekeliling, kod yang terpakai untuk eAutoGen; dan

                                </li>
                                <li>
                                    untuk semua tujuan lain berkaitan atau berkenaan dengan perkara di atas dan urus niaga di Laman Web ini.

                                </li>
                            </ol>
                        </div>

                        <h2><li>Pendedahan Data Peribadi
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                            <p>
                                Kami akan memproses data peribadi anda untuk tujuan yang dinyatakan dalam Klausa 4 di atas dan mungkin akan mendedahkan data peribadi anda kepada pihak berikut:-

                            </p>

                            <ol type="a">
                                <li>
                                    mana-mana pengarah, pengawai, pekerja dan kakitangan eAutoGen yang berkenaan;

                                </li>
                                <li>
                                    mana-mana orang yang kepadanya kami dipaksa atau dikehendaki membuat demikian di bawah undang-undang;

                                </li>
                                <li>
                                    pihak berkuasa berkanun, agensi kerajaan dan pengawal selia industri;

                                </li>
                                <li>
                                    ejen, syarikat bersekutu dan anak syarikat kami (di dalam dan/atau di luar Malaysia);

                                </li>
                                <li>
                                    syarikat dan/atau organisasi yang membantu kami dalam pemprosesan dan/atau memenuhi transaksi dan membekalkan anda perkhidmatan melalui Laman Web ini yang telah anda meminta atau melanggan;

                                </li>
                                <li>
                                    mana-mana pembekal perkhidmatan, kontraktor, perunding, akauntan, juruaudit, peguam atau penasihat kewangan atau profesional lain yang kami mempunyai perjanjian kontrak untuk perkhidmatan dan/atau produk kami;

                                </li>
                                <li>
                                    agensi-agensi pelaporan kredit, biro maklumat kredit, agensi penarafan, agensi rujukan kredit, penyedia insurans atau broker insurans, bank atau institusi kewangan dan sekiranya berlaku ingkar, kepada agensi pemungutan hutang dan/atau pihak lain yang membantu pemulihan hutang fungsi;

                                </li>
                                <li>
                                    rakan kongsi perniagaan kami dan pihak lain untuk tujuan yang berkaitan dengan tujuan mengumpul dan menggunakan data peribadi anda yang ditetapkan dalam Dasar Privasi ini;

                                </li>
                                <li>
                                    mana-mana penerima hak milik atau penerima pindahan yang sebenarnya atau yang dicadangkan dari semua atau sebahagian daripada hak atau kewajipan kami di bawah sebarang transaksi antara kami dan anda;

                                </li>
                                <li>
                                    pembekal perkhidmatan kami untuk tujuan mewujudkan dan mengekalkan pangkalan data bersama di mana kami mempunyai kepentingan bersama yang sah;

                                </li>
                                <li>
                                    mana-mana pihak, syarikat, organisasi atau individu lain yang kami berpendapat perlu

                                </li>
                            </ol>
                        </div>
                        <h2><li>Perlindungan Data Peribadi
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                            <p>
                                Kami akan menyimpan dan memproses data peribadi anda secara selamat. Kami berusaha, jika boleh dilaksanakan, untuk melaksanakan perlindungan dan prosedur pentadbiran dan keselamatan yang sesuai mengikut undang-undang dan peraturan yang terpakai untuk menghalang pemprosesan data peribadi yang tidak dibenarkan atau menyalahi undang-undang dan kehilangan atau kemusnahan secara tidak sengaja, atau kerosakan kepada, data peribadi. Walau bagaimanapun, anda bersetuju dan mengakui bahawa tiada penghantaran data melalui internet yang dijamin selamat sepenuhnya. Oleh itu, di sebalik usaha kami untuk melindungi data peribadi anda, kami tidak dapat memastikan atau menjamin keselamatan setiap maklumat yang anda sampaikan kepada kami, atau ke atau dari Platform kami. Semua penghantaran maklumat tersebut dilakukan atas risiko anda sendiri. Namun, setelah kami menerima penghantaran anda, kami akan melakukan usaha yang sewajarnya untuk memastikan keselamatannya dalam sistem kami.

                            </p>
                        </div>

                        <h2><li>Permintaan untuk Akses dan pembetulan data peribadi
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
                            <p>
                                Secara umum, anda tidak perlu menyerahkan sebarang maklumat peribadi kepada eAutoGen, tetapi untuk tujuan anda telah berinteraksi dengan kami di Laman Web ini; kami memerlukan anda memberikan maklumat peribadi tertentu untuk kami menyelenggara dan mengurus akaun anda dan menapis produk dan/atau perkhidmatan yang berkaitan dan paling sesuai untuk anda.
                            </p>
                            <br />
                            <p>
                                Anda dikehendaki memberikan dan mengekalkan data yang tepat, lengkap dan terkini untuk mendaftar dengan kami. Anda menyatakan dan menjamin bahawa semua maklumat yang diberikan kepada kami dari semasa ke semasa melalui Platform atau di sebaliknya adalah betul, dikeluarkan secara sah dan mengikat anda di sisi undang-undang.

                            </p>
                            <p>Dalam apa jua keadaan, anda mempunyai hak untuk meminta akses kepada dan pembetulan data peribadi anda yang dipegang oleh kami seperti nama, alamat e-mel, hari lahir dan butiran hubungan dan dalam hal ini, anda boleh:
                            </p>
                            <br />
                            <ol type="a">
                                <li>
                                    menyemak sama ada kami memegang atau menggunakan data peribadi anda dan meminta akses kepada data peribadi tersebut;

                                </li>
                                <li>
                                    meminta kami membetulkan mana-mana data peribadi anda yang tidak tepat, tidak lengkap atau lapuk;

                                </li>
                                <li>
                                    meminta agar data peribadi anda disimpan oleh kami hanya selagi perlu untuk memenuhi tujuan pengumpulannya;

                                </li>
                                <li>
                                    meminta kami menyatakan atau menerangkan dasar dan prosedur kami berkenaan dengan data dan jenis data peribadi yang dikendalikan oleh kami;
                                </li>
                                <li>
                                    menyampaikan kepada kami bantahan anda terhadap penggunaan data peribadi anda untuk tujuan pemasaran yang mana kami tidak akan menggunakan data peribadi anda untuk tujuan ini; dan

                                </li>
                                <li>
                                    menarik balik, sepenuhnya atau sebahagian, persetujuan anda yang diberikan sebelum ini, dalam setiap kes tertakluk kepada sebarang sekatan undang-undang yang terpakai, syarat kontrak dan tempoh masa yang munasabah. Sekiranya anda menolak untuk memberikan data peribadi wajib tersebut, kami mungkin tidak dapat memproses permohonan anda atau memberikan anda akses pada Laman Web ini dan/atau perkhidmatan yang diminta oleh anda.

                                </li>
                                <p>
                                    Anda boleh menghantar permintaan kepada kami melalui cara-cara berikut:

                                </p>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <th>No. Telefon:   </th>
                                        <td>03-7931 3663</td>
                                    </tr>

                                    <tr>
                                        <th>Alamat e-mel:   </th>
                                        <td>  cs@eautogen.my
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Alamat Pejabat:</th>
                                        <td>
                                            D-26-3, Menara Suezcap 1, KL Gateway
                                            No. 2 Jalan Kerinchi, Gerbang Kerinchi Lestari
                                            59200 Kuala Lumpur
                                        </td>
                                    </tr>
                                </table>
                                <br />
                                <p>
                                    Kami akan meminta maklumat tambahan dan/atau dokumen sokongan daripada anda sebelum membuat pembetulan tersebut. Kami mungkin menahan permintaan pembetulan sekiranya kami tidak dapat mengesahkan identiti anda. Apabila kami menerima permintaan anda dan dokumen sokongan untuk pembetulan data peribadi, kami akan mengambil tindakan yang sewajarnya untuk memperbetulkan data peribadi yang tidak atau bukan tepat, lengkap dan terkini melainkan ada justifikasi untuk menolak permintaan anda.

                                </p>
                                <br />
                                <p>

                                    Anda akan bertanggungjawab atas sebarang kerugian yang disebabkan oleh kegagalan untuk memberitahu kami mengenai perubahan tersebut disebabkan oleh kelewatan yang tidak wajar, kecuaian atau penipuan anda. Sekiranya anda tidak memaklumkan mengenai perubahan alamat anda, kami akan dibebaskan dari semua liabiliti setelah menghantar notis atau dokumen ke alamat terakhir yang diketahui.

                                </p>
                                <br />
                                <p>
                                    Kami berhak pada bila-bila masa untuk memuaskan diri kami mengenai identiti dan maklumat peribadi anda yang anda berikan termasuk untuk tujuan mencegah penipuan dan/atau pengubahan wang haram dan sementara menunggu pengesahan, kami mungkin menahan akses anda ke data peribadi anda. Di samping itu, pada masa permohonan anda atau pada bila-bila masa dalam masa hadapan, anda memberi kebenaran kepada kami untuk melakukan pemeriksaan pengesahan identiti secara langsung atau melalui pihak ketiga yang berkaitan.

                                </p>
                                <br />
                                <p>
                                    Kami berhasrat untuk mengekalkan Platform dengan cara yang melindungi maklumat daripada kemusnahan yang tidak disengajakan atau berbahaya. Oleh sebab itu, setelah anda memperbetulkan, mengubah atau mengemas kini maklumat daripada Platform, kami mungkin tidak akan segera menghapus baki salinan dari pelayan aktif kami dan mungkin tidak membuang maklumat daripada sistem kami.

                                </p>
                            </ol>
                        </div>

                        <h2><li>Pautan Ke Laman Web Yang Lain
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                            <p>
                                Sila ambil perhatian bahawa Laman Web eAutoGen mungkin mengandungi pautan ke laman web atau aplikasi lain yang dikendalikan oleh pihak ketiga ("Laman Web Pihak Ketiga") yang tidak dikawal oleh Dasar Privasi ini. Kami tidak mengawal ke atas Laman Web Pihak Ketiga dan tidak bertanggungjawab ke atas kandungan atau dasar privasi dan/atau kenyataan mereka. Kami menggalakkan Pengguna menyemak dengan teliti notis privasi dan/atau penyata dan terma dan syarat yang terpakai pada mana-mana Laman Web Pihak Ketiga yang anda akses daripada Laman Web ini.

                            </p>
                            <br />
                            <p>
                                eAutoGen tidak akan bertanggungjawab untuk Laman Web Pihak Ketiga tersebut. Sebarang maklumat peribadi yang disediakan oleh anda di laman web tersebut akan tertakluk kepada dasar privasi pihak ketiga yang berkaitan (jika ada). Anda bersetuju untuk mengetepikan sebarang tuntutan terhadap kami berkenaan dengan Laman Web Pihak Ketiga.
                            </p>
                        </div>

                        <h2><li>Pemindahan data peribadi anda ke luar Malaysia
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                            <p>
                                Kami mungkin perlu memindah data peribadi anda ke luar Malaysia jika ada pembekal perkhidmatan kami atau rakan strategik ("entiti luar negara") yang terlibat dalam membekalkan sebahagian daripada perkhidmatan kami berada di negara-negara di luar Malaysia atau jika anda menggunakan perkhidmatan dari sebuah negara selain daripada Malaysia. Anda bersetuju untuk memindahkan data peribadi anda ke luar Malaysia dalam keadaan seperti ini. Kami akan mengambil langkah yang sewajarnya untuk memastikan mana-mana entiti luar negara tersebut terikat secara kontrak untuk tidak menggunakan data peribadi anda dengan tujuan selain daripada memberikan perkhidmatan yang mereka kontrak dengan kami untuk menyediakan dan untuk melindungi data peribadi anda dengan secukupnya.

                            </p>
                        </div>

                        <h2><li>Persetujuan</li></h2>
                        <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                            <p>Penggunaan Laman Web kami oleh anda menunjukkan persetujuan anda terhadap penggunaan dan pengumpulan data peribadi anda oleh kami mengikut Dasar Privasi ini melainkan dan sehingga anda memaklumkan kami sebaliknya.
                            </p>
                            <p>
                                Kami berhak untuk menyemak dan mengemas kini Dasar Privasi ini dari semasa ke semasa untuk mencerminkan perubahan dalam undang-undang, perubahan dalam amalan perniagaan, prosedur dan struktur kami tanpa notis terlebih dahulu. Kami menasihati anda menyemak Laman Web kami untuk Dasar Privasi ini secara berkala. Versi terkini Dasar Privasi akan disediakan di https://www.eautogen.my.

                            </p>
                        </div>

                        <h2><li>Hubungi Kami
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                            <p>
                                eAutoGen komited untuk melindungi privasi dalam talian maklumat peribadi anda. Jika anda mempunyai sebarang soalan atau kebimbangan mengenai Dasar Privasi ini atau ingin mendapatkan maklumat lanjut tentang cara kami melindungi data peribadi anda, sila e-mel kepada kami di cs@eautogen.my

                            </p>
                        </div>

                        <h2><li> Pindaan dan pengemaskinian
                        </li></h2>
                        <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
                            <p>
                                eAutoGen berhak untuk mengubah suai, mengemas kini atau meminda terma Dasar Privasi ini pada bila-bila masa dengan meletakkan Dasar Privasi yang dikemas kini pada Laman Web ini. Penggunaan Laman Web ini yang terus-menerus, perkhidmatan kami, membeli produk daripada eAutoGen atau terus berkomunikasi atau terlibat dengan eAutoGen berikutan pengubahsuaian, kemas kini atau pindaan kepada Dasar Privasi ini akan dianggap sebagai penerimaan dan persetujuan anda untuk terikat dengan pengubahsuaian, kemas kini sedemikian.

                            </p>
                            <br />
                            <p>
                                Jika anda mempunyai sebarang pertanyaan berkaitan dengan Dasar Privasi ini, sila hubungi Pegawai Privasi Data kami di:

                            </p>
                            <br />
                            <p>
                                eAutoGen Capital Sdn Bhd
                                <br />
                                D-26-3, Menara Suezcap 1, KL Gateway
                                <br />
                                No. 2 Jalan Kerinchi, Gerbang Kerinchi Lestari
                                <br />
                                59200 Kuala Lumpur
                                <br />
                                Alamat E-mel: cs@eautogen.my
                                <br />
                                Telephone No: 03-7931 3663
                            </p>
                        </div>


                    </ol>

                </div>

            </Container>
            <div style={{ marginTop: "50px" }}>
                <Footer />
            </div>
        </div>
      :
      <div style={{ maxWidth: "1600px", margin: "auto" }}>
      <Header />
      <Container  >
        <div className="privacy-policy py-4">
          <h2 className="text-center privacy_head pb-2"><span>Privacy Policy</span></h2>
          <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
          <p>
            This Privacy Policy (“Privacy Policy”) is prepared and issued by
            eAutoGen Capital Sdn Bhd (Registration No. 202101001979 (1402277-K))
            (hereinafter referred to as “eAutoGen”, “we”, “us”, or “our”). We
            are committed to protect the privacy and security of all personal
            data provided to us and processed by us, in accordance with the
            Personal Data Protection Act 2010 (as may be amended from time to
            time, hereinafter referred to as the “PDPA”). For the purpose of
            this personal data policy, unless the context requires otherwise,
            the terms “personal data” and “processing” shall have the meaning
            prescribed in the PDPA. This Privacy Policy applies to our
            customers, agents, vendors, suppliers, partners, contractors,
            subcontractors and service providers (hereinafter referred to as
            “Users”, “you”, “your” or “yours”).
          </p>
          <br />
          <p>
            This Privacy Policy describes how we collect, use, process, handle
            and disclose your personal data through your use of or access to our
            website at www.eautogen.my ("Website”) and all content, products,
            services and functionality available at or through the Website. When
            you share your personal data with us or when we collect your
            personal data, we use it in line with this Privacy Policy. Please
            read through this Privacy Policy carefully to understand how we use
            and process the personal data we have collected or may collect from
            you. If you have any queries and / or concerns about your personal
            data, please contact us via the contact details set out below.
          </p>
          <br />
          <p>
            By using and accessing our Website, providing your personal data,
            applying for, registering and/or subscribing for any of our products
            and/or services, you are deemed to have read and consented to be
            bound by this Privacy Policy and the collection, use, access,
            transfer, storage and processing of your personal data described in
            this Privacy Policy as may be modified or updated from time to time
            by us. Any such revised Privacy Policy will continue to apply to all
            personal data that has previously been collected as well as
            information that has been stored or processed on an ongoing basis by
            us. Therefore, you are encouraged to check our Website on a regular
            basis for any updates or changes to the Privacy Policy.
          </p>
          </div>

          {/* list style    */}


          <ol type="1">
            <h2><li> Collection and Use of Personal Data</li></h2>
            <div
              style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}
            >
              <p>
                Any personal data provided by the Users shall be protected in
                accordance to the PDPA and our security measures put in place to
                ensure the safety of your personal data.
              </p>
              <br />
              <p>
                We aim to treat and view all your personal data that has been
                disclosed and/or provided to us seriously. We strive to uphold all
                personal data provided to us with utmost integrity.
              </p>
              <br />
              <p>
                In the course of your dealings with our Website as our valued
                Users, we will request that you provide your personal data and
                information to enable us to better understand, cater and calibrate
                best suited results, details and/or information for you and/or to
                deliver the necessary notifications, notices, services and/or
                products. Your Personal Data enables us to achieve the purpose
                herein in connection with our Website.
              </p>
            </div>
            <h2>1A. Cookies and log files</h2>
            <div
              style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}
            >
              <p>
                We allow limited use of various technologies to collect and store
                information when Users visit our Website. This may include using
                cookies (i.e. a string of unique data that a website stores on a
                user’s computer and that the user’s browser provides to the
                website each time the user returns) or similar technologies to
                identify the user’s browser or device. This helps us to optimize
                our users’ experience and to continually improve and tailor our
                products and services for users.
              </p>
              <br />
              <p>
                Users may set their browser to block all cookies or to indicate
                when a cookie is being set. However, our Website may not function
                properly if the cookies setting is disabled. We also collect
                Internet Protocol (IP) addresses of all visitors to our Website,
                browser types and dates/times, which assist with our support
                efforts, diagnosis of user-initiated issues and queries, and
                audits for security purposes. Like many web site operators, we
                also use the independent companies to measure and analyze the
                internet usage across the Website. This aggregate, non-personal
                data is collected by such independent companies provided to us to
                assist in analyzing the usage of the Website.{" "}
              </p>
            </div>

            <h2><li>Nature of Personal Data</li></h2>
            <ol >
              <li type="none">
                <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>Such personal data may be subject to applicable data protection,
                  privacy and other similar laws. Such personal data shall mean any
                  information or pieces of information that could identify you either
                  directly or indirectly and/or information which makes you
                  identifiable including any sensitive personal data and expression of
                  opinion about you. Sensitive personal data refers to any personal
                  data as to your physical or mental health or condition, your
                  political opinions, your religious beliefs or other beliefs of a
                  similar nature, the commission or alleged commission by you of any
                  offense or any other personal data as may be determined by law from
                  time to time. Personal data collected by us may include but is not
                  limited to information concerning name, age, identity card number,
                  passport number, address, gender, date of birth, marital status,
                  occupation, contact information, email address, race, ethnic origin,
                  nationality, passwords provided by you when you created an account
                  on the Website, vehicle data and information you enter to verify
                  vehicle for insurance purchase, billing and/or financial information
                  and information about your interactions on the Website including,
                  your engagement with us for any of product and/or service.</div></li>
            </ol>

            <h2><li>Impact from failure to supply Personal Data</li></h2>
            <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
              <p>
                The failure to supply such personal data will result in us being
                unable to
              </p>
              <ol type="a">
                <li>
                  Provide you with the notices, services and/or programmes
                  requested by you;
                </li>
                <li>
                  Update you on our latest programmes, offers, promotions and/or
                  services offered by us;
                </li>
                <li>
                  Recommend available products and/or services best suited to
                  you.
                </li>
              </ol>
            </div>


            <h2><li>Purpose of Collecting Personal Data</li></h2>
            <div
              style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}
            >
              <p>
                The personal data is collected, used and/or otherwise processed by
                us for, amongst others, the following purposes:
              </p>
              <ol type="a">
                <li>
                  To ascertain and establish your identity and background;{" "}
                </li>
                <li>
                  To assess your eligibility and suitability and recommend our
                  products and/or services best suited to you based on your profile
                  and process application(s) submitted by you;
                </li>
                <li>
                  To facilitate the creation of and secure your account opening
                  on the Website;{" "}
                </li>
                <li>
                  To send you information, notices, services, products, results
                  and/or newsletters;
                </li>
                <li> To administer and manage our services;</li>
                <li> To maintain and improve Users’ experience;</li>
                <li> To maintain and update internal record keeping;</li>
                <li>
                  All purpose related to or in connection with the engagement of
                  our products and/or services;
                </li>
                <li>
                  To offer, obtain, provide or facilitate insurance or financing
                  solutions for you;{" "}
                </li>
                <li>
                  To enable our partners to manage and facilitate insurance
                  solutions;
                </li>
                <li>
                  For our day-to-day operations and administrative purposes
                  including account management, billing and collection, audits,
                  reporting and investigations;
                </li>
                <li>
                  In the event there is any default or failure to keep your
                  obligations, we may inform any relevant credit reporting agencies
                  or credit reference agencies to record such information against
                  your credit profile;
                </li>
                <li>
                  To handle, investigate, process and resolve any service issues,
                  complaints, communications or other enquiries that you may submit
                  to us;
                </li>
                <li>
                  To verify the accuracy of the information or data provided by
                  you with third parties;
                </li>
                <li>
                  To facilitate our business, financial and risk monitoring and
                  decision making;{" "}
                </li>
                <li>
                  For such other purposes as may be directed or consented to by
                  you;
                </li>
                <li>
                  To comply with any legal or regulatory requirements and making
                  disclosure under the requirements of any applicable law,
                  regulation, terms and conditions, direction, court order, by-law,
                  guideline, circular, code applicable to eAutoGen; and{" "}
                </li>
                <li>
                  For all other purposes in relation to or incidental to the
                  above and the transactions on the Website.
                </li>
              </ol>
            </div>

            <h2><li>Disclosure of Personal Data</li></h2>
            <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
              <p>
                We will process your personal data for the purpose stated in Clause
                4 above and may disclose your personal data to the following
                parties:-
              </p>

              <ol type="a">
                <li>
                  Any director, officer, staff and relevant personnel of
                  eAutoGen;{" "}
                </li>
                <li>
                  Any person to whom we are compelled or required to do so under
                  law;
                </li>
                <li>
                  Statutory authorities, government agencies and industry
                  regulators;
                </li>
                <li>
                  Our agents, associates, subsidiaries and affiliates (in and/or
                  outside Malaysia);
                </li>
                <li>
                  Companies and/or organisations that assist us in processing
                  and/or otherwise fulfilling transactions and providing you with
                  services through the Website that you have requested or applied
                  for;
                </li>
                <li>
                  Any service providers, contractors, consultants, accountants,
                  auditors, lawyers or other financial or professional advisers whom
                  we have contractual agreements for our services and/or product;
                </li>
                <li>
                  Any credit reporting agencies, credit information bureau,
                  rating agencies, credit reference agencies, insurer provider or
                  insurer broker, bank or financial institution and in the event of
                  default, to debt collection agencies and/or other parties that
                  assist with debt recovery functions;{" "}
                </li>
                <li>
                  Our business partners and other parties for purposes that are
                  related to the purpose of collecting and using your personal data
                  set out in this Privacy Policy;
                </li>
                <li>
                  Any actual or proposed assignee or transferee of all or any
                  part of our rights or obligations under any transaction between us
                  and you;
                </li>
                <li>
                  Aur service providers for purposes of establishing and
                  maintaining a common database where we have a legitimate common
                  interest; and
                </li>
                <li>
                  Any other parties, companies, organizations or individuals
                  deemed necessary by us.
                </li>
              </ol>
            </div>
            <h2><li>Safeguards</li></h2>
            <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
              <p>
                We shall keep and process your personal data in a secure manner. We
                endeavour, where practicable, to implement the appropriate
                administrative and security safeguards and procedures in accordance
                with the applicable laws and regulations to prevent the unauthorized
                or unlawful processing of the personal data and the accidental loss
                or destruction of, or damage to, the personal data. However, you
                agree and acknowledge that there is no data transmission over the
                internet can be guaranteed to be completely secure. Despite the
                foregoing commitment to you, we cannot guarantee or warrant the
                security of any information you transmit to us, or to or from our
                Website. All such transmission of information is carried out at your
                own risk. However, once we receive your transmission, we will make
                reasonable efforts to ensure its security in our systems.
              </p>
            </div>

            <h2><li>Rights of Access and Correction</li></h2>
            <div style={{ background: "#F4F4F7", padding: "30px", margin: "10px" }}>
              <p>
                In general, you are not required to submit any personal information
                to eAutoGen, but for the purpose(s) that you have interacted with us
                on the Website; we will require you to provide certain personal
                information for us to maintain and manage your account and to filter
                relevant products and/or services best suited for you. You shall
                provide and maintain accurate, complete and current data required to
                register with us. You represent and warrant that all information
                furnished to us from time to time through the Website or otherwise
                is correct, validly issued and legally binding on you.
              </p>
              <br />
              <p>
                In any event, you have the right to request access to and correction
                of your personal data held by us such as name, e-mail address,
                birthday and contact details and in this respect, you may:
              </p>
              <br />
              <ol type="a">
                <li>
                  Check whether we hold or use your personal data and request
                  access to such data;
                </li>
                <li>
                  Request that we correct any of your personal data that is
                  inaccurate, incomplete or outof- date;
                </li>
                <li>
                  Request that your personal data is retained by us only as long
                  as necessary for the fulfilment of the purposes for which it was
                  collected;
                </li>
                <li>
                  Request that we specify or explain our policies and procedures
                  in relation to data and types of personal data handled by us;
                </li>
                <li>
                  Communicate to us your objection to the use of your personal
                  data for marketing purposes whereupon we will not use your
                  personal data for these purposes; and
                </li>
                <li>
                  Withdraw, in full or in part, your consent given previously, in
                  each case subject to any applicable legal restrictions,
                  contractual conditions and a reasonable time period. Should you
                  decline to provide such obligatory personal data, we may not be
                  able to process your application or provide you with the access on
                  the Website and/or services requested by you.{" "}
                </li>
                <p>
                  You may submit the request to us via one of the following methods:
                </p>
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>Telephone No: </th>
                    <td>03-7931 3663</td>
                  </tr>

                  <tr>
                    <th>Email address: </th>
                    <td>cs@eautogen.my</td>
                  </tr>
                  <tr>
                    <th>Address: </th>
                    <td>
                      : D-26-3, Menara Suezcap 1, KL Gateway No 2 Jalan Kerinchi,
                      Gerbang Kerinchi Lestari 59200 Kuala Lumpur
                    </td>
                  </tr>
                </table>
                <br />
                <p>
                  We may request additional information and/or supporting documents
                  from you before making the correction. We may withhold the
                  correction request in such case where we are unable to confirm
                  your identity. Upon receipt of your request and supporting
                  documents for correction of your personal data, we will take
                  reasonable steps to rectify the personal data which is inaccurate,
                  incomplete and not up-to-date unless we consider there are
                  justifications for refusing to comply with your request.
                </p>
                <br />
                <p>
                  You will be liable for any loss that results from any failure to
                  notify us of such a change as a result of undue delay, your gross
                  negligence or fraud. Where you fail to inform of any change in its
                  address, we shall be discharged from all liabilities upon sending
                  of any notice or document to the last known address.
                </p>
                <br />
                <p>
                  We reserve the right at any time to satisfy itself as to your
                  identity and personal details provided including for the purposes
                  of preventing fraud and/or money laundering and pending
                  verification, we may withhold your access to your personal data.
                  In addition, at the time of your application or at any time in the
                  future, you authorise us to perform identity verification checks
                  directly or using relevant third parties.
                </p>
                <br />
                <p>
                  We aim to maintain the Website in a manner that protects
                  information from accidental or malicious destruction. Because of
                  this, after you correct, change or update information from the
                  Website, we may not immediately delete residual copies from our
                  active servers and may not remove information from our systems.
                </p>
              </ol>
            </div>

            <h2><li>Links to Other Sites</li></h2>
            <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
              <p>
                Please take note that eAutoGen’s Website may contain links to other
                sites or applications operated by third parties ("Third Party
                Sites”) that are not governed by this Privacy Policy. We do not
                control over the Third Party Sites and assumes no responsibility for
                their content or privacy policies and/or statements. We encourage
                Users to carefully review the privacy notice and/or statements and
                the terms and conditions that apply to any Third Party Sites you
                access from the Website.
              </p>
              <br />
              <p>
                eAutoGen will not be responsible for such Third Party Sites. Any
                personal information made available by you on such sites will be
                subjected to the relevant third party’s privacy policy (if any). You
                agree to waive any claim against us with respect to the Third Party
                Sites.
              </p>
            </div>

            <h2><li> Transfer of your personal data outside Malaysia</li></h2>
            <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
              <p>
                It may be necessary for us to transfer your personal data outside
                Malaysia if any of our service providers or strategic partners
                (“overseas entities”) who are involved in providing part of our
                services are located in countries outside Malaysia or if you use the
                services from a country other than Malaysia. You consent to us
                transferring your personal data outside Malaysia in these instances.
                We shall take reasonable steps to ensure that any such overseas
                entities are contractually bound not to use your personal data for
                any reason other than to provide the services they are contracted by
                us to provide and to adequately safeguard your personal data.{" "}
              </p>
            </div>

            <h2><li>Consent</li></h2> <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
              <p>
                Your usage of our Website indicates your consent to our use and
                collection of your personal data in accordance with this Privacy
                Policy unless and until you inform us to the contrary herein. We
                reserve the right to review and update this Privacy Policy from time
                to time to reflect changes in the law, changes in our business
                practices, procedures, and structure without prior notice. We advise
                that you check our website for the Privacy Policy on a regular
                basis. The latest version of the Privacy Policy will be made
                available at https://www.eautogen.my
              </p>
            </div>

            <h2><li>Contact Us</li></h2>
            <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
              <p>
                eAutoGen is committed to protecting the online privacy of your
                personal information. If you have any questions or concerns
                regarding this Privacy Notice or would like further information
                about how we protect your personal data, please email us at
                cs@eautogen.my
              </p>
            </div>

            <h2><li> Amendments and updates</li></h2>
            <div style={{ background: "#F4F4F7", padding: "15px", margin: "10px" }}>
              <p>
                eAutoGen shall have the right to modify, update or amend the terms
                of this Privacy Policy at any time by placing the updated Privacy
                Policy on the Website. By continuing to use the Website, our
                services, purchase products from eAutoGen or continuing to
                communicate or engage with eAutoGen following the modifications,
                updates or amendments to this Privacy Policy shall constitute as
                your acceptance of, and agreement to be bound by such modifications,
                updates or amendments.
              </p>
              <br />
              <p>
                If you have any queries in relation to this Privacy Policy, please
                contact our Data Privacy Officer at:{" "}
              </p>
              <br />
              <p>
                eAutoGen Capital Sdn Bhd <br />
                D-26-3, Menara Suezcap 1, KL Gateway <br />
                No 2 Jalan Kerinchi, Gerbang Kerinchi Lestari <br />
                59200 Kuala Lumpur <br />
                Email address: cs@eautogen.my <br />
                Telephone No: 03-7931 3663
              </p>
            </div>


          </ol>

        </div>

      </Container>
      <div style={{ marginTop: "50px" }}>
        <Footer />
      </div>
    </div>
    }
        
    </div>
    
  );
};
