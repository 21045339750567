import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./../assets/img/eautogen_white.svg";
import { useMutation } from "@apollo/client";
import { USER_PROFILE_UPDATE } from "./../mutations/userMutations";
import { useForm } from "../utils/hooks";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@apollo/react-hooks";
import values_that_lead_us from "./../assets/img/values_that_lead_us.png";
import "react-toastify/dist/ReactToastify.css";
import Header from "../admin/components/common/header.jsx";

function UserProfileUpdate() {
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState(false);

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

    // const dealernamevalue=localStorage.getItem("dealer_name");
  const { onChange, onSubmit, values } = useForm(handleSubmit, {
    email: `${localStorage.getItem("owner_email")}`,
    mobile: `${localStorage.getItem("owner_mobile")}`,
    name: "",
    userId: `${localStorage.getItem("user_id")}`,
  });

  // const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const [addUser, { loading }] = useMutation(USER_PROFILE_UPDATE, {
    update(_, { data: { updateUserProfile: userData } }) {
      localStorage.setItem("dealer_name", userData.name);
      localStorage.setItem("owner_email", userData.email);
      localStorage.setItem("owner_mobile", userData.mobile);
     
      toast(userData.message);
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
    variables: values,
  });

  function handleSubmit() {
    if (!values.email || !values.name || !values.mobile) {
      toast("Please Fill All The Fields");

      return;
    } else if (values.email.includes("@") === false) {
      toast("Please Enter Valid Email");
      return;
    } else if (values.mobile.length > 15) {
      toast("Please Enter Valid Mobile Number");
      return;
    } else if (values.mobile.length < 8) {
      toast("Please Enter Valid Mobile Number");
      return;
    }
    addUser();
  }
  return (
    <>
      <ToastContainer />
      <Header />
      <Container fluid>
        <Row className="justify-content-center text-center">
          <Col md={2} className="mt-5 mb-2">
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          > */}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
            <div
              style={{
                height: "122px",
                width: "122px",
                borderRadius: "50%",
                border: "1px solid black",
              }}
              onClick={() => imageUploader.current.click()}
            >
              <img
                ref={uploadedImage}
                src={userData.profilePic}
                style={{
                  height: "122px",
                  width: "122px",
                  borderRadius: "50%",
                  position: "acsolute",
                }}
              />
            </div>
          </Col>
          <p
            style={{
              color: "rgb(23, 190, 187)",
              font: "bold",
              fontSize: "18px",
            }}
          >
            Upload Profile Picture
          </p>
        </Row>
      </Container>

      <section className="authentication">
        <Container fluid className="ps-0">
          <Row className="align-items-center justify-content-center">
            <Col md={5} className="p-0">
              <div className="authentication-box" style={{ marginTop: "0px" }}>
                <div className="authentication-inputs mb-0">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required="required"
                        value={values.name}
                        onChange={onChange}
                        placeholder="Enter Name"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        required="required"
                        value={values.email}
                        onChange={onChange}
                        placeholder="e.g. abc@text.com"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="e.g. 0123456789"
                        name="mobile"
                        required="required"
                        value={values.mobile}
                        onChange={onChange}
                      />
                    </Form.Group>

                    <div className="d-grid gap-2 mb-3">
                      <Button
                        variant="secondary"
                        size="lg"
                        type="submit"
                        onClick={onSubmit}
                      >
                        SAVE CHANGES
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export { UserProfileUpdate };
