import React, { useRef } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./../../assets/img/eutogen_logo.svg";

import { useContext } from "react";
import { RouteContext } from "./../../Context/RouteContext";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { Row, Col, Button } from "react-bootstrap";
import { ChakraProvider } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import UserIcon from "./../../assets/img/autogen-icon.png";
import Dropdown from "react-bootstrap/Dropdown";
export function Header() {
  const {brandslider} = useContext(RouteContext);
  const {workshopone} = useContext(RouteContext);
  const {workshoptwo} = useContext(RouteContext);
  const {hasslefree} = useContext(RouteContext);
  const {carengine} = useContext(RouteContext);
  const {periodiccar} = useContext(RouteContext);
  const {girlincar} = useContext(RouteContext);
  const {faq} = useContext(RouteContext);
  const{howitworks} = useContext(RouteContext);
 const {scrollToSection} = useContext(RouteContext);
  const {handleRoute} = useContext(RouteContext);
  const {footer} = useContext(RouteContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    navigate("/");
  }
  const logout = () => {
   
  };

  function handleHome()
  {
    localStorage.setItem("language","false");
   window.location.reload();
  }

  function handleBm()
  {
    localStorage.setItem("language","true")
  
    window.location.reload();
  }
  function handleEn()
  {
    localStorage.setItem("language","false");
    window.location.reload();
  }
  
  return (
    <>
      <ChakraProvider>
      {
        localStorage.getItem("language") === "true" ? 
        <header>
            <Navbar expand="lg">
              <Container fluid style={{ maxWidth: "1600px", margin: "auto" }}>
                <Navbar.Brand href="/">
                  <Image
                    src={Logo}
                 className="logo_image"
                    width="300px" height="300px"
                  />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="navbar_section">
                    <Nav.Link>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="Primary"
                          id="dropdown-basic"
                          style={{
                            fontSize: "15px",

                            fontWeight: "bold",
                            padding: "0px",
                          }}
                        >
                          AUTOGEN PLUS
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(brandslider)}
                          >
                           KELAYAKAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(carengine)}
                          >
                            PERLINDUNGAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(girlincar)}
                          >
                           NILAI PEMBAIKAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(hasslefree)}
                          >
                           PERMOHONAN MEMFAILKAN KEROSAKAN
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(periodiccar)}
                          >
                            PROGRAM KEPERLUAN PENYELENGGARAAN
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>

                    <Nav.Link
                      href="#link"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop:"3px"
                      }}
                      onClick={() => scrollToSection(howitworks)}
                    >
                     BAGAIMANA IA BERFUNGSI

                    </Nav.Link>
                    <Nav.Link
                      href="#link"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop:"3px"

                      }}
                      onClick={() => scrollToSection(faq)}
                    >
                      SOALAN-SOALAN LAZIM 
                    </Nav.Link>
                    <Nav.Link href="#link">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="Primary"
                          id="dropdown-basic"
                          style={{
                            fontSize: "15px",

                            fontWeight: "bold",
                            padding: "0px",
                          }}
                        >
                         LOKASI
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(workshopone)}
                          >
                          RAKAN KONGSI SAH UNTUK PENGAKTIFAN PROGRAM
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-1"
                            style={{ fontSize: "12px", color: "#777777" }}
                            onClick={() => scrollToSection(workshoptwo)}
                          >
                          BENGKEL PANEL UNTUK KERJA <br/>  PENYELENGGARAAN DAN PEMBAIKAN KERETA
                            <br />
                            WORK
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>

                {/* <Menu>
                
                  <MenuButton>
                    {" "}
                    <i
                      style={{ width: "200px" }}
                      className="fa-regular fa-circle-user fa-lg"
                    ></i>
                  </MenuButton>


                  {localStorage.getItem("token") !== null ? (
                    <MenuList>
                      <MenuItem>
                        <Row>
                          <Col md={5}>
                            <Image src={UserIcon} />
                          </Col>
                          <Col md={7}>
                            <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              {localStorage.getItem("owner_name")}
                            </p>
                            <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Your Plan
                            </p>
                            <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Expiry Date:26 May
                            </p>
                            <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Active
                            </p>
                          </Col>
                        </Row>
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/userprofile")}>
                        Edit Profile
                      </MenuItem>
                      <MenuItem onClick={() => navigate("/privacy-policy")}>
                        Privacy Policy
                      </MenuItem>
                      <MenuItem>
                        <Button
                          variant="primary"
                          style={{
                            width: "100%",
                            padding: "2px",
                            border: "2px solid #22BBB9",
                          }}
                          onClick={() => logout()}
                        >
                          Logout
                        </Button>
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList>
                      <MenuItem className="justify-content-center">
                        <Image src={UserIcon} />
                      </MenuItem>

                      <MenuItem>
                        <Button
                          variant="primary"
                          style={{
                            width: "100%",
                            padding: "2px",
                            border: "2px solid #22BBB9",
                          }}
                          onClick={() => navigate("/login")}
                        >
                          Login
                        </Button>
                      </MenuItem>
                    </MenuList>
                  )}
                </Menu> */}
                {/* <div id="google_translate_element"></div> */}
                <div className="usericon">
                <Menu >
                <MenuButton>
                  {" "}
                  <i
                    style={{ width: "40px" }}
                    className="fa-regular fa-circle-user fa-lg"
                  ></i>
                  {" "}
                <span style={{fontSize:"18px",fontWeight:"bold"}}>Login</span>
                </MenuButton>
                {localStorage.getItem("token") !== null ? (
                  <MenuList>
                    <MenuItem>
                      <Row>
                        <Col md={5}>
                          <Image src={UserIcon} />
                        </Col>
                        <Col md={7}>
                        {
                          localStorage.getItem("dealer_name") !== "null" ?
                          <p
                            style={{
                              fontSize: "10px",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            {localStorage.getItem("dealer_name")}
                          </p>
                          :null
                        }
                          
                        
                         
                          <p
                            style={{
                              fontSize: "10px",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            Active
                          </p>
                        </Col>
                      </Row>
                    </MenuItem>
                    {
                      localStorage.getItem("token") ?
                      <MenuItem onClick={() => navigate("/home")}>
                     Dashboard
                    </MenuItem>
                    : null
                    }
                    <MenuItem onClick={() => navigate("/userprofile")}>
                      Edit Profile
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/privacy-policy")}>
                      Privacy Policy
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant="primary"
                        style={{
                          width: "100%",
                          padding: "2px",
                          border: "2px solid #22BBB9",
                        }}
                        onClick={() => logout()}
                      >
                        Logout
                      </Button>
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList>
                    <MenuItem className="justify-content-center">
                      <Image src={UserIcon} />
                    </MenuItem>

                    <MenuItem>
                      <Button
                        variant="primary"
                        style={{
                          width: "100%",
                          padding: "2px",
                          border: "2px solid #22BBB9",
                        }}
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </Button>
                    </MenuItem>
                  </MenuList>
                )}
              </Menu>
              </div>
                <div className="enbm">

                <Link style={{fontSize:"18px",margin:"5px"}} onClick={handleEn} to=""> EN</Link>

                <div style={{height:"30px",width:"3px",background:"black",margin:"5px"}} className = "vertical"></div>
<Link style={{fontSize:"18px",margin:"5px"}} onClick={handleBm} to=""> BM</Link>

                </div>
                
              </Container>
            </Navbar>
          </header>
          :
          <header>
          <Navbar expand="lg">
            <Container fluid style={{maxWidth:"1600px",margin:"auto"}}>
              <Navbar.Brand href="/">
                <Image  src={Logo} className="logo_image" width="300px" height="300px"/ >
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navbar_section" style={{width:"600px",position:"relative",left:"50px"}}>
                <Nav.Link
                      href="/aboutus"
                  //  onClick={()=>handleRoute("howitworks")}
                    style={{
                      fontSize: "18px",
                     
                     
                    }}
                    
                  >
                    ABOUT US
                  </Nav.Link>
                  <Nav.Link >
                  <Dropdown>
                      <Dropdown.Toggle
                        variant="Primary"
                        id="dropdown-basic"
                        style={{ fontSize: "18px", fontWeight: "bold",padding: "0px" }}
                      >
                        AUTOGEN PLUS
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                         
                          style={{ fontSize: "12px", color: "#777777" }}
                          onClick={()=>handleRoute("brandslider")}
                        >
                          PROGRAM ELIGIBILITY
                        </Dropdown.Item>
                        <Dropdown.Item
                         
                          style={{ fontSize: "12px", color: "#777777" }}
                          onClick={()=>handleRoute("girlincar")}
                        >
                          PROGRAM COVERAGE 
                        </Dropdown.Item>
                        <Dropdown.Item
                         
                          style={{ fontSize: "12px", color: "#777777" }}
                          onClick={()=>handleRoute("hasslefree")}
                        >
                         PROGRAM REPAIR VALUE
                        </Dropdown.Item>
                        <Dropdown.Item
                         
                          style={{ fontSize: "12px", color: "#777777" }}
                          onClick={()=>handleRoute("periodiccar")}
                        >
                          FILING A BREAKDOWN REQUEST
                        </Dropdown.Item>
                        <Dropdown.Item
                         
                          style={{ fontSize: "12px", color: "#777777" }}
                          onClick={()=>handleRoute("workshopone")}
                        >
                          PROGRAM MAINTENANCE REQUIREMENT
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>

                  <Nav.Link
                   onClick={()=>handleRoute("howitworks")}
                    style={{
                      fontSize: "18px",
                     
                     
                    }}
                    
                  >
                    HOW IT WORKS
                  </Nav.Link>
                  <Nav.Link
                   onClick={()=>handleRoute("workshoptwo")}
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                     
                    }}
                  >
                    FAQ
                  </Nav.Link>
                  <Nav.Link >
                  <Dropdown>
                      <Dropdown.Toggle
                        variant="Primary"
                        id="dropdown-basic"
                        style={{ fontSize: "18px", fontWeight: "bold",padding: "0px" }}
                      >
                        LOCATE
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                       onClick={()=>handleRoute("faq")}
                          style={{ fontSize: "12px", color: "#777777" }}
                          
                        >
                          AUTHORIZED PARTNER FOR <br /> PROGRAM ACTIVATION
                        </Dropdown.Item>
                        <Dropdown.Item
                         onClick={()=>handleRoute("footer")}
                          style={{ fontSize: "12px", color: "#777777" }}
                        
                        >
                          PANEL WORKSHOP FOR CAR <br />   MAINTENANCE AND REPAIR <br/>WORK
                        </Dropdown.Item>
                       
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <div className="usericon">
                <Menu >
                <MenuButton>
                  {" "}
                  <i
                    style={{ width: "40px" }}
                    className="fa-regular fa-circle-user fa-lg"
                  ></i>
                </MenuButton>
                <span style={{fontSize:"18px",fontWeight:"bold"}}>Login</span>
                {localStorage.getItem("token") !== null ? (
                  <MenuList>
                    <MenuItem>
                      <Row>
                        <Col md={5}>
                          <Image src={UserIcon} />
                        </Col>
                        <Col md={7}>
                        {
                          localStorage.getItem("dealer_name") !== "null" ?
                          <p
                            style={{
                              fontSize: "10px",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            {localStorage.getItem("dealer_name")}
                          </p>
                          :null
                        }
                          
                        
                         
                          <p
                            style={{
                              fontSize: "10px",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            Active
                          </p>
                        </Col>
                      </Row>
                    </MenuItem>
                    {
                      localStorage.getItem("token") ?
                      <MenuItem onClick={() => navigate("/home")}>
                     Dashboard
                    </MenuItem>
                    : null
                    }
                    <MenuItem onClick={() => navigate("/userprofile")}>
                      Edit Profile
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/privacy-policy")}>
                      Privacy Policy
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant="primary"
                        style={{
                          width: "100%",
                          padding: "2px",
                          border: "2px solid #22BBB9",
                        }}
                        onClick={() => logout()}
                      >
                        Logout
                      </Button>
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList>
                    <MenuItem className="justify-content-center">
                      <Image src={UserIcon} />
                    </MenuItem>

                    <MenuItem>
                      <Button
                        variant="primary"
                        style={{
                          width: "100%",
                          padding: "2px",
                          border: "2px solid #22BBB9",
                        }}
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </Button>
                    </MenuItem>
                  </MenuList>
                )}
              </Menu>
              </div>
              <div
                  className="enbm"
                
                >
                  <Link
                    style={{ fontSize: "18px", margin: "5px" }}
                    onClick={handleEn}
                    to=""
                  >
                    {" "}
                    EN
                  </Link>

                  <div
                    style={{
                      height: "30px",
                      width: "3px",
                      background: "black",
                      margin: "5px",
                    }}
                    className="vertical"
                  ></div>
                  <Link
                    style={{ fontSize: "18px", margin: "5px" }}
                    onClick={handleBm}
                    to=""
                  >
                    {" "}
                    BM
                  </Link>
                </div>

            </Container>
          </Navbar>
        </header>
      }
     
      </ChakraProvider>
    </>
  );
}
