import React, { useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { TabContext } from '../../../Context/TabContext';
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_BOOKING, APPLYPROMOCODE, UPDATE_QOUTATION } from "../../../mutations/userMutations";
import { useForm } from "../../../utils/hooks";
import { GETPLANSBYCRITERIA } from "../../../queries/userQueries";
import { Label } from "reactstrap";
import moment from "moment";

function PlanQuotation() {
  const { handleTab } = useContext(TabContext);
  const [errors, setErrors] = useState({});
  const [promoData, setPromoData] = useState([]);
  const [promo, setPromo] = useState([]);
  const [planId, setPlanId] = useState("");
  const [planName, setPlanName] = useState("");
  const [checked, setChecked] = React.useState(false);
  const brandId = localStorage.getItem("brandId");
  const owner_year = localStorage.getItem("owner_year");
  const owner_mileage = localStorage.getItem("owner_mileage");
  var { data:plans } = useQuery(GETPLANSBYCRITERIA, { variables: { brandId, year:owner_year, odometer:owner_mileage } });

  const handleChecked = () => {
    setChecked(!checked);
  };
  const quoteId = localStorage.getItem("quote_id");
  const { onSubmit, values } = useForm(handleSubmit, {
    quotationId: `${quoteId}`,
    nric: "",
    state: "",
  });

  const [addUser, { loading }] = useMutation(CREATE_BOOKING, {
    update(_, { data: { bookingInit: userData } }) {
      localStorage.setItem("booking_id", userData.id);
      handleTab("PlanPayment");
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
    variables: {...values, planId:planId, planName:planName },
  });
  const [updateQuote] = useMutation(UPDATE_QOUTATION, {
    update(_, { data: { id } }) {
      console.log("id", id);
    },
    onError(err) {
    },
    variables: {id:localStorage.getItem("quote_id"), planId:planId, planName:planName},
  });

  function handleSubmit() {
    /* if (checked === false) {
      toast("Please Accept Terms & Conditions");
      return;
    }
    else{
      addUser();
    } */
    addUser();
    updateQuote();
    // useMutation(UPDATE_QOUTATION,{variables:{id:localStorage.getItem("quote_id"), planId:planId, planName:planName}})
  }


  const [addPromotionCode] = useMutation(APPLYPROMOCODE, {
    update(_, { data: { applyPromocode: promotionData } }) {
        if (promotionData.promocodeError === false) {
          setPromoData(promotionData);
          localStorage.setItem("owner_price",promotionData?.discountAmount)
          toast(`${promotionData?.promocodeStatus}`);
        }else{
          toast(`${promotionData?.promocodeStatus}`);
        }
    },
  });

  const codeSubmit = () => {
    addPromotionCode({
      variables: {
        quotationId: `${quoteId}`,
        promoCode: promo.trim()
      },
    });
  }

  function planChange({price, id}) {
    localStorage.setItem("owner_price", price);
    const selPlan = plans?.getQuotePlanByCriteria?.allQuotePlanRes?.find((plan)=>plan.id===id);
    localStorage.setItem("selPlan", JSON.stringify(selPlan));
    localStorage.setItem("selPlanName", selPlan?.EWPCategory);
    setPlanId(id);
    setPlanName(selPlan?.EWPCategory);
    localStorage.setItem("brands", JSON.stringify(plans?.getQuotePlanByCriteria?.brands));
  }

  useEffect(() => {
    console.log("ddsdf", plans);
  }, [plans]);
  

  return (
    <>
      <div className="payment-box">
        <ToastContainer />
        <div className="authentication-inputs">
          <h1>Plan Quotation</h1>
          <p className="mb-3">Generate quotation</p>
          {/* <Form noValidate validated={validated} onSubmit={onSubmit}> */}
          <div className="quot">
            <p>
              <strong>Car Details</strong>
            </p>

            <Row>
              <Col md={4}>
                <p><b>Brand</b> :</p>
              </Col>
              <Col md={8}>
                <p>{localStorage.getItem("owner_brand")}</p>
              </Col>
              <Col md={4}>
                <p><b>Model</b> :</p>
              </Col>
              <Col md={8}>
                <p>{localStorage.getItem("owner_model")}</p>
              </Col>
              <Col md={4}>
                <p><b>Year</b> :</p>
              </Col>
              <Col md={8}>
                {/* <p>{new Date(localStorage.getItem("owner_year")).getFullYear()}-{new Date(localStorage.getItem("owner_year")).getMonth() + 1}-{new Date(localStorage.getItem("owner_year")).getDate()}</p> */}
                <p>{moment(new Date(localStorage.getItem("owner_year"))).format('YYYY-MM-DD')}</p>
              </Col>
              <Col md={4}>
                <p><b>Mileage</b> :</p>
              </Col>
              <Col md={8}>
                <p>{localStorage.getItem("owner_mileage")}</p>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="quot">
            <p>
              <strong>Customer Details</strong>
            </p>

            <Row>
              <Col md={4}>
                <p><b>Name</b> :</p>
              </Col>
              <Col md={8}>
                <p>{localStorage.getItem("owner_name")}</p>
              </Col>
              <Col md={4}>
                <p><b>Email</b> :</p>
              </Col>
              <Col md={8}>
                <p>{localStorage.getItem("owner_email")}</p>
              </Col>
              <Col md={4}>
                <p><b>Phone Number</b> :</p>
              </Col>
              <Col md={8}>
                <p>{localStorage.getItem("owner_mobile")}</p>
              </Col>
              <Col md={4}>
                <p><b>NRIC No</b> :</p>
              </Col>
              <Col md={8}>
                <p>{localStorage.getItem("owner_nric")}</p>
              </Col>
              <Col md={12}>
                {plans?.getQuotePlanByCriteria?.allQuotePlanRes?.map((plan, index)=>(
                  <div className="car-card" key={index}>
                    <Label>
                      <div className="d-flex">
                        <div className="checkbox">
                        <Form.Check type="radio" name="selectedPlan" value={(plan.id || "silver").toString()} onChange={()=>{
                          planChange({price:plan?.DealersRate, id:plan.id})
                        }} />
                        </div>
                        <div className="continental-car">
                          <h3>{plan?.EWPCategory}</h3>
                          <span>
                            <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props} className="tooltip">
                                  <strong><u>Key Car Elegibility:</u></strong>
                                  <p><b>Accepted brands: </b>{plans?.getQuotePlanByCriteria?.brands?.map((brand, ind)=>(<>{`${ind>0?', ':''}${brand?.brand}`}</>))}</p>
                                  <p><b>Car Age: </b>{plan?.CarAge.indexOf('up to')!==-1?plan?.CarAge:`from ${plan?.CarAge}`} old</p>
                                  <p><b>Odometer Reading: </b>Less than 200,000KM</p>
                                </Tooltip>
                              )}
                              placement="top-end"
                            ><i className="fa fa-circle-info"></i>
                            </OverlayTrigger></span>
                        </div>
                      </div>
                      <div className="car-details">
                        <ul>
                          <li><b>Key In-Scope Components: </b>{plan?.CoveredComponents}</li>
                          <li><b>Total Number of In-Scope Components:   </b>{plan?.NoOfCoveredComponents};</li>
                          <li><b> Underwritten by:  </b>Berjaya Sompo Insurance</li>
                          <li><b> Coverage Period:  </b>1 Year or 30,000KM from the activation date; </li>
                          <li><b> Max. Coverage Per Visit:  </b>RM {parseFloat(plan?.MaxPerVisit).toLocaleString(undefined, {maximumFractionDigits:2})}; </li>
                          <li><b> Max. Coverage Per Year: </b> RM {parseFloat(plan?.MaxPerYear).toLocaleString(undefined, {maximumFractionDigits:2})} </li>
                        </ul>
                      </div>
                      <div className="car-price">
                        <h3><b>RM {parseFloat(plan?.DealersRate).toLocaleString(undefined, {maximumFractionDigits:2})}</b> <span>/Per Year Per Car</span></h3>
                      </div>
                    </Label>
                  </div>
                ))}
              </Col>
              <Col md={12} className="mt-3">
                <Row className="mt-1 p-0">
                  <Col md={12} className="mt-2" >
                    {
                      promoData.promocodeStatus != "Promotion Code Applied" && (
                      <Row className="mb-4">
                        <Col md={8}>
                          <div className="form-group position-relative responsive-tablet">
                            <input type="text" 
                              value={promo}
                              onChange={(e) => setPromo(e.currentTarget.value)}
                              className="form-control" id="inputPassword2" 
                              placeholder="Apply Promotion Code" />
                            <button type="" className="btn p-0 position-absolute" 
                                    style={{ color: "#32bec0", fontWeight: "600",top:"5px",right:"15px" }}
                                    onClick={codeSubmit}
                            > 
                              Apply 
                            </button>
                          </div>
                        </Col>
                      </Row>
                      ) 
                    }
                    {
                      promoData.promocodeError === false ? (
                        <>
                          <Row className="my-3">
                            <Col md={12}>
                              <strong>Payment Details</strong>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <p><b>Amount</b> :</p>
                            </Col>
                            <Col md={8}>
                              <p>RM <span>{promoData.quotationPrice}</span>.00</p>
                            </Col>

                            <Col md={4}>
                              <p><b>Deduction</b> :</p>
                            </Col>
                            <Col md={8}>
                              <p>RM <span>-{" "}{promoData.deductedAmount}</span></p>
                            </Col>

                            <Col md={4}>
                              <p><b>Grand Total</b> :</p>
                            </Col>
                            <Col md={8}>
                              <p>RM <span>{promoData.discountAmount}</span></p>
                            </Col>
                          </Row>
                        </>

                      ) : ( " " )
                    }
                    {/* </form> */}
                  </Col>
                </Row>

                {/* <strong>Get covered by AUTOGEN+ for only</strong>
                <div className="section-title d-flex align-items-end">
                  <h2 className="mb-0">
                    RM <span>
                      { promoData.promocodeError === false ? (promoData.discountAmount)  : localStorage.getItem("owner_price")}
                    </span> */}
                      {/* { (localStorage.getItem("owner_price") % 1 === 0 || promoData.discountAmount % 1 === 0) ? ".00" : "" } */}
                  {/* </h2>
                  <h5>/YEAR</h5>
                </div> */}
              </Col>
              {/* <Col md={12} className="d-flex m-auto">
                <Form.Check className="mb-3">
                  {" "}
                  <Form.Check.Input type="checkbox" onChange={handleChecked} />
                  <Form.Check.Label>
                    I have read and agreed to the <a style={{ textDecoration: "underline" }} href="/termconditions">Terms and Conditions</a> stipulated
                    and will abide by it. eAutoGen reserves the right of final
                    decision in the case of disputes and may at any appropriate time
                    and at its sole discretion update the <a style={{ textDecoration: "underline" }} href="/termconditions">Terms and Conditions</a> of
                    use.
                  </Form.Check.Label>
                </Form.Check>
              </Col> */}
            </Row>
          </div>
          <div className="d-flex justify-content-between  mt-5">
            <Link to="/buyplan" onClick={() => handleTab("CustomerDetails")} className="btn btn-outline-secondary btn-md">
              {" "}
              Back
            </Link>
            <Link
              to="/buyplan"
              className="btn btn-secondary btn-md"
              onClick={onSubmit}
            >
              {" "}
              SAVE & CONTINUE
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanQuotation;
