import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Periodic from "../../../assets/img/periodic-calender.png";
import "./PeriodicCar.css";
import Table from "react-bootstrap/Table";

export const PeriodicCar = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <Container style={{ width: "80%" }}>
        <Row>
          <Col
            md={6}
            style={{
              disply: "flex",
              textAlign: "right",
            }}
          >
            <Image src={Periodic} fluid />
          </Col>
          <Col md={6} className="periodic-left">
            <h1>
              PROGRAM AUTOGEN PLUS KEPERLUAN <br />
              PENYELENGGARAAN
            </h1>

            <h2>Keperluan Penyelenggaraan Kereta Berkala</h2>

            <p>
              <br />
              Memupuk tingkah laku yang baik sebagai seorang pemilik kereta
              dengan memastikan program anda sentiasa aktif. Anda dikehendaki
              menjalankan penyelenggaraan kereta secara berkala atas
              perbelanjaan anda sendiri di mana-mana bengkel panel eAutoGe
              <br />
              <br />
              Jadual penyelenggaraan kereta berkala AutoGen PLUS (seperti yang
              disyorkan oleh pengeluar) adalah seperti di bawah:
            </p>
          </Col>
        </Row>
        <Row className=" justify-content-center ">
          <Table style={{ width: "85%", marginTop: "30px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    color: "white",
                    margin: "10px",
                    padding: "20px",
                    alignItems: "center",
                    width: "50%",
                    fontSize: "17px",
                    border: "5px solid white",
                    background: "#0C4C81",
                  }}
                >
                  <p className="lubricant">Jenis Pelincir Kereta</p>{" "}
                </th>
                <th
                  md={5}
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    color: "white",
                    margin: "10px",
                    border: "5px solid white",
                    width: "50%",
                    fontSize: "17px",
                    padding: "20px",
                    background: "#0C4C81",
                  }}
                >
                  {" "}
                  AutoGen PLUS Periodic Car Maintenance Schedule
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    fontSize: "16px",
                    background: "#F4F6F6",
                  }}
                >
                  Semi-Sintetik
                </td>
                <td
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    fontSize: "16px",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    background: "#F4F6F6",
                  }}
                >
                  Setiap 3 bulan (atau 5,000 kilometer, yang mana lebih awal)
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    disply: "flex",
                    textAlign: "center",
                    fontSize: "16px",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    background: "#F4F6F6",
                  }}
                >
                  Sintetik Penuh
                </td>
                <td
                  style={{
                    disply: "flex",
                    fontSize: "16px",
                    textAlign: "center",
                    border: "5px solid white",
                    margin: "10px",
                    padding: "20px",
                    background: "#F4F6F6",
                  }}
                >
                  Setiap 6 bulan (atau 10,000 kilometer, yang mana lebih awal)
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Row className="justify-content-center">
          <Col md={10}>
            <p>
              Sila simpan kesemua salinan resit penyelenggaraan sepanjang tempoh
              program AutoGen PLUS dan penyerahan resit penyelenggaraan tersebut
              akan diminta semasa permohonan pembaikan dijalankan
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mt-4 text-center">
            <Button
              className="getstarted text-center"
              variant="secondary"
              onClick={() => window.scrollTo(0, window.innerHeight)}
            >
             DAPATKAN SEBUT HARGA
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
