import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Girl from "../../../assets/img/girl.png";
import "./GirlInCar.css";
export const GirlInCar = () => {
  return (
    <div className="py-5">
      <Container style={{ width: "80%" }}>
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            {" "}
            <Image src={Girl} fluid />{" "}
          </Col>
          <Col md={6} className="section-title text-lg-end">
            <span>NILAI PEMBAIKAN PROGRAM AUTOGEN PLUS</span>
            <h2>
              <span>Nilai Pembaikan Atas Komponen-Komponen Dalam Skop</span>
            </h2>
            <Row>
              <Col md={2}></Col>
              <Col md={10}>
                <p
                  className="pb-lg-5"
                  style={{
                    color: "black",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  • Melindungi anda daripada kos bil pembaikan yang semakin
                  meningkat pada masa hadapan <br />• Hanya membuat baki bayaran
                  selepas tolak kos pembaikan sekiranya ada <br /> • 100%
                  dibenarkan untuk membuat pindah milik mengikut nombor casis
                  kereta atas budi bicara eAutoGen <br /> • Tiada had bagi
                  bilangan pembaikan, asalkan ia adalah dalam nilai pembaikan
                  kereta anda
                </p>
              </Col>
            </Row>
            <Button
              className="getstarted"
              variant="secondary"
              onClick={() => window.scrollTo(0, window.innerHeight)}
            >DAPATKAN SEBUT HARGA
             
            </Button>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
          </Col>
        </Row>

        <Row className="girlpara justify-content-center ">
          <Col lg={6}>
            <h1>Penjimatan Sehingga RM20,000 Setahun</h1>
            <p>
              Nikmati nilai pembaikan dan nilai penggantian yang tinggi bagi
              komponen dalam skop program AutoGen PLUS apabila anda mengunjungi
              mana-mana bengkel panel eAutoGen
            </p>
          </Col>

          <Col lg={6}>
            <h1>Nilai Pembaikan Setinggi RM 10,000 Bagi Setiap Lawatan </h1>
            <p>
              Tiada had atas bilangan lawatan asalkan ia masih dalam nilai
              pembaikan dan dalam jumlah agregat nilai pembaikan setahun
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
