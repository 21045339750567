import React from 'react'
import "./checkout.css"
import {Row,Col,Container,Form,Image,Button} from 'react-bootstrap'
import {AuthorizedPartners} from '../home/authorizedpartnres'
export const Checkoutworkshop = () => {
  return (
    <div>

      {
        localStorage.getItem('language') === 'true' ?
        <div className="section-title mt-5">
      <div className="ckeckout_bg_slider">

     <Container>
          <Row>
            <Col md={12}>

           <span >MENGAKTIFKAN autogen plus DENGAN KOD PESANAN</span>
           <h1 className='checkout_title'>CARI RAKAN KONGSI SAH PILIHAN ANDA</h1>
           <h1 className='checkout_title'>RAKAN NIAGA UNTUK MENGAKTIFKAN AUTOGEN PLUS ANDA</h1>
          

           <AuthorizedPartners/>
            </Col>
          </Row>
     </Container>

     </div>
    </div>
        :
        <div className="section-title mt-5">
      <div className="ckeckout_bg_slider">
     <Container>
          <Row>
            <Col md={12}>
         
           <span >ACTIVATING YOUR AUTOGEN PLUS WITH THE ORDER CODE</span>
           <h1 className='checkout_title'>FIND YOUR PREFERRED AUTHORISED</h1>
           <h1 className='checkout_title'>PARTNERS TO ACTIVATE YOUR AUTOGEN PLUS</h1>
           <AuthorizedPartners/>
            </Col>
          </Row>
     </Container>
     </div>
      
    </div>
    }

    </div>
  )
}
