import React from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import car_wireframe from "../../assets/img/girl1.png";
import eautogen_product from "../../assets/img/header_bg.png";
import {IoIosArrowDown} from "react-icons/io"
import {  Nav, Navbar } from "react-bootstrap";
import "./checkout.css"
function Checkoutbox() {
  // function handleClear() {
  //   localStorage.clear();
  // }

  let voucherData = localStorage.getItem("voucher_code");
  console.warn("voucherData",voucherData)

  return (
    <div>
      {
        localStorage.getItem('language') === 'true' ?
        <section className="">
        
    <header className="py-3 justify-content-center" style={{display:"flex"}}>
    <Navbar.Brand href={
      localStorage.getItem('token') ? '/home' : '/'
    }>
      <Image src={eautogen_product} fluid width="400px" height="400px" />
    </Navbar.Brand>
        {/* <Image style={{cursor:"pointer"}} src={eautogen_product} fluid width="400px" height="400px" onClick={()=> window.location.href("/home")}/> */}
      </header>
      <hr></hr>
      <Container >
      
        <Row className="mt-3">
          <Col md={12}>
            <div className="section-title mb-5">
              <span>MENGURANGKAN  PERBELANJAAN PEMBAIKAN KERETA ANDA DENGAN autogen plus</span>
              <h2>
                <span style={{color:"#0C4C82"}}>BAYARAN BERJAYA</span>
              </h2>
              <p className="mt-4">
              Ini adalah resit janaan komputer, tiada tandatangan diperlukan.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Image src={car_wireframe} fluid width="475px" height="475px" />
          </Col>
          <Col md={6} className="checkout_right">
            <h1>
            Terima kasih atas pembelian AutoGen PLUS. Anda akan menerima e-mel dengan salinan Kod Pesanan AutoGen PLUS sebentar nanti di  {" <alamat emel pelanggan>" } .
            </h1>
            {/* <h2 style={{color:"grey"}}>For all email communication, kindly check your inbox, spam box or junk box. </h2> */}
            <h1 className="mt-5">Sila menjalankan pemeriksaan kereta anda untuk pengaktifan kereta anda di mana-mana rakan kongsi sah eAutoGen dengan Kod Pesanan di bawah:</h1>
            <Row className="checkout_right_coupon">
            {
                (voucherData == "undefined" || voucherData == null ) ? <p>Terima kasih kerana menempah. Anda akan mendapat kod baucar anda dalam masa 24 jam pada e-mel berdaftar anda.</p>
                :
               <h1 style={{fontSize:"22px"}}>{ voucherData}</h1>

             }
             <span style={{color:"#1a4789"}}>
            </span>
             <h2 style={{fontWeight:"bold"}}>Sila guna dalam tempoh 90 hari</h2>
            </Row>
            <h1 className="mt-3">Tatal ke bawah untuk langkah seterusnya <IoIosArrowDown /> </h1>
          </Col>
        </Row>
      </Container>
    </section>
    :
 <section className="">
    <header className="py-3 justify-content-center" style={{display:"flex"}}>
    <Navbar.Brand href={
      localStorage.getItem('token') ? '/home' : '/'
    }>
      <Image src={eautogen_product} fluid width="400px" height="400px" />
    </Navbar.Brand>
        {/* <Image style={{cursor:"pointer"}} onClick={()=> window.location.href("/home")} src={eautogen_product} fluid width="400px" height="400px"   /> */}
      </header>
      <hr></hr>
      <Container >
      
        <Row className="mt-3">
          <Col md={12}>
            <div className="section-title mb-5">
              <span>LIMIT YOUR CAR REPAIR EXPENSES WITH AUTOGEN PLUS </span>
             
              <h2>
                <span style={{color:"#0C4C82"}}>PAYMENT SUCCESSFUL</span>
              </h2>
              <p className="mt-4">
                This is a computer generated receipt, no signature is required.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Image src={car_wireframe} fluid width="475px" height="475px" />
          </Col>
          <Col md={6} className="checkout_right">
            <h1>
              Thank you for purchase of AutoGen PLUS. You will receive an email
              with a copy of the AutoGen PLUS Order Code shortly at your
              registered email address.{" "}
            </h1>
            <h2 style={{color:"grey"}}>For all email communication, kindly check your inbox, spam box or junk box. </h2>
            <h1 className="mt-5">Please use Order Code for program activation.</h1>
            <Row className="checkout_right_coupon">
           
             {
                (voucherData == "undefined" || voucherData == null ) ? <p>Thank you for booking. You will get your voucher code in 24 hours on your registered email.</p>
                :
               <h1 style={{fontSize:"22px"}}>{ voucherData}</h1>
             }
          
          
             <h2 style={{fontWeight:"bold"}}>Use within 90 days</h2>
            </Row>
            <h1 className="mt-3">Scroll down to see your next step <IoIosArrowDown /> </h1>
          </Col>
        </Row>
      </Container>
    </section>
      }
    </div>
  );
    }
export default Checkoutbox;
