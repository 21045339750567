import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
// import Carousel from "react-bootstrap/Carousel";
import Carousel from "react-grid-carousel";
import Hassle1 from "../../../assets/img/hasslebm1.png";
import Hassle2 from "../../../assets/img/hasslebm2.png";
import Hassle3 from "../../../assets/img/hasslebm3.png";
import Money from "../../../assets/img/bmnoupfront.png";
import "./HassleFree.css";
export const HassleFree = () => {
  return (
    <div className="py-5">
      <Container style={{ width: "80%" }}>
        <Row className="hasslefree justify-content-center align-items-center">
          <Col md={6}>
            <div className="section-title text-lg-start">
              <span
                style={{
                  color: "#777777",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                PERMOHONAN MEMFAILKAN KEROSAKAN
              </span>
              <h2>
                <span>Pembaikan Tanpa Kerumitan</span>
              </h2>
              <p style={{ color: "black", fontWeight: "normal" }}>
                • Semua kerja pembaikan perlu dilakukan di{" "}
                <span style={{ textDecoration: "underline" }}>
                  bengkel panel eAutoGen
                </span>
                <br />• Kerja-kerja pembaikan dan tuntutan nilai pembaikan boleh dijalankan setelah program
                melepasi{" "}
                <span style={{ textDecoration: "underline" }}>
                  tempoh bertenang program{" "}
                </span>
                  iaitu 7 hari pertama atau 300 kilometer pertama dari
                tarikh pengaktifan program dan bacaan odometer setakat pengaktifan program.
              </p>
              <Row>
                <Col md={5}>
                  <Image src={Money} style={{ marginTop: "30px" }} />
                </Col>
              </Row>
              <Button
                className="mt-lg-5 mt-2 mb-2 gethassle"
                variant="secondary"
                onClick={() => window.scrollTo(0, window.innerHeight)}
              >
                DAPATKAN SEBUT HARGA
              </Button>
            </div>
          </Col>

          <Col md={6}>
            <Carousel cols={1} rows={1} showDots={true} loop>
              <Carousel.Item>
                <Image src={Hassle1} fluid />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={Hassle2} fluid />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={Hassle3} fluid />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
