
import React, { useEffect, useRef , useState} from 'react'
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/img/header_bg.png';
import { useMutation } from "@apollo/client";
import { GETDEALERCURRENTCREDIT } from "../../../mutations/userMutations";

import '../../../admin/style.css'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { Row, Col, Button } from 'react-bootstrap'
import { ChakraProvider } from "@chakra-ui/react";
import UserIcon from '../../../assets/img/autogen-icon.png';


function HeaderDelaer() {
  const navigate = useNavigate()
  
  const [creditAmt, setCreditAmt] = useState(0);
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [addCredit, { loading }] = useMutation(GETDEALERCURRENTCREDIT, {
    update(_, { data: { getDealerCurrentCredit: creditData } }) {
      if (creditData) {
        setCreditAmt(creditData.amount);
      }
    },
  });

  const creditFunc = () =>{
    addCredit({
      variables: {
        dealerId: `${localStorage.getItem("user_id")}`,
      },
    });
  }

  useEffect(()=>{
    creditFunc();
  },[])
  




  return (<>
    <ChakraProvider>
      <header className='header-admin'>
        <Navbar expand="xl">
          <Container >
            <Navbar.Brand href="/">
              <Image src={Logo} style={{ position: "relative", right: "15px" }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <NavLink className="nav-link" to="/home">Home</NavLink>
                <NavLink className="nav-link" to="/buyplan">Buy Plan</NavLink>
                {
                  localStorage.getItem("role") === "dealer" ?
                    <NavLink className="nav-link" to="/dealer-admin">Admin</NavLink>
                    :
                    null

                }

              </Nav>
            </Navbar.Collapse>
            <div className='login'>

              {/* <Menu>
                          <MenuButton>
                            {" "}
                            <i className="fa-regular fa-circle-user"></i>
                          </MenuButton>
                        
                            <MenuList>
                            <MenuItem >
                              <Row>
                                <Col md={5}>
                                <Image src={UserIcon}/>
                                </Col>
                                <Col md={7}>
                                     <p style={{fontSize:"10px",margin:"0px",padding:"0px"}}>{localStorage.getItem("owner_name")}Steve</p>
                                     <p style={{fontSize:"10px",margin:"0px",padding:"0px"}}>Your Plan</p>
                                     <p style={{fontSize:"10px",margin:"0px",padding:"0px"}}>Expiry Date:26 May</p>
                                     <p style={{fontSize:"10px",margin:"0px",padding:"0px"}}>Active</p>
                                </Col>
                              </Row>
                            </MenuItem>
                            <MenuItem onClick={()=>navigate("/userprofile")} >Edit Profile</MenuItem>
                            <MenuItem onClick={()=>navigate("/privacy-policy")} >Privacy Policy</MenuItem>
                            <MenuItem  ><Button variant="primary" style={{width:"100%",padding:"2px",border:"2px solid #22BBB9",}}>Logout</Button></MenuItem>
                           
                          </MenuList> 
                         
                          
                        </Menu> */}

              <Menu>
                <MenuButton>
                  {" "}
                  <i style={{ width: "200px", }} className="fa-regular fa-circle-user fa-lg"></i>
                </MenuButton>


                {
                  localStorage.getItem("token") !== null ?

                    <MenuList>
                      <MenuItem>
                        <Row>
                          <Col md={5}>
                            <Image src={UserIcon} />
                          </Col>
                          <Col md={7}>
                            {
                              localStorage.getItem("dealer_name") !== "null" ?
                                <p
                                  style={{
                                    fontSize: "10px",
                                    margin: "0px",
                                    padding: "0px",
                                  }}
                                >
                                  {localStorage.getItem("dealer_name")}
                                </p>
                                : null
                            }



                            <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Active
                            </p>
                          </Col>
                        </Row>
                      </MenuItem>
                      {
                        localStorage.getItem("token") ?
                          <MenuItem onClick={() => navigate("/home")}>
                            Dashboard
                          </MenuItem>
                          : null
                      }

                      <MenuItem onClick={() => navigate("/userprofile")}>
                        Edit Profile
                      </MenuItem>

                      {
                        localStorage.getItem("role") === "dealer" ? <MenuItem onClick={() => navigate("/credit", { state: { creditAmt: creditAmt } })}>
                        My Credit  :&nbsp; <span style={{color:"#17BEBB", fontWeight:"500"}}> RM {creditAmt}</span>
                      </MenuItem> : ""
                      }
                      
                      <MenuItem onClick={() => navigate("/privacy-policy")}>
                        Privacy Policy
                      </MenuItem>
                      <MenuItem>
                        <Button
                          variant="primary"
                          style={{
                            width: "100%",
                            padding: "2px",
                            border: "2px solid #22BBB9",
                          }}
                          onClick={() => logout()}
                        >
                          Logout
                        </Button>
                      </MenuItem>
                    </MenuList>
                    :
                    <MenuList>
                      <MenuItem className="justify-content-center">


                        <Image src={UserIcon} />


                      </MenuItem>

                      <MenuItem>
                        <Button
                          variant="primary"
                          style={{
                            width: "100%",
                            padding: "2px",
                            border: "2px solid #22BBB9",
                          }}
                          onClick={() => navigate("/login")}
                        >
                          Login
                        </Button>
                      </MenuItem>
                    </MenuList>
                }


              </Menu>

              {/* <Link to=""> EN</Link>

            <Link to=""> BM</Link> */}

            </div>
          </Container>
        </Navbar>
      </header>
    </ChakraProvider>
  </>);
}

export default HeaderDelaer;