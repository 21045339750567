import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import './BarChart.css';
import { Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js';
import { Col, Row } from "react-bootstrap";
import { subDays, startOfWeek, endOfWeek, addDays, startOfMonth, endOfMonth, addMonths } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import { DateRangePicker } from 'rsuite';
import '/node_modules/rsuite/dist/rsuite.css'; // 
import moment from 'moment';

import { GETADMINREVENUEBYDATES } from "../mutations/userMutations";
import { useMutation } from "@apollo/client";

export function BarChart() {
    const adminId = localStorage.getItem('user_id');

    let now = new Date();
    let previousDate = new Date(now);
    previousDate.setDate(now.getDate() - 7);

    const [value, setValue] = useState([new Date(previousDate), new Date()]);
    let selectFrom = moment(value[0]).format('YYYY-MM-DD')
    let selectTo = moment(value[1]).format('YYYY-MM-DD')
    const [fromDate, setFromDate] = useState(selectFrom);
    const [toDate, setToDate] = useState(selectTo);
    const [count, setCount] = useState(1);

    useEffect(()=>{
        if (count > 0) {
          console.log('count is greater than 0 and now',count);
        }
        setFromDate(selectFrom);
        setToDate(selectTo);

        addGraph({
            variables: {
                userId: adminId,
                fromDate: fromDate,
                toDate: toDate
            },
        });

    },[count])


    const datesArr = [];
    const initialDataPoints = [];
    const [dates, setDates] = useState([]);
    const [dataPoints, setDataPoints] = useState([]);

    const [addGraph, { loading }] = useMutation(GETADMINREVENUEBYDATES, {
        update(_, { data: { getAdminRevenueByDates: { adminRevenueByDates: revenueInfo } } }) {

            let sortRevenueInfo = revenueInfo?.slice().sort((a, b) => a.date.localeCompare(b.date));
            if (sortRevenueInfo) {
                for (let i = 0; i < sortRevenueInfo.length; i++) {
                    datesArr.push(moment(sortRevenueInfo[i].date).format('DD/MM'));
                    initialDataPoints.push(sortRevenueInfo[i].revenue);
                }
                setDates(datesArr);
                setDataPoints(initialDataPoints);
            }
        },
    });

    const changeDateFunc = () => {
        setFromDate(selectFrom);
        setToDate(selectTo);
        setCount(count + 1)
       
        addGraph({
            variables: {
                userId: adminId,
                fromDate: fromDate,
                toDate: toDate
            },
        });
    };


    ChartJS.register(
        Title, Tooltip, LineElement, Legend,
        CategoryScale, LinearScale, PointElement, Filler
    )

    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()]
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)]
        },
        {
            label: 'Past 7 days',
            value: [subDays(new Date(), 6), new Date()]
        },
        {
            label: 'Past 30 days',
            value: [subDays(new Date(), 29), new Date()]
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()]
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)]
        },
        {
            label: 'All time',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
        }
    ];

    return (
        <>
            <ToastContainer />
            <div className="overview-box">
                <Row className="justify-content-between">
                    <Col md={3}>
                        <h5 style={{ color: "#0c4b82", fontSize: '21px', fontWeight: '700' }}>Revenue</h5>
                    </Col>
                    <Col md={4} className="date-wrap" style={{ display: 'flex', justifyContent: 'end' }} >
                        <DateRangePicker
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            value={value}
                            onChange={setValue}
                            onClose={changeDateFunc}
                            direction="horizontal"
                            ranges={predefinedBottomRanges}
                            placeholder="Select Dates Ranges"
                        />
                    </Col>
                </Row>

                <div >
                    <Line
                        id="myChart"
                        data={{
                            labels: dates,
                            backgroundColor: "#1c9ba9",
                            datasets: [
                                {
                                    label: "First Dataset",
                                    data: dataPoints,
                                    backgroundColor: "#1c9ba9",
                                    borderColor: [
                                        "#20b3b5",
                                        "rgba(54, 162, 235, 1)",
                                        "rgba(255, 206, 86, 1)",
                                        "rgba(75, 192, 192, 1)",
                                        "rgba(153, 102, 255, 1)",
                                        "rgba(255, 159, 64, 1)",
                                    ],
                                    borderWidth: 1,
                                    fill: true,
                                    pointBorderColor: '#22bdb9',
                                    pointBackgroundColor: '#fff',
                                    tension: 0.4,
                                },
                            ],
                        }}
                        height={250}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                yAxes: {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    gridLines: {
                                        display: false
                                    }
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}

