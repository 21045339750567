import React from 'react'
import { Header } from '../../components/common/header'
import { AboutBanner } from '../AboutUs/AboutBanner/AboutBanner'
import { AboutHeader } from '../AboutUs/AboutHeader/AboutHeader'
import { AboutSectionBM1 } from './AboutSectionBM1/AboutSectionBM1'
import { AboutSectionBM2 } from './AboutSectionBM2/AboutSectionBM2'
import { AboutSectionBM3 } from './AboutSectionBM3/AboutSectionBM3'
import { AboutSectionBM4 } from './AboutSectionBM4/AboutSectionBM4'
import Footer from '../../components/common/footer'

export const AboutUsBM = () => {
    return (
        <div>
            <Header />
            <AboutHeader />
            <AboutSectionBM1 />
            <AboutSectionBM2 />
            <AboutSectionBM3 />
            <AboutSectionBM4 />
            <AboutBanner />
            <Footer />
        </div>
    )
}
