import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Girl from "../../../assets/img/girl.png";
import "./GirlInCar.css";
export const GirlInCar = () => {
  return (
    <div className="py-5">
      <Container style={{ width: "80%" }}>
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            {" "}
            <Image src={Girl} fluid />{" "}
          </Col>
          <Col md={6} className="section-title text-lg-end">
            <span>AUTOGEN PLUS PROGRAM REPAIR VALUE</span>
            <h2>
              <span>Repair Value On In-Scope Components</span>
            </h2>
            <Row>
              <Col md={2}></Col>
              <Col md={10}>
                <p
                  className="pb-lg-5"
                  style={{
                    color: "black",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  • Protects you from the rising cost of future repair bills{" "}
                  <br />• Pay only your deductible, if any, per covered repair
                  visit <br /> • 100% transferable by car chassis number at
                  eAutoGen’s discretion <br /> • No limit to number of repairs,
                  as long as it is within your repair value.
                </p>
              </Col>
            </Row>
            <Button
              className="getstarted"
              variant="secondary"
              onClick={() =>
                (window.location.href = "https://pgmall.my/s/IMA2/3033")
              }
            >
             Buy Now Exclusively On PGMall
            </Button>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
          </Col>
        </Row>

        <Row className="girlpara justify-content-center ">
          <Col lg={6}>
            <h1>Save Up To RM20,000 Per Annum</h1>
            <p>
              Enjoy high repair and replacement value on AutoGen PLUS program
              in-scope components when you visit any of eAutoGen's panel
              workshops.
            </p>
          </Col>

          <Col lg={6}>
            <h1> Repair Value Of Up To RM10,000 Per Visit </h1>
            <p>
              There is no limit to the number of visits as long as it is within
              your repair value per visit and within the aggregate amount of
              repair value per annum.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
