import React, { useState, useRef } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import autogenplus_logo from "../../assets/img/autogenplus_logo.png";
import { useQuery } from "@apollo/react-hooks";
import Slider from 'react-slick'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import "./react.css";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { GETALLSTATES } from "../../queries/userQueries";
import { GETAllWORKSHOPS } from "../../queries/userQueries";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Container,
  Chip,
} from "@material-ui/core/";
import Carousel from "react-material-ui-carousel";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { ContactMail } from "@material-ui/icons";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

function AuthorizedWorkshops() {
  const [stateValue, setStateValue] = useState("62d134e6f13bf1bf557aa7d8");
  function onChange(e) {
    setStateValue(e.target.value);
  }
  localStorage.setItem("stateId", stateValue);
  var { data } = useQuery(GETALLSTATES);
  const stateArray = data?.getAllState?.allStateRes;

  const newStateArray = stateArray?.filter((object) => {
    return (
      object.id !== "62d134e6f13bf1bf557aa7d8"
      // &&
      // object.id !== " 62d13579f13bf1bf557aa7f7" &&
      // object.id !== "62d1355cf13bf1bf557aa7ed" &&
      // object.id !== "62d13556f13bf1bf557aa7ea" &&
      // object.id !== "62d1351ef13bf1bf557aa7dc" 
    );
  });

  const stateId = localStorage.getItem("stateId");
  var { data } = useQuery(GETAllWORKSHOPS, { variables: { stateId } });
  const workshops = data?.getWorkshopById?.allWorkshopRes;
  const lengthprop2 = workshops?.length;

  const newVar2 = lengthprop2 >= 2 ? true : false;
  const [sliderRef, setSliderRef] = useState(1)

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: newVar2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }


  return (
    <>
      <section className="workshops">
        <Container className="p-0 m-0">
          <Row>
            <Col md={4}>
              <Form.Group
                className="brand_margin"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={onChange}
                >
                  <option value={"62d134e6f13bf1bf557aa7d8"}>
                    Kuala Lumpur
                  </option>
                  {newStateArray?.map((option, index) => {
                    if (option.name !== "Labuan" && option.name !== "Putrajaya") {
                      return (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={12}>
              <div className='content'>
                <div className='controls'>
                  <button onClick={sliderRef?.slickPrev}>
                    <BsFillCaretLeftFill />
                  </button>
                  <button onClick={sliderRef?.slickNext}>
                    <BsFillCaretRightFill />
                  </button>
                </div>
                <div style={{ width: "90%", margin: "auto" }}>
                  <Slider ref={setSliderRef} {...sliderSettings}>
                    {workshops?.map((card, index) => (
                      <React.Fragment key={index}>
                        <div className='card' >
                          <div className='text-info'>
                            <div >
                              <Image src={autogenplus_logo} fluid  style={{ marginTop: "-45px" }} />
                              <div className="p-2" style={{ marginTop: '-50px'}}>
                                <p style={{ color: "#000000", fontWeight: "bold" }}>{card.name}</p>
                                <p style={{ color: "#000000" }}>{card.address}</p>
                                <p style={{ color: "#000000" }}>{card.telephone}</p>
                              </div>
                            </div>
                            <ul>
                            </ul>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AuthorizedWorkshops;
