import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export const AboutSectionBM3 = () => {
    return (
        <div>
            <section>
                <Container>
                    <Row className=' mt-5 pb-5'>
                        <Col md={4} >
                            <h2 className='section_title'><span>Pasangan  <br /> teknologi  <br /> ketiga </span><br /> FMCCAM.</h2>
                        </Col>
                        <Col md={8} className='section_prg '>
                            <Row>
                                <p>Gabungan Persatuan Syarikat Motor dan Kredit Malaysia (FMCCAM) -- suara bagi peniaga kereta terpakai sejak 1979 – sebuah persatuan yang berpengaruh dan berwawasan bagi kemajuan industri automotif Malaysia hari ini.
                                    Bersama FMCCAM, kami bertujuan membuka kunci peluang yang baru bagi peniaga dan pembeli kereta terpakai di Malaysia. Program waranti lanjutan eAutoGen direka untuk menggalakkan keyakinan di pasaran kereta terpakai dan merapatkan jurang kepercayaan di antara penjual dan pembeli dalam pasaran yang kini kurang dikawal selia.
                                </p>
                            </Row>
                            <Row className='mt-3'>
                                <p>eAutoGen menciptakan pengalaman pembelian kereta yang bertambah nilai dalam pelbagai cara dengan program waranti lanjutan. Salah satu ciri-ciri program kami termasuk pemeriksaan kereta waktu pengaktifan program, kedua-duanya peniaga dan pembeli kereta terpakai boleh mengurangkan risiko berkaitan dengan perdagangan dan pembelian kereta terpakai dengan isu-isu sedia ada seperti keadaan kereta untuk komponen dalam skop program, kereta dicuri, kereta di luar pembaikan ekonomi.</p>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
