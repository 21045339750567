import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AuthorizedPartners } from "../../../components/home/authorizedpartnres";

import "./WorkshopOne.css";
export const WorkshopOne = () => {
  return (
    <div style={{ background: "#F4F6F6", marginTop: "50px" }} className="py-5">
      <Container style={{ width: "80%" }}>
        <Row className="workshopone">
          <Col md={6}>
            <p>
              Kunjungi mana-mana rakan kongsi sah kami dengan kod pesanan anda
              untuk pemeriksaan kereta bagi pengaktifan program AutoGen PLUS.
              Untuk mengaturkan pemeriksaan kenderaan secara mudah alih (kawasan
              tertentu), sila hubungi Talian Bantuan eAutoGen.
            </p>
          </Col>
          <Col md={6} className="section-title text-lg-end">
            <span>RAKAN kongsi SAH EAUTOGEN</span>
            <h2>
              <span>Sila pilih Lokasi Untuk Pengaktifan Program </span>
            </h2>
          </Col>
          <AuthorizedPartners />
        </Row>
      </Container>
    </div>
  );
};
