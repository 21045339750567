import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Checkbox,
  Image,
  Row,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
// import ipay from "./../../../assets/img/ipay88.png";
import reyPay from "./../../../assets/img/revPay.png";
import credit from "./../../../assets/img/credit logo crop.jpg";
import { ToastContainer, toast } from "react-toastify";
import OnlineBanking from "./Payment/onlinebanking";
import OtherPayment from "./Payment/other";
import Cardpage from "./Payment/card";
import { TabContext } from "../../../Context/TabContext";
import { useMutation } from "@apollo/client";
import { CREATE_PAYMENT, UPDATEBOOKINGPRICEBYUSINGCREDITPOINT, GETDEALERCURRENTCREDIT, HANDLEBOOKINGSUCCESSAFTERCREDIT } from "../../../mutations/userMutations";
import { useForm } from "../../../utils/hooks";

import ipay from "./../../../assets/img/ipay88.png";
import revPay from "./../../../assets/img/revPay.png";
import visa from "./../../../assets/img/visa.png";
import fpx from "./../../../assets/img/fpxlogo.png";
import touchngo from "./../../../assets/img/touchlogo.png";
import boost from "./../../../assets/img/boostlogo.png";
import maybank from "./../../../assets/img/Maybank.png";

import { useQuery } from "@apollo/react-hooks";
import { hashValueRev } from '../../../components/quotation/hashFunc/HashFunc';
import { isString } from "formik";
const publicIp = require("react-public-ip");

function PlanPayment() {
  const navigate = useNavigate();
  const { handleTab } = useContext(TabContext);
  let userbookingValue;
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = React.useState(false);
  const [showbtn, setShowbtn] = React.useState(false);
  const [plan, setPlan] = React.useState({});
  const [brands, setBrands] = React.useState([]);
  const selPlanName = localStorage.getItem("selPlanName");
  const [payGatewayForm, setPayGatewayForm] = useState(null);
  const [payMethod, setPayMethod] = useState(null);

  const handleChecked = () => {
    setChecked(!checked);
  };

  const hashValue = function (s) {
    var chrsz = 8;
    var hexcase = 0;

    function safe_add(x, y) {
      var lsw = (x & 0xffff) + (y & 0xffff);
      var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
      return (msw << 16) | (lsw & 0xffff);
    }

    function S(X, n) {
      return (X >>> n) | (X << (32 - n));
    }
    function R(X, n) {
      return X >>> n;
    }
    function Ch(x, y, z) {
      return (x & y) ^ (~x & z);
    }
    function Maj(x, y, z) {
      return (x & y) ^ (x & z) ^ (y & z);
    }
    function Sigma0256(x) {
      return S(x, 2) ^ S(x, 13) ^ S(x, 22);
    }
    function Sigma1256(x) {
      return S(x, 6) ^ S(x, 11) ^ S(x, 25);
    }
    function Gamma0256(x) {
      return S(x, 7) ^ S(x, 18) ^ R(x, 3);
    }
    function Gamma1256(x) {
      return S(x, 17) ^ S(x, 19) ^ R(x, 10);
    }

    function core_sha256(m, l) {
      var K = new Array(
        0x428a2f98,
        0x71374491,
        0xb5c0fbcf,
        0xe9b5dba5,
        0x3956c25b,
        0x59f111f1,
        0x923f82a4,
        0xab1c5ed5,
        0xd807aa98,
        0x12835b01,
        0x243185be,
        0x550c7dc3,
        0x72be5d74,
        0x80deb1fe,
        0x9bdc06a7,
        0xc19bf174,
        0xe49b69c1,
        0xefbe4786,
        0xfc19dc6,
        0x240ca1cc,
        0x2de92c6f,
        0x4a7484aa,
        0x5cb0a9dc,
        0x76f988da,
        0x983e5152,
        0xa831c66d,
        0xb00327c8,
        0xbf597fc7,
        0xc6e00bf3,
        0xd5a79147,
        0x6ca6351,
        0x14292967,
        0x27b70a85,
        0x2e1b2138,
        0x4d2c6dfc,
        0x53380d13,
        0x650a7354,
        0x766a0abb,
        0x81c2c92e,
        0x92722c85,
        0xa2bfe8a1,
        0xa81a664b,
        0xc24b8b70,
        0xc76c51a3,
        0xd192e819,
        0xd6990624,
        0xf40e3585,
        0x106aa070,
        0x19a4c116,
        0x1e376c08,
        0x2748774c,
        0x34b0bcb5,
        0x391c0cb3,
        0x4ed8aa4a,
        0x5b9cca4f,
        0x682e6ff3,
        0x748f82ee,
        0x78a5636f,
        0x84c87814,
        0x8cc70208,
        0x90befffa,
        0xa4506ceb,
        0xbef9a3f7,
        0xc67178f2
      );
      var HASH = new Array(
        0x6a09e667,
        0xbb67ae85,
        0x3c6ef372,
        0xa54ff53a,
        0x510e527f,
        0x9b05688c,
        0x1f83d9ab,
        0x5be0cd19
      );
      var W = new Array(64);
      var a, b, c, d, e, f, g, h, i, j;
      var T1, T2;

      m[l >> 5] |= 0x80 << (24 - (l % 32));
      m[(((l + 64) >> 9) << 4) + 15] = l;

      for (var i = 0; i < m.length; i += 16) {
        a = HASH[0];
        b = HASH[1];
        c = HASH[2];
        d = HASH[3];
        e = HASH[4];
        f = HASH[5];
        g = HASH[6];
        h = HASH[7];

        for (var j = 0; j < 64; j++) {
          if (j < 16) W[j] = m[j + i];
          else
            W[j] = safe_add(
              safe_add(
                safe_add(Gamma1256(W[j - 2]), W[j - 7]),
                Gamma0256(W[j - 15])
              ),
              W[j - 16]
            );

          T1 = safe_add(
            safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]),
            W[j]
          );
          T2 = safe_add(Sigma0256(a), Maj(a, b, c));

          h = g;
          g = f;
          f = e;
          e = safe_add(d, T1);
          d = c;
          c = b;
          b = a;
          a = safe_add(T1, T2);
        }

        HASH[0] = safe_add(a, HASH[0]);
        HASH[1] = safe_add(b, HASH[1]);
        HASH[2] = safe_add(c, HASH[2]);
        HASH[3] = safe_add(d, HASH[3]);
        HASH[4] = safe_add(e, HASH[4]);
        HASH[5] = safe_add(f, HASH[5]);
        HASH[6] = safe_add(g, HASH[6]);
        HASH[7] = safe_add(h, HASH[7]);
      }
      return HASH;
    }

    function str2binb(str) {
      var bin = Array();
      var mask = (1 << chrsz) - 1;
      for (var i = 0; i < str.length * chrsz; i += chrsz) {
        bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
      }
      return bin;
    }

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    }

    function binb2hex(binarray) {
      var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
      var str = "";
      for (var i = 0; i < binarray.length * 4; i++) {
        str +=
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
      }
      return str;
    }

    s = Utf8Encode(s);
    return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
  };

  function handleSign() {
    const price = +localStorage.getItem("owner_price");
    localStorage.setItem("calculated_price", String(price.toFixed(2)));
    const price_str = localStorage.getItem("calculated_price");
    const editPrice = price_str.split('.').join("");
    const paymentId = localStorage.getItem("payment_id");
    const result = "D9ZO8VzbaR" + "M39459" + `${paymentId}` + `${editPrice}` + "MYR";
    // const result = "D9ZO8VzbaR" + "M39459" + `${paymentId}` + "100" + "MYR";
    hashValue(result);
    const signature = hashValue(result);
    localStorage.setItem("signature", signature);
  }

  const custIPAdd = async () => {
    const ipv4 = await publicIp.v4() || "";
    localStorage.setItem("customer_IP", ipv4);
  }

  function handleRevPaySign() {
    custIPAdd();
    const price = +localStorage.getItem("owner_price");
    localStorage.setItem("calculated_price", String(price.toFixed(2)));
    const price_str = localStorage.getItem("calculated_price");
    const referenceNumber = localStorage.getItem("payment_id");
    const result = "wF7jkXHBAR" + "MER00000128183" + `${referenceNumber}` + `${price_str}` + "MYR";
    const signature_rev = hashValueRev(result);
    localStorage.setItem("signature_rev", signature_rev);

  }

  const bookingId = localStorage.getItem("booking_id");
  // const { onSubmit, values } = useForm(handleSubmit, {
  //   bookingId: `${bookingId}`,
  // });

  const [addUser, { loading }] = useMutation(CREATE_PAYMENT, {
    update(_, { data: { createPayment: userData } }) {
      localStorage.setItem("payment_id", userData.id);
      handleSign();
      handleRevPaySign();
      // loadusers();
    },
    onError(err) {
      setErrors(err);
      toast(err.message);
    },
    variables: bookingId,
  });

  useEffect(() => {
    setChecked(true);
    setShowbtn(!showbtn);
  }, [])

  const userPay = () => {
    addUser({
      variables: {
        bookingId: bookingId,
      },
    });
  }

  const [creditVal, setCreditVal] = useState(null);
  const [creditAmt, setCreditAmt] = useState(null);

  const [addCredit] = useMutation(GETDEALERCURRENTCREDIT, {
    update(_, { data: { getDealerCurrentCredit: creditData } }) {
      if (creditData) {
        setCreditVal(creditData.amount);
      }
    },
  });

  const creditFunc = () => {
    addCredit({
      variables: {
        dealerId: `${localStorage.getItem("user_id")}`,
      },
    });
  }

  useEffect(() => {
    creditFunc();
  }, [])

  useEffect(() => {
    userPay();
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const setPlanLS = JSON.parse(localStorage.getItem('selPlan'));
      const brandsLS = JSON.parse(localStorage.getItem('brands'));
      if(setPlanLS)
        setPlan(setPlanLS);
      if(brandsLS)
      setBrands(brandsLS);
      console.log(localStorage.getItem('selPlan'));
    }, 500);
  }, []);

  useEffect(() => {
    console.log("plan", plan);
  }, [plan])
  
  
  

  const [addPrice] = useMutation(UPDATEBOOKINGPRICEBYUSINGCREDITPOINT, {
    update(_, { data: { updateBookingPriceByUsingCreditPoint: priceData } }) {
      if (priceData.price == '0') {
        successFunc();
        // navigate("/checkout");
        setCreditAmt(priceData.price);
      } else {
        toast("Insufficient Credit")
      }
    },
  });

  const [successPoint] = useMutation(HANDLEBOOKINGSUCCESSAFTERCREDIT, {
    update(_, { data: { handleBookingSuccessAfterCredit: successData } }) {
      if (successData.error !== 'true') {
        navigate("/checkout");
      } else {
        toast("Something went wrong")
      }
    },
  });

  const priceUpdate = () => {
    addPrice({
      variables: {
        bookingId: `${localStorage.getItem("booking_id")}`,
      },
    });
  }

  const successFunc = () => {
    successPoint({
      variables: {
        bookingId: `${localStorage.getItem("booking_id")}`,
      },
    });
  }

  // useEffect(()=>{
  //   priceUpdate();
  // },[])
 
  return (
    <>
      <ToastContainer />
      <div className="payment-box">
        <div className="authentication-inputs">

          {/* <Row className="align-items-center">
            <Col md={8} className="mb-3">
              <strong>Get covered by AUTOGEN+ for only</strong>
              <div className="section-title d-flex align-items-end">
                <h2 className="mb-0">
                  RM <span>{localStorage.getItem("owner_price")}</span>
                </h2>
                <h5>/YEAR</h5>
              </div>
            </Col>
          </Row> */}

          <div className="car-card">
            <div className="d-flex">
              <div className="checkbox">
              <Form.Check type="radio" name="selectedPlan" defaultChecked/>
              </div>
              <div className="continental-car">
                <h3>{plan?.EWPCategory}</h3>
                <span>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props} className="tooltip">
                        <strong><u>Key Car Elegibility:</u></strong>
                        <p><b>Accepted brands: </b>{brands?.map((brand, ind)=>(<>{`${ind>0?', ':''}${brand?.brand}`}</>))}</p>
                        <p><b>Car Age: </b>{plan?.CarAge.indexOf('up to')!==-1?plan?.CarAge:`from ${plan?.CarAge}`} old</p>
                        <p><b>Odometer Reading: </b>Less than 200,000KM</p>
                      </Tooltip>
                    )}
                    placement="top-end"
                  ><i className="fa fa-circle-info"></i>
                  </OverlayTrigger></span>
              </div>
            </div>
            <div className="car-details">
              <ul>
                <li><b>Key In-Scope Components: </b>{plan?.CoveredComponents}</li>
                <li><b>Total Number of In-Scope Components:   </b>{plan?.NoOfCoveredComponents};</li>
                <li><b> Underwritten by:  </b>Berjaya Sompo Insurance</li>
                <li><b> Coverage Period:  </b>1 Year or 30,000KM from the activation date; </li>
                <li><b> Max. Coverage Per Visit:  </b>RM {parseFloat(plan?.MaxPerVisit).toLocaleString(undefined, {maximumFractionDigits:2})}; </li>
                <li><b> Max. Coverage Per Year: </b> RM {parseFloat(plan?.MaxPerYear).toLocaleString(undefined, {maximumFractionDigits:2})} </li>
              </ul>
            </div>
            <div className="car-price">
              <h3><b>RM {parseFloat(plan?.DealersRate).toLocaleString(undefined, {maximumFractionDigits:2})}</b> <span>/Per Year Per Car</span></h3>
            </div>
          </div>

          {
            showbtn === true && (
              <div className="d-flex justify-content-between  mt-1">
                {
                  localStorage.getItem("owner_price") != '0.00' ?
                    <div>
                      <h1 >Payment</h1>
                      <p className="mb-3">Choose your payment method</p>
                      <Row>
                        <Col md={12} className="d-flex m-auto pay-block">
                          <Form.Check.Input
                            type="radio"
                            required="required"
                            name="payOption"
                            value="iPay88"
                            style={{ marginTop: "8px" }}
                            checked={payGatewayForm === 'iPay88'}
                            onChange={(e) => setPayGatewayForm(e.currentTarget.value)}
                          />
                          <Col md={3} className="d-flex justify-content-end">
                            <Image src={ipay} className="ipay" />
                          </Col>
                          <Col md={2} className="d-flex justify-content-end">
                            <span >Ipay88</span>
                          </Col>
                        </Col>
                        <Col md={12} className="d-flex m-auto pay-block">
                          {" "}
                          <Form.Check.Input
                            type="radio"
                            required="required"
                            name="payOption"
                            value="revPay"
                            checked={payGatewayForm === 'revPay'}
                            style={{ marginTop: "8px" }}
                            onChange={(e) => setPayGatewayForm(e.currentTarget.value)}
                          />
                          <Col md={3} className="d-flex justify-content-end">
                            <Image src={reyPay} width="105px" height="35px" className="revPay" />
                          </Col>
                          <Col md={2} className="d-flex justify-content-end">
                            <span >revPAY</span>
                          </Col>
                        </Col>

                        {
                          localStorage.getItem("role") === "dealer" ? <Col md={12} className="d-flex m-auto pay-block">
                            {" "}
                            <Form.Check.Input
                              type="radio"
                              required="required"
                              name="payOption"
                              value="creditPoint"
                              checked={payGatewayForm === 'creditPoint'}
                              style={{ marginTop: "8px" }}
                              onChange={(e) => setPayGatewayForm(e.currentTarget.value)}
                            />
                            <Col md={3} className="d-flex justify-content-end">
                              <Image src={credit} width="105px" height="35px" className="revPay" />
                            </Col>
                            <Col md={4} className="d-flex justify-content-end">
                              <span style={{ marginLeft: "20px" }}>Credit:</span><span style={{ marginLeft: "20px" }}>RM {" "}{creditVal}</span>
                            </Col>
                          </Col> : ""
                        }

                    { (payGatewayForm == "revPay" || payGatewayForm == "creditPoint") ? "" : (
                        <Col className="d-flex justify-content-between  mt-5">
                          <Link to="/buyplan" onClick={() => handleTab("PlanQuotation")} className="btn btn-outline-secondary btn-md">
                            {" "}
                            Back
                          </Link>
                        </Col>
                      ) 
                    }

                      </Row>
                    </div> : <div>
                      <Row>
                        <Col className="d-flex justify-content-between  mt-5">
                          <Link to="/buyplan" onClick={() => handleTab("PlanQuotation")} className="btn btn-outline-secondary btn-md">
                            {" "}
                            Back
                          </Link>
                        </Col>
                        <Col className="d-flex justify-content-between  mt-5">
                          <form
                                method="post"
                                name="ePayment"
                                action="https://uat.eautogen.my/payment/promocode"
                              >
                                <input
                                  type="hidden"
                                  name="booking_id"
                                  value={localStorage.getItem("booking_id")}
                                />
                                <input
                                    type="hidden"
                                    name="ResponseURL"
                                    value="https://uat.eautogen.my/payment/response"
                                    // value="https://www.eautogen.my/payment/response"
                                  />
                                  <input
                                    type="hidden"
                                    name="BackendURL"
                                    value="https://uat.eautogen.my/payment/backend"
                                    // value="https://www.eautogen.my/payment/backend"
                                  />
                                  <input
                                    type="submit"
                                    value="PROCEED"
                                    name="Submit"
                                    className="submit-btn"
                                    style={{
                                      backgroundColor: "#17BEBB",
                                      border: "none",
                                      color: "white",
                                      padding: "10px 20px",
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </form>
                          {/* <button onClick={handlePay} className="btn btn-outline-secondary btn-md">
                            {" "}
                            Proceed
                          </button> */}
                        </Col>
                      </Row>
                    </div>
                }
              </div>
            )
          }

          {payGatewayForm == "creditPoint" && (
            <div className="d-flex justify-content-between mt-5 flex-wrap col-md-12">
              <div className="section-title col-md-12">
                <h2 className="mb-0">
                  RM <span>{localStorage.getItem("owner_price")}</span>
                </h2>
                <span>/YEAR</span>
                <h5>Available credit : RM {" "}{creditVal}</h5>

                <div className="d-flex justify-content-between mt-5 col-md-12">
                  <Col md={6} className="mt-5" style={{ textAlign: "start"}}>
                    <Link to="/buyplan" onClick={() => handleTab("PlanQuotation")} className="btn btn-outline-secondary btn-md">
                      {" "}
                      Back
                    </Link>
                  </Col>
                  <Col md={6} className="mt-5" style={{ textAlign: "end"}}>
                    <input
                      type="submit"
                      value="PAY NOW"
                      name="Submit"
                      onClick={priceUpdate}
                      style={{
                        backgroundColor: "#17BEBB",
                        border: "none",
                        color: "white",
                        padding: "10px 20px",
                        fontSize: "16px",
                        cursor: "pointer",
                        borderRadius: "5px"
                      }}
                    />
                  </Col>
                </div>
              </div>
            </div>
          )
          }

          {payGatewayForm == "iPay88" && (
            <div className="d-flex justify-content-end flex-wrap col-md-12" style={{marginTop: "-40px"}}>
              <form
                method="post"
                name="ePayment"
                action="https://payment.ipay88.com.my/ePayment/entry.asp"
              >
                <input type="hidden" name="MerchantCode" value="M39459" />
                <input type="hidden" name="PaymentId" value="" />
                <input
                  type="hidden"
                  name="RefNo"
                  value={localStorage.getItem("payment_id")}
                />
                <input type="hidden" name="Amount" value={localStorage.getItem("calculated_price")} />
                <input type="hidden" name="Currency" value="MYR" />
                <input type="hidden" name="ProdDesc" value={selPlanName} />
                <input
                  type="hidden"
                  name="UserName"
                  value={localStorage.getItem("owner_name")}
                />
                <input
                  type="hidden"
                  name="UserEmail"
                  value={localStorage.getItem("owner_email")}
                />
                <input
                  type="hidden"
                  name="UserContact"
                  value={localStorage.getItem("owner_mobile")}
                />
                <input type="hidden" name="Remark" value="" />
                <input type="hidden" name="Lang" value="UTF-8" />
                <input type="hidden" name="SignatureType" value="SHA256" />
                <input
                  type="hidden"
                  name="Signature"
                  value={localStorage.getItem("signature")}
                />
                <input
                  type="hidden"
                  name="ResponseURL"
                  value="https://uat.eautogen.my/payment/response"
                  // value="https://www.eautogen.my/payment/response"
                />
                <input
                  type="hidden"
                  name="BackendURL"
                  value="https://uat.eautogen.my/payment/backend"
                  // value="https://www.eautogen.my/payment/backend"
                />
                <input
                  type="submit"
                  value="PAY NOW"
                  name="Submit"
                  className="submit-btn"
                  style={{
                    backgroundColor: "#17BEBB",
                    border: "none",
                    color: "white",
                    padding: "10px 20px",
                    fontSize: "16px",
                    cursor: "pointer",
                    borderRadius: "5px",
                  }}
                />
              </form>
            </div>
          )}

          {payGatewayForm === "revPay" && (
            <div className="d-flex justify-content-between mt-5 flex-wrap col-md-12 ">
              <div className="payment_box payment_method_revpay col-md-12">
                <p>Pay with revPAY </p>
                <div className="form-row form-row-wide">
                  <label>Select yout payment method<span className="required">*</span></label><br />
                  <div className="pay-method form-check justify-content-left">

                    <div className="method-wrap">
                      <Form.Check.Input
                        type="radio"
                        required="required"
                        name="payMethod"
                        value="2"
                        checked={payMethod == '2'}
                        onChange={(e) => setPayMethod(e.currentTarget.value)}
                      /> Visa / Mastercard <img src={visa} width="142px" height="36px" />
                    </div>

                    <div className="method-wrap">
                      <Form.Check.Input
                        type="radio"
                        required="required"
                        name="payMethod"
                        value="3"
                        checked={payMethod == '3'}
                        onChange={(e) => setPayMethod(e.currentTarget.value)}
                      /> FPX<img src={fpx} width="97px" height="33px" />
                    </div>

                    <div className="method-wrap">
                      <Form.Check.Input
                        type="radio"
                        required="required"
                        name="payMethod"
                        value="28"
                        checked={payMethod == '28'}
                        onChange={(e) => setPayMethod(e.currentTarget.value)}
                      /> Touch n Go<img src={touchngo} width="141px" height="46px" />
                    </div>

                    <div className="method-wrap">
                      <Form.Check.Input
                        type="radio"
                        required="required"
                        name="payMethod"
                        value="11"
                        checked={payMethod == '11'}
                        onChange={(e) => setPayMethod(e.currentTarget.value)}
                      /> Boost<img src={boost} width="110px" height="45px" />
                    </div>

                    <div className="method-wrap">
                      <Form.Check.Input
                        type="radio"
                        required="required"
                        name="payMethod"
                        value="12"
                        checked={payMethod == '12'}
                        onChange={(e) => setPayMethod(e.currentTarget.value)}
                      /> Maybank QR<img src={maybank} width="45px" height="45px" />
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end mt-5">
                <form
                  method="post"
                  name="ePayment"
                  action="https://gateway.revpay.com.my/payment"
                >
                  <input type="hidden" name="Revpay_Merchant_ID" value="MER00000128183" />
                  <input type="hidden" name="Payment_ID" value={payMethod} />
                  <input type="hidden" name="Bank_Code" value="" />
                  <input type="hidden" name="Reference_Number" value={localStorage.getItem("payment_id")} />
                  <input type="hidden" name="Amount" value={localStorage.getItem("calculated_price")} />
                  <input type="hidden" name="Currency" value="MYR" />

                  <input type="hidden"
                    name="Transaction_Description"
                    value={selPlanName}
                  />

                  <input type="hidden" name="Billing_Address" value="" />
                  <input type="hidden" name="Shipping_Address" value="" />
                  <input type="hidden" name="Device_ID" value="" />
                  <input type="hidden" name="Ecomm_Marketplace" value="" />
                  <input type="hidden" name="Promo_Code" value="" />
                  <input type="hidden" name="Transaction_Type" value="" />
                  <input type="hidden" name="Customer_ID" value="" />
                  <input type="hidden" name="Merchant_key" value="wF7jkXHBAR" />
                  <input
                    type="hidden"
                    name="UserName"
                    value={localStorage.getItem("owner_name")}
                  />
                  <input
                    type="hidden"
                    name="UserEmail"
                    value={localStorage.getItem("owner_email")}
                  />
                  <input
                    type="hidden"
                    name="UserContact"
                    value={localStorage.getItem("owner_mobile")}  //customer contact number
                  />
                  <input type="hidden" name="customer_IP" value={localStorage.getItem("customer_IP")} />
                  <input type="hidden" name="Card_Type" value="" />
                  <input type="hidden" name="Card_Holder_Name" value="" />
                  <input type="hidden" name="Funding_Pan" value="" />
                  <input type="hidden" name="Funding_Exp_Date" value="" />
                  <input type="hidden" name="Funding_CVV" value="" />
                  <input type="hidden" name="Card_Issuer_Bank_Country_Code" value="" />
                  <input type="hidden" name="Instalment_Plan" value="" />
                  <input type="hidden" name="Instalment_Term" value="" />
                  <input type="hidden" name="Token_Pan" value="" />
                  <input type="hidden" name="Token_Exp_Date" value="" />
                  <input type="hidden" name="Key_Index" value="1" />
                  <input type="hidden" name="SignatureType" value="SHA512" />
                  <input
                    type="hidden"
                    name="Signature"
                    value={localStorage.getItem("signature_rev")}
                  />
                  <input
                    type="hidden"
                    name="Return_URL"
                    value="https://uat.eautogen.my/payment/response"
                    // value="https://www.eautogen.my/payment/response"    
                  />
                  <input
                    type="hidden"
                    name="Backend_URL"
                    // value="https://www.eautogen.my/payment/backend"
                    value="https://uat.eautogen.my/payment/backend"
                  />
                  
                  <input
                    type="submit"
                    value="PAY NOW"
                    name="Submit"
                    className="submit-btn"
                    style={{
                      backgroundColor: "#17BEBB",
                      border: "none",
                      color: "white",
                      padding: "10px 20px",
                      fontSize: "16px",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                  />
                </form>
              </div> 
              <Row style={{ marginTop: "-40px"}}>             
                <Col className="d-flex justify-content-between">
                  <Link to="/buyplan" onClick={() => handleTab("PlanQuotation")} className="btn btn-outline-secondary btn-md">
                    {" "}
                    Back
                  </Link>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PlanPayment;
