import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
// import Carousel from "react-bootstrap/Carousel";
import Carousel from 'react-grid-carousel'
import Brand1 from "../../../assets/img/1.png";
import Brand2 from "../../../assets/img/2.png";
import Brand3 from "../../../assets/img/3.png";
import Brand4 from "../../../assets/img/4.png";
import "./BrandSlider.css";
export const BrandSlider = () => {
  return (
    <div className="py-5 " >
      <Container className="brandslider_div">
        <Row  className="justify-content-center "  >
          <Col lg={6} className="section-title text-lg-start">
            <p>AUTOGEN PLUS PROGRAM ELIGIBILITY</p>
            <Col md={10}>
            <h2><span>AutoGen PLUS Extended Warranty Program</span></h2>
            </Col>
          </Col>
          <Col lg={6} className="brandslider_right" >
          <p>
              Have peace of mind as AutoGen PLUS cushions you from expensive
              repair cost of engine and transmission breakdown. <br/>
              <span className="program_eligibilty">Program Eligibility:</span>
            </p>
          
          <ul>
            <li>
             <p><strong>• Private use cars (excluding e-hailing), </strong>and</p>
            </li>
            <li>
            <p>  <strong>• Car age: up to 10 years</strong> (from date of registration), and</p>
            </li>
            <li>
            <p><strong>• Odometer reading: less than 200,000 kilometers.</strong></p>
            </li>
            
          </ul>
          <p>Multiple car brands and models accepted; choose from 4 main categories: </p>
         
          </Col>
        </Row>
        <Container >
        <Row width="100%" className="brandcarausal">
         

          <Carousel cols={1} rows={1}  showDots={true} loop>
      <Carousel.Item className="justify-content-center">
      <Col md={12} className="justify-content-center">
        <Image src={Brand1}  fluid width="100%"/>
      </Col>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={Brand2} fluid width="100%" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={Brand3} fluid width="100%"/>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={Brand4} fluid width="100%"/>
      </Carousel.Item>
      </Carousel>

          {/* <Carousel>
            <Carousel.Item>
              <Image src={Brand1} fluid />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={Brand2} fluid />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={Brand3} fluid />
            </Carousel.Item>
            <Carousel.Item>
              <Image src={Brand4} fluid />
            </Carousel.Item>
          </Carousel> */}


          <p>
            NON-AFFILIATION DISCLAIMER: Logos used are for illustration purposes
            only. We are not affiliated, associated, authorized, endorsed by, or
            in any way officially connected with or any of its subsidiaries or
            its affiliates.
          </p>
        
          <Col md={8} className="d-flex m-auto">
          
              <a
                className=" text-center m-auto"
                style={{ textDecoration: "none" }}
                href="https://pgmall.my/s/IMA2/3033"
              >
                <Button className="getstarted" variant="secondary">Buy Now Exclusively On PGMall</Button>
              </a>
            </Col>
        </Row>
      </Container>
      </Container>
    </div>
  );
};
