import React from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import autohgenplus from "../../../assets/img/autohgenplus.png";
import autogenplus_logo from "../../../assets/img/autogenplus_logo.png";
import car_inspection_icon from "../../../assets/img/car_inspection_icon.png";
import arrow from "../../../assets/img/arrow.svg";
import Letter from "../../../assets/img/mailbox.png";
import "../../../assets/css/style.css";
function HowItWorks() {
  return (
    <>
      <section className="py-5 how_work"  style={{marginTop:"150px"}}>
        <Container>
          <Row style={{ marginBottom: "100px" }}>
            <Col md={12}>
              <div className="section-title">
                <span>BAGAIMANA IA BERFUNGSI
</span>
                <h2>
                  <span>Permulaan Yang Mudah</span>{" "}
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={3}>
              <Image src={autohgenplus} fluid className="hiw_img" />
              <strong className="pb-2 d-flex">
                Beritahu Kami Tentang Diri Dan Kereta Anda
              </strong>
              <p className="pb-2">
                Lengkapkan borang dengan butiran-butiran dan dapatkan sebut
                harga segera untuk program AutoGen PLUS anda
              </p>
            </Col>
            <Col md={1}>
              <Image src={arrow} className="arrow_img" />
            </Col>
            <Col md={3}>
              <Image src={Letter} fluid className="hiw_img" />
              <strong className="pb-2 d-flex">Terima Kod Pesanan</strong>
              <p className="pb-2">
                Selepas pembayaran berjaya, satu Kod Pesanan yang mempunyai
                tempoh sah selama 90 hari akan dihantar kepada e-mel berdaftar
                anda
              </p>
            </Col>
            <Col md={1}>
              <Image src={arrow} className="arrow_img" />
            </Col>
            <Col md={3}>
              <Image src={car_inspection_icon} className="hiw_img" fluid />
              <strong className="pb-2  d-flex">Pengaktifan Program</strong>
              <p className="pb-2">
                Sila kunjungi rakan kongsi sah kami dengan Kod Pesanan untuk
                pemeriksaan kereta anda untuk pengaktifan program AutoGen PLUS
              </p>
            </Col>
            <Col md={8} className="d-flex m-auto">
              {/* <Button variant="primary" className=" text-center m-auto">GET QUOTE</Button> */}
              <a
                className=" text-center m-auto"
                style={{ textDecoration: "none" }}
                onClick={() => window.scrollTo(0, window.innerHeight)}
              >
                <Button variant="secondary" className="gethassle">
                DAPATKAN SEBUT HARGA
                </Button>
              </a>
            </Col>
            <Col md={12} className="pt-2">
              <p style={{ color: "#777777" }}>*Tertakluk kepada Terma Dan Syarat </p>
              <p style={{ color: "#777777" }}>
              Untuk semua komunikasi melalui e-mel, sila semak peti masuk, kotak spam atau peti pembuangan e-mel anda
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export { HowItWorks };
