import React from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import "./LandingFaq.css";
import { useNavigate } from "react-router-dom";
export const LandingFaqSection = () => {
  const navigate = useNavigate();
  return (
    <div className="py-5">
      <Container style={{ width: "80%" }}>
        <Row className="justify-content-center">
          <Col
            md={9}
            style={{ marginBottom: "28px" }}
            className="section-title"
          >
            <h2>
              <span>Frequently Asked Questions</span>{" "}
            </h2>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How do I know if my car is eligible for AutoGen PLUS?
                </Accordion.Header>
                <Accordion.Body>
                  Your car must meet the following criterias: <br />
                  Car Type: Private Use Car (excluding Commercial Use Car and
                  e-Hailing), including Reconditioned Car/Used Car/Existing on
                  the road car
                  <br />
                  Car Age: up to 10 years old (from date of registration), and{" "}
                  <br />
                  Odometer Reading: less than 200,000{" "}
                  <span style={{ color: "" }}>kilometer</span> mileage
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Is Car Inspection compulsory prior to plan activation?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, your Car is required to undergo a car inspection at any
                  of our{" "}
                  <a style={{ textDecoration: "underline" }}>
                    authorized partners
                  </a>{" "}
                  prior to plan activation. Please visit the one nearest you for
                  a short diagnosis test results within 90 days the Order Code
                  is sent to you after purchase.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What if I am unable to send my car for inspection at any of
                  your authorized partners listed?
                </Accordion.Header>
                <Accordion.Body>
                  Please call our Help Desk at 03-7931 3663 during office hour
                  or WhatsApp at the same number to arrange for a mobile
                  inspection (limited areas).
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What are the warranty components of AutoGen PLUS?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  AutoGen PLUS covers 2 key components of a car - the engine and
                  transmission.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What is the program coverage period of AutoGen PLUS?
                </Accordion.Header>
                <Accordion.Body>
                  1 calendar year or 30,000 kilometers mileage, whichever comes
                  first.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={12}>
            <Accordion className="accordance">
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What is the repair limit of AutoGen PLUS?
                </Accordion.Header>
                <Accordion.Body>
                  AutoGen PLUS provides repair limit up to RM20,000 per annum
                  and RM10,000 per repair depending on your program plan.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What is the maximum number of repairs per annum?
                </Accordion.Header>
                <Accordion.Body>
                  There is no limit on the number of repairs as long as it is
                  within your repair value per annum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What is the program cooling-off period?
                </Accordion.Header>
                <Accordion.Body>
                  The cooling-off period is the first 7 days or the first 300 kilometers from program activation date before
                  car repair works can commence. 
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Do I have to perform the program maintainence as required?
                </Accordion.Header>
                <Accordion.Body>
                  "To keep your AutoGen PLUS active, you must perform periodic
                  maintenance according to the Interval Service Maintenance
                  Schedule per manufacturer's recommendation at any eAutoGen's
                  panel workshop, at your own expense below: - For cars using
                  semi-synthetic lubricant: 3 months (or 5,000 kilometers,
                  whichever earlier) - For cars using fully-synthetic lubricant:
                  6 months (or 10,000 kilometers, whichever earlier) See all
                  eAutoGen's panel workshop here."
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Can I go to a non-panel workshop for program maintenance ?
                </Accordion.Header>
                <Accordion.Body>
                  "You are required to send your car to only eAutoGen's panel
                  workshops for periodic maintenance. Please retain copies of
                  all maintenance receipts (computer generated document with
                  workshop stamp) during the term of the AutoGen PLUS and
                  submission of these maintenance receipts may be requested
                  during repair claim. See all eAutoGen's panel workshop here."
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How do I know if my car problem is within the scope of AutoGen
                  PLUS?
                </Accordion.Header>
                <Accordion.Body>
                  You can take your car to any eAutoGen’s panel workshops for
                  assessment. See all eAutoGen's panel workshop here.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What kind of replacement part(s) will be provided in the event
                  of engine and transmission repair?
                </Accordion.Header>
                <Accordion.Body>
                  Damaged components under AutoGen PLUS will be replaced with
                  used, reconditioned or OEM parts under the discretion of
                  eAutoGen.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Do I need to pay upfront for the repair?
                </Accordion.Header>
                <Accordion.Body>
                  No upfront payment is required. Kindly ensure you only go to
                  eAutoGen's panel workshop for the repair. See all eAutoGen's
                  panel workshop here.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What happens if repair works involve AutoGen PLUS warranty out
                  of scope components ?
                </Accordion.Header>
                <Accordion.Body>
                  Should there be any repairs beyond AutoGen PLUS coverage and
                  repair limit, you are required to pay the workshop directly.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  In the event of car accident, will I be entitled to repair the
                  damaged parts (car engine and transmission) under AutoGen
                  PLUS?
                </Accordion.Header>
                <Accordion.Body>
                  In such situation, you should reach out to your car insurance
                  company. AutoGen PLUS program cushions you from repair cost
                  arising from unexpected mechanical breakdown of the warranty
                  components.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Is AutoGen Plus cancellable or refundable ?
                </Accordion.Header>
                <Accordion.Body>
                  AutoGen PLUS is non-cancellable and non-refundable. By
                  purchasing AutoGen PLUS, it is deemed that you have read and
                  agreed to the Terms and Conditions stipulated and will abide
                  by it.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Is AutoGen PLUS renewable ?</Accordion.Header>
                <Accordion.Body>
                  AutoGen PLUS is renewable at every 1 calendar period or 30,000
                  kilometers with preliminary car inspection and subject to no
                  claims reported in prior year.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Is AutoGen PLUS transferable?
                </Accordion.Header>
                <Accordion.Body>
                  AutoGen PLUS is non-transferable by car owner. However, should the car change ownership during
                  program period, please contact us for car ownership update for continuity of program.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="accordance">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Who should I contact for enquiries on AutoGen PLUS ?
                </Accordion.Header>
                <Accordion.Body>
                  For any enquiries, our Help Desk is available at +603-7931
                  3663 every Mondays to Fridays from 9:00 am to 6:00 pm.
                  Alternatively, you can email us at cs@eAutoGen.my
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


          </Col>
        </Row>
      </Container>
    </div>
  );
};
