import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Affordable from "./../../assets/img/engine.png";
import Workshops from "./../../assets/img/gearbox.png";
import high_claim_value_white from "./../../assets/img/coverage.png";
import hassle_free_process_white from "./../../assets/img/anuam_covreage.png";
import lady_in_car from "./../../assets/img/car_logo.png";
import Dropdown from "react-bootstrap/Dropdown";
function WhatIncluded() {
  return (
    <div>
      {
        localStorage.getItem("language") === "true" ?
        <section className="autogen whatincluded py-5">
      <Container>
        <Row>
          <Col md={12}>
           
          </Col>
        </Row>
        <Row>
          <Col md={6}>
          <div className="include_auto">
          <strong style={{color:"#777777",fontSize:"16px",fontWeight:"normal"}} className="pb-3 d-flex m-auto">

          KOMPONEN-KOMPONEN IN SKOP: {" "}

            </strong>

            <div className="icon-box">
              <Image src={Affordable} className="image_content"/>
              <div className="icon-content">
                {/* <strong className="pb-2 d-flex">Engine</strong>
                <p>terms and conditions about engine here</p> */}
                <Row className="enginedrop">
              <Col md={12}>

                <h3>Enjin Kereta</h3>
                
                <Dropdown style={{ marginTop: "10px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                  21x Bahagian Kereta Enjin  <br/> Yang Dilindungi

                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                    Blok Enjin{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                    Kepala Silinder
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Crankshaft</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Eccentric Shaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Connecting Rod
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Piston</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Piston Ring</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Variable Valve Timing
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Camshaft Adjuster Solenoid
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Balancer Shaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Intake Valve
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Exhaust Valve
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Intake Camshaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Exhaust Camshaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Valvetronic Motor
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Oil Pump</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Emission Control Air Pump
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Air Mass Sensor (Air Flow Sensor) &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp;
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Engine Vacuum Pump
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Crankshaft</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Position Sensor
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
             
            </Row>
              </div>
            </div>
            <div className="icon-box">
              <Image src={Workshops} className="image_content"/>
              <div className="icon-content">
               <Row className="enginedrop" >
              <Col md={12}>

                <h3>Kereta Transmisi (a.k.a Kotak Gear)</h3>
                
                <Dropdown style={{ marginTop: "10px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                  9x Bahagian Kereta <br/>
                  Transmisi Yang Dilindungi

                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                    (Automatik atau Manual) Penderia Suhu Klac &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Modul Kawalan Penghantaran TCM
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Pam Minyak Penghantaran
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Semua Jenis Injap Solenoid
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Penukar Tork
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Penderia Kelajuan Penghantaran
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Aci Gear</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                    Peralihan Gear/ Modul Pemilih
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Badan Injap</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              </Row>

              </div>
            </div>
            <strong style={{color:"#777777",fontSize:"16px",fontWeight:"normal"}} className="pb-3 d-flex">NILAI PEMBAIKAN KOMPONEN-KOMPONEN IN-SKOP: </strong>
            <div className="icon-box">
              <Image src={high_claim_value_white} className="image_content"/>
              <div className="icon-content">
                <strong className="pb-2">
                Sehingga RM &nbsp;
              
              {
                localStorage.getItem("owner_price") === "298" && 5000
              }
              {
                localStorage.getItem("owner_price") === "528" && 7500
              }
                {
                localStorage.getItem("owner_price") === "1088" && 10000
                }
                {
                localStorage.getItem("owner_price") === "1688" && 10000
                }
                &nbsp;
                  liputan <span>setiap lawatan</span>
                </strong>
              </div>
            </div>
            <div className="icon-box">
              <Image src={hassle_free_process_white} className="image_content"/>
              <div className="icon-content">
                <strong className="pb-2">
                ehingga RM 
                &nbsp;
                
                {
                localStorage.getItem("owner_price") === "298" && 7500
              }
              {
                localStorage.getItem("owner_price") === "528" && 10000
              }
                {
                localStorage.getItem("owner_price") === "1088" && 15000
                }
                {
                localStorage.getItem("owner_price") === "1688" && 20000
                }
                &nbsp;
                liputan  <span>setiap tahun </span>
                </strong>
              </div>
            </div>
          
          </div>
          </Col>
          <Image src={lady_in_car} className="section-img" />
          <Col md={8} className="d-flex m-auto">
            <a
              className=" text-center m-auto"
              style={{ textDecoration: "none" }}
              href=""
            >
           
            </a>
          </Col>
        </Row>
      </Container>
    </section>
    :
    <section className="autogen whatincluded py-5">
      <Container>
        <Row>
          <Col md={12}>
           
          </Col>
        </Row>
        <Row>
          <Col md={6}>
          <div className="include_auto">
          <strong style={{color:"#777777",fontSize:"16px",fontWeight:"normal"}} className="pb-3 d-flex m-auto">
          IN-SCOPE COMPONENTS: {" "}
            </strong>

            <div className="icon-box">
            <Image src={Affordable} className="image_content"/>
              <div className="icon-content">
             
                {/* <strong className="pb-2 d-flex">Engine</strong>
                <p>terms and conditions about engine here</p> */}
                <Row className="enginedrop">
               
              <Col md={12}>
                <h3>Car Engine</h3>
                
                <Dropdown style={{ marginTop: "10px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    21x Car Engine Covered Parts
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Engine Block{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Cylinder Head
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Crankshaft</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Eccentric Shaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Connecting Rod
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Piston</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Piston Ring</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Variable Valve Timing
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Camshaft Adjuster Solenoid
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Balancer Shaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Intake Valve
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Exhaust Valve
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Intake Camshaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Exhaust Camshaft
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Valvetronic Motor
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Oil Pump</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Emission Control Air Pump
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Air Mass Sensor (Air Flow Sensor) 
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Engine Vacuum Pump
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Crankshaft</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Position Sensor
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
             
            </Row>
              </div>
            </div>
            <div className="icon-box">
              <Image src={Workshops} className="image_content"/>
              <div className="icon-content">
               <Row className="enginedrop">
              <Col md={12}>
                <h3>Car Transmission</h3>
                
                <Dropdown style={{ marginTop: "10px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    9x Car Transmission Covered Parts
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      (Automatic or Manual) Clutch Temperature Sensor 
                     
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Transmission Control Module TCM
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Transmission Oil Pump
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      All Type of Solenoid Valve
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Torque Converter
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Transmission Speed Sensor
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Gear Shaft</Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      Gear Shifting / Selector Module
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">Valve Body</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              </Row>
              </div>
            </div>

            <strong style={{color:"#777777",fontSize:"16px",fontWeight:"normal"}} className="pb-3 d-flex">REPAIR VALUE ON IN-SCOPE COMPONENTS: </strong>
            <div className="icon-box">
              <Image src={high_claim_value_white} className="image_content"/>
              <div className="icon-content">
                <strong className="pb-2">
                  Up to RM &nbsp;
                   
              {
                localStorage.getItem("owner_price") === "298" && 5000
              }
              {
                localStorage.getItem("owner_price") === "528" && 7500
              }
                {
                localStorage.getItem("owner_price") === "1088" && 10000
                }
                {
                localStorage.getItem("owner_price") === "1688" && 10000
                }
                &nbsp;  coverage <span>per visit</span>
                </strong>
              </div>
            </div>
            <div className="icon-box">
              <Image src={hassle_free_process_white} className="image_content"/>
              <div className="icon-content">
                <strong className="pb-2">
                  Up to RM  &nbsp;
                    
                  {
                localStorage.getItem("owner_price") === "298" && 7500
              }
              {
                localStorage.getItem("owner_price") === "528" && 10000
              }
                {
                localStorage.getItem("owner_price") === "1088" && 15000
                }
                {
                localStorage.getItem("owner_price") === "1688" && 20000
                }
                &nbsp;  coverage <span>per annum</span>
                </strong>
              </div>
            </div>
          
          </div>
          </Col>
          <Image src={lady_in_car} className="section-img" />
          <Col md={8} className="d-flex m-auto">
            <a
              className=" text-center m-auto"
              style={{ textDecoration: "none" }}
              href=""
            >
           
            </a>
          </Col>
        </Row>
      </Container>
    </section>

      }
    </div>
  
  );
}

export default WhatIncluded;
