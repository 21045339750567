import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

export const AboutSectionBM1 = () => {
  return (
    <div>
    <section>
        <Container>
            <Row className=' mt-5 pb-5'>
                <Col md={4} >
                    <h2 className='section_title'>Dengan kami,<br />setiap idea<br /> bermula dengan<br /> <span> bagaimana kalau</span>.</h2>
                </Col>
                <Col md={8} className='section_prg'>
                    <Row>
                        <p>"Bagaimana kalau pemilikan kereta adalah berpatutan dan mampan?"</p>
                    </Row>
                    <Row>
                        <p>"Bagaimana kalau pembaikan kereta tidak akan menjadikan dompet kami kosong?"</p>
                    </Row>
                    <Row>
                        <p >"Bagaimana kalau kami boleh mencipta pengalaman nilai tambah bagi pembelian kereta?"</p>
                    </Row>
                    <Row className='mt-3'>
                        <p >Pembilian kereta adalah satu pembelian yang besar bagi seseorang dalam seumur hidup. Walaupun bayaran balik pinjaman dan jangka hayat kereta yang panjang, industri automotif tradisional meletakkan nilai yang tinggi hanya di peringkat awal kitaran hidup sebuah kereta sahaja</p>
                    </Row>
                    <Row className='mt-3'>
                        <p>Kereta-kereta baru biasanya hanya mempunyai waranti terhad yang menjamin kebolehan, prestasi dan qualiti satu produk. Selepas warranty terhad, pemilik kereta dan pembeli kereta terpakai semakin terdedah kepada perbelanjaan pembaikan sendirian yang membebankan.</p>
                    </Row>
                    <Row className='mt-3'>
                        <p>Diasaskan pada tahun 2022, eAutoGen menawarkan program waranti lanjutan bagi pemilik kereta berusia dan pembeli kereta terpakai dengan ciri-ciri yang direka untuk memberi manfaat kepada mereka melalui produk-produk yang lebih baik, kebolehcapaian dan kemampuan yang lebih tinggi.</p>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
</div>
  )
}
